import { Pipe, PipeTransform } from '@angular/core';
import { IconAndWord } from '@kp/past-reflections/reflection-history/image-icon-list/image-icon-list.component';
import { Question } from '@kp/question-set/models/question.model';

@Pipe({ name: 'imageWordListAnswer' })
export class ImageWordListAnswerPipe implements PipeTransform {
  transform(question: Question): IconAndWord[] {
    const selected = question.answer.values.filter((ans) => ans.value === true);
    return question.display.options
      .filter((option) => selected.some((ans) => ans.optionId === option.id))
      .map((option) => ({ icon: option.appImageUrl, word: option.text }));
  }
}
