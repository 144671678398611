import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { PastReflections } from '@kp/past-reflections/models/past-reflections.model';
import { PastReflectionsBloc } from '@kp/past-reflections/past-reflections.bloc';
import { QuestionSet } from '@kp/question-set/models/question-set.model';
import { DataStatus, Status } from '@ktypes/models';
import { ModalComponent } from '@kui';
import { MockComponent } from '@kutil/test';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kp-reflection-history',
  templateUrl: './reflection-history.component.html',
  styleUrls: ['./reflection-history.component.scss'],
})
export class ReflectionHistoryComponent implements OnInit, OnDestroy {
  public reflections: DataStatus<PastReflections>;
  public index: number;
  public status = Status;
  public hasReflections = false;
  public focusReflectionBack = false;
  public focusReflectionNext = false;
  public questionSets: QuestionSet[];
  private reflectionSubscription: Subscription;

  reflectionBackButton: HTMLElement;
  @ViewChild('reflectionBack', { static: false }) set reflectionBackContent(value: ElementRef) {
    this.reflectionBackButton = value && (value.nativeElement as HTMLElement);
  }

  reflectionNextButton: HTMLElement;
  @ViewChild('reflectionNext', { static: false }) set reflectionNextContent(value: ElementRef) {
    this.reflectionNextButton = value && (value.nativeElement as HTMLElement);
  }

  @Output() closeClicked = new EventEmitter();

  constructor(
    private _pastReflectionsBloc: PastReflectionsBloc,
    @Optional() private modalComponent?: ModalComponent
  ) {}

  ngOnInit() {
    this.index = 0;
    this._pastReflectionsBloc.getReflectionHistory();

    this.reflectionSubscription = this._pastReflectionsBloc.reflectionSubject$.subscribe((pastReflections) => {
      this.reflections = pastReflections;

      if (this.reflections && this.reflections.status === Status.done) {
        this.hasReflections = this.reflections?.data?.availableDates?.[0]?.[0] != null;
        this.calculateQuestionSets();
        setTimeout(() => {
          // update modal rotation focusable elements
          if (this.modalComponent) {
            this.modalComponent.updateFocusRotation();
          }

          if (this.focusReflectionBack) {
            if (this.reflectionBackButton) {
              this.reflectionBackButton.focus();
            } else if (this.reflectionNextButton) {
              this.reflectionNextButton.focus();
            }
          }
          if (this.focusReflectionNext) {
            if (this.reflectionNextButton) {
              this.reflectionNextButton.focus();
            } else if (this.reflectionBackButton) {
              this.reflectionBackButton.focus();
            }
          }
          this.focusReflectionBack = false;
          this.focusReflectionNext = false;
        }, 50);
      }
    });
  }

  ngOnDestroy(): void {
    this.reflectionSubscription.unsubscribe();
  }

  calculateQuestionSets() {
    const questionIds: Set<string> = new Set();
    const questionSets: QuestionSet[] = [];
    const currentPageQuestionSets = this.reflections.data.availableDates[this.index];

    if (currentPageQuestionSets) {
      for (let index = 0; index <= currentPageQuestionSets.length - 1; index++) {
        currentPageQuestionSets[index].pages = currentPageQuestionSets[index].pages.filter((page) => {
          page.questions = page.questions.filter((question) => {
            if (question.hasAnswer && !questionIds.has(question.id)) {
              questionIds.add(question.id);
              return true;
            }
            return false;
          });
          return page.questions.length > 0;
        });
        if (currentPageQuestionSets[index].pages.length > 0) {
          questionSets.push(currentPageQuestionSets[index]);
        }
      }
      this.questionSets = questionSets;
    } else {
      this.questionSets = [];
    }
  }

  onPageChanged() {
    this._pastReflectionsBloc.getForPage(this.index);
  }

  onForward() {
    if (this.index > 0) {
      this.index -= 1;
      this.onPageChanged();
    }

    if (this.index === 0) {
      this.onPageChanged();
    }
    this.focusReflectionBack = false;
    this.focusReflectionNext = true;
  }

  onBack(hasMoreQuestions: boolean) {
    if (hasMoreQuestions) {
      this.index += 1;
      this.onPageChanged();
    }
    this.focusReflectionBack = true;
    this.focusReflectionNext = false;
  }
}

export const MockReflectionHistoryComponent = MockComponent({
  selector: 'kp-reflection-history',
});
