import { AsyncPipe } from '@angular/common';
import { Component, OnInit, input, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateDirective, TranslatePipe } from '@kf-loc';
import { VerifyType } from '@ktypes/enums';
import { validateKeyIsNumber } from '@kutil';
import { MockComponent } from '@kutil/test';
import { SvgIconComponent } from 'angular-svg-icon';
import { EmailScreensBaseComponent } from '../../auth/email-screens-base/email-screens-base.component';
import { UserEmailComponent } from '../../auth/user-email/user-email.component';

interface CodeFormValue {
  code: string;
}

@Component({
  selector: 'kui-verify-code',
  standalone: true,
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss'],
  imports: [
    EmailScreensBaseComponent,
    SvgIconComponent,
    TranslatePipe,
    AsyncPipe,
    ReactiveFormsModule,
    RouterLink,
    UserEmailComponent,
    TranslateDirective,
  ],
})
export class VerifyCodeComponent implements OnInit {
  constructor() {
    this._codeControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.formatError =
        value == null ||
        (value?.length > 5 && (this._codeControl.touched || this._codeControl.dirty) && this._codeControl.invalid);
      this._userSubmitted = false;
    });
  }

  customInstructionText = input<string>();
  email = input<string>();
  haveAccountLink = input<string>();
  headerTitle = input<string>();
  noCodeLink = input<string>();
  verifyType = input<VerifyType>();
  verifyDisabled = input<boolean>();
  verifyErrorMessage = input<string>();
  verifyCodeChanged = output();
  verifyCodeSubmitted = output<CodeFormValue>();

  form: FormGroup;
  formatError = false;

  private _codeControl = new FormControl<string>('', Validators.pattern(/^\d{6}$/));
  private _userSubmitted = false;

  protected readonly VerifyType = VerifyType;

  ngOnInit(): void {
    this.form = new FormGroup({
      code: this._codeControl,
    });
  }

  onSubmit(value: CodeFormValue) {
    if (this._userSubmitted || this.verifyDisabled()) {
      // only allow the first one through
      return;
    }
    this._userSubmitted = true;
    if (this.form?.valid) {
      this.verifyCodeSubmitted.emit(value);
    }
  }

  codeChanged() {
    this.verifyCodeChanged.emit();
    this._userSubmitted = false;
  }

  handleKeydown($event: KeyboardEvent) {
    this.verifyCodeChanged.emit();
    this._userSubmitted = false;
    if (!validateKeyIsNumber($event)) {
      $event.preventDefault();
      return false;
    }
    return true;
  }
}

export const MockVerifyCodeComponent = MockComponent({
  selector: 'kui-verify-code',
  standalone: true,
  inputs: [
    'customInstructionText',
    'email',
    'haveAccountLink',
    'headerTitle',
    'noCodeLink',
    'verifyDisabled',
    'verifyType',
    'verifyErrorMessage',
  ],
  outputs: ['verifyCodeChanged', 'verifyCodeSubmitted'],
});
