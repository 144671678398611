import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { SharingValidation } from '@kp/sharing/sharing-validation';
import { DataStatus, Group, JsonObject, Status, StatusMessage, TokenResponse } from '@ktypes/models';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationApi extends BaseApi {
  public async validateGroupCode(groupCode: string): Promise<DataStatus<Group>> {
    if (!groupCode) {
      return generateError(
        groupCode,
        new HttpResponse({ body: 'Internal error - missing groupCode or generalToken.' }),
        new Group().deserialize({ groupCode })
      );
    }

    const url = this.buildUrl('/group', false, { groupCode });
    const request$ = this.performRequest<Group>(RequestType.GET, url);

    const response = await firstValueFrom<HttpResponse<Group>>(request$).catch((error: HttpResponse<Group>) => {
      console.warn(`Error getting validation status for group "${groupCode}": `, error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(
        Status.done,
        new StatusMessage(response.status, ''),
        new Group().deserialize(response.body)
      );
    }

    return generateError(groupCode, response, new Group().deserialize({ groupCode }));
  }

  public async validateEligibilityToken(token: string, source: string): Promise<DataStatus<TokenResponse>> {
    if (!token) {
      const defaultMessage = StatusMessage.generic();
      const errorResponse = new HttpResponse({ body: 'Internal error - missing groupCode or generalToken.' });
      return new DataStatus(
        Status.error,
        new StatusMessage(errorResponse?.status || defaultMessage.code, errorResponse?.body || defaultMessage.message),
        null
      );
    }
    const url = this.buildUrl('/validate/eligibility', false, { token, source });
    const request$ = this.performRequest<TokenResponse>(RequestType.GET, url);
    const response = await firstValueFrom<HttpResponse<TokenResponse>>(request$).catch((error): null => {
      console.warn('Error fetching validation status for eligibility token: ', error);
      return null;
    });
    if (response?.ok) {
      return new DataStatus<TokenResponse>(
        Status.done,
        new StatusMessage(response.status, ''),
        new TokenResponse().deserialize(response.body)
      );
    }
    return null;
  }

  public async validateSharingToken(token: string, source: string): Promise<DataStatus<SharingValidation>> {
    if (!token) {
      generateError(null, new HttpResponse<string>({ body: 'Internal error - missing token.' }), null);
    }
    const url = this.buildUrl('/validate', false, { token, source });
    const request$ = this.performRequest<SharingValidation>(RequestType.GET, url);
    const response = await firstValueFrom<HttpResponse<SharingValidation>>(request$).catch((error): null => {
      console.warn('Error fetching validation status for sharing token: ', error);
      return null;
    });
    if (response?.ok) {
      return new DataStatus<SharingValidation>(
        Status.done,
        new StatusMessage(response.status, ''),
        new SharingValidation().deserialize(response.body)
      );
    }
    return null;
  }
}

function generateError<T>(
  groupCode: string,
  response: HttpResponse<Group> | HttpResponse<string> | HttpErrorResponse,
  returnObject: T
): DataStatus<T> {
  const defaultMessage = StatusMessage.generic();

  return new DataStatus<T>(
    Status.error,
    new StatusMessage(
      response?.status || defaultMessage.code,
      ((response as HttpErrorResponse)?.error as JsonObject)?.['message'] ||
        (response as HttpErrorResponse)?.message ||
        (response as HttpResponse<Group> | HttpResponse<string>)?.body ||
        response?.statusText ||
        defaultMessage.message
    ),
    returnObject
  );
}
