import { CardItem } from './card-item.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

interface QuestItemTab {
  data: {
    card: JsonObject<CardItem>;
  };
  key: string;
}

export class QuestItem implements Deserializable<JsonObject, QuestItem> {
  constructor(
    public id?: string,
    public card?: CardItem,
    public isMilestone?: boolean,
    public order?: string
  ) {}

  deserialize(input: JsonObject): QuestItem {
    if (input == null) {
      return null;
    }

    const cardTab = (input['tabs'] as QuestItemTab[])?.find((tab) => (tab.key = 'guide')) as QuestItemTab;
    this.id = input['id'] as string;
    const card: JsonObject = (cardTab?.data?.card as JsonObject<CardItem>) || {};
    card['inQuest'] = true;
    this.card = new CardItem().deserialize(card);
    this.isMilestone = !!input['isMilestone'];
    this.order = input['order'] as string;
    return this;
  }
}
