import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { HttpApiErrorResponse, HttpStatusCode, RequestType } from '@ktypes/enums';
import {
  CardEvent,
  DataStatus,
  JsonObject,
  LocalResourcesProgram,
  LocalResourcesSearchItem,
  Status,
  StatusMessage,
} from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import pick from 'lodash/pick';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalResourcesApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    public dataStoreService: DataStoreService,
    public _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, dataStoreService, _environmentVariablesService);
  }

  createCardEvent(cardId: string, event: CardEvent): void {
    if (cardId != null) {
      const url = this.buildUrl(`/card/${cardId}/event`, true, {
        type: event?.requestType?.toLowerCase(),
      });

      const eventJson = event.serialize() as JsonObject<CardEvent>;
      let model: string[];
      if (eventJson.eventType) {
        model = ['deviceCreatedTimestamp', 'eventType', 'eventInfo'];
      } else {
        model = ['deviceCreatedTimestamp', 'isRepeated', 'cardId'];
      }

      const result = pick(eventJson, model);

      const request$ = this.performRequest(RequestType.POST, url, {
        requestBody: result,
        includeToken: true,
      }).pipe(
        catchError((error) => {
          console.warn('Failed creating card event: ', error);
          return of(null);
        })
      );
      firstValueFrom(request$).catch((error) => {
        console.warn('Error creating card event: ', error);
        return null;
      });
    }
  }

  async performLocalResourceSearch(
    searchTerm: string,
    zipCode?: string,
    setDefault?: boolean
  ): Promise<DataStatus<LocalResourcesSearchItem>> {
    const url = this.buildUrl('/search/local-resources', true);
    const body: JsonObject = {
      searchText: searchTerm,
      zip: zipCode,
      setDefault: setDefault,
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
    };
    const request$ = this.performRequest<LocalResourcesSearchItem>(RequestType.POST, url, {
      requestBody: body,
      includeToken: true,
    }).pipe(
      map(
        (response: HttpResponse<LocalResourcesSearchItem>): DataStatus<LocalResourcesSearchItem> =>
          new DataStatus<LocalResourcesSearchItem>(
            new LocalResourcesSearchItem().deserialize(response?.body) ? Status.done : Status.error,
            new StatusMessage(response?.body ? HttpStatusCode.OK : HttpStatusCode.BAD_REQUEST, ''),
            new LocalResourcesSearchItem().deserialize(response?.body)
          )
      ),
      catchError((error: HttpErrorResponse) => {
        console.warn('Failed trying to perform local resource search: ', error);
        return of(
          new DataStatus<LocalResourcesSearchItem>(
            Status.error,
            new StatusMessage(
              error?.status ?? HttpStatusCode.BAD_REQUEST,
              (error?.error as HttpApiErrorResponse)?.explanation ?? error?.statusText ?? 'Bad Request'
            ),
            null
          )
        );
      })
    );
    return firstValueFrom(request$).catch((error) => {
      console.warn('There was an error trying to perform local resource search: ', error);
      return Promise.reject(error);
    });
  }

  async getLocalResourceDetails(resourceId: string, zipCode: string): Promise<DataStatus<LocalResourcesProgram>> {
    const endpoint = `/search/local-resources/${resourceId}`;
    const request$ = this.performRequest<LocalResourcesProgram>(RequestType.POST, this.buildUrl(endpoint, true), {
      requestBody: { zip: zipCode },
      includeToken: true,
    }).pipe(
      map(
        (response: HttpResponse<LocalResourcesProgram>): DataStatus<LocalResourcesProgram> =>
          response?.ok
            ? new DataStatus<LocalResourcesProgram>(
                Status.done,
                null,
                new LocalResourcesProgram().deserialize(response.body)
              )
            : new DataStatus<LocalResourcesProgram>(Status.error, null, null)
      ),
      catchError((error) => {
        console.warn('Failed trying to get details on a local resource: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error) => {
      console.warn('There was an error trying to get details on a local resource: ', error);
      return new DataStatus(Status.error, null, error);
    });
  }
}
