<kp-detail-view-action
  [showEditableAction]="notification?.isEditing"
  [showCompactAction]="!notification?.isEditing"
  (trashIconClicked)="deleteReminder()"
  [saveDisabled]="saveDisabled"
  (saveClicked)="saveReminder()">
  <button class="reminder-row" (click)="showExpandedView()" compact>
    <svg-icon class="reminder" src="/assets/icons/notification.svg"></svg-icon>
    <p class="display-days">
      {{ selectedDays | translate | async }} &bull;
      {{ timeFromCardNotification }}
    </p>
    <svg-icon class="icon-button edit" src="/assets/icons/edit.svg"></svg-icon>
  </button>
  <div class="diamond-row" expanded>
    @for (day of dateTimeUtil.allDays; track day) {
      <kp-action-reminder-diamond
        [day]="day"
        [isSelected]="days?.includes(dateTimeUtil.weekDaysData[day].abbreviation)"
        (reminderDay)="updateDay($event)"></kp-action-reminder-diamond>
    }
    <kp-action-reminder-time-selector
      class="time-picker"
      [time]="timeToEdit"
      (timeEventEmitter)="updateTime($event)"></kp-action-reminder-time-selector>
  </div>
</kp-detail-view-action>
