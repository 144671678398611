import { Document } from '@contentful/rich-text-types';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class Insight implements Deserializable<JsonObject, Insight>, Serializable<JsonObject> {
  constructor(
    public userId?: string,
    public contentId?: string,
    public body?: Document,
    public delta?: number,
    public priority?: number
  ) {}

  deserialize(input: JsonObject): Insight {
    if (input == null) {
      return null;
    }

    this.userId = input['user_id'] as string;
    this.contentId = input['content_id'] as string;
    this.body = input['body'] as Document;
    this.delta = input['delta'] as number;
    this.priority = (input?.['priority'] as number) || 0;

    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['user_id'] = this.userId;
    json['content_id'] = this.contentId;
    json['body'] = this.body;
    json['delta'] = this.delta;
    json['priority'] = this.priority;
    return json;
  }
}
