import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardType } from '@kp/shared/components/cards/card.bloc';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-first-card-added',
  templateUrl: './first-card-added-modal.component.html',
  styleUrls: ['./first-card-added-modal.component.scss'],
})
export class FirstCardAddedComponent {
  @Input() cardType = CardType.action;
  @Output() closeClicked = new EventEmitter();
  cardModalType = CardType;
}

export const MockFirstCardAddedComponent = MockComponent({ selector: 'kp-first-card-added', inputs: ['cardType'] });
