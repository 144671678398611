<a
  routerLink="/purposeful-days"
  [attr.aria-label]="
    'Click link for more information about Purposeful Days. Link shows three diamonds indicating Purposeful Day status: ' +
      (complete
        ? 'Completed! All filled'
        : ' 1. Showed Up? ' +
          (showedUp ? 'done' : 'not yet') +
          ' 2. Checked In? ' +
          (checkedIn ? 'done' : 'not yet') +
          ' 3. Action Completed? ' +
          (actionCompleted ? 'done' : 'not yet')) +
      (streakLength > 0 ? ' Purposeful Day Streak: ' + streakLength : '')
      | translate
      | async
  "
  (click)="purposefulDaysClicked.emit()">
  <div class="purposeful-days-indicator" [ngClass]="{ 'purposeful-day-fulfilled': complete }">
    <div class="purposeful-days-diamond" [ngClass]="{ 'purposeful-day-item-fulfilled': showedUp }">
      @if (showedUp) {
        <div class="purposeful-days-inner-diamond"></div>
      }
    </div>
    <div class="bullet-separator"></div>
    <div class="purposeful-days-diamond" [ngClass]="{ 'purposeful-day-item-fulfilled': checkedIn }">
      @if (checkedIn) {
        <div class="purposeful-days-inner-diamond"></div>
      }
    </div>
    <div class="bullet-separator"></div>
    <div class="purposeful-days-diamond" [ngClass]="{ 'purposeful-day-item-fulfilled': actionCompleted }">
      @if (actionCompleted) {
        <div class="purposeful-days-inner-diamond"></div>
      }
    </div>
    @if (streakLength > 0) {
      <div class="bullet-separator"></div>
    }
    @if (streakLength > 0) {
      <div class="purposeful-days-streak-flame">
        <svg-icon src="/assets/icons/streak-flame.svg"></svg-icon>
        <span class="streak-number">{{ streakLength | translate | async }}</span>
      </div>
    }
  </div>
</a>

@if (showCelebrationModal) {
  <kui-modal
    [isOpen]="showCelebrationModal"
    (isOpenChange)="isCelebrationModalOpen()"
    (click)="showCelebrationModal = false"
    [canDismiss]="false"
    type="info">
    <kp-modal-purposeful-days (closeClicked)="showCelebrationModal = false"></kp-modal-purposeful-days>
  </kui-modal>
}
