import { Injectable } from '@angular/core';
import { FirstVisitApi } from '@kapi';
import { BrowserStorage } from '@kservice';
import { FirstVisitCase, LocalFirstVisitCase, RemoteFirstVisitCase } from '@ktypes/enums';
import { FirstVisit, LegacyFirstVisit, LocalFirstVisit, RemoteFirstVisit } from '@ktypes/models';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FirstVisitBloc {
  private _firstVisit = new FirstVisit();
  private _firstVisitSubject = new ReplaySubject<FirstVisit>(1);
  public firstCallMade = false;
  private _callCompleted = new ReplaySubject<void>();

  constructor(
    private _browserStorage: BrowserStorage,
    private _firstVisitApi: FirstVisitApi
  ) {}

  get hasSeen$(): Observable<FirstVisit> {
    void this._loadFromStorage();
    return this._firstVisitSubject.asObservable();
  }

  incrementFirstVisitValue(type: FirstVisitCase, browserStorageKey = ''): void {
    this.hasSeen$.pipe(take(1)).subscribe((firstVisit) => {
      // @ts-ignore TODO: Fix with followup story
      let value = (firstVisit[type] as number) ?? 0;
      if (browserStorageKey) {
        const legacyValue = parseInt(this._browserStorage.getItem(browserStorageKey) as string, 10) ?? 0;
        value = value >= legacyValue ? value : legacyValue;
      }
      this.updateFirstVisit(type, value + 1);
    });
  }

  private _loadFromStorage(shouldUpdateStream = true): Observable<void> {
    if (this.firstCallMade) {
      return this._callCompleted;
    }
    this.firstCallMade = true;
    this._firstVisitApi.getUiSettings().then((remoteFirstVisit) => {
      let localFirstVisit;
      if (!remoteFirstVisit?.webMigrated) {
        const legacyFirstVisit = new LegacyFirstVisit().deserialize(this._browserStorage.getObject('firstVisit', true));
        localFirstVisit = legacyFirstVisit.mapToLocalFirstVisit();
        remoteFirstVisit = new RemoteFirstVisit().deserialize({
          ...legacyFirstVisit.mapToRemoteFirstVisit(),
          ...remoteFirstVisit,
        });
        this._browserStorage.setObject('firstVisit', localFirstVisit, 30, true);
        remoteFirstVisit.webMigrated = true;
        Object.keys(remoteFirstVisit).forEach((key) => {
          // @ts-ignore TODO: Fix with followup story
          if (remoteFirstVisit[key] === false || remoteFirstVisit[key] === 0) {
            // @ts-ignore TODO: Fix with followup story
            delete remoteFirstVisit[key];
          }
        });
        void this._firstVisitApi.updateUiSettings(remoteFirstVisit);
      } else {
        localFirstVisit = new LocalFirstVisit().deserialize(this._browserStorage.getObject('firstVisit', true));
      }
      this._firstVisit = new FirstVisit().deserialize({ ...remoteFirstVisit, ...localFirstVisit });
      if (shouldUpdateStream) {
        this._firstVisitSubject.next(this._firstVisit);
      }
      this._callCompleted.next();
    });

    return this._callCompleted;
  }

  updateFirstVisit(firstVisitCase: FirstVisitCase, value: boolean | string | number = true): void {
    this._loadFromStorage()
      .pipe(take(1))
      .subscribe(() => {
        // guarding with an if to ensure that only things specified in FirstVisit model are updated
        if (Object.keys(LocalFirstVisitCase || {}).includes(firstVisitCase)) {
          // @ts-ignore TODO: Fix with followup story
          this._firstVisit[firstVisitCase] = value;
          this._browserStorage.setObject('firstVisit', this._firstVisit, 30, true);
        } else if (Object.keys(RemoteFirstVisitCase || {}).includes(firstVisitCase)) {
          // @ts-ignore TODO: Fix with followup story
          this._firstVisit[firstVisitCase] = value;
          const updateBody = {};
          // @ts-ignore TODO: Fix with followup story
          updateBody[firstVisitCase] = value;
          void this._firstVisitApi.updateUiSettings(updateBody);
        }
        this._firstVisitSubject.next(this._firstVisit);
      });
  }

  resetCachedFirstVisit() {
    this._firstVisit = new FirstVisit();
    this._callCompleted = new ReplaySubject<void>();
    this.firstCallMade = false;
    this._firstVisitSubject.next(this._firstVisit);
  }

  clear() {
    this.resetCachedFirstVisit();
    this._browserStorage.remove('firstVisit', true);
  }
}
