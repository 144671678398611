import { Component, Input } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-diamond',
  templateUrl: './diamond.component.html',
  styleUrls: ['./diamond.component.scss'],
})
export class DiamondComponent {
  @Input() appearDisabled = false;
  @Input() index: number;
  @Input() isDiamondCard = false;
  @Input() isDisabled = false;
  @Input() isMilestone = false;
  @Input() isSelected: boolean;
  @Input() milestoneDiamondIndicator = false;
  @Input() milestoneIndex: number;
  @Input() order: string; // Note: if translation is needed, translate before passing in
  @Input() text: string; // Note: if translation is needed, translate before passing in
}

export const MockDiamondComponent = MockComponent({
  selector: 'kp-diamond',
  inputs: [
    'appearDisabled',
    'index',
    'isDiamondCard',
    'isDisabled',
    'isMilestone',
    'isSelected',
    'milestoneDiamondIndicator',
    'milestoneIndex',
    'order',
    'text',
  ],
});
