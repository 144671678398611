import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DisplayOption } from '@kp/question-set/models/display-option.model';

@Component({
  selector: 'kp-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
})
export class CheckboxListComponent {
  @Input() min: number;
  @Input() max: number;
  @Input() displayOptions: DisplayOption[];

  @Output() checkboxesChecked: EventEmitter<DisplayOption[]> = new EventEmitter<DisplayOption[]>();

  private _numberOfCheckboxesChecked = 0;

  getInstructions(min: number, max: number): string {
    if (min != null || max != null) {
      if (this._numberOfCheckboxesChecked === 0) {
        if (min == null && max != null) {
          return `Select up to ${max}`;
        } else if (min != null && max == null) {
          return `Select at least ${min}`;
        } else if (min === max && min != null) {
          return `Select ${min}`;
        } else if (min != null && max != null && min !== max) {
          return `Select between ${min} and ${max}`;
        }
        return '';
      } else {
        return max == null
          ? `${this._numberOfCheckboxesChecked} selected`
          : `${this._numberOfCheckboxesChecked} of ${max} selected`;
      }
    } else {
      return '';
    }
  }

  onCheckboxChecked(checkedStatus: boolean, option: DisplayOption) {
    if (this.displayOptions) {
      option.isSelected = checkedStatus;
      if (this.max === 1) {
        // make checkboxes work like radio buttons when max === 1
        this.displayOptions.forEach((displayOption) => {
          if (displayOption.id !== option.id) {
            displayOption.isSelected = false;
          }
        });
      }
      this._numberOfCheckboxesChecked = this.displayOptions.filter((displayOption) => displayOption.isSelected).length;
      this.checkboxesChecked.emit(this.displayOptions);
    }
  }

  isLessThanMax(): boolean {
    return this.max == null || this._numberOfCheckboxesChecked < this.max;
  }
}
