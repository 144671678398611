@if (apps?.length > 0) {
  <div class="more-apps" [class.active]="open">
    <button
      class="more-apps-icon"
      [attr.aria-label]="(open ? 'Close more apps launcher' : 'Open more apps launcher') | translate | async"
      (click)="toggleAppDrawer()">
      <svg-icon src="/assets/shared/images/icons/more-apps.svg"></svg-icon>
    </button>
  </div>
}
<div [class.active-triangle]="open"></div>
<div class="apps-container" [class.show-apps-container]="open">
  @for (app of apps; track app) {
    @if (app?.currentApp) {
      <a class="app-icon-container" [routerLink]="app?.homepage" (click)="onAppClick(app)">
        <div class="app-icon">
          <svg-icon [src]="app?.icon"></svg-icon>
        </div>
        <div class="app-logo">
          <svg-icon [src]="app?.logo"></svg-icon>
        </div>
      </a>
    }
    @if (!app?.currentApp) {
      <a class="app-icon-container" (click)="onAppClick(app)" [href]="app?.link" target="_blank">
        <div class="app-icon">
          <svg-icon [src]="app?.icon"></svg-icon>
        </div>
        <div class="app-logo">
          <svg-icon [src]="app?.logo"></svg-icon>
        </div>
      </a>
    }
  }
</div>
