export enum CardItemState {
  DISCOVER_DEFAULT = 'DISCOVER_DEFAULT',
  DISCOVER_ADDED_TO_ACT = 'DISCOVER_ADDED_TO_ACT',
  ACT_TO_DO = 'ACT_TO_DO',
  ACT_ANIMATION = 'ACT_ANIMATION',
  ACT_COMPLETE = 'ACT_COMPLETE',
  ACT_REMOVED = 'ACT_REMOVED',
  ERROR_COMPLETE = 'ERROR_COMPLETE',
  ERROR_TRACK = 'ERROR_TRACK',
}
