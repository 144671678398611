import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class Streak implements Deserializable<JsonObject, Streak>, Serializable<JsonObject> {
  constructor(
    public longest?: number,
    public current?: number
  ) {}

  deserialize(input: JsonObject): Streak {
    if (input == null) {
      return null;
    }

    this.longest = input['max_streak'] as number;
    this.current = input['current_streak'] as number;
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['max_streak'] = this.longest;
    json['current_streak'] = this.current;

    return json;
  }
}
