import { Injectable } from '@angular/core';
import { CardItem, JsonObject, LocalResourcesProgram } from '@ktypes/models';
import { isOfType } from '@kutil';

@Injectable({
  providedIn: 'root',
})
export class CardCollectionService {
  cardsById: JsonObject<CardItem | LocalResourcesProgram> = {};

  addCards(cards: CardItem[] | LocalResourcesProgram[]) {
    cards?.forEach((card) => {
      this.cardsById[isOfType<LocalResourcesProgram, CardItem>(card, 'contentId') ? card?.contentId : card?.id] = card;
    });
  }

  addCard(card: CardItem | LocalResourcesProgram) {
    this.addCards([card]);
  }
}
