import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent {
  @Input() isShortText = false;
  @Input() maxCharacters: number;
  @Input() placeholderText: string;
  @Input() textValue = '';
  // `autofocus` cannot be alphabetical because it needs to come after `isShortText`
  @Input() autofocus = !this.isShortText;

  @Output() textAreaAnswer = new EventEmitter<string>();

  onTyping(text: string): void {
    this.textAreaAnswer.emit(text);
  }
}

export const MockTextFieldComponent = MockComponent({
  selector: 'kp-text-field',
  inputs: ['autofocus', 'isShortText', 'maxCharacters', 'placeholderText', 'textValue'],
});
