import { Injectable } from '@angular/core';
import { AnalyticEvent, AnalyticsBloc, AnalyticsPageName } from '@kanalytics';
import { SearchState } from '@kp/search/search-state.enum';
import { SearchApi } from '@kp/search/search.api';
import { Search, SearchType } from '@kp/search/search.model';
import { CardCollectionService } from '@kp/shared/components/cards/card-collection.service';
import { CardScreen } from '@ktypes/enums';
import { CardCollection, CardItem, DataStatus, JsonObject, PredefinedSearch, Status } from '@ktypes/models';
import { EnumUtil } from '@kutil';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchBloc {
  private _lastSearchId$ = new BehaviorSubject<string>(null);
  private _lastSearchType$ = new BehaviorSubject<string>(null);
  private _newSearchSubject$ = new BehaviorSubject<boolean>(true);
  private _predefinedSearch$ = new BehaviorSubject<DataStatus<PredefinedSearch[]>>(null);
  private _searchDisplayText$ = new BehaviorSubject<string>(null);
  private _searchState$ = new BehaviorSubject<SearchState>(SearchState.BASE_START);
  private _searchStatus$ = new BehaviorSubject<DataStatus<Search>>(null);

  // TODO: Do we want to allow these properties to be settable externally to allow
  //  Search Components to communicate via the searchBloc, or should these be pushed
  //  into the BLoC pattern (maybe _searchStatus BehaviorSubject?)
  currentSearchState: SearchState = SearchState.BASE_START;
  fadeOut = false;
  displayAnimation = true;
  initialSearchScreen: CardScreen;
  searchScreen: CardScreen;
  searchText = '';

  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _cardCollectionService: CardCollectionService,
    private _searchApi: SearchApi
  ) {}

  get searchResults$() {
    return this._searchStatus$.asObservable();
  }

  get predefinedSearchStatus$() {
    return this._predefinedSearch$.asObservable();
  }

  get searchCardCollection$() {
    return this.searchResults$.pipe(
      map((searchResultsStatus) => {
        const search = searchResultsStatus?.data;
        if (search?.searchResults) {
          const collection: JsonObject = {};

          search.searchResults.forEach((card) => {
            collection[card.id] = card;
          });

          return new CardCollection(null, null, collection);
        }
        return null;
      })
    );
  }

  get searchState$() {
    return this._searchState$.asObservable();
  }

  get lastSearchId() {
    return this._lastSearchId$.value;
  }

  get lastSearchType() {
    return EnumUtil.getByValue(SearchType, this._lastSearchType$.value);
  }

  get isBaseState(): boolean {
    return [SearchState.BASE_START, SearchState.BASE_FOCUSED_EMPTY, SearchState.BASE_WITH_TEXT].includes(
      this._searchState$.value
    );
  }

  get isBaseState$(): Observable<boolean> {
    return this.searchState$.pipe(
      map((searchState) => {
        return [SearchState.BASE_START, SearchState.BASE_FOCUSED_EMPTY, SearchState.BASE_WITH_TEXT].includes(
          searchState
        );
      }),
      distinctUntilChanged()
    );
  }

  get isFocusedState(): boolean {
    return [SearchState.BASE_FOCUSED_EMPTY, SearchState.SEARCH_FOCUSED_EMPTY].includes(this._searchState$.value);
  }

  get isFocusedState$(): Observable<boolean> {
    return this.searchState$.pipe(
      map((searchState) => {
        return [SearchState.BASE_FOCUSED_EMPTY, SearchState.SEARCH_FOCUSED_EMPTY].includes(searchState);
      }),
      distinctUntilChanged()
    );
  }

  get isSearchState(): boolean {
    return [
      SearchState.SEARCH_FOCUSED_EMPTY,
      SearchState.SEARCH_WITH_TEXT,
      SearchState.SEARCH_REQUESTED,
      SearchState.SEARCH_RESULTS,
    ].includes(this._searchState$.value);
  }

  get isSearchState$(): Observable<boolean> {
    return this.searchState$.pipe(
      map((searchState) => {
        return [
          SearchState.SEARCH_FOCUSED_EMPTY,
          SearchState.SEARCH_WITH_TEXT,
          SearchState.SEARCH_REQUESTED,
          SearchState.SEARCH_RESULTS,
        ].includes(searchState);
      }),
      distinctUntilChanged()
    );
  }

  get searchResultsValue(): DataStatus<Search> {
    return this._searchStatus$.value;
  }

  get searchDisplayText$(): Observable<string> {
    return this._searchDisplayText$.asObservable();
  }

  get newSearchSubject$(): Observable<boolean> {
    return this._newSearchSubject$.asObservable();
  }

  handleSearchRequest(search?: Search): void {
    if (search != null) {
      this.performSearch(search);
    } else {
      this.resetSearch();
    }
  }

  performSearch(search: Search): void {
    if (this._searchStatus$.getValue()?.status === Status.starting) {
      // don't start another search if one is already in flight
      return;
    }
    this.handleSearchStateChange(SearchState.SEARCH_REQUESTED);
    this.searchText = search.searchText;
    this._searchStatus$.next(new DataStatus<Search>(Status.starting, null, null));
    this._searchDisplayText$.next(null);
    this._searchApi.performSearch(search).then((searchResponse: DataStatus<Search>) => {
      this._lastSearchType$.next(EnumUtil.getByValue(SearchType, search.searchType));
      this._searchDisplayText$.next(search.searchDisplayText || searchResponse?.data?.searchText || null);
      this.publishUpdatedSearchResultsToStream(searchResponse);
    });
  }

  resetSearch(): void {
    this.searchText = '';
    this._searchDisplayText$.next(null);
    this.searchScreen = this.initialSearchScreen;
    this.handleSearchStateChange(null);
    this._searchStatus$.next(new DataStatus<Search>(Status.done, null, null));
  }

  handleSearchStateChange(newSearchState: SearchState): void {
    if (
      this.currentSearchState === SearchState.SEARCH_REQUESTED &&
      this.searchResultsValue?.status === Status.starting
    ) {
      // don't change state while a search request is in progress
      return;
    }
    if (newSearchState !== this.currentSearchState && newSearchState != null) {
      this._searchState$.next(newSearchState);
      this.currentSearchState = newSearchState;
    } else if (newSearchState == null) {
      this._searchState$.next(SearchState.BASE_START);
      this.currentSearchState = SearchState.BASE_START;
    }
  }

  publishUpdatedSearchResultsToStream(searchResponse: DataStatus<Search>, isCardBlocUpdate = false): void {
    this._newSearchSubject$.next(this.lastSearchId !== searchResponse?.data?.id);
    if (searchResponse?.data != null && searchResponse.status === Status.done) {
      const analyticsJson = {
        search_id: searchResponse.data.id,
        search_type: searchResponse.data.searchType,
        search_page: this.initialSearchScreen,
      };
      this._searchState$.next(SearchState.SEARCH_RESULTS);
      const searchType: SearchType = EnumUtil.getByValue(SearchType, this.lastSearchType);
      if (searchResponse.data.searchType == null && searchType != null) {
        searchResponse.data = searchResponse.data.copyWith(searchType);
      }
      this._searchStatus$.next(searchResponse);
      if (isCardBlocUpdate !== true) {
        this._analyticsBloc.sendEvent(
          this._analyticsBloc.createEventFromInteraction({
            page: AnalyticsPageName.search,
            event: AnalyticEvent.search_initiated,
            meta: analyticsJson,
          })
        );
      }
      this._lastSearchId$.next(searchResponse.data.id);
      this._cardCollectionService.addCards(searchResponse.data.searchResults);
    }
  }

  screenChanged(newScreen: CardScreen) {
    if (newScreen) {
      this.searchScreen = newScreen;
    }
  }

  predefinedSearchSuggestions(): void {
    this._searchApi.predefinedSearchSuggestions().then((predefinedSearchStatus) => {
      this._predefinedSearch$.next(predefinedSearchStatus);
    });
  }

  private _getCardItemsMap(cards: CardItem[]) {
    if (cards != null) {
      const cardItemsMap: JsonObject = {};
      cards.forEach((card) => {
        cardItemsMap[card.id] = card;
      });
      return cardItemsMap;
    } else {
      return {};
    }
  }
}
