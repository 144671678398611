import { Answer } from '@kp/question-set/models/answer.model';
import { QuestionDisplay } from '@kp/question-set/models/question-display.model';
import { QuestionSettings } from '@kp/question-set/models/question-settings.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export enum QuestionType {
  word_list = 'word_list',
  long_text = 'long_text',
  short_text = 'short_text',
  radio_button = 'radio_button',
  image_icon_selection = 'image_icon_selection',
  image_tile = 'image_tile',
  image_word_list = 'image_word_list',
  slider = 'slider',
  checkbox = 'checkbox',
  likert = 'likert',
}

export const questionTypeText: QuestionType[] = [QuestionType.long_text, QuestionType.short_text];
export const questionTypeSingleOption: QuestionType[] = [QuestionType.radio_button, QuestionType.slider];
export const questionTypeMultipleOptions: QuestionType[] = [
  QuestionType.word_list,
  QuestionType.image_icon_selection,
  QuestionType.image_tile,
  QuestionType.image_word_list,
  QuestionType.checkbox,
];

export enum DataType {
  ordinal = 'ordinal',
  numeric = 'numeric',
}

export enum QuestionLogicKey {
  best_self_question_v1 = 'best-self-question-v1',
  life_purpose_question_v1 = 'onboarding-purpose_question-v1',
  reflection_intention_question_2 = 'reflection_intention_question_2',
  roles_question_v1 = 'onboarding-roles_question-v1',
  work_purpose_question_v1 = 'work-purpose-question-v1',
}

export class Question implements Deserializable<JsonObject, Question>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public display?: QuestionDisplay,
    public settings?: QuestionSettings,
    public logicKey?: string | QuestionLogicKey,
    public refKey?: string,
    public inputType?: QuestionType,
    public dataType?: DataType,
    public answer?: Answer,
    public questionSetId?: string
  ) {}

  get hasAnswer() {
    const answerValues = this.answer?.values || [];
    const hasAnswerValues = answerValues.filter((answerValue) => answerValue != null).length > 0;
    const isMultipleChoice = [QuestionType.checkbox, QuestionType.image_word_list, QuestionType.word_list].includes(
      this.inputType
    );
    let hasMultipleChoiceAnswersAndMatchingOption = false;
    if (isMultipleChoice) {
      const selected = this.answer?.values?.filter((ans) => (ans?.value as boolean) === true) || [];
      const hasMultipleChoiceAnswers = answerValues.some((answerValue) => answerValue?.value);
      const hasMatchingMultipleChoiceOption = this.display?.options?.some((option) =>
        selected.some((ans) => ans?.optionId === option.id)
      );
      hasMultipleChoiceAnswersAndMatchingOption = hasMultipleChoiceAnswers && hasMatchingMultipleChoiceOption;
    }
    return (
      hasAnswerValues && ((isMultipleChoice && hasMultipleChoiceAnswersAndMatchingOption) || answerValues[0]?.value)
    );
  }

  get isTextInput() {
    return this.inputType === QuestionType.long_text || this.inputType === QuestionType.short_text;
  }

  get isNumeric() {
    return this.dataType === DataType.numeric;
  }

  deserialize(input: JsonObject): Question {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.display = new QuestionDisplay().deserialize(input['display']);
    this.settings = new QuestionSettings().deserialize(input['settings']);
    this.logicKey = input['logicKey'] as string;
    this.refKey = input['refKey'] as string;
    this.inputType = input['inputType'] as QuestionType;
    this.dataType = input['dataType'] as DataType;
    this.answer = new Answer().deserialize(input['answer'] ?? input['previousAnswer']);
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['display'] = this.display?.serialize();
    json['settings'] = this.settings?.serialize();
    json['logicKey'] = this.logicKey;
    json['refKey'] = this.refKey;
    json['inputType'] = this.inputType;
    json['dataType'] = this.dataType;
    json['answer'] = this.answer?.serialize();
    return json;
  }
}
