import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { Product } from '@ktypes/enums';

const defaultUrls = new Map([
  [Product.purposeful, '/today'],
  [Product.resourceful, '/resources'],
]);

@Injectable({
  providedIn: 'root',
})
export class ReturnUrlService {
  constructor(private _environmentVariablesService: EnvironmentVariablesService) {
    this._defaultUrl = defaultUrls.get(this._environmentVariablesService.product) ?? '';
  }

  private readonly _defaultUrl: string;
  private _savedReturnUrl = '';

  get savedUrl() {
    return this._savedReturnUrl;
  }

  get isDefaultUrl() {
    return this._savedReturnUrl === this._defaultUrl || this._savedReturnUrl === '' || this._savedReturnUrl === '/';
  }

  setSavedUrl(url: string) {
    this._savedReturnUrl = decodeURIComponent(url || '') || '';
  }

  unsetSavedUrl() {
    this._savedReturnUrl = '';
  }
}
