import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AnalyticEvent, AnalyticsBloc, AnalyticsCategory, AnalyticsEvent, AnalyticsPageName } from '@kanalytics';
import { FirstVisitBloc, TagBloc } from '@kbloc';
import { CardBloc } from '@kp/shared/components/cards/card.bloc';
import { GlobalElementsService } from '@kp/shared/services/global-elements.service';
import { DataStoreService, SharedConstants } from '@kservice';
import { FirstVisitCase, UserType } from '@ktypes/enums';
import { MockComponent } from '@kutil/test';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-account-creation-banner',
  templateUrl: './account-creation-banner.component.html',
  styleUrls: ['./account-creation-banner.component.scss'],
})
export class AccountCreationBannerComponent implements OnInit, OnDestroy {
  accountCreationModelVisible = false;
  private _destroy$ = new Subject<void>();
  openAccountCreationModal$ = new BehaviorSubject<boolean>(false);
  requireAccountCreation = false;

  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _cardBloc: CardBloc,
    private _dataStoreService: DataStoreService,
    @Inject(DOCUMENT) private document: Document,
    private _firstVisitBloc: FirstVisitBloc,
    private _globalElementsService: GlobalElementsService,
    private _renderer: Renderer2,
    private _tagBloc: TagBloc
  ) {}

  ngOnInit(): void {
    combineLatest([
      this._globalElementsService.showAccountCreationBanner$,
      this._firstVisitBloc.hasSeen$,
      this._cardBloc.showAccountCreationModal$,
      this._tagBloc.requireResourcefulAccountCreation$,
      this._dataStoreService.user$,
      this.openAccountCreationModal$,
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        ([
          showAccountCreationBanner,
          hasSeen,
          showAccountCreationModal,
          requireResourcefulAccountCreation,
          user,
          openAccountCreationModal,
        ]) => {
          this.requireAccountCreation = requireResourcefulAccountCreation && user?.type !== UserType.user;
          this.accountCreationModelVisible =
            this.requireAccountCreation ||
            openAccountCreationModal ||
            (showAccountCreationBanner && (!hasSeen.dismissedAccountCreationModal || showAccountCreationModal));
        }
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isAccountCreationModalOpen(dismissedModalType: string) {
    if (this.requireAccountCreation) {
      return;
    }
    if (dismissedModalType !== SharedConstants.actionChoice.clickToCreateAccount) {
      this._firstVisitBloc.updateFirstVisit(FirstVisitCase.dismissedAccountCreationModal);
    }
    this._renderer.removeClass(this.document.documentElement, 'no-scroll');
    this._cardBloc.setShowAccountCreationModal(false);
    this.openAccountCreationModal$.next(false);
    this.accountCreationModelVisible = false;

    const closeAnalyticEvent = new AnalyticsEvent().deserialize({
      page: AnalyticsPageName.resources,
      category: AnalyticsCategory.interaction,
      event: AnalyticEvent.account_creation_reaction,
      meta: dismissedModalType,
    });
    this._analyticsBloc.sendEvent(closeAnalyticEvent);
  }

  learnMore() {
    this.openAccountCreationModal$.next(true);
  }

  createAccount() {
    const createAccountAnalyticsEvent = new AnalyticsEvent().deserialize({
      page: AnalyticsPageName.resources,
      category: AnalyticsCategory.interaction,
      event: AnalyticEvent.account_creation_reaction,
      meta: SharedConstants.actionChoice.clickBannerToCreateAccount,
    });
    this._analyticsBloc.sendEvent(createAccountAnalyticsEvent);
  }
}

export const MockAccountCreationBannerComponent = MockComponent({
  selector: 'kp-account-creation-banner',
});
