import { Deserializable } from './deserializable.model';

export class PhoneNumber implements Deserializable<string, PhoneNumber> {
  constructor(
    public countryCode?: string,
    public areaCode?: string,
    public centralOfficeCode?: string,
    public lineNumber?: string,
    public phoneNumberWithoutExtension?: string,
    public extension?: string,
    public rawPhoneNumber?: string
  ) {}

  deserialize(input: string): PhoneNumber {
    if (input == null || input === '') {
      return null;
    }
    const phoneNumberParts = input.match(
      /^(1)?[^A-Za-z0-9]*([A-Za-z0-9]{3})[^A-Za-z0-9]*([A-Za-z0-9]{3})[^A-Za-z0-9]*([A-Za-z0-9]{4})\D*(\d.*)?/
    );
    this.countryCode = phoneNumberParts ? phoneNumberParts[1] : null;
    this.areaCode = phoneNumberParts ? phoneNumberParts[2] : null;
    this.centralOfficeCode = phoneNumberParts ? phoneNumberParts[3] : null;
    this.lineNumber = phoneNumberParts ? phoneNumberParts[4] : null;
    this.extension = phoneNumberParts ? phoneNumberParts[5] : null;
    this.phoneNumberWithoutExtension =
      phoneNumberParts && this.areaCode && this.centralOfficeCode && this.lineNumber
        ? (this.countryCode ? this.countryCode + '-' : '') +
          this.areaCode +
          '-' +
          this.centralOfficeCode +
          '-' +
          this.lineNumber
        : input;
    this.rawPhoneNumber = input;

    return this;
  }
}
