import { DateTimeUtil, EnumUtil, assertIsOfType, isOfType } from '@kutil';
import { CardEventType, CardRequestType } from '../enums';
import { CardItem } from './card-item.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LocalResourcesProgram } from './local-resources-program.model';
import { Serializable } from './serializable.model';

export class CardEvent implements Deserializable<JsonObject, CardEvent>, Serializable<JsonObject> {
  public deviceCreatedTimestamp: string;

  constructor(
    public id?: string,
    public card?: CardItem | LocalResourcesProgram,
    public type?: CardEventType,
    public requestType?: CardRequestType,
    public eventInfo?: JsonObject
  ) {
    this.deviceCreatedTimestamp = DateTimeUtil.formatInLocal();
    if ((eventInfo ?? false)?.constructor?.name !== 'Object') {
      this.eventInfo = {};
    }
  }

  private _serverEventMap = new Map([
    [CardEventType.DISCOVER_VIEW, 'explore_card_view_web'],
    [CardEventType.REFLECT_VIEW, 'reflect_card_view'],
    [CardEventType.SEARCH_VIEW, 'search_card_view'],
    [CardEventType.SEARCH_OPEN, 'search_card_open'],
    [CardEventType.OPEN, 'detail_open'],
    [CardEventType.CLOSE, 'detail_close'],
    [CardEventType.MORE, 'card_more'],
    [CardEventType.LESS, 'card_less'],
    [CardEventType.NEUTRAL, 'card_neutral'],
    [CardEventType.COMPLETE, 'habit_completed'],
    [CardEventType.QUEST_CARD_COMPLETE, 'quest_card_completed'],
    [CardEventType.QUEST_CARD_VIEW, 'quest_card_view'],
    [CardEventType.QUEST_CARD_OPEN, 'quest_card_open'],
    [CardEventType.QUEST_CARD_CLOSE, 'quest_card_close'],
    [CardEventType.SEARCH_VIEW, 'search_card_viewed'],
    [CardEventType.SEARCH_OPEN, 'search_card_opened'],
    [CardEventType.LOCAL_RESOURCE_PRESENTED, 'local_resource_presented'],
    [CardEventType.LOCAL_RESOURCE_VIEWED, 'local_resource_viewed'],
    [CardEventType.LOCAL_RESOURCE_OPENED, 'local_resource_opened'],
    [CardEventType.LOCAL_RESOURCE_MORE, 'local_resource_more'],
    [CardEventType.LOCAL_RESOURCE_LESS, 'local_resource_less'],
    [CardEventType.LOCAL_RESOURCE_NEUTRAL, 'local_resource_neutral'],
    [CardEventType.LOCAL_RESOURCE_CLOSED, 'local_resource_closed'],
    [CardEventType.LOCAL_RESOURCE_NEXT_STEP_WEBSITE, 'local_resource_next_step_website'],
    [CardEventType.LOCAL_RESOURCE_NEXT_STEP_PHONE, 'local_resource_next_step_phone'],
    [CardEventType.LOCAL_RESOURCE_NEXT_STEP_EMAIL, 'local_resource_next_step_email'],
    [CardEventType.LOCAL_RESOURCE_NEXT_STEP_DIRECTIONS, 'local_resource_next_step_directions'],
    [CardEventType.LOCAL_RESOURCE_SEARCH_OPENED, 'local_resource_search_opened'],
  ]);

  deserialize(input: JsonObject, existingData?: CardEvent): CardEvent {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.card = (input['card'] as CardItem) || (input['program'] as LocalResourcesProgram);
    this.type = input['type'] as CardEventType;
    this.requestType = input['requestType'] as CardRequestType;
    this.eventInfo = { ...input['eventInfo'] } as JsonObject;

    return this;
  }

  serialize(): JsonObject<CardEvent> {
    const cardEvent: JsonObject = {
      // TODO: is this a false assumption that the CardEvent ID is the same as the Card ID?
      cardId: this.id,
      isRepeated: isOfType<CardItem, LocalResourcesProgram>(this.card, 'isRepeated') ? this.card.isRepeated : null,
      deviceCreatedTimestamp: this.deviceCreatedTimestamp,
      eventInfo: this.eventInfo,
    };
    if (this._serverEventMap.get(this.type)) {
      cardEvent.eventType = this._serverEventMap.get(this.type);
      cardEvent.value = this._getValueForType(this.type);
    }
    return cardEvent as JsonObject<CardEvent>;
  }

  clone() {
    return new CardEvent().deserialize({ ...this });
  }

  private _getValueForType(_type: CardEventType) {
    switch (_type) {
      case CardEventType.MORE:
      case CardEventType.LESS:
      case CardEventType.NEUTRAL:
      case CardEventType.LOCAL_RESOURCE_MORE:
      case CardEventType.LOCAL_RESOURCE_LESS:
      case CardEventType.LOCAL_RESOURCE_NEUTRAL:
        return isOfType<CardItem, LocalResourcesProgram>(this.card, 'isRepeated')
          ? EnumUtil.getByValue(CardEventType, this.card.userState.preference)
          : EnumUtil.getByValue(CardEventType, this.card.preference);
      case CardEventType.COMPLETE:
      case CardEventType.UNCOMPLETE:
      case CardEventType.QUEST_CARD_COMPLETE:
        assertIsOfType<CardItem, LocalResourcesProgram>(this.card, 'isRepeatable');
        return this.card.userState.isCompleted;
      case CardEventType.TRACK:
      case CardEventType.UNTRACK:
        assertIsOfType<CardItem, LocalResourcesProgram>(this.card, 'isRepeatable');
        return this.card.userState.isTracking;
      case CardEventType.DISCOVER_VIEW:
      case CardEventType.REFLECT_VIEW:
      case CardEventType.QUEST_CARD_VIEW:
      case CardEventType.OPEN:
      case CardEventType.QUEST_CARD_OPEN:
      case CardEventType.CLOSE:
      case CardEventType.QUEST_CARD_CLOSE:
      case CardEventType.LOCAL_RESOURCE_OPENED:
      case CardEventType.LOCAL_RESOURCE_VIEWED:
      case CardEventType.LOCAL_RESOURCE_CLOSED:
        return true;
      default:
        break;
    }
    return null;
  }
}
