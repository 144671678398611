import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TagBloc } from '@kbloc';
import { CardEventType } from '@ktypes/enums';
import { CardItem, SocialChallenge, SocialChallengeState, Status } from '@ktypes/models';
import { EMPTY, switchMap, withLatestFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SocialChallengeBloc } from './social-challenge.bloc';

export const CANCEL_ANIMATION_TIME = 1000;

@Injectable({
  providedIn: 'root',
})
export class SocialChallengeCompletion {
  constructor(
    private _socialChallengeBloc: SocialChallengeBloc,
    private _router: Router,
    private _tagBloc: TagBloc
  ) {}

  private _getChallengesStart: number;

  checkForCompletion(card: CardItem, cardEventType: CardEventType): void {
    const cardDomainKeys = card.domains?.map((domain) => domain.key) ?? [];
    if (cardDomainKeys.length === 0 || !card.isActionable) {
      // don't do anything if there aren't any domains to match or card is not actionable
      return;
    }

    // Filter if challenges are not enabled
    this._tagBloc.socialChallengeEnabled$
      .pipe(
        filter((socialChallengesEnabled) => socialChallengesEnabled),
        switchMap(() => {
          // each time this method is called (on card completion), ensure we have challenges loaded (and
          // load them if not), then grab the list of active challenges and check if card domains matched
          return this._socialChallengeBloc.challengesStatus$.pipe(
            filter((challengeStatus) => challengeStatus.status !== Status.starting),
            switchMap((challengeStatus) => {
              this._getChallengesStart = Date.now();
              // local is set initially, no challenges have been requested, get now
              if (challengeStatus?.status === Status.local) {
                this._socialChallengeBloc.getChallenges();
                return EMPTY;
              }
              return this._socialChallengeBloc
                .getChallengesByState$(SocialChallengeState.active)
                .pipe(
                  withLatestFrom(
                    this._socialChallengeBloc.currentChallenge$,
                    this._socialChallengeBloc.mostRecentJoinedChallenge$
                  )
                );
            })
          );
        }),
        take(1)
      )
      .subscribe(([challenges, currentChallenge, mostRecentJoinedChallenge]) => {
        const getChallengesEnd = Date.now();
        const totalTime = getChallengesEnd - this._getChallengesStart;
        if (this._getChallengesStart && totalTime > CANCEL_ANIMATION_TIME) {
          // don't show the animation if had to get challenges and took more than CANCEL_ANIMATION_TIME
          delete this._getChallengesStart;
          console.warn('Took too long updating challenges, aborting celebration modal check');
          return;
        }
        delete this._getChallengesStart;
        const matchedChallenges = challenges?.filter(
          (challenge) => challenge.individual.status.enrolled && cardDomainKeys.includes(challenge.domain.key)
        );
        if (matchedChallenges?.length > 0) {
          const matchedChallenge = this._getMatchedChallenge(
            matchedChallenges,
            currentChallenge != null ? currentChallenge.id : null,
            mostRecentJoinedChallenge != null && this._router.url.includes('today')
              ? mostRecentJoinedChallenge.id
              : null
          );
          if (matchedChallenge) {
            this._socialChallengeBloc.updateChallengeStateBasedOnActionChange(matchedChallenge, cardEventType);
          }
        }
        // on no match, do nothing
      });
  }

  private _getMatchedChallenge(
    matchedChallenges: SocialChallenge[],
    currentChallengeId?: string,
    mostRecentJoinedChallengeId?: string
  ): SocialChallenge {
    // If there is a current challenge, use that
    if (currentChallengeId) {
      const currentChallengeMatch = matchedChallenges.find((challenge) => challenge?.id === currentChallengeId);
      if (currentChallengeMatch) {
        return currentChallengeMatch;
      }
    }
    // otherwise if there is a mostRecentJoined challenge (only sent if on "today" route), use that
    if (mostRecentJoinedChallengeId) {
      const mostRecentJoinedChallengeMatch = matchedChallenges.find(
        (challenge) => challenge?.id === mostRecentJoinedChallengeId
      );
      if (mostRecentJoinedChallengeMatch) {
        return mostRecentJoinedChallengeMatch;
      }
    }
    // otherwise get the one closest to ending
    return matchedChallenges
      .filter((challenge) => typeof challenge.status?.daysLeft === 'number')
      .sort((challenge1, challenge2) => {
        const daysLeftDiff = challenge1.status.daysLeft - challenge2.status.daysLeft;
        if (daysLeftDiff > 0) {
          return 1;
        } else if (daysLeftDiff < 0) {
          return -1;
        }
        return 0;
      })[0];
  }
}
