import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationRange } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

const DEFAULT_A11Y_TEXT = 'Action reminder set for: ';

@Component({
  selector: 'kp-action-reminder-time-selector',
  templateUrl: './action-reminder-time-selector.component.html',
  styleUrls: ['./action-reminder-time-selector.component.scss'],
})
export class ActionReminderTimeSelectorComponent {
  @Input() accessibilityPrefixText: string = DEFAULT_A11Y_TEXT;
  @Input() time: string;
  @Output() timeEventEmitter = new EventEmitter<string>();

  modalOpened = false;
  notificationRange = NotificationRange;

  saveClicked(time: string) {
    this.time = time;
    this.timeEventEmitter.emit(time);
  }
}

export const MockActionReminderTimeSelectorComponent = MockComponent({
  selector: 'kp-action-reminder-time-selector',
  inputs: ['accessibilityPrefixText', 'time', 'timeEventEmitter'],
});
