import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import { QuestionType } from '@kp/question-set/models/question.model';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-image-icon',
  templateUrl: './image-icon.component.html',
  styleUrls: ['./image-icon.component.scss'],
})
export class ImageIconComponent implements OnChanges {
  @Input() appearDisabled: boolean;
  @Input() isDisabled: boolean;
  @Input() isSelected: boolean;
  @Input() option: DisplayOption;
  @Input() questionType: QuestionType;
  @Input() showTextUnderImage: boolean;

  @Output() imageAnswer = new EventEmitter<DisplayOption>();

  imageIconSrc: string;
  questionTypes = QuestionType;

  ngOnChanges() {
    this.imageIconSrc = this._setImageIconSrc(this.option);
  }

  onImageSelect(option: DisplayOption) {
    this.imageAnswer.emit(option);
  }

  private _setImageIconSrc(option: DisplayOption): string {
    if (this.questionType === QuestionType.image_tile) {
      return `${option?.appImageUrl}?w=600`;
    }
    if (option?.appImageUrl) {
      return `${option?.appImageUrl}?w=104`;
    }
    return option?.appImage
      ? '/assets/images/question-sets/' + option?.appImage
      : '/assets/images/question-sets/default.png';
  }
}

export const MockImageIconComponent = MockComponent({
  selector: 'kp-image-icon',
  inputs: ['appearDisabled', 'isDisabled', 'isSelected', 'option', 'questionType', 'showTextUnderImage'],
});
