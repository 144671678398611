import { AsyncPipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslatePipe } from '@kf-loc';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-email-screens-base',
  standalone: true,
  templateUrl: './email-screens-base.component.html',
  styleUrls: ['./email-screens-base.component.scss'],
  imports: [TranslatePipe, AsyncPipe],
})
export class EmailScreensBaseComponent {
  headerTitle = input<string>();
}

export const MockEmailScreensBaseComponent = MockComponent({
  selector: 'kui-email-screens-base',
  standalone: true,
  inputs: ['headerTitle'],
  template: '<ng-content></ng-content>',
});
