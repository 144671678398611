import { AsyncPipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslatePipe } from '@kf-loc';
import { MockComponent } from '@kutil/test';

const ANNOUNCEMENT_CLEAR_TIME = 500;
let timer: ReturnType<typeof setTimeout>;

@Component({
  selector: 'kui-a11y-live-announce',
  standalone: true,
  templateUrl: './a11y-live-announce.component.html',
  imports: [AsyncPipe, TranslatePipe],
})
export class A11yLiveAnnounceComponent implements OnChanges, OnDestroy {
  @Input() politenessSetting: 'polite' | 'assertive' | 'off' = 'polite';
  @Input() announcement = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['announcement']) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.announcement = '';
      }, ANNOUNCEMENT_CLEAR_TIME);
    }
  }

  ngOnDestroy() {
    this.announcement = '';
  }
}

export const MockA11yLiveAnnounceComponent = MockComponent({
  selector: 'kui-a11y-live-announce',
  inputs: ['politenessSetting', 'announcement'],
});
