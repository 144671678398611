import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslationBloc } from '@kf-loc';
import { NotificationsBloc } from '@kp/notifications/notifications.bloc';
import { Constants } from '@kp/shared/constants.service';
import { CardEventType } from '@ktypes/enums';
import { NotificationInfo, NotificationType } from '@ktypes/models';
import { DateTimeUtil, DaysWeek, DaysWeekAbbreviation } from '@kutil';
import { MockComponent } from '@kutil/test';
import { CronTime } from 'cron-time-generator';
import { format } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-action-reminder',
  templateUrl: './action-reminder.component.html',
  styleUrls: ['./action-reminder.component.scss'],
})
export class ActionReminderComponent implements OnInit, OnDestroy {
  constructor(
    private _notificationsBloc: NotificationsBloc,
    public translationBloc: TranslationBloc
  ) {}

  @Input() cardId: string;
  @Input() notification: NotificationInfo;

  @Output() notificationEdited = new EventEmitter<void>();
  @Output() notificationDeleted = new EventEmitter<NotificationInfo>();
  @Output() notificationUpdated = new EventEmitter<void>();

  constants = Constants;
  dateTimeUtil = DateTimeUtil;
  days: DaysWeekAbbreviation[];
  saveDisabled = false;
  selectedDays: string;
  timeToEdit: string;
  timeFromCardNotification: string;

  private _destroy$ = new Subject<void>();

  ngOnInit() {
    if (this.notification?.id) {
      const interval = this.notification.interval;
      this.timeToEdit = DateTimeUtil.getTimeFromCron(this.notification.notificationSchedule);
      this.days = interval.fields.dayOfWeek.map((dayIndex) => DateTimeUtil.everyDay[dayIndex]);
      this.selectedDays = this._notificationsBloc.getSelectedDays(this.days);
      this._showCompactView();
      this.saveDisabled = true;
    } else {
      const defaultTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 9, 0);
      const defaultTimeInLocal = defaultTime.toLocaleTimeString();
      const is24HourFormat = !defaultTimeInLocal.match(/am|pm/i);
      this.timeToEdit = is24HourFormat ? format(defaultTime, 'H:mm') : format(defaultTime, 'h:mm a');
      this.days = [];
      this.saveDisabled = true;
    }

    this.timeFromCardNotification = DateTimeUtil.getTimeFromCron(this.notification?.notificationSchedule);

    this._notificationsBloc.updatedNotification$.pipe(takeUntil(this._destroy$)).subscribe((notification) => {
      if (notification) {
        this.notification.id = notification.id;
        this.notificationUpdated.emit();
      }
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  deleteReminder() {
    this._notificationsBloc.handleCardNotificationUpdates(CardEventType.REMINDER_DELETED, this.notification);
    this.notificationDeleted.emit(this.notification);
  }

  saveReminder() {
    this.selectedDays = this._notificationsBloc.getSelectedDays(this.days);
    const { hour, minutes } = DateTimeUtil.getHourAndMinutesFromTime(this.timeToEdit);
    this.timeFromCardNotification = this.timeToEdit;
    const cronString = CronTime.onSpecificDaysAt(this.days, hour, minutes);
    this._setNotificationProperties(cronString);
    this._notificationsBloc.handleCardNotificationUpdates(CardEventType.REMINDER_EDITED, this.notification);
    this._showCompactView();
  }

  updateTime(time: string) {
    this.timeToEdit = time;
    this.saveDisabled = this.days.length === 0;
  }

  updateDay(day: DaysWeek) {
    if (this.days.includes(DateTimeUtil.weekDaysData[day].abbreviation)) {
      this.days = this.days.filter((originalDay) => originalDay !== DateTimeUtil.weekDaysData[day].abbreviation);
    } else {
      this.days.push(DateTimeUtil.weekDaysData[day].abbreviation);
    }
    this.saveDisabled = this.days.length === 0;
  }

  showExpandedView() {
    if (this.notification?.notificationSchedule) {
      this.timeToEdit = DateTimeUtil.getTimeFromCron(this.notification.notificationSchedule);
      const interval = this.notification.interval;
      this.days = interval.fields.dayOfWeek.map((dayIndex) => DateTimeUtil.everyDay[dayIndex]);
      this.selectedDays = this._notificationsBloc.getSelectedDays(this.days);
    }
    if (this.notification) {
      this.notification.isEditing = true;
    }
    this.notificationEdited.emit();
  }

  private _showCompactView() {
    if (this.notification) {
      this.notification.isEditing = false;
    }
    this.notificationEdited.emit();
  }

  private _setNotificationProperties(cronString: string) {
    this.notification.notificationType = NotificationType.Reminder;
    this.notification.notificationSchedule = cronString;
    this.notification.contentId = this.cardId;
    this.notification.dateDeviceCreated = DateTimeUtil.formatInLocal();
  }
}

export const MockActionReminderComponent = MockComponent({
  selector: 'kp-action-reminder',
  inputs: ['cardId', 'notification'],
});
