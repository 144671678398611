import { Injectable } from '@angular/core';
import { SettingsApi } from '@kapi';
import { DataStoreService } from '@kservice';
import { JsonObject, Setting, Settings, User } from '@ktypes/models';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsBloc {
  constructor(
    private _dataStoreService: DataStoreService,
    private _settingsApi: SettingsApi
  ) {}

  private _mfaChanged$ = new BehaviorSubject<boolean>(null);

  get mfaChanged$() {
    return this._mfaChanged$.asObservable();
  }

  getUserSettings() {
    this._settingsApi
      .getUserSettings()
      .then((settings) => {
        if (settings instanceof Settings) {
          this._dataStoreService.setUserSettings(settings);
        }
      })
      .catch((error) => {
        console.warn('Error getting user settings: ', error);
      });
  }

  changeUserSetting(setting: Setting, value: boolean | string) {
    if (setting === Setting.staySignedIn && typeof value === 'boolean') {
      this._dataStoreService.setLoggedIn(value);
    } else {
      const requestBody: JsonObject = {
        [setting]: value,
      };
      this._settingsApi
        .changeUserSetting(requestBody)
        .then((settings) => {
          if (Object.prototype.hasOwnProperty.call(settings, setting)) {
            this._dataStoreService.setUserSettings(settings as Partial<Settings>);
          } else if (settings == null && typeof value === 'boolean') {
            this._dataStoreService.setUserSettings({ [setting]: !value } as unknown as Settings);
          }
        })
        .catch((error) => {
          console.warn('Error changing user settings: ', error);
          if (typeof value === 'boolean') {
            this._dataStoreService.setUserSettings({ [setting]: !value } as unknown as Settings);
          }
        });
    }
  }

  changeUserMfaSetting(enabled: boolean, password?: string) {
    if (this._dataStoreService.user.userMfaEnabledSetting && !enabled && !password) {
      // cannot make the call to disable MFA without the password
      return;
    }
    this._settingsApi
      .changeUserMfa(enabled, password)
      .then((response) => {
        if (Object.prototype.hasOwnProperty.call(response, 'userMfaEnabledSetting')) {
          this._dataStoreService.setUser(response as Partial<User>);
          this._mfaChanged$.next(true);
        } else {
          this._mfaChanged$.next(false);
        }
      })
      .catch((error) => {
        console.warn('Error changing user MFA setting: ', error);
        this._mfaChanged$.next(false);
      });
  }

  seenAutoAdvanceSettings() {
    this._dataStoreService.user$.pipe(take(1)).subscribe((user) => {
      if (user.settings?.autoAdvance == null) {
        this.changeUserSetting(Setting.autoAdvance, true);
      }
    });
  }
}
