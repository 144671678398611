import { PulseSurveyDisplay } from '@kp/pulse-survey/models/pulse-survey-display.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class PulseSurvey implements Deserializable<JsonObject, PulseSurvey>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public presentedInAppId?: string,
    public display?: PulseSurveyDisplay,
    public completed?: boolean,
    public contentReportId?: string
  ) {}

  deserialize(input: JsonObject): PulseSurvey {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.completed = !!input['completed'];
    this.display = new PulseSurveyDisplay().deserialize(input['display']);
    this.presentedInAppId = input['presentedInAppId'] as string;
    this.contentReportId = input['contentReportId'] as string;

    return this;
  }
  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['completed'] = this.completed;
    json['display'] = this.display.serialize();
    json['presentedInAppId'] = this.presentedInAppId;
    json['contentReportId'] = this.contentReportId;

    return json;
  }
}
