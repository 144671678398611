import { Location } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { FirstVisitBloc, TagBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { UserBloc } from '@kp/user/user.bloc';
import { FirstVisitCase } from '@ktypes/enums';
import { MockComponent } from '@kutil/test';
import { Subject, combineLatest } from 'rxjs';
import { distinct, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-live-support',
  templateUrl: './live-support.component.html',
  styleUrls: ['./live-support.component.scss'],
})
export class LiveSupportComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _minimizeTag = false;
  private _shownExpandedLiveSupport = false;

  @HostBinding('class.expand') shouldExpand = false;
  @HostBinding('class.expanded') finishedExpand = false;
  @HostBinding('class.minimize') shouldMinimize = true;

  // Using Location because Router events are not triggered on first route load
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _elementRef: ElementRef,
    private _firstVisitBloc: FirstVisitBloc,
    public environmentVariablesService: EnvironmentVariablesService,
    private _location: Location,
    private _tagBloc: TagBloc,
    public userBloc: UserBloc
  ) {}

  ngOnInit() {
    combineLatest([
      this._firstVisitBloc.hasSeen$.pipe(distinct((firstVisit) => firstVisit.shownExpandedLiveSupport)),
      this._tagBloc.minimizeLiveSupport$,
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([firstVisit, minimizeLiveSupportTag]) => {
        this._minimizeTag = minimizeLiveSupportTag;
        this._shownExpandedLiveSupport = firstVisit.shownExpandedLiveSupport;
        // Initial route live support state check
        this._checkInitialLiveSupportState();
      });
    // Check on route change
    this._location.onUrlChange(() => {
      this._checkInitialLiveSupportState();
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  // Needed for collapsing live support when user clicks outside the component
  @HostListener('document:click', ['$event'])
  collapseLiveSupport(event: Event): void {
    if (
      !(this._elementRef.nativeElement as HTMLElement).contains(event.target as HTMLElement) &&
      !((event.target as HTMLElement).closest('button') || (event.target as HTMLElement).closest('a')) &&
      this.shouldExpand
    ) {
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.live_support_collapsed })
      );
      this._minimize();
    }
  }

  toggleLiveSupport(): void {
    if (!this.shouldExpand) {
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.live_support_expanded })
      );
    } else {
      if (this.hasRouteThatExpands && !this._shownExpandedLiveSupport) {
        this._firstVisitBloc.updateFirstVisit(FirstVisitCase.shownExpandedLiveSupport);
      }
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.live_support_collapsed })
      );
    }
    this._toggle();
  }

  liveSupportLinkClicked(): void {
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.live_support_link_clicked })
    );
  }

  liveSupportNumberClicked(): void {
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.live_support_phone_clicked })
    );
  }

  private _checkInitialLiveSupportState(): void {
    if (
      !this._minimizeTag &&
      (this.hasGeneratedReport || (!this._shownExpandedLiveSupport && this.hasRouteThatExpands))
    ) {
      this._expand();
    }
  }

  get hasRouteThatExpands(): boolean {
    return (
      this._location.path().includes('resources') ||
      this._location.path().includes('discover') ||
      (this._location.path().includes('report') &&
        !this._location.path().includes('dialogue') &&
        !this._location.path().includes('new'))
    );
  }

  get hasGeneratedReport(): boolean {
    return (
      !this._location.path().includes('dialogue') &&
      this._location.path().includes('report') &&
      this._location.path().includes('new')
    );
  }

  private _minimize(): void {
    this.shouldExpand = false;
    this.finishedExpand = false;
    this.shouldMinimize = true;

    if (this.hasRouteThatExpands && !this._shownExpandedLiveSupport) {
      this._firstVisitBloc.updateFirstVisit(FirstVisitCase.shownExpandedLiveSupport);
      this._shownExpandedLiveSupport = true;
    }
  }

  private _expand(): void {
    this.shouldExpand = true;
    this._setFinishedExpand(true);
    this.shouldMinimize = false;
  }

  private _toggle(): void {
    this.shouldExpand = !this.shouldExpand;
    this._setFinishedExpand(this.shouldExpand);
    this.shouldMinimize = !this.shouldMinimize;
  }

  private _setFinishedExpand(finished: boolean) {
    setTimeout(() => {
      this.finishedExpand = finished;
    }, 500);
  }
}

export const MockLiveSupportComponent = MockComponent({
  selector: 'kp-live-support',
});
