import { Deserializable } from '@ktypes/models';

export interface TranslationResponse {
  cacheKey?: string;
  detectedSourceLanguage?: string;
  model?: string;
  originalText?: string;
  translatedText?: string;
}

export class Translation implements Deserializable<TranslationResponse, Translation> {
  constructor(
    public cacheKey?: string,
    public detectedSourceLanguage?: string,
    public model?: string,
    public originalText?: string,
    public translatedText?: string
  ) {}

  deserialize(input: TranslationResponse): Translation {
    if (input == null) {
      return null;
    }

    this.cacheKey = input['cacheKey'];
    this.detectedSourceLanguage = input['detectedSourceLanguage'];
    this.model = input['model'];
    this.originalText = input['originalText'];
    this.translatedText = input['translatedText'];

    return this;
  }
}
