import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class Report implements Deserializable<any, Report> {
  constructor(
    public title?: string,
    public dateUploaded?: string,
    public fileType?: string,
    public fileName?: string
  ) {}

  /**
   * Assign Report params from JSON
   */
  deserialize(json: JsonObject): Report {
    this.dateUploaded = json['dateUploaded'] as string;
    this.fileName = json['fileName'] as string;
    this.fileType = json['fileType'] as string;
    this.title = json['title'] as string;

    return this;
  }
}
