import { SubscriptionStatusType } from '../enums/subscription-status.enum';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class SubscriptionStatus implements Deserializable<JsonObject, SubscriptionStatus> {
  constructor(
    public access?: boolean,
    public canceledDate?: string,
    public status?: SubscriptionStatusType,
    public trialEndDate?: string,
    public trialStartDate?: string
  ) {}

  deserialize(input: JsonObject): SubscriptionStatus {
    if (input == null) {
      return null;
    }

    this.access = !!input['access'];
    this.canceledDate = input['canceledDate'] as string;
    this.status = input['status'] as SubscriptionStatusType;
    this.trialEndDate = input['trialEndDate'] as string;
    this.trialStartDate = input['trialStartDate'] as string;

    return this;
  }
}
