import { ContentEventType } from '../enums';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export class ContentEvent implements Deserializable<JsonObject, ContentEvent>, Serializable<JsonObject> {
  constructor(
    public contentId?: string,
    public deviceCreatedTimestamp?: string,
    public eventInfo?: JsonObject,
    public eventType?: ContentEventType
  ) {}

  deserialize(input: JsonObject): ContentEvent {
    if (input == null) {
      return null;
    }
    this.contentId = input['contentId'] as string;
    this.deviceCreatedTimestamp = input['deviceCreatedTimestamp'] as string;
    this.eventInfo = input['eventInfo'] as JsonObject;
    this.eventType = input['eventType'] as ContentEventType;

    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['deviceCreatedTimestamp'] = this.deviceCreatedTimestamp;
    json['eventInfo'] = this.eventInfo;
    json['eventType'] = this.eventType;
    return json;
  }
}
