import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MockComponent } from '@kutil/test';
import KeenSlider, { KeenSliderInstance, KeenSliderOptions, KeenSliderPlugin } from 'keen-slider';

/**
 * Implements wrapper for Keen Slider
 *  Site: https://keen-slider.io/
 *  Documentation: https://keen-slider.io/docs
 *  Github: https://github.com/rcbyr/keen-slider
 *
 *  See documentation for available options, how to create plugins, and other information
 *  like event hooks, available methods/properties, attributes, and so on.
 */
@Component({
  selector: 'kp-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @Input() options: KeenSliderOptions = {};
  @Input() plugins: KeenSliderPlugin[] = [];
  @Output() sliderCreated = new EventEmitter<KeenSliderInstance>();

  private _slider: KeenSliderInstance = null;

  @ViewChild('sliderRef') private _sliderRef: ElementRef<HTMLElement>;

  @HostListener('window:keydown', ['$event']) keyEvents(event: KeyboardEvent) {
    switch (event.code) {
      case 'ArrowLeft':
      case 'Numpad4':
      case 'KeyJ':
      case 'ArrowUp':
      case 'Numpad8':
      case 'KeyH':
        this._slider?.prev();
        break;
      case 'ArrowRight':
      case 'Numpad6':
      case 'KeyK':
      case 'Numpad2':
      case 'ArrowDown':
      case 'KeyL':
        this._slider?.next();
        break;
      default:
        // do nothing if it isn't a recognized key
        break;
    }
  }

  ngAfterViewInit() {
    if (this.options.selector !== null && !this.options.selector) {
      // default selector is ".keen-slider__slide" so add it and slide numbers if a selector is not provided
      Array.from(this._sliderRef.nativeElement.children).forEach((slideItem, index) =>
        slideItem.classList.add('keen-slider__slide', `slide_${index}`)
      );
    }
    this._slider = new KeenSlider(this._sliderRef.nativeElement, this.options, this.plugins);
    this.sliderCreated.emit(this._slider);
  }

  ngOnDestroy() {
    if (this._slider) {
      this._slider.destroy();
      delete this._slider;
    }
  }
}

export const MockCarouselComponent = MockComponent({
  selector: 'kp-carousel',
  inputs: ['options', 'plugins'],
});
