import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { MockComponent } from '@kutil/test';
import { Selection, arc, interpolate, scaleLinear, select } from 'd3';

export enum ArcType {
  base,
  fill,
}

export interface ArcData {
  class?: string;
  name?: string;
  type?: ArcType;
  innerRadius?: number;
  outerRadius?: number;
  startAngle?: number;
  endAngle?: number;
  initEndAngle?: number;
  finalEndAngle?: number;
  padAngle?: number;
  padRadius?: number;
  cornerRadius?: number;
  context?: CanvasRenderingContext2D;
}

const MIN_ARC_SIZE = scaleRadians(scale360(0.0275));
const DEFAULT_ARC_SIZE = 60;
const DEFAULT_ARC_WEIGHT = 5;
const ANIMATION_DURATION = 1200;

@Component({
  selector: 'kui-arc-chart',
  templateUrl: './arc-chart.component.html',
  styleUrls: ['./arc-chart.component.scss'],
})
export class ArcChartComponent implements AfterViewInit, OnChanges {
  // specifically not grouping so that changes to data is immediately reflected in SVG data
  @Input() arcSize = DEFAULT_ARC_SIZE; // TODO: scales SVG but not arc
  @Input() arcWeight = DEFAULT_ARC_WEIGHT; // TODO: should this be a percentage of size? or have a minimum?
  @Input() fillOnNoData = true;
  @Input() greyscale = false;
  @Input() initProgress = 0;
  @Input() isOnBackground = false;
  @Input() progress = 0;
  @Input() target: number;

  private _arcData: ArcData[] = [];
  private _arcInnerRadius = (DEFAULT_ARC_SIZE - DEFAULT_ARC_WEIGHT * 2) / 2;
  private _arcOuterRadius = DEFAULT_ARC_SIZE / 2;
  private _arcChartSvg: Selection<SVGElement, unknown, null, undefined>;
  private _arcChartSvgArc: Selection<any, ArcData, SVGElement, unknown>;
  private _currentProgress: number;
  private _svgInitialized = false;

  @ViewChild('arcChart') private set arcChartRef(value: ElementRef<SVGElement>) {
    this._arcChartSvg = select(value?.nativeElement);
  }

  ngOnChanges(/* changes: SimpleChanges */) {
    if (this.arcWeight > this.arcSize) {
      this.arcWeight = this.arcSize;
    }
    this._arcInnerRadius = (this.arcSize - this.arcWeight * 2) / 2;
    this._arcOuterRadius = this.arcSize / 2;
    if (this.initProgress < 0) {
      this.initProgress = 0;
    }
    this._currentProgress = this._currentProgress ?? this.initProgress;
    if (this.progress > this.target) {
      this.progress = this.target;
    }
    if (this._svgInitialized) {
      this._updateArcChartData();
    }
  }

  ngAfterViewInit() {
    this._waitForArcChartSvg();
  }

  private _waitForArcChartSvg() {
    if (this._arcChartSvg == null) {
      setTimeout(() => {
        this._waitForArcChartSvg();
      }, 50);
      return;
    }
    this._setupArcChartSVG();
  }

  private _setupArcChartSVG() {
    this._addBasePath();

    this._arcChartSvgArc = this._arcChartSvg
      .append('g')
      .attr('transform', `translate(${this.arcSize / 2}, ${this.arcSize / 2})`);

    this._updateArcChartData();

    this._svgInitialized = true;
  }

  private _addBasePath() {
    const fillBase = this.fillOnNoData && (!this.progress || !this.target);
    if (fillBase) {
      this.arcWeight = this.arcSize;
      this._arcInnerRadius = 0;
    }
    this._updateArcData('Arc Chart Base', {
      class: 'arc-chart-base',
      name: 'Arc Chart Base',
      type: ArcType.base,
      initEndAngle: scaleRadians(fillBase ? 360 : 0),
      finalEndAngle: scaleRadians(360),
    });
  }

  private _updateArcChartData() {
    this._updateFillArc();
    this._updateSvg();
  }

  private _updateFillArc() {
    if (this.greyscale) {
      // delete Group Progress Fill for individual exclusive
      this._updateArcData('Arc Chart Fill');
    } else {
      // update Arc Chart Fill
      const initEndAngle = scaleRadians(scale360(this._currentProgress / this.target));
      const finalEndAngle =
        this.progress > 0 ? Math.max(scaleRadians(scale360(this.progress / this.target)), MIN_ARC_SIZE) : 0;
      this._updateArcData('Arc Chart Fill', {
        class: 'arc-chart-fill',
        name: 'Arc Chart Fill',
        type: ArcType.fill,
        initEndAngle,
        finalEndAngle,
      });
      this._currentProgress = this.progress;
    }
  }

  private _updateArcData(name: string, arcData?: ArcData) {
    const existingIndex = this._arcData.findIndex((arc) => arc?.name === name);
    // remove the data if it existed already (based on name)
    if (existingIndex > -1) {
      this._arcData = [...this._arcData.filter((arc) => arc?.name !== name)];
    }
    // as long as it is not null/undefined, push the new arcData
    if (arcData) {
      this._arcData.push(arcData);
    }
  }

  private _updateSvg() {
    const transitionDuration = this._arcChartSvgArc.transition().duration(ANIMATION_DURATION);
    this._arcChartSvgArc
      .selectAll('path')
      .data(this._arcData)
      .join(
        (enter) => {
          return enter
            .append('path')
            .attr('class', (arcData) => arcData.class)
            .attr('d', (arcData) => {
              arcData.endAngle = arcData.initEndAngle;
              arcData.initEndAngle = arcData.finalEndAngle;
              return this._arcGen(arcData);
            })
            .call((enter) => {
              enter.transition(transitionDuration).attrTween('d', (arcData) => {
                return this._arcTween(arcData);
              });
            });
        },
        (update) => {
          return update.call((update) =>
            update.transition(transitionDuration).attrTween('d', (arcData: ArcData) => {
              // only redraw if end angle changed
              if (arcData.endAngle !== arcData.initEndAngle) {
                arcData.endAngle = arcData.initEndAngle;
                return this._arcTween(arcData);
              }
              return null;
            })
          );
        },
        (exit) => {
          exit.call((exit) => exit.remove());
        }
      );
  }

  private _arcGen(
    {
      type,
      innerRadius = this._arcInnerRadius,
      outerRadius = this._arcOuterRadius,
      startAngle,
      endAngle,
      padAngle,
      padRadius,
      cornerRadius,
      context,
    }: ArcData,
    _?: undefined
  ): string {
    if (type === ArcType.fill) {
      cornerRadius = cornerRadius ?? 5;
    }
    return arc()
      .innerRadius(innerRadius ?? 0)
      .outerRadius(outerRadius)
      .startAngle(startAngle ?? 0)
      .endAngle(endAngle ?? scaleRadians(360))
      .padAngle(padAngle ?? 0)
      .padRadius(padRadius ?? 0)
      .cornerRadius(cornerRadius ?? 0)
      .context(context)(_); // setting values above
  }

  private _arcTween(arcData: ArcData) {
    const interpolation = interpolate(arcData.endAngle || 0, arcData.finalEndAngle || 0);
    return (currentAngle: number) => {
      arcData.endAngle = interpolation(currentAngle || 0);
      return this._arcGen(arcData);
    };
  }
}

// Exporting functions for testing
export function scale360(value: number, domain = [0, 1]) {
  return scaleLinear().range([0, 360]).domain(domain)(value);
}

export function scaleRadians(value: number, domain = [0, 360]) {
  return scaleLinear()
    .range([0, Math.PI * 2])
    .domain(domain)(value);
}

export const MockArcChartComponent = MockComponent({
  selector: 'kui-arc-chart',
  inputs: ['arcSize', 'arcWeight', 'fillOnNoData', 'greyscale', 'initProgress', 'isOnBackground', 'progress', 'target'],
});
