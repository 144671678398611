<button class="button button-quick-actions" aria-label="Quick Actions Menu" (click)="toggleQuickActions()">
  <span class="button-plus" [ngClass]="{ 'button-x': quickActionsOpen }"></span>
</button>

@if (quickActionsOpen) {
  <div class="menu-container">
    @for (quickAction of quickActions; track quickAction; let last = $last) {
      <a
        class="quick-action"
        (click)="toggleQuickActions(quickAction)"
        [routerLink]="quickAction.route"
        [queryParams]="quickAction.queryParams"
        (keydown.tab)="last ? toggleQuickActions() : true">
        <span class="quick-action-text">
          <svg-icon class="icon-quick-action" [src]="quickAction.svgUrl"></svg-icon>
          {{ quickAction.text | translate | async }}
        </span>
      </a>
    }
  </div>
}
<kui-a11y-live-announce [announcement]="quickActionsAnnouncement"></kui-a11y-live-announce>
