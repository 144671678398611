import { StringUtil } from '@kutil';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export interface LegalDocumentItem {
  type: string;
  latestVersion: number;
  signed: boolean;
}

export class LegalDocument implements Deserializable<JsonObject, LegalDocument> {
  constructor(
    public id?: string,
    public type?: LegalDocumentType,
    public summary?: string,
    public content?: string,
    public version?: number,
    public effectiveDate?: string,
    public dateCreated?: string
  ) {}

  get contentAsParsedHtml(): string {
    return StringUtil.contentAsParsedHtml(this.content);
  }

  deserialize(input: JsonObject): LegalDocument {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.type = input['type'] as LegalDocumentType;
    this.summary = input['summary'] as string;
    this.content = input['content'] as string;
    this.version = input['version'] as number;
    this.effectiveDate = input['effectiveDate'] as string;
    this.dateCreated = input['dateCreated'] as string;

    return this;
  }
}

export enum LegalDocumentType {
  EULA = 'EULA',
  PRIVACY = 'PRIVACY',
}
