import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AnswerValue } from '@kp/question-set/models/answer-value.model';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import { Question, QuestionType } from '@kp/question-set/models/question.model';
import { MockComponent } from '@kutil/test';

const MAX_CHARACTERS_DEFAULT = 400;

@Component({
  selector: 'kp-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
})
export class AnswerComponent implements OnChanges {
  @Input() key: string;
  @Input() placeholderText: string;
  @Input() question: Question;

  @Output() answer = new EventEmitter<DisplayOption[] | string | AnswerValue>();

  maxCharacters: number;
  options: DisplayOption[];
  questionType = QuestionType;
  listQuestionTypes = [
    QuestionType.word_list,
    QuestionType.image_icon_selection,
    QuestionType.image_tile,
    QuestionType.image_word_list,
  ];
  textQuestionTypes = [QuestionType.long_text, QuestionType.short_text];

  ngOnChanges() {
    if (this.question) {
      this.options = this.question.hasAnswer
        ? this.question.display?.optionsWithAnswers(this.question.answer?.values)
        : this.question.display?.options;
    }
    if (!this.maxCharacters) {
      this.maxCharacters = this.question?.settings?.characterLimitMax ?? MAX_CHARACTERS_DEFAULT;
    }
    if (!this.placeholderText) {
      this.placeholderText = 'Type here to answer...';
    }
  }

  get answerValue(): string {
    return (this.question?.answer?.values?.[0]?.value as string) || '';
  }
}

export const MockAnswerComponent = MockComponent({
  selector: 'kp-answer',
  inputs: ['key', 'placeholderText', 'question'],
});
