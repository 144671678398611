import { Injectable } from '@angular/core';
import { DailyChallengeStatusApi } from '@kp/shared/components/daily-challenge/daily-challenge-status.api';
import { DailyChallenge } from '@kp/shared/components/daily-challenge/daily-challenge.model';
import { BrowserStorage } from '@kservice';
import { DataStatus, Status, StatusMessage } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DailyChallengeStatusBloc {
  constructor(
    private _browserStorage: BrowserStorage,
    private _dailyChallengeStatusApi: DailyChallengeStatusApi
  ) {}

  private _challengeStatusSubject = new BehaviorSubject<DataStatus<DailyChallenge>>(
    new DataStatus<DailyChallenge>(Status.starting, new StatusMessage(Status.starting, ''), null)
  );

  private _shownPurposefulDayCelebration$ = new BehaviorSubject<boolean>(null);

  private _showLoadingState = true;

  get dailyChallengeStatus$(): Observable<DataStatus<DailyChallenge>> {
    return this._challengeStatusSubject.asObservable();
  }

  get shownPurposefulDayCelebration$(): Observable<boolean> {
    return this._shownPurposefulDayCelebration$.asObservable();
  }

  setPurposefulDayCelebrationStatus(value: boolean) {
    this._shownPurposefulDayCelebration$.next(value);
  }

  refreshDailyChallengeStatus(): void {
    if (this._showLoadingState) {
      this._challengeStatusSubject.next(
        new DataStatus<DailyChallenge>(Status.starting, new StatusMessage(Status.starting, ''), null)
      );
      this._showLoadingState = false;
    }
    this._dailyChallengeStatusApi.getCurrentStatus().then((challengeStatus) => {
      if (challengeStatus) {
        const status = new DataStatus<DailyChallenge>(
          Status.done,
          new StatusMessage(Status.done, ''),
          challengeStatus.data?.[0]
        );
        this._challengeStatusSubject.next(status);
      }
    });
  }
}
