import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StreakBloc } from '@kp/streaks/streak.bloc';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-modal-purposeful-days',
  templateUrl: './modal-purposeful-days.component.html',
  styleUrls: ['./modal-purposeful-days.component.scss'],
})
export class ModalPurposefulDaysComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter<boolean>();
  streakLength = 0;
  streakText = '0';

  private _destroy$ = new Subject<void>();

  constructor(
    private _streakBloc: StreakBloc,
    private _router: Router
  ) {}

  ngOnInit() {
    this._streakBloc.streak$.pipe(takeUntil(this._destroy$)).subscribe((response) => {
      const streaks = response?.data;
      if (streaks) {
        this.streakLength = streaks.current || 0;
        this.streakText = Math.max(this.streakLength - 1, 0).toString();
      }
    });
    this._streakBloc.refreshStreaks();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onAnimationStart() {
    this.streakText = `${this.streakLength}-day streak!`;
    setTimeout(() => this.closeClicked.emit(true), 2000);
  }
}
