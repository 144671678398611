import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { Search } from '@kp/search/search.model';
import { DataStoreService } from '@kservice';
import { DataStatus, PredefinedSearch, Status } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, _dataStoreService, _environmentVariablesService);
  }

  async performSearch(search: Search): Promise<DataStatus<Search>> {
    const url = this.buildUrl('/search/cards', true);
    const request = this.performRequest<Search>(RequestType.POST, url, {
      requestBody: {
        searchText: search.searchText,
        searchMeta: { search_type: search.searchType },
        deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      },
      includeToken: true,
    });
    const response = await firstValueFrom<HttpResponse<Search>>(request).catch((error): null => {
      console.warn('Error posting search: ', error);
      return null;
    });

    const searchData = new Search().deserialize(response?.body);
    return new DataStatus<Search>(Status.done, null, searchData);
  }

  async predefinedSearchSuggestions(): Promise<DataStatus<PredefinedSearch[]>> {
    const url = this.buildUrl('/search/predefined', true);
    const request = this.performRequest<PredefinedSearch[]>(RequestType.GET, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<PredefinedSearch[]>>(request).catch((error): null => {
      console.warn('Error getting predefined search items: ', error);
      return null;
    });

    if (response?.ok) {
      const predefinedSearchData = response.body?.map((rawPredefinedSearch) =>
        new PredefinedSearch().deserialize(rawPredefinedSearch)
      );
      return new DataStatus<PredefinedSearch[]>(Status.done, null, predefinedSearchData);
    }
    return new DataStatus<PredefinedSearch[]>(Status.error, null, null);
  }
}
