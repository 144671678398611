import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { RequestType } from '@ktypes/enums';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AnalyticsEvent } from './analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsApi extends BaseApi {
  constructor(
    private client: HttpClient,
    public dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  /**
   * post accepts an AnalyticEvent or an array of AnalyticEvents and sends the request to the API
   */
  async post(analyticsEvents: AnalyticsEvent | AnalyticsEvent[]): Promise<boolean> {
    const url = this.buildUrl('/analytics', true);
    const requestBody = Array.isArray(analyticsEvents)
      ? analyticsEvents?.map((analyticsEvent) => analyticsEvent.serialize())
      : analyticsEvents.serialize();
    const request$ = this.performRequest<boolean>(RequestType.POST, url, {
      requestBody,
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<boolean>): boolean => {
        return response?.ok;
      }),
      catchError((err): Observable<boolean> => {
        console.warn('Failed posting analytics event(s): ', err);
        return of(false);
      })
    );
    return await firstValueFrom(request$).catch((error) => {
      console.warn('Error posting analytics event(s): ', error);
      return false;
    });
  }
}
