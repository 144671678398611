import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MockDirective } from '../utils/spec.util';

/**
 * Rerender Directive will rerender the component when the property referenced changes, including causing
 * the entire component lifecycle to be re-run, including OnInit.
 *
 * Import UtilsModule in the appropriate module to use. Included in SharedModule (Purposeful) already.
 *
 * Example:
 *
 * <ng-container *kutilRerender='changeTrigger'>
 *    This content/component will be re-rendered everytime `changeTrigger` changes
 * </ng-container>
 *
 * Inspiration: https://github.com/ngehlert/ngx-rerender
 * Note: if we need more than what this specific directive provides, look to just import that package
 */
@Directive({
  selector: '[kutilRerender]',
})
export class RerenderDirective {
  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef
  ) {}

  // if detects changes of the input `val`, clear and rerender the view
  @Input() set kutilRerender(val: any) {
    this._viewContainer.clear();
    this._viewContainer.createEmbeddedView(this._templateRef);
  }
}

export const MockRerenderDirective = MockDirective({
  selector: '[kutilRerender]',
  inputs: ['kutilRerender'],
});
