import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslatePipe } from '@kf-loc';

@Component({
  selector: 'kauth-auth-sso-logout',
  standalone: true,
  templateUrl: './auth-sso-logout.component.html',
  styleUrls: ['./auth-sso-logout.component.scss'],
  imports: [TranslatePipe, AsyncPipe],
})
export class AuthSsoLogoutComponent {}
