import { Question } from '@kp/question-set/models/question.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class Page implements Deserializable<JsonObject, Page>, Serializable<JsonObject> {
  constructor(public questions?: Question[]) {}

  deserialize(input: JsonObject): Page {
    if (input == null) {
      return null;
    }
    this.questions = (input['questions'] as Question[]).map((question) => new Question().deserialize(question));
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['questions'] = this.questions.map((question) => question.serialize());
    return json;
  }
}
