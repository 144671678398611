import { AnswerValue } from '@kp/question-set/models/answer-value.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class Answer implements Deserializable<JsonObject, Answer>, Serializable<JsonObject> {
  constructor(
    public deviceCreatedTimestamp?: string,
    public values?: AnswerValue[]
  ) {}

  deserialize(input: JsonObject): Answer {
    if (input == null) {
      return null;
    }
    this.deviceCreatedTimestamp = input['deviceCreatedTimestamp'] as string;
    this.values = (input['values'] as AnswerValue[])?.map((value) => new AnswerValue().deserialize(value));
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['deviceCreatedTimestamp'] = this.deviceCreatedTimestamp;
    json['values'] = this.values?.map((value) => value?.serialize());
    return json;
  }
}
