import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kp-input-option',
  templateUrl: './input-option.component.html',
  styleUrls: ['./input-option.component.scss'],
})
export class InputOptionComponent {
  @Input() answerText: string;
  @Input() appearDisabled = false;
  @Input() checkbox = false;
  @Input() forId: string;
  @Input() isDisabled = false;
  @Input() isSelected: boolean;

  @Output() selectInputOption = new EventEmitter<boolean>();
}
