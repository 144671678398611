import { HttpErrorResponse } from '@angular/common/http';

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  CLIENT_UNKNOWN = 999,
}

export enum PaymentRequiredStatusMessage {
  PAYMENT_REQUIRED = 'Payment Required',
  EXPIRED_USER = 'Expired user',
  EXPIRED = 'Payment Required Expired',
  PASSED_ACCOUNT_DURATION = 'Payment Required Passed Account Duration',
  GROUP_DISABLED = 'Payment Required Group Disabled',
  INACTIVE_GROUP = 'Inactive group',
  INITIAL_PAYMENT_NEEDED = 'Payment Required Initial Payment Needed',
  PAYMENT_NEEDED = 'Payment Required Payment Needed',
}

export class HttpApiErrorResponse extends HttpErrorResponse {
  explanation?: string;
}
