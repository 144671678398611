import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedConstants } from '@kservice';

@Component({
  selector: 'kp-account-creation-modal',
  templateUrl: './account-creation-modal.component.html',
  styleUrls: ['./account-creation-modal.component.scss'],
})
export class AccountCreationModalComponent {
  accountCreationChoice = SharedConstants.actionChoice;
  @Input() canDismiss = true;
  @Output() closeClicked = new EventEmitter<string>();
}
