export function assertIsOfType<T, S>(value: T | S, prop: string): asserts value is T {
  // make sure to choose a prop that only exists on T or could have false negatives/positives
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (value != null && (value as T as any)[prop] === undefined) {
    throw Error(`invalid property: ${prop}`);
  }
}

export function isOfType<T, S>(value: T | S, prop: string): value is T {
  // make sure to choose a prop that only exists on T or could have false negatives/positives
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return value != null && (value as T as any)[prop] !== undefined;
}

export type MutableReadonly<T> = { -readonly [P in keyof T]: T[P] }; // Remove readonly
