export enum CardEventType {
  DISCOVER_VIEW = 'DISCOVER_VIEW', // View a compact card on Discover page
  REFLECT_VIEW = 'REFLECT_VIEW', // View a compact card on Reflect page
  SEARCH_VIEW = 'SEARCH_VIEW', // View a compact card on Search results
  SEARCH_OPEN = 'SEARCH_OPEN', // Open display view of card on Search results
  OPEN = 'OPEN', // Open display view of card
  CLOSE = 'CLOSE', // Close display view of card
  MORE = 'MORE', // Toggle more like this, eliminate less like this if set
  LESS = 'LESS', // Toggle less like this, eliminate more like this if set
  NEUTRAL = 'NEUTRAL', // Toggle either more/less, eliminate either if set
  COMPLETE = 'COMPLETE', // Mark completed
  ANIMATION_COMPLETE = 'ANIMATION_COMPLETE', // (UI) Completion animation completed
  UNCOMPLETE = 'UNCOMPLETE', // Mark not completed
  TRACK = 'TRACK', // Add to Act
  UNTRACK = 'UNTRACK', // Remove from Act
  RESET_ERROR = 'RESET_ERROR',
  QUEST_CARD_COMPLETE = 'QUEST_CARD_COMPLETE',
  QUEST_CARD_VIEW = 'QUEST_CARD_VIEW',
  QUEST_CARD_OPEN = 'QUEST_CARD_OPEN',
  QUEST_CARD_CLOSE = 'QUEST_CARD_CLOSE',
  LOCAL_RESOURCE_PRESENTED = 'LOCAL_RESOURCE_PRESENTED',
  LOCAL_RESOURCE_VIEWED = 'LOCAL_RESOURCE_VIEWED',
  LOCAL_RESOURCE_OPENED = 'LOCAL_RESOURCE_OPENED',
  LOCAL_RESOURCE_MORE = 'LOCAL_RESOURCE_MORE',
  LOCAL_RESOURCE_LESS = 'LOCAL_RESOURCE_LESS',
  LOCAL_RESOURCE_NEUTRAL = 'LOCAL_RESOURCE_NEUTRAL',
  LOCAL_RESOURCE_CLOSED = 'LOCAL_RESOURCE_CLOSED',
  LOCAL_RESOURCE_NEXT_STEP_WEBSITE = 'LOCAL_RESOURCE_NEXT_STEP_WEBSITE',
  LOCAL_RESOURCE_NEXT_STEP_PHONE = 'LOCAL_RESOURCE_NEXT_STEP_PHONE',
  LOCAL_RESOURCE_NEXT_STEP_EMAIL = 'LOCAL_RESOURCE_NEXT_STEP_EMAIL',
  LOCAL_RESOURCE_NEXT_STEP_DIRECTIONS = 'LOCAL_RESOURCE_NEXT_STEP_DIRECTIONS',
  LOCAL_RESOURCE_SEARCH_OPENED = 'LOCAL_RESOURCE_SEARCH_OPENED',
  SAVED = 'SAVED',
  UNSAVED = 'UNSAVED',
  HABIT_ADDED = 'HABIT_ADDED',
  HABIT_EDITED = 'HABIT_EDITED',
  HABIT_DELETED = 'HABIT_DELETED',
  REMINDER_EDITED = 'REMINDER_EDITED',
  REMINDER_DELETED = 'REMINDER_DELETED',
}
