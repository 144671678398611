import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class LocalFirstVisit implements Deserializable<JsonObject, LocalFirstVisit> {
  constructor(
    public clearedIoNotifications = false,
    public notificationPrompt = false,
    public reminders = false
  ) {}

  deserialize(json?: JsonObject, previousValue?: LocalFirstVisit): LocalFirstVisit {
    if (json != null || previousValue != null) {
      Object.assign(this, previousValue || {}, json);
    }
    return this;
  }
}
