import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import { QuestionType } from '@kp/question-set/models/question.model';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-word-list',
  templateUrl: './word-list.component.html',
  styleUrls: ['./word-list.component.scss'],
})
export class WordListComponent implements AfterViewInit {
  @Input() displayOptions: DisplayOption[];
  @Input() max: number;
  @Input() min: number;
  // questionType input only handles these answer types, but TypeScript does
  // not like it to be limited via a union operator instead of QuestionType:
  //   QuestionType.word_list
  //   QuestionType.image_icon_selection
  //   QuestionType.image_tile
  //   QuestionType.image_word_list
  @Input() questionType: QuestionType;

  @Output() answerSelected = new EventEmitter<DisplayOption[]>();

  startRow1 = 0;
  endRow1 = 5;
  startRow2 = 5;
  endRow2 = 9;

  isExpanded = false;
  numberOfOptionsSelected = 0;
  optionRows: DisplayOption[][] = [];
  rowsToShow = 2;
  minOptions = 10;

  constructor(private element: ElementRef) {}

  @HostListener('window: resize', ['$event'])
  resizeHandler() {
    this._getDiamonds();
  }

  @HostBinding('class.word-list')
  get isWordlist() {
    // diamonds
    return this.questionType === QuestionType.word_list;
  }

  @HostBinding('class.image-tile')
  get isImageTile() {
    // Image Tiles + words
    return this.questionType === QuestionType.image_tile;
  }

  @HostBinding('class.image-word-list')
  get isImageWordList() {
    // Image Icons + Words
    return this.questionType === QuestionType.image_word_list;
  }

  @HostBinding('class.image-icon-selection')
  get isImageIconSelection() {
    // basic Smiley
    return this.questionType === QuestionType.image_icon_selection;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.isWordlist) {
        this._getDiamonds();
      } else if (this.isImageWordList) {
        this._hideOptions();
      } else if (this.isImageIconSelection) {
        this.max = 1;
      }
    });
  }

  private _getDiamonds(): void {
    const DIAMOND_WIDTH = 195;
    // needs to be called from AfterViewInit so that the width is established and rendered
    const containerWidth =
      ((this.element.nativeElement as HTMLElement).getElementsByClassName('options')?.[0]?.clientWidth as number) ||
      1000;
    const totalDiamondsPerRow = Math.floor(containerWidth / DIAMOND_WIDTH);
    this.endRow1 = totalDiamondsPerRow;
    this.startRow2 = totalDiamondsPerRow;
    this.endRow2 = totalDiamondsPerRow * 2 - 1;
    let row = 0;
    if (this.displayOptions != null) {
      this.optionRows = this.displayOptions.reduce(
        (acc, option) => {
          if (acc[row].length >= totalDiamondsPerRow * 2 - 1) {
            row += 1;
            acc.push([]);
          }
          acc[row].push(option);
          return acc;
        },
        [[]]
      ) as DisplayOption[][];
    }
  }

  private _hideOptions(): void {
    const IMAGE_ICON_WIDTH = 150;
    // needs to be called from AfterViewInit so that the width is established and rendered
    const containerWidth =
      ((this.element.nativeElement as HTMLElement).getElementsByClassName('options')?.[0]?.clientWidth as number) ||
      1000;
    const totalOptionsPerRow = Math.floor(containerWidth / IMAGE_ICON_WIDTH);
    this.rowsToShow = Math.ceil(this.minOptions / totalOptionsPerRow);
  }

  getSelectStatementString(min: number, max: number): string {
    if (min != null || max != null) {
      if (min == null && max != null) {
        return `Select up to ${max}`;
      } else if (min != null && max == null) {
        return `Select at least ${min}`;
      } else if (min === max && min != null) {
        return `Select ${min}`;
      } else if (min != null && max != null && min !== max) {
        return `Select between ${min} and ${max}`;
      } else {
        return max == null
          ? `${this.numberOfOptionsSelected} selected`
          : `${this.numberOfOptionsSelected} of ${max} selected`;
      }
    } else {
      return '';
    }
  }

  onSelect(option: DisplayOption) {
    if (this.displayOptions) {
      if (this.max === 1) {
        option.isSelected = !option.isSelected;
        // make checkboxes work like radio buttons when max === 1
        this.displayOptions.forEach((displayOption) => {
          if (displayOption.id !== option.id) {
            displayOption.isSelected = false;
          }
        });
      } else {
        option.isSelected = option.isSelected ? false : this.isLessThanMax();
      }
      this.numberOfOptionsSelected = this.displayOptions.filter((questionOption) => questionOption.isSelected).length;
      this.answerSelected.emit(this.displayOptions);
    }
  }

  isLessThanMax(): boolean {
    return this.max == null
      ? true
      : this.displayOptions.filter((displayOption) => displayOption.isSelected).length < this.max;
  }
}

export const MockWordListComponent = MockComponent({
  selector: 'kp-word-list',
  inputs: ['displayOptions', 'max', 'min', 'questionType'],
});
