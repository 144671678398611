import { DialogueBlock } from '@kp/dialogue/models/dialogue-block.model';
import { Deserializable, JsonObject } from '@ktypes/models';

export enum DialogueLogicKey {
  dialogue_daily_reflection_v1 = 'dialogue_daily_reflection_v1',
  dialogue_purposeful_onboarding_v1 = 'dialogue_purposeful_onboarding_v1',
  dialogue_resourceful_onboarding_v1 = 'dialogue_resourceful_onboarding_v1',
  focus_aware_daily_checkin_dialogue = 'focus_aware_daily_checkin_dialogue',
  getting_to_know_you = 'getting_to_know_you',
  gpt_coach_checkin = 'gpt_coach_checkin',
  gpt_opt_in_dialogue = 'gpt_opt_in_dialogue',
  purpose_crafting_dialogue = 'purpose_crafting_dialogue',
  templated_question_set_dialogue = 'templated_question_set_dialogue',
  templated_dialogue = 'templated_dialogue',
}

// These keys are a subset of DialogueLogicKeys that use refKeys
export const DIALOGUES_USING_REF_KEYS = [
  DialogueLogicKey.templated_question_set_dialogue,
  DialogueLogicKey.templated_dialogue,
];

// These keys are a subset of DialogueLogicKeys that are closeable
export const CLOSEABLE_DIALOGUES_KEYS = [DialogueLogicKey.templated_question_set_dialogue];

// These keys are a subset of DialogueLogicKeys that create daily reflections
export const DAILY_REFLECTION_KEYS = [
  DialogueLogicKey.dialogue_daily_reflection_v1,
  DialogueLogicKey.focus_aware_daily_checkin_dialogue,
];

// TODO: is there a better generic name other that KumanuKeyType?
export enum KumanuKeyType {
  logicKey = 'logicKey',
  refKey = 'refKey',
}

// TODO: This might be able to be useful in places other than the Dialogue, extract if it gets used elsewhere
export interface Progress {
  blockIndex: number;
  totalCount: number;
}

export class Dialogue implements Deserializable<JsonObject, Dialogue> {
  constructor(
    public id?: string,
    public deviceCreatedTimestamp?: string,
    public dialogueBlock?: DialogueBlock,
    public languageCode?: string,
    public logicKey?: DialogueLogicKey,
    public progress?: Progress,
    public refKey?: string,
    public systemKey?: string
  ) {}

  deserialize(input: JsonObject): Dialogue {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.deviceCreatedTimestamp = input['deviceCreatedTimestamp'] as string;
    this.dialogueBlock = new DialogueBlock().deserialize(input['currentUserDialogueBlock'] ?? input['dialogueBlock']);
    this.languageCode = input['languageCode'] as string;
    this.logicKey = input['logicKey'] as DialogueLogicKey;
    this.progress = input['progress'] as Progress;
    this.refKey = input['refKey'] as string;
    this.systemKey = input['systemKey'] as string;
    return this;
  }
}
