import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { DataStatus, JsonObject, SocialChallenge, Status } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SocialChallengeApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, dataStoreService, environmentVariablesService);
  }

  async getChallenges(): Promise<DataStatus<SocialChallenge[]>> {
    const url = this.buildUrl('/social-challenges', true, {
      queryParams: {
        currentUserTimestamp: DateTimeUtil.formatInLocal(),
      },
    });
    const request$ = this.performRequest<SocialChallenge[]>(RequestType.GET, url, { includeToken: true }).pipe(
      map((response: HttpResponse<JsonObject[]>): DataStatus<SocialChallenge[]> => {
        const challenges = response?.body?.map?.((item) => {
          return new SocialChallenge().deserialize(item);
        });
        return new DataStatus(response?.ok ? Status.done : Status.error, response?.ok ? challenges : null);
      })
    );
    return firstValueFrom(request$).catch((error) => {
      console.warn('Error getting challenges: ', error);
      return new DataStatus(Status.error, null);
    });
  }

  async getChallenge(id: string): Promise<DataStatus<SocialChallenge>> {
    const url = this.buildUrl(`/social-challenge/${id}`, true, {
      queryParams: {
        currentUserTimestamp: DateTimeUtil.formatInLocal(),
      },
    });
    const request$ = this.performRequest<SocialChallenge>(RequestType.GET, url, { includeToken: true }).pipe(
      map((response: HttpResponse<JsonObject>): DataStatus<SocialChallenge> => {
        const challenge = new SocialChallenge().deserialize(response?.body);
        return new DataStatus(response?.ok ? Status.done : Status.error, response?.ok ? challenge : null);
      })
    );
    return firstValueFrom(request$).catch((error) => {
      console.warn(`Error getting challenge ${id}: `, error);
      return new DataStatus(Status.error, null);
    });
  }
}
