import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationBloc } from '@kbloc';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent {
  @Input() navActive: boolean;
  @Input() quickActionsOpen = false;
  @Output() navBtnClick = new EventEmitter<void>();
  @Output() quickActionsToggled = new EventEmitter<boolean>();

  constructor(public navBloc: NavigationBloc) {}
}

export const MockSiteFooterComponent = MockComponent({
  selector: 'kp-site-footer',
  inputs: ['navActive', 'quickActionsOpen'],
});
