import { Location } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MutationPlugin, ResizePlugin } from '@kp/shared/components/carousel/plugins/carousel-plugins';
import { ImageRecord } from '@kp/shared/image/image-record.model';
import { ImageBloc, ImageType } from '@kp/shared/image/image.bloc';
import { productDisplayName } from '@kservice';
import { StringUtil } from '@kutil';
import { MockComponent } from '@kutil/test';
import { KeenSliderInstance, KeenSliderOptions } from 'keen-slider';

@Component({
  selector: 'kp-photo-carousel',
  templateUrl: './photo-carousel.component.html',
  styleUrls: ['./photo-carousel.component.scss'],
})
export class PhotoCarouselComponent implements OnInit, OnChanges {
  @Input() allImages: ImageRecord | ImageRecord[];
  @Input() initialImageType: ImageType;
  @Input() selectedImageId: string;
  @Output() closeModal = new EventEmitter<void>();

  images: ImageRecord[];
  initialImageIndex: number;
  deletingImage = false;
  MutationPlugin = MutationPlugin;
  product = productDisplayName;
  ResizePlugin = ResizePlugin;
  showConfirmDeletion = false;
  showNavButtons = false;
  slider: KeenSliderInstance;
  sliderOptions: KeenSliderOptions;

  private _deletedImageId: string;
  private _initialImageId: string;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (
      (event.target as HTMLElement)?.tagName?.toLowerCase() === 'kp-photo-carousel' ||
      (event.target as HTMLElement)?.classList.contains('carousel-image-wrap')
    ) {
      this._closeImageModal();
    }
  }

  constructor(
    private _imageBloc: ImageBloc,
    private _location: Location
  ) {}

  ngOnInit() {
    this.images = !Array.isArray(this.allImages) ? [this.allImages] : this.allImages;
    this.initialImageIndex = this.images?.findIndex?.((image) => this.selectedImageId === image?.id);
    this.sliderOptions = {
      loop: true,
      initial: this.initialImageIndex || 0,
      slides: {
        perView: 1,
        spacing: 20,
      },
      created: (slider: KeenSliderInstance) => {
        this.slider = slider;
      },
    };
    this.showNavButtons = this.images?.length > 1;
    this._initialImageId = isSingleImageDisplayType(this.initialImageType) ? this.selectedImageId : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.allImages) {
      this.images = !Array.isArray(this.allImages) ? [this.allImages] : this.allImages;
      if (this.deletingImage) {
        // images updated after image deletion
        if (this._initialImageId === this._deletedImageId && isSingleImageDisplayType(this.initialImageType)) {
          // if original image type was Random or Latest (i.e. single image uses, refresh after deleted)
          // @ts-ignore ignoring to allow for dynamic function calls
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this._imageBloc[`fetch${StringUtil.capitalizeFirstLetter(this.initialImageType)}Image`]();
        }

        this.showNavButtons = this.images?.length > 1;
        this.deletingImage = false;
        this.showConfirmDeletion = false;

        if (this.images?.length < 1) {
          // if last image
          this._closeImageModal();
        }
      }
    }
  }

  _closeImageModal() {
    this.slider?.destroy();
    this.closeModal.emit();
  }

  checkCurrentPage(event: Event) {
    if (this._location.isCurrentPathEqualTo('/me/photos')) {
      // don't follow link if already on photos page, just close modal
      event.preventDefault();
    }
    this._closeImageModal();
  }

  deleteImageIntent() {
    this.showConfirmDeletion = true;
  }

  deleteImage(confirm: boolean) {
    if (!confirm) {
      this.showConfirmDeletion = false;
    } else {
      const imageToDelete = this.images?.[this.slider?.track.details.rel];
      if (!imageToDelete?.id) {
        console.warn('no imageId found, cannot delete image');
        this.showConfirmDeletion = false;
        return;
      }
      this._deletedImageId = imageToDelete?.id;
      this.deletingImage = true;

      // trigger photo deletion
      this._imageBloc.deleteImage(imageToDelete?.id, imageToDelete?.type);
    }
  }
}

function isSingleImageDisplayType(imageType: ImageType) {
  return [ImageType.random, ImageType.latest].includes(imageType);
}

export const MockPhotoCarouselComponent = MockComponent({
  selector: 'kp-photo-carousel',
  inputs: ['allImages', 'initialImageType', 'selectedImageId'],
});
