import { Component, Input, OnChanges } from '@angular/core';
import { CardBloc } from '@kp/shared/components/cards/card.bloc';
import { CardEventType } from '@ktypes/enums';
import { CardItem } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-habit-pairing',
  templateUrl: './habit-pairing.component.html',
  styleUrls: ['./habit-pairing.component.scss'],
})
export class HabitPairingComponent implements OnChanges {
  @Input() card: CardItem;

  readonly addIcon = 'assets/icons/plus-circle.svg';
  readonly cancelIcon = 'assets/icons/x-circle.svg';
  readonly editIcon = 'assets/icons/edit-circle.svg';

  habitText = '';
  primaryActionIcon = this.addIcon;
  showEditableHabit = false;
  showCompactHabit = false;
  showInfo: boolean;

  constructor(private _cardBloc: CardBloc) {}

  ngOnChanges() {
    if (this.card?.userState?.habits?.[0]?.value) {
      this._showExistingCompactHabit();
    }
  }

  updateHabit() {
    if (this.showEditableHabit && !this.showCompactHabit) {
      if (this.card?.userState?.habits?.[0]?.value) {
        this._showExistingCompactHabit();
      } else {
        this._showNoHabits();
      }
    } else {
      this.showEditableHabit = true;
      this.showCompactHabit = false;
      this.primaryActionIcon = this.cancelIcon;
    }
  }

  resetHabits() {
    this._showNoHabits();
    this._cardBloc.handleHabitEvents(CardEventType.HABIT_DELETED, this.card?.id, this.card?.userState?.habits?.[0]?.id);
  }

  saveHabit() {
    this.showEditableHabit = false;
    this.showCompactHabit = true;
    this.primaryActionIcon = this.editIcon;
    this._cardBloc.handleHabitEvents(
      this.card?.userState?.habits?.[0]?.id ? CardEventType.HABIT_EDITED : CardEventType.HABIT_ADDED,
      this.card?.id,
      this.card?.userState?.habits?.[0]?.id ? this.card?.userState?.habits?.[0]?.id : null,
      this.habitText
    );
  }

  private _showExistingCompactHabit() {
    this.showEditableHabit = false;
    this.showCompactHabit = true;
    this.habitText = this.card.userState.habits?.[0]?.value;
    this.primaryActionIcon = this.editIcon;
  }

  private _showNoHabits() {
    this.showEditableHabit = false;
    this.showCompactHabit = false;
    this.habitText = '';
    this.primaryActionIcon = this.addIcon;
  }
}

export const MockHabitPairingComponent = MockComponent({
  selector: 'kp-habit-pairing',
  inputs: ['card'],
});
