import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { TagBloc } from '@kbloc';
import { Tag, TagKey } from '@ktypes/models';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface TagKeyData {
  tag: TagKey;
  redirectUrl: string;
}

enum TagCheck {
  required,
  excluded,
}

@Injectable({
  providedIn: 'root',
})
export class TagGuard {
  constructor(
    public router: Router,
    private _tagBloc: TagBloc
  ) {}

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot /*,
    routerStateSnapshot: RouterStateSnapshot*/
  ): Observable<boolean | UrlTree> {
    const { requiredTags, excludedTags } = (activatedRouteSnapshot.data ?? []) as {
      requiredTags: TagKeyData[];
      excludedTags: TagKeyData[];
    };
    return this._tagBloc.userTags$.pipe(
      filter((tags: Tag[]) => tags != null),
      map((tags: Tag[]) => {
        const userTags: TagKey[] = tags.map((tag) => tag.tagKey as TagKey);
        const meetsRequired = checkTags(requiredTags, userTags, TagCheck.required);
        const meetsExcluded = checkTags(excludedTags, userTags, TagCheck.excluded);
        if (typeof meetsRequired === 'string') {
          return this.router.parseUrl(meetsRequired);
        }
        if (typeof meetsExcluded === 'string') {
          return this.router.parseUrl(meetsExcluded);
        }
        if (!meetsRequired || !meetsExcluded) {
          console.warn('TagGuard: Did not meet tag requirements but no redirect URL provided');
          return this.router.parseUrl('/welcome/error');
        }
        return true;
      })
    );
  }
}

function checkTags(tags: TagKeyData[], userTags: TagKey[], checkType: TagCheck): boolean | string {
  let lastRedirectUrl: string;
  const meetsTagRequirements = tags?.every((requiredTagData) => {
    const { tag, redirectUrl } = requiredTagData || {};
    lastRedirectUrl = redirectUrl;
    return userTags.includes(tag) === (checkType === TagCheck.required);
  });
  if (!meetsTagRequirements && lastRedirectUrl) {
    return lastRedirectUrl;
  }
  return meetsTagRequirements !== false; // explicitly checking for false as undefined is an acceptable result
}
