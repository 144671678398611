import { Component, Input, OnInit } from '@angular/core';
import { LocalResourceHours } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-local-resource-hours',
  templateUrl: './local-resource-hours.component.html',
  styleUrls: ['./local-resource-hours.component.scss'],
})
export class LocalResourceHoursComponent implements OnInit {
  @Input() hours: LocalResourceHours;

  isOpenToday: boolean;
  todayStartTime: string;
  todayFinishTime: string;

  ngOnInit() {
    this.isOpenToday = this.hours.days?.find(
      (localResourceDay) => localResourceDay.day === DateTimeUtil.weekdayName(new Date(), true)
    )?.isOpen;
    this.todayStartTime = this.hours.days?.find(
      (localResourceDay) => localResourceDay.day === DateTimeUtil.weekdayName(new Date(), true)
    )?.start;
    this.todayFinishTime = this.hours.days?.find(
      (localResourceDay) => localResourceDay.day === DateTimeUtil.weekdayName(new Date(), true)
    )?.finish;
  }
}

export const MockLocalResourceHoursComponent = MockComponent({
  selector: 'kp-local-resource-hours',
  inputs: ['hours'],
});
