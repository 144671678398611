import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KumanuKeyType } from '@kp/dialogue/models/dialogue.model';
import { Question, QuestionLogicKey } from '@kp/question-set/models/question.model';
import { QuestionApi } from '@kp/question-set/question/question.api';
import { DataStatus, Status, StatusMessage } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionBloc {
  get currentQuestion$(): Observable<DataStatus<Question>> {
    return this._currentQuestion.asObservable();
  }

  constructor(private _questionApi: QuestionApi) {}

  private _currentQuestion = new BehaviorSubject<DataStatus<Question>>(null);
  private _questionStreams = new Map<string, BehaviorSubject<DataStatus<Question>>>(null);

  getLatestQuestionValue$(keyType: KumanuKeyType, key: string | QuestionLogicKey): Observable<DataStatus<Question>> {
    this.getQuestion(keyType, key);
    return this._questionStreams.get(key).asObservable();
  }

  getQuestion(keyType: KumanuKeyType, key: string | QuestionLogicKey): void {
    this._addStreamAsNeeded(key);
    this._currentQuestion.next(new DataStatus(Status.starting, null, null));
    this._questionApi
      .getQuestion(keyType, key)
      .then((question: Question) => {
        this._questionStreams.get(key).next(new DataStatus<Question>(Status.done, null, question));
        this._currentQuestion.next(new DataStatus(Status.done, null, question));
      })
      .catch((error: HttpErrorResponse) => {
        this._questionStreams
          .get(key)
          .next(new DataStatus<Question>(Status.error, new StatusMessage(Status.error, error.error), null));
        this._currentQuestion.next(new DataStatus(Status.error, new StatusMessage(Status.error, error.error), null));
      });
  }

  private _addStreamAsNeeded(key: string): void {
    if (!this._questionStreams.has(key)) {
      this._questionStreams.set(
        key,
        new BehaviorSubject<DataStatus<Question>>(new DataStatus<Question>(Status.starting, null, null))
      );
    } else {
      this._questionStreams.get(key).next(new DataStatus<Question>(Status.starting, null, null));
    }
  }
}
