import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    if (url === '/initial/:groupCode') {
      return super.parse(url);
    }
    let lowerCaseUrl = url.split('?')[0].toLowerCase();
    const queryParams = url.split('?')[1];
    if (queryParams) {
      lowerCaseUrl = lowerCaseUrl + `?${queryParams}`;
    }
    return super.parse(lowerCaseUrl);
  }
}
