import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionGroupComponent implements OnInit, AfterViewInit {
  @Input() key: string;
  @Input() initialStateOpen = true; // defaults to open
  @Input()
  @HostBinding('class.on-background')
  onBackground = false;

  @Output() didChange = new EventEmitter<boolean>();

  isOpen = false;
  bodyOpenHeight = 0;
  isAnimating = false;

  @ViewChild('input', { static: false }) inputCheckbox: ElementRef;
  @ViewChild('collapsibleBody', { static: false }) collapsibleBody: ElementRef;

  ngOnInit() {
    this.isOpen = this.initialStateOpen;
    this.key = `${this.key ?? ''}_${Math.round(Math.random() * 1000000)}`; // random to ensure keys are unique
  }

  ngAfterViewInit() {
    if (!this.isOpen && this.collapsibleBody) {
      (this.collapsibleBody.nativeElement as HTMLElement).style.height = '0px';
    }
    setTimeout(() => {
      this.bodyOpenHeight = ((this.collapsibleBody?.nativeElement as HTMLElement)?.scrollHeight as number) || 0;
      this._updateAccordion(this.isOpen);
    }, 400);
  }

  accordionClick(isChecked: boolean) {
    this._updateAccordion(isChecked);
    this.didChange.emit(isChecked);

    // blur and focus checkbox in order to update voiceover text if in use
    this.isAnimating = true;
    (this.inputCheckbox.nativeElement as HTMLElement).blur();
    setTimeout(() => {
      (this.inputCheckbox.nativeElement as HTMLElement).focus();
      this.isAnimating = false;
    }, 400);
  }

  private _updateAccordion(isChecked: boolean) {
    this.isOpen = isChecked;
    if (this.collapsibleBody) {
      // update bodyOpenHeight in case content has changed since ViewInit
      this.bodyOpenHeight = ((this.collapsibleBody?.nativeElement as HTMLElement)?.scrollHeight as number) || 0;
      (this.collapsibleBody.nativeElement as HTMLElement).style.height = isChecked ? `${this.bodyOpenHeight}px` : '0px';
    }
  }
}

export const MockAccordionGroupComponent = MockComponent({
  selector: 'kui-accordion-group',
  inputs: ['key', 'initialStateOpen'],
  template: '<ng-content></ng-content>',
});
