import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { DataStatus, Status, StatusMessage, Version } from '@ktypes/models';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VersionApi extends BaseApi {
  constructor(
    private client: HttpClient,
    public dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async getVersions(): Promise<DataStatus<Version[]>> {
    const url = this.buildUrl('/versions');
    const request = this.performRequest<Version[]>(RequestType.GET, url).pipe(
      map((response: HttpResponse<Version[]>) => {
        if (response?.status === HttpStatusCode.OK) {
          return new DataStatus<Version[]>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            response.body?.map((version) => new Version().deserialize(version))
          );
        }
        return new DataStatus(Status.error, null, null);
      })
    );

    return firstValueFrom(request).catch((error): DataStatus<Version[]> => {
      console.warn(`Error getting app versions`, error);
      return new DataStatus(Status.error, null, null);
    });
  }
}
