<div class="reflection-container">
  <h1 id="pastReflectionsTitle">{{ 'Past Reflections' | translate | async }}</h1>
  @if (reflections?.status === status.done && reflections?.data; as reflection) {
    <div class="reflection-inner-container">
      <div class="reflection-header">
        @if (reflection.hasMoreQuestions) {
          <button
            #reflectionBack
            class="container-caret left"
            (click)="onBack(reflection.hasMoreQuestions)"
            [attr.aria-label]="'Previous reflection' | translate | async">
            <svg-icon src="/assets/icons/chevron-left-nocircle.svg"></svg-icon>
          </button>
        } @else {
          <ng-container *ngTemplateOutlet="placeholder"></ng-container>
        }
        <div id="date" class="reflection-header-date page-item-center">
          {{ reflection.date | date: 'fullDate' | translate | async }}
        </div>
        @if (index !== 0) {
          <button
            #reflectionNext
            class="container-caret right"
            (click)="onForward()"
            [attr.aria-label]="'Next reflection' | translate | async">
            <svg-icon src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
          </button>
        } @else {
          <ng-container *ngTemplateOutlet="placeholder"></ng-container>
        }
      </div>
      @if (reflection.availableDates) {
        <div class="reflections-container">
          @if (reflections.status === status.done && !hasReflections) {
            <div class="no-reflections">
              <h3 class="no-reflections no-reflections-header">{{ 'Nothing here yet!' | translate | async }}</h3>
              <p class="no-reflections no-reflections-description">
                {{ 'Complete a reflection to see your responses here.' | translate | async }}
              </p>
            </div>
          }
          @if (!reflection.isLoading && reflection.availableDates[index] === null) {
            <div class="reflection-nocontent">
              <span>{{ 'No Available Data' | translate | async }}</span>
            </div>
          }
          @if (hasReflections && questionSets) {
            @for (questionSet of questionSets; track questionSet) {
              <div class="reflection-content">
                <kp-reflection-question-set [questionSet]="questionSet"></kp-reflection-question-set>
              </div>
            }
          }
        </div>
      }
    </div>
  } @else {
    <kp-shimmer class="date-shimmer"></kp-shimmer>
    <kp-shimmer class="past-reflection-shimmer"></kp-shimmer>
    <kp-shimmer class="past-reflection-shimmer"></kp-shimmer>
    <kp-shimmer class="past-reflection-shimmer"></kp-shimmer>
    <kp-shimmer class="past-reflection-shimmer"></kp-shimmer>
  }
</div>

<ng-template #placeholder>
  <div class="container-caret placeholder"></div>
</ng-template>
