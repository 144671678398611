import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { JsonObject } from '@ktypes/models';
import { tryParseJSON } from '@kutil';

interface StorageConfigOptions {
  domain?: string;
  expires?: number;
  path?: string;
  session?: boolean;
  expiration?: string;
  envName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BrowserStorage {
  constructor(private _environmentVariablesService: EnvironmentVariablesService) {}

  // Browser Storage API
  hasStorageSupport = this._hasStorageSupport;
  set = this._setValue;
  setItem = this._setValue;
  get = this._getValue;
  getItem = this._getValue;
  setObject = this._setObjectValue;
  getObject = this._getObjectValue;
  remove = this._removeValue;
  removeItem = this._removeValue;
  removeAll = this._clear;
  clear = this._clear;
  config = this.setOptions;

  /**
   * Defaults for setting cookies when the browser falls back
   */
  private _options: StorageConfigOptions = {
    domain: this._environmentVariablesService.config?.hostname?.includes('kumanu.com')
      ? 'kumanu.com'
      : this._environmentVariablesService.config?.hostname || '',
    expires: 30 * 24 * 60 * 60 * 1000,
    path: '/',
    session: false,
    envName: this._environmentVariablesService.config?.environment,
  };
  // TODO: update to use injected window?
  private _storage = window.localStorage;

  /**
   * setOptions - set options if different than defaults
   *
   * @param [config] Config object to set things like domain or cookie duration
   */
  public setOptions(config?: StorageConfigOptions) {
    if (config && this?._options) {
      this._options.domain = config.domain || this._options.domain;
      this._options.expires = config.expires || this._options.expires;
      this._options.path = config.path || this._options.path;
      this._options.session = config.session || this._options.session;
      this._options.envName = config.envName || this._options.envName;

      // setup which type of storage to use
      this._storage = this._options.session ? window.sessionStorage : window.localStorage;
    }
  }

  /**
   * hasStorageSupport - Whether the current browser supports local/session storage as a way of storing data
   *
   * @param [storageType] The storage type to check
   *
   * @returns Boolean stating whether or not storage is available
   */
  private _hasStorageSupport(storageType?: string) {
    let _storageType = this._options?.session ? 'sessionStorage' : 'localStorage';
    const _oldStorage = this._storage;
    let _hasSupport = false;

    if (this._storageTypeExists(storageType)) {
      _storageType = storageType + 'Storage';
      // @ts-ignore TODO: Revisit to resolve lint issue
      this._storage = window[_storageType] as Storage;
    }

    try {
      this._storage.test = '2';
      this._storage.setItem('test', '2');
      this._storage.removeItem('test');
      // @ts-ignore TODO: Revisit to resolve lint issue
      _hasSupport = _storageType in window && window[_storageType] !== null;
    } catch (e) {
      _hasSupport = false;
    }

    if (this._storageTypeExists(storageType)) {
      this._storage = _oldStorage;
    }
    return _hasSupport;
  }

  /**
   * _readCookie - read cookie
   *
   * @param [name] The name of the property to read from this document's cookies
   *
   * @returns The specified cookie property's value (or null if it has not been set)
   */
  private _readCookie(name: string) {
    const nameEQ = `${name}${this._options.envName ? `-${this._options.envName}` : ''}=`;
    const ca = document.cookie.split(';');
    let c: string;
    let i: number;
    for (i = 0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length) as string;
      }
      if (c.indexOf(nameEQ) === 0) {
        return decodeURI(c.substring(nameEQ.length, c.length));
      }
    }

    return null;
  }

  /**
   * _writeCookie - writes a cookie
   *
   * @param [name] The name of the property to set by writing to a cookie
   * @param [value] The value to use when setting the specified property
   * @param [lifespan] The number of days or the expiration Date until the storage of this item expires
   */
  private _writeCookie(name: string, value: string, lifespan?: number | Date) {
    const expiration = this._getExpiration(lifespan);
    const path = this._options?.path ? '; path=' + this._options?.path : '';
    const domain = this._options?.domain ? '; domain=' + this._options?.domain : '';

    document.cookie = `${name}${this._options.envName ? `-${this._options.envName}` : ''}=${encodeURI(
      value
    )}${expiration}${path}${domain}`;
  }

  /**
   * _getExpiration - gets expiration date from number of days
   *
   * @param [lifespan] The number of days or the expiration Date until the storage of this item expires
   */
  private _getExpiration(lifespan: number | Date) {
    let date: Date;
    if (lifespan) {
      date = new Date();
      if (typeof lifespan === 'number') {
        date.setTime(date.getTime() + lifespan * 24 * 60 * 60 * 1000);
      } else {
        date.setTime(lifespan.getTime());
      }
      return '; expires=' + date.toUTCString();
    } else {
      return this._options?.expiration ? ' expires=' + this._options?.expiration : '';
    }
  }

  /**
   * setValue - set a value to storage or cookie
   *
   * @param [name] The name of the property to set
   * @param [value] The value to use when setting the specified property
   * @param [lifespan] The number of days or the expiration Date until the storage of this item expires
   *                    (if storage of the provided item must fallback to using cookies)
   * @param [forceCookie] Whether or not to force the use of cookies
   * @param [storageType] Override the default storage type [local, session]
   */
  private _setValue(
    name: string,
    value: string,
    lifespan?: number | Date,
    forceCookie?: boolean,
    storageType?: string
  ) {
    if (!forceCookie && this._hasStorageSupport()) {
      if (!storageType || !this._storageTypeExists(storageType)) {
        this._storage.setItem(name, value);
      } else if (this._storageTypeExists(storageType)) {
        // @ts-ignore TODO: Revisit to resolve lint issue
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        (window[storageType + 'Storage'] as WindowLocalStorage | WindowSessionStorage).setItem(name, value);
      }
    } else {
      this._writeCookie(name, value, lifespan);
    }
  }

  /**
   * setObjectValue - Stringify the value before saving
   *
   * @param [name] The name of the value to save
   * @param [value] The value to save
   * @param [expiration] Time period before expiration (Cookies only)
   * @param [forceCookie] Whether or not to force the use of cookies
   * @param [storageType] Override the default storage type [local, session]
   */
  private _setObjectValue(
    name: string,
    value: any,
    expiration?: number | Date,
    forceCookie?: boolean,
    storageType?: string
  ) {
    const stringifiedValue = JSON.stringify(value);
    this._setValue(name, stringifiedValue, expiration, forceCookie, storageType);
  }

  /**
   * getValue - get a value by name from storage or cookie
   *
   * @param [name] The name of the value to retrieve
   * @param [forceCookie] Whether or not to force the use of cookies
   * @param [storageType] Override the default storage type [local, session]
   *
   * @returns The value requested by name from the storage type or cookie
   */
  private _getValue(name: string, forceCookie?: boolean, storageType?: string): string | Error | JsonObject {
    if (!!storageType && this._storageTypeExists(storageType)) {
      return !forceCookie && this._hasStorageSupport()
        ? // @ts-ignore TODO: Revisit to resolve lint issue
          (window[storageType + 'Storage'] as BrowserStorage).getItem(name)
        : this._readCookie(name);
    }
    return !forceCookie && this._hasStorageSupport() ? this._storage.getItem(name) : this._readCookie(name);
  }

  /**
   * getObjectValue - Parse a stringified object to JSON before returning
   *
   * @param [name] The name of the value to retrieve
   * @param [forceCookie] Whether or not to force the use of cookies
   * @param [storageType] Override the default storage type [local, session]
   *
   * @returns The JSON object requested by name from the storage type or cookie
   */
  private _getObjectValue(name: string, forceCookie?: boolean, storageType?: string) {
    const storedValue = this._getValue(name, forceCookie, storageType);
    if (storedValue != null) {
      return (tryParseJSON(storedValue) ?? {}) as JsonObject;
    }
    return null;
  }

  /**
   * removeValue - delete a cookie or storage value by name
   * @param [name] The name of the value to delete/remove from storage
   * @param [forceCookie] Whether or not to force the use of cookies
   */
  private _removeValue(name: string, forceCookie?: boolean) {
    if (!forceCookie && this._hasStorageSupport()) {
      this._storage.removeItem(name);
    } else {
      this._setValue(name, '', -1, true);
    }
  }

  /**
   * clear - remove all storage values
   * only works for localStorage or sessionStorage, not cookies!!
   */
  private _clear() {
    if (this._hasStorageSupport()) {
      this._storage.clear();
    }
  }

  /**
   * _storageTypeExists - check if the storage type passed is valid (local/session)
   * @param  [storageType] - storage type passed
   *
   * @returns returns true if the type is valid, false if not
   */
  private _storageTypeExists(storageType: string) {
    return ['local', 'session'].indexOf(storageType) > -1;
  }
}

export class MockBrowserStorage {
  _storage: JsonObject = {};
  hasStorageSupport = true;
  set(name: string, value: string, days?: number, forceCookie?: boolean, storageType?: string) {
    this._storage[name] = value;
  }
  setItem(name: string, value: string, days?: number, forceCookie?: boolean, storageType?: string) {
    this._storage[name] = value;
  }
  setObject(name: string, value: JsonObject, days?: number, forceCookie?: boolean, storageType?: string) {
    this._storage[name] = value;
  }
  get(name: string, forceCookie?: boolean, storageType?: string) {
    return this._storage[name] as JsonObject;
  }
  getItem(name: string, forceCookie?: boolean, storageType?: string) {
    return this._storage[name] as string;
  }
  getObject(name: string, forceCookie?: boolean, storageType?: string) {
    return this._storage[name] as JsonObject;
  }
  remove(name: string, forceCookie?: boolean) {
    delete this._storage[name];
  }
  removeItem(name: string, forceCookie?: boolean) {
    delete this._storage[name];
  }
  removeAll() {
    this._storage = {};
  }
  clear() {
    this._storage = {};
  }
  config(config?: StorageConfigOptions) {
    /* no-op */
  }
}
