import { Injectable } from '@angular/core';
import { LegalDocumentApi } from '@kapi';
import { GroupValidationBloc } from '@kp/auth/group-validation.bloc';
import { AuthDataService, BrowserStorage } from '@kservice';
import {
  DataStatus,
  LegalDocument,
  LegalDocumentItem,
  LegalDocumentType,
  OnboardingData,
  Status,
  StatusMessage,
} from '@ktypes/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export interface LegalDocumentStorage {
  privacy: DataStatus<LegalDocument>;
  eula: DataStatus<LegalDocument>;
}
@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsBloc {
  private _currentLegalDocumentStatus$ = new BehaviorSubject<DataStatus<LegalDocumentItem[]>>(null);
  private _serverSideAcceptLegalDocumentsResult$ = new BehaviorSubject<boolean>(null);
  private _legalDocumentStorage$ = new BehaviorSubject<LegalDocumentStorage>({
    eula: null,
    privacy: null,
  });

  privacyPledgeAccepted = false;

  constructor(
    private _authDataService: AuthDataService,
    private _browserStorage: BrowserStorage,
    // TODO: eliminate BLoC <-> BLoC injection
    private _groupValidationBloc: GroupValidationBloc,
    private _legalDocumentApi: LegalDocumentApi
  ) {}

  get currentLegalDocumentStatus(): DataStatus<LegalDocumentItem[]> {
    return this._currentLegalDocumentStatus$.getValue();
  }

  get currentLegalDocumentStatus$(): Observable<DataStatus<LegalDocumentItem[]>> {
    return this._currentLegalDocumentStatus$.asObservable();
  }

  async fetchLegalDocuments(groupIdInput?: string, docType?: LegalDocumentType) {
    const onboardingData: OnboardingData = this._browserStorage.getObject('onboarding') as OnboardingData;
    const userGroupCode: string = this._authDataService.loadToken()?.user?.group?.groupCode;
    const groupValidationInfo = this._groupValidationBloc.checkForGroup();

    if (groupValidationInfo.error && !groupIdInput && !userGroupCode) {
      this._legalDocumentStorage$.next({
        privacy: groupValidationInfo.error,
        eula: groupValidationInfo.error,
      });
      return;
    }

    // If currently fetching or already fetched, don't queue up
    // another set of requests.
    const fetchingOrAlreadyFetched = (document: DataStatus<LegalDocument>) => {
      return (document && document.status === Status.starting) || (document && document.data != null);
    };

    if (
      fetchingOrAlreadyFetched(this.legalDocumentStorage.privacy) &&
      fetchingOrAlreadyFetched(this.legalDocumentStorage.eula)
    ) {
      return;
    }

    // use, in order, groupId passed in, from groupValidationInfo, onboardingData
    let groupId = groupIdInput || groupValidationInfo.groupId || onboardingData?.groupId;

    const starting = new DataStatus<LegalDocument>(Status.starting, new StatusMessage(Status.starting, ''), null);
    this._legalDocumentStorage$.next({
      privacy: starting,
      eula: starting,
    });

    if (!groupId && userGroupCode) {
      this._groupValidationBloc.refreshGroup(userGroupCode);
      let subscription: Subscription;
      groupId = await new Promise((resolve, reject) => {
        subscription = this._groupValidationBloc.groupValidationDataStatus$.subscribe((status) => {
          if (status && status.status === Status.done && status?.data?.id != null) {
            resolve(status?.data?.id);
          } else if (status && status.status === Status.error) {
            reject(null);
          }
        });
      });
      if (subscription) {
        subscription.unsubscribe();
      }
    }

    if (groupId) {
      switch (docType) {
        case LegalDocumentType.PRIVACY:
          this._legalDocumentStorage$.next({
            privacy: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.PRIVACY),
            eula: null,
          });
          break;
        case LegalDocumentType.EULA:
          this._legalDocumentStorage$.next({
            privacy: null,
            eula: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.EULA),
          });
          break;
        default:
          this._legalDocumentStorage$.next({
            privacy: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.PRIVACY),
            eula: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.EULA),
          });
          break;
      }
    } else {
      // no group Id found
      this._legalDocumentStorage$.next({
        privacy: new DataStatus<LegalDocument>(Status.error, new StatusMessage(Status.error, ''), null),
        eula: new DataStatus<LegalDocument>(Status.error, new StatusMessage(Status.error, ''), null),
      });
    }
  }

  getCurrentLegalDocumentStatus(): void {
    this._legalDocumentApi.getCurrentLegalDocumentStatus().then((docStatus) => {
      this._currentLegalDocumentStatus$.next(docStatus);
    });
  }

  clearLegalDocumentStatus(privacyPledgeStatus = false): void {
    // update privacyPledgeAccepted before clearing
    this.privacyPledgeAccepted = privacyPledgeStatus;
    this._currentLegalDocumentStatus$.next(null);
  }

  resetServerSideLegalDocumentsAcceptance() {
    this._serverSideAcceptLegalDocumentsResult$.next(null);
  }

  serverSideAcceptLegalDocuments(type?: LegalDocumentType) {
    const privacyId = this.legalDocumentStorage?.privacy?.data?.id;
    const eulaId = this.legalDocumentStorage?.eula?.data?.id;
    switch (type) {
      case LegalDocumentType.PRIVACY:
        if (!privacyId) {
          console.warn('Missing privacy policy, unable to accept.');
          this._serverSideAcceptLegalDocumentsResult$.next(false);
          return;
        }
        break;
      case LegalDocumentType.EULA:
        if (!eulaId) {
          console.warn('Missing eula, unable to accept.');
          this._serverSideAcceptLegalDocumentsResult$.next(false);
          return;
        }
        break;
      default:
        if (!privacyId || !eulaId) {
          console.warn('Missing privacy policy and/or eula, unable to accept.');
          this._serverSideAcceptLegalDocumentsResult$.next(false);
          return;
        }
        break;
    }

    Promise.all([
      new Promise<boolean>((resolve) => {
        if (!type || type === LegalDocumentType.PRIVACY) {
          this._legalDocumentApi
            .acceptLegalDocument(privacyId)
            .then((result) => resolve(result))
            .catch(() => resolve(false));
        } else {
          resolve(true);
        }
      }),
      new Promise<boolean>((resolve) => {
        if (!type || type === LegalDocumentType.EULA) {
          this._legalDocumentApi
            .acceptLegalDocument(eulaId)
            .then((result) => resolve(result))
            .catch(() => resolve(false));
        } else {
          resolve(true);
        }
      }),
    ]).then(([privacyResult, eulaResult]) => {
      this._serverSideAcceptLegalDocumentsResult$.next(privacyResult && eulaResult);
    });
  }

  get legalDocumentStorage$(): Observable<LegalDocumentStorage> {
    return this._legalDocumentStorage$.asObservable();
  }

  get legalPrivacyDocument$(): Observable<LegalDocument | undefined> {
    return this._legalDocumentStorage$.pipe(map((legalDocuments) => legalDocuments?.privacy?.data));
  }

  get legalEulaDocument$(): Observable<LegalDocument | undefined> {
    return this._legalDocumentStorage$.pipe(map((legalDocuments) => legalDocuments?.eula?.data));
  }

  get legalDocumentStorage(): LegalDocumentStorage {
    return this._legalDocumentStorage$.getValue();
  }

  clearLegalDocumentStorage(): void {
    this._legalDocumentStorage$.next({
      eula: null,
      privacy: null,
    });
  }

  get serverSideAcceptLegalDocumentsResult$(): Observable<boolean> {
    return this._serverSideAcceptLegalDocumentsResult$.asObservable();
  }

  get serverSideAcceptLegalDocumentsResult(): boolean {
    return this._serverSideAcceptLegalDocumentsResult$.getValue();
  }

  resetPrivacyPledgeAccepted() {
    this.privacyPledgeAccepted = false;
  }
}
