<div class="hours">
  <svg-icon src="/assets/icons/clock.svg" class="hidden-print" [ngClass]="{ closed: !isOpenToday }"></svg-icon>
  @if (isOpenToday) {
    <h4 class="title-hours today hidden-print">
      {{ 'Open Today' | translate | async }} &middot; {{ todayStartTime }} - {{ todayFinishTime }}
    </h4>
  }
  @if (!isOpenToday) {
    <h4 class="title-hours closed hidden-print">{{ 'Closed Today' | translate | async }}</h4>
  }
  <h4 class="title-hours hidden-screen">{{ 'Hours' | translate | async }}</h4>

  <ul class="hours-list">
    @for (day of hours.days; track day) {
      <li>
        {{ (day.day | translate | async)?.charAt(0) | uppercase }}:
        @if (day.isOpen) {
          <span>
            {{ day.start | lowercase | translate | async }} - {{ day.finish | lowercase | translate | async }}
          </span>
        } @else {
          {{ 'Closed' | translate | async }}
        }
      </li>
    }
  </ul>
</div>
