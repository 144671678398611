import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotificationRange } from '@ktypes/models';
import { ClockSystem, DateTimeUtil } from '@kutil';
import { MockComponent } from '@kutil/test';

export enum TimeProperty {
  hour = 'hour',
  minutes = 'minutes',
  amPM = 'AMPM',
}

@Component({
  selector: 'kp-modal-time-picker',
  templateUrl: './modal-time-picker.component.html',
  styleUrls: ['./modal-time-picker.component.scss'],
})
export class ModalTimePickerComponent implements OnInit {
  @Input() notificationRange: NotificationRange;
  @Input() svgURL: string;
  @Input() time: string;

  @Output() closeClicked = new EventEmitter();
  @Output() saveClicked = new EventEmitter<string>();

  timeOfDay: string;
  is24HrFormat: boolean;
  hourIndex: number;
  minutesIndex: number;
  amPMIndex: number;
  timeProperty = TimeProperty;
  showMinInput = false;
  showHourInput = false;

  @ViewChild('hourInput', { static: false }) hourInput: ElementRef;
  @ViewChild('minutesInput', { static: false }) minutesInput: ElementRef;
  timeChangeAnnouncement = '';

  ngOnInit() {
    const notificationRangeString = this.notificationRange.toString();
    this.timeOfDay = notificationRangeString[0].toUpperCase() + notificationRangeString.substring(1);
    this.is24HrFormat = !this.time.includes('AM') && !this.time.includes('PM');
    this.getHoursAndMinutes();
  }

  private getHoursAndMinutes() {
    const hour = Number(this.time.substr(0, this.time.indexOf(':')));
    const minutes = this.time.substr(this.time.indexOf(':') + 1, 2);

    this.minutesIndex = DateTimeUtil.reminderMinutes().findIndex((minutesStr) => minutesStr === minutes);
    if (this.is24HrFormat) {
      this.hourIndex = DateTimeUtil.hourArray(ClockSystem.hr24).findIndex((hourStr) => +hourStr === hour);
    } else {
      const AMPM = this.time.substring(this.time.length - 2);
      this.hourIndex = DateTimeUtil.hourArray(ClockSystem.hr12).findIndex((hourStr) => +hourStr === hour);
      this.amPMIndex = DateTimeUtil.AMPM().findIndex((amPMStr) => amPMStr === AMPM);
    }
  }

  get minHour(): string {
    return this.is24HrFormat
      ? DateTimeUtil.hourArray(ClockSystem.hr24)[0]
      : DateTimeUtil.hourArray(ClockSystem.hr12)[0];
  }

  get maxHour(): string {
    return this.is24HrFormat
      ? DateTimeUtil.hourArray(ClockSystem.hr24)[DateTimeUtil.hourArray(ClockSystem.hr24).length - 1]
      : DateTimeUtil.hourArray(ClockSystem.hr12)[DateTimeUtil.hourArray(ClockSystem.hr12).length - 1];
  }

  get hour(): string {
    return this.is24HrFormat
      ? DateTimeUtil.hourArray(ClockSystem.hr24)[this.hourIndex]
      : DateTimeUtil.hourArray(ClockSystem.hr12)[this.hourIndex];
  }

  get minutes(): string {
    return DateTimeUtil.reminderMinutes()[this.minutesIndex];
  }

  get AMPM(): string {
    return DateTimeUtil.AMPM()[this.amPMIndex];
  }

  showTimePropertyInput(timeProperty: TimeProperty) {
    switch (timeProperty) {
      case TimeProperty.hour:
        this.showHourInput = true;
        setTimeout(() => (this.hourInput.nativeElement as HTMLElement).focus(), 0);
        break;
      case TimeProperty.minutes:
        this.showMinInput = true;
        setTimeout(() => (this.minutesInput.nativeElement as HTMLElement).focus(), 0);
        break;
    }
  }

  inputTime(timeProperty: TimeProperty, value: string) {
    switch (timeProperty) {
      case TimeProperty.hour:
        if (this.is24HrFormat) {
          this.hourIndex = findClosestValue(DateTimeUtil.hourArray(ClockSystem.hr24), value);
        } else {
          this.hourIndex = findClosestValue(DateTimeUtil.hourArray(ClockSystem.hr12), value);
        }
        this.showHourInput = false;
        break;
      case TimeProperty.minutes:
        this.minutesIndex = findClosestValue(DateTimeUtil.reminderMinutes(), value);
        this.showMinInput = false;
        break;
    }
  }

  adjustTime(timeProperty: TimeProperty, adjuster: number) {
    switch (timeProperty) {
      case TimeProperty.hour: {
        const totalHours = this.is24HrFormat
          ? DateTimeUtil.hourArray(ClockSystem.hr24).length
          : DateTimeUtil.hourArray(ClockSystem.hr12).length;
        const lastHour = this.is24HrFormat
          ? DateTimeUtil.hourArray(ClockSystem.hr24).length - 1
          : DateTimeUtil.hourArray(ClockSystem.hr12).length - 1;
        this.hourIndex = this.hourIndex + adjuster;
        if (this.hourIndex >= totalHours) {
          this.hourIndex = 0;
        } else if (this.hourIndex < 0) {
          this.hourIndex = lastHour;
        }
        this.timeChangeAnnouncement = `Hours changed to ${this.hour}`;
        break;
      }
      case TimeProperty.minutes:
        this.minutesIndex = this.minutesIndex + adjuster;
        if (this.minutesIndex >= DateTimeUtil.reminderMinutes().length) {
          this.minutesIndex = 0;
        } else if (this.minutesIndex < 0) {
          this.minutesIndex = DateTimeUtil.reminderMinutes().length - 1;
        }
        this.timeChangeAnnouncement = `Minutes changed to ${this.minutes}`;
        break;
      case TimeProperty.amPM:
        this.amPMIndex = this.amPMIndex + adjuster;
        if (this.amPMIndex >= DateTimeUtil.AMPM().length) {
          this.amPMIndex = 0;
        } else if (this.amPMIndex < 0) {
          this.amPMIndex = DateTimeUtil.AMPM().length - 1;
        }
        this.timeChangeAnnouncement = `AM PM changed to ${this.AMPM}`;
        break;
    }
  }

  save() {
    this.saveClicked.emit(
      this.is24HrFormat ? `${this.hour}:${this.minutes}` : `${this.hour}:${this.minutes} ${this.AMPM}`
    );
    this.closeClicked.emit();
  }
}

function findClosestValue(timeValues: string[], valueFromUserInput: string): number {
  return timeValues.findIndex(
    (stringToMatch) => stringToMatch === timeValues.reduce((prev, curr) => timeReducer(prev, curr, valueFromUserInput))
  );
}

function timeReducer(prev: string, curr: string, value: string) {
  return Math.abs(+curr - +value) < Math.abs(+prev - +value) ? curr : prev;
}

export const MockModalTimePickerComponent = MockComponent({
  selector: 'kp-modal-time-picker',
  inputs: ['notificationRange', 'svgURL', 'time'],
});
