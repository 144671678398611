import { envVariables } from '@kenv';
import { Product } from '@ktypes/enums';
import { StringUtil } from '@kutil';

// defaults to Purposeful if no PRODUCT environment variable set
export const productDisplayName: string = (envVariables as { [key: string]: string }).PRODUCT
  ? StringUtil.capitalizeFirstLetter(
      Product[((envVariables as { [key: string]: string }).PRODUCT as string).toLowerCase() as Product]
    )
  : StringUtil.capitalizeFirstLetter(Product.purposeful);
