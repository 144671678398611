export class QuestionSetSource {
  static actCard = 'completed_from_act_card';
  static afternoonReflection = 'completed_from_afternoon_reflection';
  static eveningReflection = 'completed_from_evening_reflection';
  static discoverCard = 'completed_from_discover_card';
  static morningReflection = 'completed_from_morning_reflection';
  static notificationReflection = 'completed_from_notification';
  static pulseSurvey = 'completed_from_pulse_survey';
  static questCard = 'completed_from_quest_card';

  static pastReflectionsFilter = [
    QuestionSetSource.actCard,
    QuestionSetSource.afternoonReflection,
    QuestionSetSource.eveningReflection,
    QuestionSetSource.discoverCard,
    QuestionSetSource.morningReflection,
    QuestionSetSource.notificationReflection,
    QuestionSetSource.questCard,
  ];
  static dailyReflections = [
    QuestionSetSource.afternoonReflection,
    QuestionSetSource.eveningReflection,
    QuestionSetSource.morningReflection,
  ];
}
