import { Injectable } from '@angular/core';
import { ContentEventApi } from '@kapi';
import { ContentEventCreatedResponse, ContentEventType } from '@ktypes/enums';
import { ContentEvent, JsonObject } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentEventBloc {
  constructor(private _contentEventApi: ContentEventApi) {}

  private _contentEventResponse$ = new Subject<ContentEventCreatedResponse>();

  get latestContentEventResponse$(): Observable<ContentEventCreatedResponse> {
    return this._contentEventResponse$.asObservable();
  }

  createContentEvent(contentId: string, eventType: ContentEventType, eventInfo?: JsonObject) {
    this._contentEventApi
      .createContentEvent(
        new ContentEvent().deserialize({
          contentId,
          deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
          eventInfo,
          eventType,
        })
      )
      .then((response) => {
        this._contentEventResponse$.next({ ...response, eventInfo });
      });
  }
}
