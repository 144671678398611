import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { EnvironmentVariablesService } from '@kenv';
import { PulseSurveyBloc } from '@kp/pulse-survey/pulse-survey.bloc';
import { BrowserStorage } from '@kservice';
import { Product, UserType } from '@ktypes/enums';
import { OnboardingData, User } from '@ktypes/models';

@Injectable({
  providedIn: 'root',
})
export class EmptyRedirectGuard {
  constructor(
    public browserStorage: BrowserStorage,
    public environmentVariablesService: EnvironmentVariablesService,
    private _pulseSurveyBloc: PulseSurveyBloc,
    public router: Router
  ) {}

  canActivate(/*activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot*/): UrlTree {
    if (this.environmentVariablesService.product === Product.resourceful) {
      // explicitly checking true to avoid "truthy" strings
      const completedResourcefulSurvey = this._pulseSurveyBloc.getHasCompletedResourcefulSurvey() === true;
      const groupCode = (this.browserStorage.getObject('onboarding') as OnboardingData)?.groupCode as string;
      const { type: userType } = (this.browserStorage.getObject('user', true) as User) ?? {};

      if (completedResourcefulSurvey || userType === UserType.user) {
        return this.router.parseUrl('/resources');
      } else if (groupCode) {
        return this.router.parseUrl('/welcome');
      }
      return this.router.parseUrl('/login');
    }
    return this.router.parseUrl('/today');
  }
}
