import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';
import { RoleKey } from './tag.model';

export class TagRole implements Deserializable<JsonObject, TagRole>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public key?: RoleKey,
    public priority?: number
  ) {}

  deserialize(input: JsonObject): TagRole {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.key = input['tagRoleKey'] as RoleKey;
    this.priority = input['tagRolePriority'] as number;
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['tagRoleKey'] = this.key;
    json['tagRolePriority'] = this.priority;

    return json;
  }
}
