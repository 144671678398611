import { Component } from '@angular/core';
import { CenterPath } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { SocialChallengeBloc } from '../../social-challenge.bloc';

@Component({
  selector: 'kf-sc-modal-social-challenge-celebrate',
  templateUrl: './modal-social-challenge-celebrate.component.html',
  styleUrls: ['./modal-social-challenge-celebrate.component.scss'],
})
export class ModalSocialChallengeCelebrateComponent {
  centerPath = CenterPath;

  constructor(public socialChallengeBloc: SocialChallengeBloc) {}
}

export const MockModalSocialChallengeCelebrateComponent = MockComponent({
  selector: 'kf-sc-modal-social-challenge-celebrate',
});
