@if (userBloc.liveSupport$ | async; as liveSupport) {
  <button class="header" (click)="toggleLiveSupport()">
    <svg-icon class="icon-support" src="/assets/icons/live-support.svg"></svg-icon>
    <span class="title">{{ liveSupport?.title }}</span>
    <svg-icon class="icon-chevron" src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
  </button>
  <div class="contents">
    <kui-live-support-detail
      [liveSupport]="liveSupport"
      [productName]="environmentVariablesService.product | titlecase"
      (liveSupportLinkClicked)="liveSupportLinkClicked()"
      (liveSupportNumberClicked)="liveSupportNumberClicked()"></kui-live-support-detail>
  </div>
}
