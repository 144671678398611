import { Document } from '@contentful/rich-text-types';

import cloneDeep from 'lodash/cloneDeep';
import { CardRequestType, CardScreen, CustomCardCategory } from '../enums';
import { ContentImage } from './content-image.model';
import { ContentMedia } from './content-media.model';
import { Deserializable } from './deserializable.model';
import { Domain } from './domain.model';
import { JsonObject } from './json-object.model';
import { Link } from './link.model';
import { QuestItem } from './quest-item.model';
import { Quest } from './quest.model';
import { UserCardState } from './user-card-state.model';

export enum CardAction {
  MEDIA,
  LINK,
  BODY,
  BASE,
}

export interface CardData {
  card?: CardItem;
  customCardCategory?: CustomCardCategory;
  detailViewClicked: boolean;
  requestType?: CardRequestType;
  screen?: CardScreen;
  audio?: boolean;
  isSearch?: boolean;
  quest?: Quest;
  questItem?: QuestItem;
  shouldPlay?: boolean;
  video?: boolean;
}

export enum CardContentType {
  card = 'card',
  domain = 'domain',
  local_resource = 'local_resource',
}

export class CardItem implements Deserializable<JsonObject, CardItem> {
  constructor(
    public id?: string,
    public presentedEventId?: string,
    public how?: string,
    public why?: string,
    public rawBody?: Document,
    public cardImage?: ContentImage,
    public cardLink?: Link,
    public callToAction?: string,
    public domains?: Domain[],
    public title?: string,
    public topic?: string | JsonObject,
    public keywords?: string[],
    public isSponsored?: boolean,
    public isActionable?: boolean,
    public isRepeatable?: boolean,
    public primaryMedia?: ContentMedia,
    public primaryAction?: CardAction,
    public completionTime?: number,
    public userState?: UserCardState,
    public isUserSubmitted?: boolean,
    public hideFromUI = false,
    public preloadImage = false,
    public categoryIsInViewport = false,
    public isInViewport = false,
    public isRepeated = false,
    public contentType = CardContentType.card,
    public providerName?: string,
    public localResourceId?: string,
    public inQuest?: boolean,
    public excludeFrom?: string[]
  ) {
    if (typeof (topic as JsonObject)?.['topicLabel'] === 'string') {
      this.topic = (topic as JsonObject)['topicLabel'] as string;
    }
  }

  clone(card: CardItem) {
    return cloneDeep(card);
  }

  deserialize(json: JsonObject) {
    if (json == null) {
      return null;
    }
    const card = (json['card'] || json) as JsonObject;
    if (card['keywords'] != null) {
      card['keywords'] = (card['keywords'] as JsonObject[]).map((kw: JsonObject) => kw.toString()) as string[];
    } else {
      card['keywords'] = [];
    }
    const domains = (json['domains'] || card['domains']) as Domain[];

    this.id = card['id'] as string;
    this.presentedEventId = card['presentedEventId'] as string;
    this.how = card['how'] as string;
    this.why = card['why'] as string;
    this.rawBody = (card['body'] || card['rawBody']) as Document;
    this.primaryMedia = new ContentMedia().deserialize(card['primaryMedia'] || {});
    this.cardImage =
      card['image'] || card['cardImage']
        ? new ContentImage().deserialize(card['image'] || card['cardImage'])
        : this.primaryMedia?.asImage;
    this.cardLink = new Link().deserialize(card['link'] || card['cardLink'] || {}, null, card['callToAction']);
    this.callToAction = card['callToAction'] as string;
    this.domains = domains?.map?.((domain) => new Domain().deserialize(domain)) ?? [];
    this.title = card['title'] as string;
    const topic = ((card['topic'] as JsonObject)?.['topicLabel'] as string) || (card['topic'] as string);
    if (typeof topic === 'string') {
      this.topic = topic;
    }
    this.keywords = card['keywords'] as string[];
    this.isSponsored = !!card['isSponsored'];
    this.isActionable = !!card['isActionable'];
    this.isRepeatable = !!card['isRepeatable'];
    this.primaryAction = card['primaryAction'] as CardAction;
    this.completionTime = card['completionTime'] as number;
    this.userState = new UserCardState().deserialize(json['userState']);
    this.isUserSubmitted = !!card['isUserSubmitted'];
    this.hideFromUI = !!card['hideFromUI'];
    this.preloadImage = !!card['preloadImage'];
    this.categoryIsInViewport = !!card['categoryIsInViewport'];
    this.isInViewport = !!card['isInViewport'];
    this.isRepeated = !!card['isRepeated'];
    this.contentType = (card['contentType'] as CardContentType) || CardContentType.card;
    this.providerName = card['providerName'] as string;
    this.localResourceId = card['localResourceId'] as string;
    this.inQuest = !!json['inQuest'] || false;
    this.excludeFrom = (card['excludeFrom'] || []) as string[];

    return this;
  }

  get isQuestCard(): boolean {
    return this.cardLink.deepLinkBase === 'Quest';
  }

  get isTrackingAndActionable(): boolean {
    return this.isActionable && this.userState.isTracking;
  }

  get isActionableAndNotTracking(): boolean {
    return this.isActionable && !this.userState.isTracking;
  }
}
