@if (inviteModalOpened) {
  <kui-modal [isOpen]="inviteModalOpened" (isOpenChange)="inviteModalOpened = false" type="info">
    <kui-modal-invite
      [invite]="sharingBloc.invites$ | async | inviteFilter: inviteType"
      [challengeId]="challengeId"
      [challengeTitle]="challengeTitle"
      [challengeInviteMessage]="inviteMessage"
      (closeClicked)="inviteModalOpened = false"></kui-modal-invite>
  </kui-modal>
}

<div class="challenge-progress challenge-progress-{{ type }}" [ngClass]="{ 'show-info': showInfo }">
  <h3 class="title progress-title">
    <span>{{ title }}</span>
    @if (challengeStatus?.state !== challengeState.ended) {
      <button class="button-instruction-info" (click)="toggleInfo()">
        <svg-icon src="/assets/icons/info-circle.svg"></svg-icon>
      </button>
    }
  </h3>
  @if (challengeStatus?.state !== challengeState.ended) {
    <div class="info-wrap">
      <kui-rich-text [richText]="challengeInfo?.instruction" [translate]="false"></kui-rich-text>
    </div>
  }
  <div class="progress-details">
    <div class="progress-details-header">
      <div class="progress-detail-info">
        <h4 class="title progress-details-title">
          {{ challengeInfo?.status?.progress?.count | preventNegative | number }}
          {{ challengeObjective }}
        </h4>
        <p class="progress-goal">
          {{ 'Challenge Goal:' | translate | async }} {{ challengeInfo?.goal | preventNegative | number }}
        </p>
      </div>
      <ng-content></ng-content>
    </div>
    @if (challengeGoalCompleted || challengeStatus?.state !== challengeState.ended) {
      <div class="motivation-message motivation-message-{{ type }}">
        <svg-icon src="/assets/icons/mountain-summit-flag-no-border.svg"></svg-icon>
        <kui-rich-text
          class="message"
          [richText]="challengeGoalCompleted ? challengeInfo?.completionMessage : challengeInfo?.motivation"
          [translate]="false"></kui-rich-text>
      </div>
    }
  </div>
  @if (
    enrolled &&
      inviteMessage &&
      (challengeStatus?.state === socialChallengeState.upcoming ||
        challengeStatus?.state === socialChallengeState.active) &&
      sharingBloc.invites$
      | async
      | inviteFilter: inviteType
  ) {
    @if (type === 'group') {
      <button class="button invite" (click)="inviteModalOpened = true">
        <svg-icon class="menu-icon" src="/assets/icons/user-invite.svg"></svg-icon>
        {{ 'Invite Co-workers' | translate | async }}
      </button>
    }
  }
</div>
