import { Component } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {}

export const MockAccordionComponent = MockComponent({
  selector: 'kui-accordion',
  template: '<ng-content></ng-content>',
});
