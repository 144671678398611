<kui-rich-text class="description" [richText]="liveSupport?.description" [translate]="false"></kui-rich-text>
@if (liveSupport?.linkUrl && liveSupport?.linkText) {
  @if (interactive) {
    <a class="link" href="{{ liveSupport?.linkUrl }}" target="_blank" (click)="liveSupportLinkClicked.emit()">
      <ng-container *ngTemplateOutlet="notesLinkContent"></ng-container>
    </a>
  }
  @if (!interactive) {
    <div class="link">
      <ng-container *ngTemplateOutlet="notesLinkContent"></ng-container>
    </div>
  }
}

@if (liveSupport?.email) {
  @if (interactive) {
    <a
      class="link"
      href="mailto:{{ liveSupport?.email }}?Subject={{
        productName | titlecase
      }}%20user%20is%20requesting%20email%20support">
      <ng-container *ngTemplateOutlet="emailLinkContent"></ng-container>
    </a>
  }
  @if (!interactive) {
    <div class="link">
      <ng-container *ngTemplateOutlet="emailLinkContent"></ng-container>
    </div>
  }
}

@if (liveSupport?.phoneNumber) {
  @if (interactive) {
    <a class="link" href="tel:{{ liveSupport?.phoneNumber }}" (click)="liveSupportNumberClicked.emit()">
      <ng-container *ngTemplateOutlet="phoneLinkContent"></ng-container>
    </a>
  }
  @if (!interactive) {
    <div class="link">
      <ng-container *ngTemplateOutlet="phoneLinkContent"></ng-container>
    </div>
  }
}

<ng-template #notesLinkContent>
  <svg-icon class="icon-contents notes" src="/assets/icons/notes.svg"></svg-icon>
  <!-- Adding comment to remove extra space that Prettier adds -->
  <!-- prettier-ignore-attribute -->
  <span class="link-text">{{ liveSupport?.linkText }}</span>
</ng-template>

<ng-template #emailLinkContent>
  <svg-icon class="icon-contents mail" src="/assets/icons/mail.svg"></svg-icon>
  <!-- Adding comment to remove extra space that Prettier adds -->
  <!-- prettier-ignore-attribute -->
  <span class="link-text">{{ liveSupport?.email }}</span>
</ng-template>

<ng-template #phoneLinkContent>
  <svg-icon class="icon-contents call" src="/assets/icons/call.svg"></svg-icon>
  <ng-container>
    <!-- Adding comment to remove extra space that Prettier adds -->
    <!-- prettier-ignore-attribute -->
    <span class="link-text">{{ liveSupport?.phoneNumber }}</span>
    <!-- Adding comment to remove extra space that Prettier adds -->
    <!-- prettier-ignore-attribute -->
    <span class="link-hours">{{ liveSupport?.hours }}</span>
  </ng-container>
</ng-template>
