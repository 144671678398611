import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SettingsBloc } from '@kbloc';
import { Setting } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { SvgIconComponent } from 'angular-svg-icon';
import { TranslationBloc } from '../../translation.bloc';

@Component({
  selector: 'kf-loc-language-dropdown',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent {
  @Input() showIcon = false;

  constructor(
    public settingsBloc: SettingsBloc,
    public translationBloc: TranslationBloc
  ) {}

  onLanguageChange(selectedOption: EventTarget) {
    const language = (selectedOption as HTMLOptionElement).value;
    this.translationBloc.changeLanguage(language);
    this.settingsBloc.changeUserSetting(Setting.language, language);
  }
}

export const MockLanguageDropdownComponent = MockComponent({
  selector: 'kf-loc-language-dropdown',
  inputs: ['showIcon'],
});
