import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { VersionApi } from '@kp/version/version.api';
import { Status } from '@ktypes/models';
import { EnumUtil } from '@kutil';
import { BehaviorSubject } from 'rxjs';

enum VersionPart {
  major = 0,
  minor = 1,
  patch = 2,
}

@Injectable({
  providedIn: 'root',
})
export class VersionBloc {
  constructor(
    private _environmentVariablesService: EnvironmentVariablesService,
    private _versionApi: VersionApi
  ) {}

  private _appVersionRequired = new BehaviorSubject<string>('');
  // defaulting to true to eliminate blocking or flashing during API call
  private _appVersionMeetsMinimum = new BehaviorSubject<boolean>(true);

  get appVersionRequired$() {
    return this._appVersionRequired.asObservable();
  }
  get appVersionMeetsMinimum$() {
    return this._appVersionMeetsMinimum.asObservable();
  }

  checkAppVersion() {
    this._versionApi.getVersions().then((versionsStatus) => {
      if (versionsStatus?.status === Status.done) {
        const appVersionRequired =
          versionsStatus.data?.find(
            (versionInfo) => versionInfo?.product?.toLowerCase() === this._environmentVariablesService.product
          )?.minimumVersion || '';
        this._appVersionMeetsMinimum.next(
          compareAppVersions(
            appVersionRequired,
            this._environmentVariablesService.versions.app[this._environmentVariablesService.product]
          )
        );
        this._appVersionRequired.next(appVersionRequired);
      }
    });
  }
}

// Note: exporting for testing
export function compareAppVersions(requiredAppVersion: string, currentAppVersion: string) {
  if (requiredAppVersion) {
    const currentVersionParts = getVersionParts(currentAppVersion);
    const versionRequiredParts = getVersionParts(requiredAppVersion);
    if (currentVersionParts.every((vp) => vp === 0) || versionRequiredParts.every((vp) => vp === 0)) {
      // if either version string cannot be parsed, return true so as not to block
      return true;
    }
    // Ensure enum values are sorted ASC by the numeric value
    const versionParts = Object.keys(VersionPart)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => Number(key))
      .sort()
      .map((key) => VersionPart[key]);

    for (const versionPart of versionParts) {
      // Work through version string (major, minor, patch)
      // shortcut if not equal, otherwise continue checking
      if (
        currentVersionParts[EnumUtil.getByValue(VersionPart, versionPart) as number] !==
        versionRequiredParts[EnumUtil.getByValue(VersionPart, versionPart) as number]
      ) {
        return (
          currentVersionParts[EnumUtil.getByValue(VersionPart, versionPart) as number] >
          versionRequiredParts[EnumUtil.getByValue(VersionPart, versionPart) as number]
        );
      }
    }
  }
  // if no requiredAppVersion, assume it is all good
  return true;
}

// Note: exporting for testing
export function getVersionParts(version: string): [number, number, number] {
  const versionParts = version?.split('-')[0]?.split('.');
  if (!versionParts) {
    // if version cannot be parsed, return 0s for easy comparison
    return [0, 0, 0];
  }
  while (versionParts?.length < 3) {
    versionParts.push('0');
  }
  // take first 3 (major, minor, patch) and ensure they are numbers for comparison purposes
  return versionParts?.slice(0, 3)?.map((version) => {
    const versionNum = parseInt(version, 10);
    return isNaN(versionNum) ? 0 : versionNum || 0;
  }) as [number, number, number];
}
