import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AnswerValue } from '@kp/question-set/models/answer-value.model';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import { Question } from '@kp/question-set/models/question.model';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnChanges {
  @Input() question: Question;

  @Output() radioAnswer: EventEmitter<AnswerValue> = new EventEmitter<AnswerValue>();

  public displayOptions: DisplayOption[];

  ngOnChanges() {
    if (this.question?.hasAnswer) {
      this.displayOptions = this.question?.display?.optionsWithAnswers(this.question?.answer?.values);
      this.radioAnswer.emit(this.question?.answer?.values[0]);
    } else {
      this.displayOptions = this.question?.display?.options;
      this.radioAnswer.emit(new AnswerValue());
    }
  }

  onOptionChange(isChecked: boolean, currentOption: DisplayOption) {
    this.radioAnswer.emit(
      new AnswerValue().deserialize({
        optionId: currentOption.id,
        value: true,
      })
    );
    this.question.display.options.forEach((option) => {
      option.isSelected = isChecked && option.id === currentOption.id;
    });
    this.displayOptions.forEach((option) => {
      option.isSelected = isChecked && option.id === currentOption.id;
    });
  }
}

export const MockRadioButtonComponent = MockComponent({
  selector: 'kp-radio-button',
  inputs: ['question'],
});
