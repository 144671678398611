import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { JsonObject, Quest, QuestEvent, QuestKeyType } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QuestApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async requestQuest(questKey: string, keyType?: QuestKeyType): Promise<Quest> {
    const body: JsonObject = {};
    body['deviceCreatedTimestamp'] = DateTimeUtil.formatInLocal();
    if (keyType === QuestKeyType.logicKey) {
      body['logicKey'] = questKey;
    }
    if (keyType === QuestKeyType.refKey) {
      body['refKey'] = questKey;
    }
    const request$ = this.performRequest<Quest>(RequestType.POST, this.buildUrl('/quest', true), {
      requestBody: body,
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Quest>): Quest => new Quest().deserialize(response.body)),
      catchError((error) => {
        console.warn('Failed requesting quest: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error requesting quest: ', error);
      return null;
    });
  }

  async createQuestEvent(event: QuestEvent): Promise<HttpResponse<void>> {
    const body: JsonObject = event.serialize();
    const request$ = this.performRequest<void>(RequestType.POST, this.buildUrl(`/quest/${event.questId}/event`, true), {
      requestBody: body,
      includeToken: true,
    }).pipe(
      catchError((error) => {
        console.warn('Failed creating quest event: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error creating quest event: ', error);
      return null;
    });
  }
}
