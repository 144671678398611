<div class="modal-container">
  <div class="modal-title">Welcome to Resourceful!</div>
  <img
    class="creation-image"
    src="/assets/images/family-card.jpg"
    alt="Family members of different ages collectively looking at the horizon" />
  <div class="account-creation-container">
    <p class="description">
      Create your free Kumanu account to save and return to your personalized resources at any time.
    </p>
    <a
      class="button button-pill button-reverse account-creation"
      data-test="account-creation-link"
      routerLink="/welcome/signup"
      (click)="closeClicked.emit(accountCreationChoice.clickToCreateAccount)">
      Create Account
    </a>
    @if (canDismiss) {
      <button
        class="continue"
        data-test="continue-button"
        (click)="closeClicked.emit(accountCreationChoice.clickNotNow)">
        Not now
      </button>
    }
  </div>
</div>
