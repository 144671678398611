<h3 class="title">Welcome to {{ page() }}!</h3>
<p class="cta">Let's build your profile to center your {{ product }} experience on what matters most to you.</p>
<a
  class="button-profile button button-pill"
  routerLink="/dialogue"
  [queryParams]="{
    returnUrl: '/' + page().toLowerCase(),
    logicKey: dialogueLogicKey()
  }">
  Get Started
</a>
