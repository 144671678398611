import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class DisplayOption implements Deserializable<JsonObject, DisplayOption>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public text?: string,
    public appImage?: string,
    public appImageUrl?: string,
    public isSelected = false
  ) {}

  deserialize(input: JsonObject): DisplayOption {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.text = input['text'] as string;
    this.appImage = input['appImage'] as string;
    this.appImageUrl = input['appImageUrl'] as string;
    this.isSelected = !!input['isSelected'];
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['text'] = this.text;
    json['appImage'] = this.appImage;
    json['appImageUrl'] = this.appImageUrl;
    json['isSelected'] = this.isSelected;
    return json;
  }
}
