import { Injectable } from '@angular/core';
import { StreakApi } from '@kp/streaks/streak.api';
import { Streak } from '@kp/streaks/streak.model';
import { DataStatus, Status, StatusMessage } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StreakBloc {
  constructor(private _streakApi: StreakApi) {}

  private _streakSubject = new BehaviorSubject<DataStatus<Streak>>(null);

  get streak$(): Observable<DataStatus<Streak>> {
    return this._streakSubject.asObservable();
  }

  refreshStreaks(): void {
    this._streakSubject.next(new DataStatus<Streak>(Status.starting, new StatusMessage(Status.starting, ''), null));
    this._streakApi.getStreaks().then((streaks) => {
      if (streaks) {
        this._streakSubject.next(streaks);
      }
    });
  }
}
