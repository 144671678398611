@if (link?.type === cardLinkType.EXTERNAL || link?.deepLinkBase === deepLinkBase.ExternalLink) {
  <a
    [href]="link?.uri"
    [ngClass]="{
      'icon-link': showCompactView && requestType,
      'button button-pill button-reverse button-cta': requestType || showAsButton,
      'button-standard': showAsButton,
      'cta-non-card': !requestType && !showAsButton
    }"
    [attr.aria-label]="callToAction"
    target="_blank"
    (click)="handleCardCTAClick($event)">
    <ng-container *ngTemplateOutlet="ctaContent"></ng-container>
  </a>
}

@if (link?.type === cardLinkType.INTERNAL) {
  <a
    [routerLink]="[navigationOptions?.route]"
    [queryParams]="navigationOptions?.queryParams"
    [ngClass]="{
      'icon-link': showCompactView && requestType,
      'button button-pill button-reverse button-cta': requestType || showAsButton,
      'button-standard': showAsButton,
      'cta-non-card': !requestType && !showAsButton
    }"
    [attr.aria-label]="callToAction"
    (click)="handleCardCTAClick($event)">
    <ng-container *ngTemplateOutlet="ctaContent"></ng-container>
  </a>
}

<!--
NOTE: no click event on ACTION button intentionally, the click is handled
externally with a click event on the kp-call-to-action component. Mainly
used for  completing Quest Cards that don't have a Reflection or other
dialogue to complete.
-->
@if (link?.type === cardLinkType.ACTION) {
  <button
    [ngClass]="{
      'icon-link': showCompactView && requestType,
      'button button-pill button-reverse button-cta button-action': requestType || showAsButton,
      'button-standard': showAsButton,
      'cta-non-card': !requestType && !showAsButton
    }"
    [attr.aria-label]="callToAction">
    <ng-container *ngTemplateOutlet="ctaContent"></ng-container>
  </button>
}

<ng-template #ctaContent>
  @if (showCompactView && requestType && isQuestCard) {
    <svg-icon src="/assets/icons/icons-nav-quest-flag.svg"></svg-icon>
  }
  @if (showCompactView && requestType && !isQuestCard) {
    <svg-icon src="/assets/icons/link.svg"></svg-icon>
  }
  @if (!showCompactView && requestType) {
    {{ callToAction }}
  }
  @if (!requestType) {
    <ng-content></ng-content>
  }
</ng-template>
