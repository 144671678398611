import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';

@Injectable({
  providedIn: 'root',
})
export class DeletionGuard {
  private _linkToken: string;
  private _linkUserId: string;

  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _authenticationBloc: AuthenticationBloc,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot /*, state: RouterStateSnapshot*/): boolean {
    this._linkToken = route?.queryParams?.token as string;
    this._linkUserId = route?.queryParams?.userId as string;
    if (this._linkToken && this._linkUserId) {
      this.logout();
      return true;
    } else {
      void this._router.navigate(['']);
      return false;
    }
  }

  logout(): void {
    // this call may fail if there is no logged-in user, but should send with the existing user
    this._analyticsBloc.sendEvent(this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.app_exit }));
    // only clearing user data locally as it will all be wiped server-side on user delete
    this._authenticationBloc.clearAuthData();
  }
}
