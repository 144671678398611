import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { WordListItem } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
// @ts-ignore TODO: Add types from modules once they work; using ./d3-cloud.model file due to issues found
import * as cloud from 'd3-cloud';
import { scaleLinear } from 'd3-scale';
import { Selection, select } from 'd3-selection';
import { DeviceDetectorService } from 'ngx-device-detector';
import { D3Cloud, D3Cloud_BOUNDS, D3Cloud_WORDS } from './d3-cloud.model';

// Defaults
const defaultFontRange = [12, 24, 36];
const defaultColorRange = ['rgb(32,33,117)', 'rgb(134,82,157)', 'rgb(124,61,71)'];
const defaultOpacityRange = [1, 1, 1];
const defaultPadding = 4;
const MAX_WIDTH = 300;
const MAX_HEIGHT = 180;
const MIN_HEIGHT = 123;
const RATIO = 0.625; // 0.75 = 4:3, 0.625 = 16:10

@Component({
  selector: 'kui-word-cloud',
  template: '', // no template
  styleUrls: ['./word-cloud.component.scss'],
})
export class WordCloudComponent implements AfterViewChecked, OnChanges {
  readonly htmlElement: HTMLElement;
  private host;
  private layout: D3Cloud;
  private width: number;
  private height: number;
  private redrawAttempts = 0;
  private maxRedrawAttempts = 10;
  private isBuilt = false;
  private drawStarted = false;
  private wordCloudInstances: Selection<SVGSVGElement, unknown, null, unknown>[] = [];

  @Input() wordsList: WordListItem[];
  @Input() wordsToHighlight: WordListItem[];
  @Input() fontSizeRange: number[];
  @Input() colorRange: string[];
  @Input() opacityRange: number[];
  @Input() wordPadding = defaultPadding;
  @Input() noData: boolean;

  @Input()
  @HostBinding('class.loading')
  isLoading: boolean;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private element: ElementRef,
    private deviceService: DeviceDetectorService
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.htmlElement = this.element.nativeElement;
    this.host = select(this.element.nativeElement);
  }

  ngAfterViewChecked() {
    this.drawWordCloud();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.wordsList) {
      this.isBuilt = false;
      this.drawStarted = false;
      this.redrawAttempts = 0;
      this.drawWordCloud();
    }
  }

  private drawWordCloud() {
    if (this.drawStarted || this.isLoading || !this.wordsList || this.wordsList.length === 0 || this.isBuilt) {
      return;
    }
    this.drawStarted = true;
    const sortedWordList = this.wordsList && this.wordsList.sort((a, b) => a.size - b.size);
    const wordSizes = sortedWordList.map((wordItem) => wordItem.size);
    const min = Math.min(...wordSizes);
    const max = Math.max(...wordSizes);
    const mid = (sortedWordList.length > 0 && sortedWordList[Math.floor((sortedWordList.length - 1) / 2)].size) || 0;

    if (this.redrawAttempts > 0) {
      // if it's redrawing, the words didn't fit. Resize font ranges down 10% on each redraw
      if (!this.fontSizeRange) {
        this.fontSizeRange = defaultFontRange.map((size) => Math.floor(size * 0.9));
      } else {
        this.fontSizeRange = this.fontSizeRange.map((size) => Math.floor(size * 0.9));
      }
    }

    // D3 Scale functions
    const fontScale = scaleLinear<number, number>()
      .domain([min, mid, max])
      .range(this.fontSizeRange || defaultFontRange)
      .clamp(true);
    const colorScale = scaleLinear<string, number>()
      .domain([min, mid, max])
      .range(this.colorRange || defaultColorRange)
      .clamp(true);
    const opacityScale = scaleLinear<number, number>()
      .domain([min, mid, max])
      .range(this.opacityRange || defaultOpacityRange)
      .clamp(true);

    // use timeout to ensure browser paint is finished for accurate sizes
    // 20ms because the average paint at 60fps is ~16ms
    setTimeout(() => {
      // subtract 40px to account for horizontal padding
      this.width = Math.floor(Math.min(this.htmlElement.offsetWidth - 40, MAX_WIDTH));
      this.height = Math.floor(
        Math.min(Math.max(this.htmlElement.offsetHeight, this.width * RATIO, MIN_HEIGHT), MAX_HEIGHT)
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.layout = (cloud() as D3Cloud)
        .size([this.width, this.height])
        .canvas(() => {
          const canvas = this._document.createElement('canvas');
          canvas.getContext('2d', {
            willReadFrequently: true,
          });
          return canvas;
        })
        .words(
          this.wordsList.map((wordItem) => {
            return {
              text: wordItem.word,
              size: wordItem.size,
              fontSize: Math.round(fontScale(wordItem.size)),
              color: colorScale(wordItem.size),
              opacity: opacityScale(wordItem.size),
            };
          })
        )
        .padding(this.wordPadding)
        .rotate(() => 0)
        .font('Nunito Sans')
        .fontSize((d, index) => d?.[index]?.fontSize)
        .spiral('archimedean') // or ('rectangular')
        .on('end', this.draw.bind(this));

      this.layout.start();
    }, 20);
  }

  draw(words: D3Cloud_WORDS, topBtmXYs: D3Cloud_BOUNDS) {
    const browser = this.deviceService.getDeviceInfo().browser;
    const width: number = this.layout.size()[0];
    const height: number = this.layout.size()[1];
    const scale = topBtmXYs
      ? Math.min(
          width / Math.abs(topBtmXYs[1].x - width / 2),
          width / Math.abs(topBtmXYs[0].x - width / 2),
          height / Math.abs(topBtmXYs[1].y - height / 2),
          height / Math.abs(topBtmXYs[0].y - height / 2)
        ) / 2
      : 1;

    // words = computed layout, it contains the actually displayed words, if they don't match the total words, try again
    if (words && words.length < this.wordsList.length && this.redrawAttempts < this.maxRedrawAttempts) {
      // try again
      this.layout.stop();
      this.redrawAttempts += 1;
      this.drawStarted = false;
      this.drawWordCloud();

      return;
    }

    const svg = this.host.append('svg');
    // @ts-ignore no type available
    this.wordCloudInstances?.push(svg);

    const filter = svg.append('defs').append('filter');
    filter.attr('id', 'dropShadow');
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', '3');
    filter.append('feOffset').attr('dx', '0').attr('dy', '0').attr('result', 'offsetblur');
    filter.append('feFlood').attr('flood-color', 'white');
    filter.append('feComposite').attr('in2', 'offsetblur').attr('operator', 'in');

    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    svg
      .attr('width', this.layout.size()[0])
      .attr('height', this.layout.size()[1])
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', `0 0 ${this.layout.size()[0]} ${this.layout.size()[1]}`)
      .append('g')

      .attr('transform', `translate(${this.layout.size()[0] / 2}, ${this.layout.size()[1] / 2})scale(${scale})`)
      .selectAll('text')
      .data(words)
      .enter()
      .append('text')
      .style('font-family', 'Nunito Sans')
      .style('font-size', (d) => d.fontSize + 'px')
      .style('color', (d) => d.color)
      .style('opacity', (d) => d.opacity)
      .style('text-shadow', (d) => {
        const highlightWordExists = this.wordsToHighlight?.find(
          (item) => item.word.toLowerCase() === d.text.toLowerCase()
        );
        if (highlightWordExists && !['IE', 'MS-Edge'].includes(browser)) {
          return '0 0 7px ' + d.color;
        }
        return null;
      })
      .attr('filter', (d) => {
        const highlightWords = this.wordsToHighlight?.filter(
          (item) => item.word.toLowerCase() === d.text.toLowerCase()
        );
        if (highlightWords?.length > 0 && ['IE', 'MS-Edge'].includes(browser)) {
          return 'url(#dropShadow)';
        }
        return null;
      })
      .attr('text-anchor', 'middle')
      .attr('transform', (d) => 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')')
      .text((d) => d.text);

    // Remove deleted words
    this.host.exit().style('font-size', '0px').style('opacity', 0).remove();
    if (this.wordCloudInstances?.length > 1) {
      const oldCloud = this.wordCloudInstances.shift();
      oldCloud.remove();
    }

    // Mark it built
    this.isBuilt = true;
  }
}

export const MockWordCloudComponent = MockComponent({
  selector: 'kui-word-cloud',
  inputs: [
    'wordsList',
    'wordsToHighlight',
    'fontSizeRange',
    'colorRange',
    'opacityRange',
    'wordPadding',
    'noData',
    'isLoading',
  ],
});
