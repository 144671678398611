@if (modalOpened === true) {
  <kui-modal
    labelValue="Time picker modal for action reminders - set reminder time"
    [isOpen]="modalOpened === true"
    (isOpenChange)="modalOpened = false"
    type="info">
    <kp-modal-time-picker
      (closeClicked)="modalOpened = false"
      (saveClicked)="saveClicked($event)"
      [notificationRange]="notificationRange.action"
      svgURL="/assets/icons/notification.svg"
      [time]="time"></kp-modal-time-picker>
  </kui-modal>
}

<button
  class="button button-pill button-opaque-border button-reminder-time"
  (click)="modalOpened = true"
  [attr.aria-label]="accessibilityPrefixText + time">
  {{ time }}
  <svg-icon class="dropdown-arrow" src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
</button>
