export enum CardScreen {
  category = 'category',
  challenges = 'challenges',
  discover = 'discover',
  other = 'other',
  quest = 'quest',
  recommended = 'recommended',
  reflect = 'reflect',
  reminders = 'reminders',
  resources = 'resources',
  saved = 'saved',
  search = 'search',
  today = 'today',
}
