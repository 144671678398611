import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { KumanuKeyType } from '@kp/dialogue/models/dialogue.model';
import { CreateQuestionBody } from '@kp/question-set/models/create-question-body.model';
import { Question, QuestionLogicKey } from '@kp/question-set/models/question.model';
import { DataStoreService } from '@kservice';
import { JsonObject } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QuestionApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  getQuestion(keyType: KumanuKeyType, key?: string | QuestionLogicKey): Promise<Question> {
    const queryParams: JsonObject = {
      [keyType]: key,
    };
    const request$ = this.performRequest<Question>(RequestType.GET, this.buildUrl('/question', true, queryParams), {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Question>): Question => new Question().deserialize(response.body)),
      catchError((error) => {
        console.warn('Failed getting question: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting question: ', error);
      return null;
    });
  }

  // Not in use but keeping in code since endpoint exists
  getUserQuestion(userQuestionId: string): Promise<Question> {
    const queryParams: JsonObject = {};

    const request$ = this.performRequest<Question>(
      RequestType.GET,
      this.buildUrl(`/question/${userQuestionId}`, true, queryParams),
      {
        includeToken: true,
      }
    ).pipe(
      map((response: HttpResponse<Question>): Question => new Question().deserialize(response.body)),
      catchError((error) => {
        console.warn('Failed getting user question: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting user question: ', error);
      return null;
    });
  }

  // Not in use but keeping in code since endpoint exists
  createQuestion(questionBody: CreateQuestionBody): Promise<Question> {
    const request$ = this.performRequest<Question>(RequestType.POST, this.buildUrl('/question', true), {
      requestBody: questionBody.serialize(),
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Question>): Question => new Question().deserialize(response.body)),
      catchError((error) => {
        console.warn('Failed creating question: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error creating question: ', error);
      return null;
    });
  }

  updateQuestion(question: Question): Promise<Question> {
    let url: string;
    question.questionSetId != null
      ? (url = this.buildUrl(`/question-set/${question.questionSetId}/question/${question.id}`, true))
      : (url = this.buildUrl(`/question/${question.id}`, true));

    const request$ = this.performRequest<Question>(RequestType.PUT, url, {
      requestBody: question.serialize(),
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Question>): Question => new Question().deserialize(response.body)),
      catchError((error) => {
        console.warn('Failed updating question: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn(`Error updating question${question.questionSetId != null ? ' set' : ''}: `, error);
      return null;
    });
  }
}
