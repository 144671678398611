import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SocialChallengeBloc } from '@kf-sc';
import { DailyChallengeStatusBloc } from '@kp/shared/components/daily-challenge/daily-challenge-status.bloc';
import { DailyChallenge } from '@kp/shared/components/daily-challenge/daily-challenge.model';
import { StreakBloc } from '@kp/streaks/streak.bloc';
import { MockComponent } from '@kutil/test';
import { Subject, combineLatestWith } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-purposeful-days',
  templateUrl: './purposeful-days-indicator.component.html',
  styleUrls: ['./purposeful-days-indicator.component.scss'],
})
export class PurposefulDaysIndicatorComponent implements OnInit, OnDestroy {
  showedUp = false;
  checkedIn = false;
  actionCompleted = false;
  streakLength = 0;
  complete = false;
  showCelebrationModal = false;

  @Output() purposefulDaysClicked = new EventEmitter<void>();

  private _destroy$ = new Subject<void>();
  private _previouslyCompleted: boolean;

  constructor(
    private _dailyChallengeStatusBloc: DailyChallengeStatusBloc,
    private _socialChallengeBloc: SocialChallengeBloc,
    private _streakBloc: StreakBloc
  ) {}

  ngOnInit() {
    this._dailyChallengeStatusBloc.shownPurposefulDayCelebration$
      .pipe(takeUntil(this._destroy$))
      .subscribe((shownPurposefulDayCelebration) => {
        this._previouslyCompleted = shownPurposefulDayCelebration;
      });
    this._dailyChallengeStatusBloc.dailyChallengeStatus$
      .pipe(combineLatestWith(this._socialChallengeBloc.showCelebrationModal$), takeUntil(this._destroy$))
      .subscribe(([dailyChallengeStatus, showSocialChallengeCelebrationModal]) => {
        const status = dailyChallengeStatus?.data;
        if (status) {
          this._updateStatus(status, showSocialChallengeCelebrationModal);
        }
      });
    this._dailyChallengeStatusBloc.refreshDailyChallengeStatus();

    this._streakBloc.streak$.pipe(takeUntil(this._destroy$)).subscribe((response) => {
      const streaks = response?.data;
      if (streaks) {
        this.streakLength = streaks.current || 0;
      }
    });
    this._streakBloc.refreshStreaks();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isCelebrationModalOpen() {
    this.showCelebrationModal = false;
  }

  private _updateStatus(status: DailyChallenge, showSocialChallengeCelebrationModal: boolean) {
    this.showedUp = status.showedUp;
    this.checkedIn = status.checkedIn;
    this.actionCompleted = status.actionCompleted;
    this.complete = status.challengeCompleted();

    // If the purposeful day steps were not previously completed, but are now then present the celebration modal
    if (this._previouslyCompleted != null && !this._previouslyCompleted && this.complete) {
      this._showCelebrationModal(showSocialChallengeCelebrationModal);
    }

    // Update the current purposeful day steps completion status
    this._previouslyCompleted = this.complete;

    this._dailyChallengeStatusBloc.setPurposefulDayCelebrationStatus(this._previouslyCompleted);
  }

  private _showCelebrationModal(showSocialChallengeCelebrationModal: boolean) {
    // only show daily challenge celebration if social challenge is not showing
    this.showCelebrationModal = !showSocialChallengeCelebrationModal;
  }
}

export const MockPurposefulDaysIndicatorComponent = MockComponent({
  selector: 'kp-purposeful-days',
  template: '<ng-content></ng-content>',
});
