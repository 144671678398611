import cloneDeep from 'lodash/cloneDeep';
import { CardItem } from './card-item.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { UserCardState } from './user-card-state.model';

const ITEMS_KEY = 'items';

export class CardCollection implements Deserializable<JsonObject, CardCollection> {
  constructor(
    public name?: string,
    public description?: string,
    public cardItems?: JsonObject<CardItem>
  ) {}

  get itemCount(): number {
    return Object.entries(this.cardItems || {}).length;
  }

  deserialize(json: JsonObject) {
    if (json == null) {
      return null;
    }

    if (Object.prototype.hasOwnProperty.call(json, 'cardItems')) {
      /*
      server sends back an array of cards, but instances of CardCollection may have
      the JsonObject of CardId: Card

      [card, card, card]
      {id: card, id: card, id: card}
       */
      json[ITEMS_KEY] = [...Object.values(json['cardItems'])].map((card: JsonObject) => ({
        card,
        userState: card['userState'] as UserCardState,
      }));
    }
    if (Object.prototype.hasOwnProperty.call(json, ITEMS_KEY)) {
      this.cardItems = {};
      (json[ITEMS_KEY] as JsonObject<CardItem>[]).forEach((itemJson) => {
        const _card: JsonObject = (itemJson['card'] as CardItem) || {};
        this.cardItems[_card['id'] as string] = new CardItem().deserialize(itemJson);
      });
    }

    this.name = json['name'] as string;
    this.description = json['description'] as string;

    return this;
  }

  clone(cardCollection?: CardCollection) {
    // TODO: when there is time for additional testing, we could use deserialize instead of cloneDeep
    return cloneDeep(cardCollection ?? this);
  }
}
