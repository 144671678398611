import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LocalResourceHours } from './local-resource-hours.model';
import { PhoneNumber } from './phone-number.model';

export class LocalResourceLocation implements Deserializable<JsonObject, LocalResourceLocation> {
  constructor(
    public address1?: string,
    public address2?: string,
    public city?: string,
    public formattedHours?: LocalResourceHours,
    public phoneNumber?: PhoneNumber,
    public postal?: string,
    public state?: string,
    public distance?: number,
    public email?: string,
    public notes?: string,
    public fax?: string,
    public name?: string,
    public isAdministrative?: boolean
  ) {}

  deserialize(input: JsonObject): LocalResourceLocation {
    if (input == null) {
      return null;
    }

    this.address1 = input['address1'] as string;
    this.address2 = input['address2'] as string;
    this.city = input['city'] as string;
    this.formattedHours =
      new LocalResourceHours().deserialize(input['formattedHours']) ||
      new LocalResourceHours().deserialize(input['hours']);
    const phoneNumber = (input['phoneNumber'] || input['phone_number']) as string | PhoneNumber;
    this.phoneNumber = phoneNumber instanceof PhoneNumber ? phoneNumber : new PhoneNumber().deserialize(phoneNumber);
    this.postal = input['postal'] as string;
    this.state = input['state'] as string;
    this.distance = Math.round(input['distance']);
    this.email = input['email'] as string;
    this.notes = input['notes'] as string;
    this.fax = input['fax_number'] as string;
    this.name = input['name'] as string;
    this.isAdministrative = !!(input['is_administrative'] || input['isAdministrative']);

    return this;
  }
}
