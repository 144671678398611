<button
  (click)="toggleSave($event)"
  [attr.aria-label]="isSaved ? 'Un-save' : ('Save' | translate | async)"
  class="save-button">
  <kp-simple-icon-animation
    [ngClass]="{ 'is-saved': isSaved }"
    [options]="options"
    [iconShouldFill]="isSaved"
    [iconClicked]="saveClicked"
    [color]="color"></kp-simple-icon-animation>
  <ng-content></ng-content>
</button>
