import { Component, Inject } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService, WINDOW, productDisplayName } from '@kservice';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  getEncodedURI = encodeURI;
  environment = this._environmentVariablesService.config;
  productName = productDisplayName;

  constructor(
    public dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService,
    @Inject(WINDOW) public window: Window
  ) {}
}

export const MockFeedbackComponent = MockComponent({
  selector: 'kp-feedback',
});
