@if (error) {
  <div class="error-generic">
    <h2 class="error-header">{{ 'Login Error' | translate | async }}</h2>
    <p>
      {{
        'There was an error trying to login with the selected Google account. If the error persists, please contact'
          | translate
          | async
      }}
      <a href="mailto:support@kumanu.com" class="error-email-link" target="_blank">support&#64;kumanu.com</a>
      {{ 'for assistance.' | translate | async }}
    </p>
  </div>
}
@if (!error) {
  <div class="error-generic">
    @if (authorizingUser) {
      <h2 class="error-header">{{ 'Logging In' | translate | async }}</h2>
    }
    @if (!authorizingUser) {
      <h2 class="error-header">{{ 'Login Error' | translate | async }}</h2>
    }
    @if (authorizingUser) {
      <p>{{ 'Finalizing user authentication' | translate | async }}&hellip;</p>
    }
    @if (!authorizingUser) {
      <p>
        {{
          'There was an error trying to finalize user authentication. If the error persists, please contact'
            | translate
            | async
        }}
        <a href="mailto:support@kumanu.com" class="error-email-link" target="_blank">support&#64;kumanu.com</a>
        {{ 'for assistance.' | translate | async }}
      </p>
    }
  </div>
}
<button class="button button-pill error-button" (click)="close()">{{ 'Close Window' | translate | async }}</button>
