import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { productDisplayName } from '@kservice';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-page-welcome',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './page-welcome.component.html',
  styleUrl: './page-welcome.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWelcomeComponent {
  page = input('');
  dialogueLogicKey = input('');

  protected readonly product = productDisplayName;
}

export const MockPageWelcomeComponent = MockComponent({
  selector: 'kui-page-welcome',
  standalone: true,
  inputs: ['page'],
});
