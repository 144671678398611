import cloneDeep from 'lodash/cloneDeep';
import { CardItemState } from '../enums/card-item-state.enum';
import { Deserializable } from './deserializable.model';
import { Habit } from './habit.model';
import { JsonObject } from './json-object.model';
import { NotificationInfo } from './notification-info.model';
import { SavedCardState } from './saved-card-state.model';
import { Serializable } from './serializable.model';

export enum UserCardPreference {
  MORE,
  LESS,
  NEUTRAL,
}

export class UserCardState implements Deserializable<JsonObject, UserCardState>, Serializable<JsonObject> {
  constructor(
    public preference?: UserCardPreference,
    public isTracking = false,
    public isRepeating = false,
    public isRecommended = false,
    public completedId?: string,
    public completedCount?: number,
    public availableItemCount?: number,
    public completedItemCount?: number,
    public isQuestComplete?: boolean,
    public likeCount?: number,
    // UI specific states
    public shownAnimation?: boolean,
    public trackingJustChanged = false,
    public currentState?: CardItemState,
    public saveState?: SavedCardState,
    public notifications?: NotificationInfo[],
    public habits?: Habit[]
  ) {}

  deserialize(json: JsonObject): UserCardState {
    if (json == null) {
      return null;
    }

    const tracking = (json['tracking'] || json || {}) as JsonObject;
    this.preference =
      (json['preference'] as string)?.endsWith?.('_more') || json['preference'] === UserCardPreference.MORE
        ? UserCardPreference.MORE
        : UserCardPreference.NEUTRAL;
    this.isTracking = !!tracking['isTracking'];
    this.isRepeating = !!tracking['isRepeating'];
    this.isRecommended = !!tracking['isRecommended'];
    // Need to set to null if isQuestComplete is undefined due to quest tab sorting of boolean
    this.isQuestComplete = !!tracking['isQuestComplete'] || null;
    // TODO: REMOVE HACK ONCE THE API CAN NO LONGER RETURN null/undefined/0
    //  along with a UserPreference of MORE (i.e. liked)
    this.likeCount = (
      (json['likeCount'] == null || json['likeCount'] === 0) && this.preference === UserCardPreference.MORE
        ? 1 // hack to fix server sending 0 incorrectly?
        : json['likeCount']
    ) as number;
    this.completedId = tracking['completedId'] as string;
    this.completedCount = tracking['completedCount'] as number;
    this.availableItemCount = tracking['availableItemCount'] as number;
    this.completedItemCount = tracking['completedItemCount'] as number;
    this.shownAnimation = checkCompletedId(this.completedId);
    this.currentState = this.isTracking
      ? checkCompletedId(this.completedId)
        ? CardItemState.ACT_COMPLETE
        : CardItemState.ACT_TO_DO
      : CardItemState.DISCOVER_DEFAULT;
    this.saveState = new SavedCardState().deserialize(json['saveState'] || {});
    this.habits = (json['habits'] as Habit[])?.map((habit) => new Habit().deserialize(habit));
    this.notifications = (json['notifications'] as NotificationInfo[])?.map((notification) =>
      new NotificationInfo().deserialize(notification)
    );
    return this;
  }

  clone(card: CardItemState) {
    return cloneDeep(card);
  }

  serialize() {
    return {
      preference:
        this.preference === UserCardPreference.MORE
          ? 'card_more'
          : this.preference === UserCardPreference.LESS
            ? 'card_less'
            : null,
      likeCount: this.likeCount,
      tracking: {
        isTracking: this.isTracking,
        completedId: this.completedId,
        isRepeating: this.isRepeating,
        isQuestComplete: this.isQuestComplete,
        isRecommended: this.isRecommended,
        completedCount: this.completedCount,
        availableItemCount: this.availableItemCount,
        completedItemCount: this.completedItemCount,
      },
      saveState: this.saveState.serialize(),
      habits: this.habits.map((habit) => habit.serialize()),
      notifications: this.notifications.map((notification) => notification.serialize()),
    };
  }

  get isTransitioningFromToDoState() {
    return this.isCompleted && [CardItemState.ACT_ANIMATION].includes(this.currentState);
  }

  get isCompleted() {
    // Completed quest cards have isQuestComplete boolean but completedId set to null
    // Other completed cards have completedIds
    return checkCompletedId(this.completedId) || this.isQuestComplete;
  }
}

function checkCompletedId(completedId: string): boolean {
  return completedId && completedId !== '' && completedId !== 'None';
}
