import { DialogueInformation } from '@kp/dialogue/models/dialogue-information.model';
import { Question } from '@kp/question-set/models/question.model';
import { AiFeedback, Deserializable, JsonObject } from '@ktypes/models';

export enum DialogueBlockType {
  information = 'dialogue_block_info_screen',
  question = 'dialogue_block_question',
  terminate = 'dialogue_block_terminate',
  dialogue_block_signup_sequence = 'dialogue_block_signup_sequence',
}

export interface DailyReflectionDialogueBlockData {
  question: Question;
  validation: { required: boolean };
}

export interface InformationDialogueBlockData {
  information: DialogueInformation;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OnboardingDialogueBlockData {}

export type DialogueBlockData =
  | DailyReflectionDialogueBlockData
  | OnboardingDialogueBlockData
  | InformationDialogueBlockData;

export class DialogueBlock implements Deserializable<JsonObject, DialogueBlock> {
  constructor(
    public id?: string,
    public type?: string,
    public canUndo?: boolean,
    public data?: DialogueBlockData,
    public feedback?: Partial<AiFeedback>,
    public hasAiCoach?: boolean
  ) {}

  deserialize(input: JsonObject): DialogueBlock {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.type = input['type'] as DialogueBlockType;
    this.canUndo = !!input['canUndo'];
    this.feedback = new AiFeedback().deserialize(input['feedback']);
    this.hasAiCoach = !!input['hasAiCoach'];

    switch (this.type) {
      case DialogueBlockType.information:
        this.data = {
          information: new DialogueInformation().deserialize((input['data'] as JsonObject)?.['userInfoScreen']),
        };
        break;
      case DialogueBlockType.question:
        this.data = {
          question: new Question().deserialize((input['data'] as JsonObject)?.['userQuestion']),
          validation: (input['data'] as JsonObject)?.['validation'] as { required: boolean },
        };
        break;
      case DialogueBlockType.dialogue_block_signup_sequence:
      case DialogueBlockType.terminate:
      default:
        this.data = null;
        break;
    }
    return this;
  }

  get question(): Question {
    return (this.data as DailyReflectionDialogueBlockData)?.question;
  }

  get validation() {
    return (this.data as DailyReflectionDialogueBlockData)?.validation;
  }

  get information() {
    return (this.data as InformationDialogueBlockData)?.information;
  }
}
