import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EnvironmentVariablesService } from '@kenv';
import { Constants } from '@kp/shared/constants.service';
import { Product } from '@ktypes/enums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductGuard {
  constructor(
    public router: Router,
    private environmentVariablesService: EnvironmentVariablesService
  ) {}

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = (activatedRouteSnapshot.data?.productGuardRedirectUrl as string) ?? '/error';
    return (
      this.urlMatchesProduct(routerStateSnapshot.url, Product.purposeful) ||
      this.urlMatchesProduct(routerStateSnapshot.url, Product.resourceful) ||
      this.router.parseUrl(redirectUrl)
    );
  }

  urlHasMatch(routes: string[], url: string) {
    if (Array.isArray(routes) && url) {
      return routes.some((route) => url.match(RegExp(route)));
    }
    return false;
  }

  urlMatchesProduct(url: string, product: Product) {
    return (
      this.urlHasMatch(Constants.appSpecificRoutes[product], url) &&
      this.environmentVariablesService.product === product
    );
  }
}
