import { DateTimeUtil } from '@kutil';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export enum QuestEventType {
  QUEST_OPEN = 'quest_open',
  QUEST_COMPLETE = 'quest_completed',
}

export class QuestEvent implements Serializable<JsonObject> {
  public deviceCreatedTimestamp: string;

  constructor(
    public questId?: string,
    public type?: QuestEventType,
    public eventInfo?: JsonObject
  ) {
    this.deviceCreatedTimestamp = DateTimeUtil.formatInLocal();
    if (!eventInfo) {
      this.eventInfo = {};
    }
  }

  serialize(): JsonObject {
    return {
      eventType: this.type,
      deviceCreatedTimestamp: this.deviceCreatedTimestamp,
      eventInfo: this.eventInfo,
    };
  }
}
