import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class TokenResponse implements Deserializable<JsonObject, TokenResponse> {
  tokenId: string;
  token: string;
  tokenType: string;
  groupId: string;
  userId: string;

  deserialize(input: JsonObject): TokenResponse {
    if (input == null) {
      return null;
    }
    this.tokenId = input['id'] as string;
    this.token = input['token'] as string;
    this.tokenType = input['tokenType'] as string;
    this.groupId = input['groupId'] as string;
    this.userId = input['userId'] as string;
    return this;
  }
}
