import { CardContentType } from './card-item.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LocalResourceLocation } from './local-resource-location.model';
import { UserCardPreference, UserCardState } from './user-card-state.model';

export class LocalResourcesProgram implements Deserializable<JsonObject, LocalResourcesProgram> {
  constructor(
    public description?: string,
    public programName?: string,
    public id?: string,
    public providerName?: string,
    public offices?: LocalResourceLocation[],
    public nearestOffice?: LocalResourceLocation,
    public email?: string,
    public websiteUrl?: string,
    public updateDate?: string,
    public preference?: UserCardPreference,
    public rules?: string[],
    public contentId?: string,
    public isInViewport = false,
    public itemIndex?: number,
    public userState?: UserCardState,
    public hideFromUI = false,
    public contentType: CardContentType = CardContentType.local_resource
  ) {}

  deserialize(input: JsonObject): LocalResourcesProgram {
    if (input == null) {
      return null;
    }
    this.description = input['description'] as string;
    this.programName = (input['name'] as string) || (input['programName'] as string);
    this.id = (input['program_numeric_id'] as string) || (input['id'] as string);
    this.providerName = (input['provider_name'] as string) || (input['providerName'] as string);
    if (Array.isArray(input['offices'])) {
      this.offices = input['offices'].map((office) => new LocalResourceLocation().deserialize(office));
    }
    this.nearestOffice = this.offices?.[0];
    this.email = this.offices?.[0].email;
    this.websiteUrl = (input['website_url'] as string) || (input['websiteUrl'] as string);
    this.updateDate =
      (input['updateDate'] as string) ||
      (input['update_date'] ? ((input['update_date'] as string).split('@')[0] as string) : null);
    this.preference =
      (input['preference'] as string)?.endsWith?.('_more') || input['preference'] === UserCardPreference.MORE
        ? UserCardPreference.MORE
        : UserCardPreference.NEUTRAL;
    this.rules = input['rules'] as string[];
    this.contentId = (input['contentId'] as string) || (input['content_id'] as string);
    this.isInViewport = !!input['isInViewport'];
    this.userState = input['userState'] ? new UserCardState().deserialize(input['userState']) : null;
    this.hideFromUI = !!input['hideFromUI'];
    this.contentType = (input['contentType'] as CardContentType) || CardContentType.local_resource;
    return this;
  }
}
