import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { NavigationBloc, SharingBloc, TagBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { UserBloc } from '@kp/user/user.bloc';
import { DataStoreService } from '@kservice';
import { AppSwitchLocation } from '@ktypes/enums';
import { NavItemType, ProductInfo } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-site-nav',
  templateUrl: './site-nav.component.html',
  styleUrls: ['./site-nav.component.scss'],
})
export class SiteNavComponent implements OnInit, OnChanges {
  @Input() navActive: boolean;
  @Output() sidebarClick = new EventEmitter<boolean>();

  navType = NavItemType;
  sideMenuAnnouncement: string;

  private _navigated = false;

  @HostListener('window:keydown.escape', ['$event']) private _triggerEsc() {
    if (this.navActive) {
      this.closeOverlay(true);
    }
  }

  constructor(
    private _analyticsBloc: AnalyticsBloc,
    public dataStore: DataStoreService,
    @Inject(DOCUMENT) private _document: Document,
    private _environmentVariablesService: EnvironmentVariablesService,
    public navBloc: NavigationBloc,
    private _router: Router,
    public sharingBloc: SharingBloc,
    public tagBloc: TagBloc,
    private _userBloc: UserBloc
  ) {}

  ngOnInit() {
    this.routeEvent(this._router);
    this.sharingBloc.getInvites();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['navActive'] != null &&
      !changes['navActive'].firstChange &&
      changes['navActive'].previousValue !== changes['navActive'].currentValue &&
      !this._navigated
    ) {
      this.sideMenuAnnouncement = `${this.navActive ? 'Opening' : 'Closing'} side navigation`;
    } else if (this._navigated) {
      this._navigated = false;
    }
  }

  routeEvent(router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.sidebarClick.emit(true);
        this.sideMenuAnnouncement = '';
        this._navigated = true;
      }
    });
  }

  onSidebarClick(e: Event) {
    const element = e.target as HTMLElement;
    if (element.tagName !== 'A' && !element.closest('a')) {
      this.closeOverlay();
      e.preventDefault();
    }
  }

  closeOverlay(shouldClose = false) {
    if (this.navActive && shouldClose) {
      (this._document.querySelectorAll('.nav-menu-button')?.[0] as HTMLElement).focus();
    }
    this.sidebarClick.emit(shouldClose);
  }

  trackEvent(app: ProductInfo) {
    const event = this._analyticsBloc.createEventFromInteraction({
      event: AnalyticEvent.app_switch_requested,
      meta: {
        app_requested: app.product,
        link_location: AppSwitchLocation.menu,
        source_app: this._environmentVariablesService.product,
      },
    });
    this._analyticsBloc.sendEvent(event);
    this._userBloc.switchApplication(app.product);
    this.closeOverlay();
  }

  sideNavTab(e: Event) {
    if (this.navActive) {
      (this._document.querySelectorAll('.nav-menu-button')?.[0] as HTMLElement).focus();
      e.preventDefault();
    }
  }
}

export const MockSiteNavComponent = MockComponent({
  selector: 'kp-site-nav',
  inputs: ['navActive'],
});
