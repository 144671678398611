import { Component, Input, OnInit } from '@angular/core';
import { Product } from '@ktypes/enums';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-branding-icon',
  templateUrl: './branding-icon.component.html',
  styleUrls: ['./branding-icon.component.scss'],
})
export class BrandingIconComponent implements OnInit {
  @Input() product: Product;

  productPurposeful: boolean;
  productResourceful: boolean;

  ngOnInit() {
    this.productPurposeful = Product.purposeful === this.product;
    this.productResourceful = Product.resourceful === this.product;
  }
}

export const MockBrandingIconComponent = MockComponent({
  selector: 'kp-branding-icon',
  inputs: ['product'],
});
