@if (imageUrl && !isPlaying) {
  <img class="placeholder-image" [src]="imageUrl" [alt]="altText | translate | async" />
}
@if (!isPlaying) {
  <button
    type="button"
    (click)="play()"
    (keyup.space)="!isPlaying && play($event)"
    class="video-play-button"
    [attr.aria-label]="'Play video, ' + title | translate | async">
    <svg-icon class="icon" [ngClass]="{ primary: primary }" src="/assets/icons/play-circle.svg"></svg-icon>
  </button>
}
<video
  [ngClass]="{ 'not-loaded': !isPlaying }"
  controls
  #player
  preload="auto"
  (timeupdate)="updateTime()"
  (ended)="ended()"
  (play)="playStarted()"
  (pause)="paused()">
  <source [src]="url" />
  {{ "Sorry, your browser doesn't support embedded videos." | translate | async }}
</video>
