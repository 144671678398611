import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';
import { DataLinkBloc } from '@kp/data-link/data-link.bloc';
import { UserBloc } from '@kp/user/user.bloc';
import { DataStoreService } from '@kservice';
import { UserType } from '@ktypes/enums';
import { Observable, combineLatest, withLatestFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExistingAccountGuard {
  constructor(
    private _authenticationBloc: AuthenticationBloc,
    private _dataStoreService: DataStoreService,
    private _router: Router,
    private _userBloc: UserBloc
  ) {}

  canActivate(/*route: ActivatedRouteSnapshot, state: RouterStateSnapshot*/): Observable<boolean | UrlTree> {
    return combineLatest([this._userBloc.fetchingUser$, this._dataStoreService.user$]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      withLatestFrom(this._authenticationBloc.existingAccount$),
      map(([[_, user], existingAccountStatus]) => {
        if (
          !DataLinkBloc.isExternalPulseSurvey &&
          (existingAccountStatus?.data || user?.type === UserType.user) &&
          !user?.email
        ) {
          return this._router.parseUrl('/login/existing_account');
        } else {
          return true;
        }
      })
    );
  }
}
