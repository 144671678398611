import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class Domain implements Deserializable<any, Domain> {
  constructor(
    public id?: string,
    public key?: string,
    public name?: string,
    public term?: string,
    public type?: string
  ) {}

  deserialize(input: JsonObject): Domain {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.key = input['key'] as string;
    this.name = input['name'] as string;
    this.term = input['term'] as string;
    this.type = input['type'] as string;

    return this;
  }
}
