import { Deserializable, Domain, JsonObject } from '@ktypes/models';

export class Intention implements Deserializable<JsonObject, Intention> {
  constructor(
    public questionId?: string,
    public intention?: string,
    public optionId?: string,
    public domain?: Domain
  ) {}

  deserialize(input: JsonObject): Intention {
    if (input == null) {
      return null;
    }

    this.questionId = input['questionId'] as string;
    this.intention = input['intention'] as string;
    this.optionId = input['optionId'] as string;
    this.domain = new Domain().deserialize(input['domain']);
    return this;
  }
}
