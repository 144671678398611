import { Component, EventEmitter, HostBinding, Input, NgZone, OnInit, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

const ANIMATION_DURATION = 1500; // 1:30s

@Component({
  selector: 'kp-done-animation',
  templateUrl: './done-animation.component.html',
  styleUrls: ['./done-animation.component.scss'],
})
export class DoneAnimationComponent implements OnInit {
  constructor(private _ngZone: NgZone) {}

  @Input() doneMessage = 'Done!';
  @Output() animationStateChange = new EventEmitter<boolean>();

  animationLoadedFired = false;
  canvasHeight = 900;
  canvasWidth = 750;
  playAnimation = true;

  @HostBinding('class.use-alternate')
  useAlternate = false;

  ngOnInit() {
    // use generic alternate if no WASM support
    this.useAlternate = typeof WebAssembly !== 'object';

    if (this.useAlternate) {
      this.loaded();
    }

    setTimeout(() => {
      if (this.animationLoadedFired) {
        // only run if animation didn't fire loaded state change
        return;
      }
      // Use ngZone because this is outsize the global Zone due to timeout
      void this._ngZone.run(() => {
        this.animationStateChange.emit(false);
      });
    }, ANIMATION_DURATION + 50);
  }

  loaded() {
    setTimeout(() => {
      this.animationLoadedFired = true;
      // Use ngZone because this is outsize the global Zone due to timeout
      void this._ngZone.run(() => {
        this.animationStateChange.emit(false);
      });
    }, ANIMATION_DURATION);
  }
}

export const MockDoneAnimationComponent = MockComponent({
  selector: 'kp-done-animation',
  inputs: ['doneMessage'],
});
