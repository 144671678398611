import { Inject, Injectable, InjectionToken } from '@angular/core';
import { OauthResponseTypes, Product } from '@ktypes/enums';
import { envVariables, latestVersion, versions } from './environment-variables';

export const CONFIG = new InjectionToken<EnvironmentConfig>('EnvironmentConfig');

export interface Versions {
  npm_version?: string;
  app?: {
    purposeful?: string;
    resourceful?: string; // TODO: Remove
    insightful?: string;
  };
  revision?: string;
  branch?: string;
}

export interface FirebaseAppConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export interface EnvironmentConfig {
  production?: boolean;
  apiHostUrl?: string;
  appUrls?: {
    purposeful?: string;
    resourceful?: string; // TODO: Remove
    insightful?: string;
  };
  bugLink?: string;
  environment?: string;
  feedbackLink?: string;
  firebase: FirebaseAppConfig;
  vapidKey: string;
  hostname?: string;
  version?: number;
  oauth: {
    authClientSsoId: string;
    authClientSocialId: string;
    clientId: string;
    domain: string;
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: OauthResponseTypes;
  };
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentVariablesService {
  constructor(@Inject(CONFIG) private _environmentConfig: EnvironmentConfig) {}

  get config(): EnvironmentConfig {
    return this._environmentConfig as EnvironmentConfig;
  }

  get circleTag(): Product {
    return (envVariables as { [key: string]: string })?.CIRCLE_TAG as Product;
  }

  // TODO: Rename to Project for consistency with scripts
  get product(): Product {
    return (envVariables as { [key: string]: string })?.PROJECT as Product;
  }

  get versions(): Versions {
    return versions as Versions;
  }

  get latestVersion(): string {
    return latestVersion as string;
  }

  get appVersion(): string {
    return (versions as Versions)?.app?.[(envVariables as { [key: string]: string }).PROJECT as Product] as string;
  }
}

export class MockPurposefulEnvVariable {
  // TODO: Rename to Project for consistency with scripts
  product = 'purposeful';
}
