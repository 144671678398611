import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

type InviteTypes = 'coworker' | 'friend' | 'spouse';

export type InviteEmail = {
  [invite in InviteTypes]: InviteEmailContents;
};

export enum InviteType {
  coworker = 'coworker',
  friend = 'friend',
  spouse = 'spouse',
}

export interface InviteEmailContents {
  subject: string;
  body: string;
}

export class Invite implements Deserializable<JsonObject, Invite> {
  constructor(
    public type?: InviteType,
    public available?: number,
    public accepted?: number,
    public sharingUrl?: string,
    public needsVerification?: boolean
  ) {}

  deserialize(input: JsonObject): Invite {
    if (input == null) {
      return null;
    }
    this.type = input['type'] as InviteType;
    this.available = (input['available'] as number) ?? -1; // -1 means unlimited available
    this.accepted = (input['accepted'] as number) ?? 0;
    this.sharingUrl = input['sharingUrl'] as string;
    this.needsVerification = !!input['needsVerification'];
    return this;
  }
}
