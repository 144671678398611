import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiveSupportInformation } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-live-support-detail',
  templateUrl: './live-support-detail.component.html',
  styleUrls: ['./live-support-detail.component.scss'],
})
export class LiveSupportDetailComponent {
  @Input() interactive = true;
  @Input() liveSupport: LiveSupportInformation;
  @Input() productName: string;

  @Output() liveSupportLinkClicked = new EventEmitter();
  @Output() liveSupportNumberClicked = new EventEmitter();
}

export const MockLiveSupportDetailComponent = MockComponent({
  selector: 'kui-live-support-detail',
  inputs: ['interactive', 'liveSupport', 'productName'],
});
