@if (checkbox) {
  <input
    #checkbox
    type="checkbox"
    aria-hidden="true"
    [id]="forId"
    [checked]="isSelected"
    [disabled]="isDisabled && !isSelected"
    (change)="selectInputOption.emit(checkbox.checked)" />
}
@if (!checkbox) {
  <input
    #radio
    type="radio"
    name="radio"
    aria-hidden="true"
    [id]="forId"
    [checked]="isSelected"
    (change)="selectInputOption.emit(radio.checked)" />
}
<label
  class="input-option"
  [for]="forId"
  [ngClass]="{ 'selected': isSelected, 'disabled': isDisabled && !isSelected, 'appear-disabled': appearDisabled }">
  <p class="input-copy" [attr.aria-label]="answerText + (isSelected ? ', selected' : ', unselected')">
    {{ answerText | translate | async }}
  </p>
  <div class="toggle"></div>
</label>
