import { HttpErrorResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { AuthDataService, BrowserStorage, DataStoreService, OnboardingUtilities, WINDOW } from '@kservice';
import { HttpApiErrorResponse, HttpStatusCode, LoginError, UserType } from '@ktypes/enums';
import {
  AuthData,
  Credentials,
  DataStatus,
  JsonObject,
  MfaRequiredApiResponse,
  OnboardingData,
  Status,
  StatusMessage,
} from '@ktypes/models';
import { isOfType } from '@kutil';
import { compareAsc } from 'date-fns';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationApi } from './authentication.api';

export class BaseAuthenticationBloc {
  constructor(
    private _authDataService: AuthDataService,
    private _authenticationApi: AuthenticationApi,
    public browserStorage: BrowserStorage,
    private _dataStoreService: DataStoreService,
    public onboardingUtilities: OnboardingUtilities,
    @Inject(WINDOW) private _window: Window
  ) {}

  private _changePasswordReturnUrl = new BehaviorSubject<string>(null);
  private _checkForExistingAccountDestroyer$ = new Subject<void>();
  private _currentPaymentRequiredErrorMessage = new BehaviorSubject<string>(null);
  private _existingAccount = new BehaviorSubject<DataStatus<boolean>>(null);
  private _existingAccountSubscription: Subscription;
  private _isCheckingForExistingAccount = false;
  private _resendMfaVerificationCodeStatus = new BehaviorSubject<DataStatus<boolean>>(null);
  private _passwordReset = new Subject<DataStatus<AuthData>>();
  private _passwordResetRequest = new Subject<DataStatus<AuthData>>();
  private _verifyMfaStatus$ = new BehaviorSubject<DataStatus<boolean>>(null);

  private _resetEmail: string;
  public proposedResetEmail: string;

  private _unverifiedNewEmail: string;
  private _changeEmailAccessToken = new BehaviorSubject<string>(null);

  get resetPasswordStatus$(): Observable<DataStatus<AuthData>> {
    return this._passwordReset.asObservable();
  }

  get changePasswordReturnUrl(): string {
    return this._changePasswordReturnUrl.getValue();
  }

  get unverifiedNewEmail(): string {
    return this._unverifiedNewEmail;
  }

  get changeEmailAccessToken(): string {
    return this._changeEmailAccessToken.getValue();
  }

  get currentPaymentRequiredErrorMessage$(): Observable<string> {
    return this._currentPaymentRequiredErrorMessage.asObservable();
  }

  get existingAccount$(): Observable<DataStatus<boolean>> {
    return this._existingAccount.asObservable();
  }

  get verifyMfaStatus$() {
    return this._verifyMfaStatus$.asObservable();
  }

  get resendMfaVerificationCodeStatus$(): Observable<DataStatus<boolean>> {
    return this._resendMfaVerificationCodeStatus.asObservable();
  }

  get storedGroupCode(): string {
    return this.storedOnboarding?.groupCode || '';
  }

  get latestPage(): string {
    return this.storedOnboarding?.latestPage || '';
  }

  get storedOnboarding(): OnboardingData {
    return this.browserStorage.getObject('onboarding') as OnboardingData;
  }

  get resetEmail() {
    return this._resetEmail;
  }

  get isRefreshTokenUnset() {
    return this._dataStoreService.authData?.refreshToken === 'unset' || !this._dataStoreService.authData?.refreshToken;
  }

  setPasswordReset(passwordReset: DataStatus<AuthData>) {
    this._passwordReset.next(passwordReset);
  }

  setChangePasswordReturnUrl(returnUrl: string) {
    this._changePasswordReturnUrl.next(returnUrl);
  }

  setUnverifiedNewEmail(email: string) {
    this._unverifiedNewEmail = email;
  }

  setChangeEmailAccessToken(accessToken: string) {
    this._changeEmailAccessToken.next(accessToken);
  }

  setPaymentRequiredErrorMessage(message: string) {
    this._currentPaymentRequiredErrorMessage.next(message);
  }

  resetPaymentRequiredErrorMessage() {
    this._currentPaymentRequiredErrorMessage.next(null);
  }

  resetChangeEmailVariables() {
    this.setUnverifiedNewEmail(null);
    this.setChangeEmailAccessToken(null);
  }

  get passwordResetStatus$(): Observable<DataStatus<AuthData>> {
    return this._passwordResetRequest.asObservable();
  }

  // Do login operation
  login(
    credentials?: Credentials,
    errorFunction?: (loginResponse?: DataStatus<AuthData> | JsonObject | { error: MfaRequiredApiResponse }) => void,
    successFunction?: (loginResponse?: DataStatus<AuthData> | JsonObject) => void
  ): void {
    this._dataStoreService.setAuthStatus(new DataStatus<AuthData>(Status.starting));

    this._authenticationApi
      .login(credentials)
      .then((loginResponse) => this._handleAuthResponse(loginResponse, 'login', errorFunction, successFunction));
  }

  private async _handleAuthResponse(
    authResponse:
      | DataStatus<AuthData>
      | DataStatus<AuthData | JsonObject>
      | { error: MfaRequiredApiResponse }
      | HttpErrorResponse,
    authType: 'login' | 'mfa' | 'reset-password',
    errorFunction?: (loginResponse?: DataStatus<AuthData> | JsonObject | { error: MfaRequiredApiResponse }) => void,
    successFunction?: (loginResponse?: DataStatus<AuthData> | JsonObject) => void
  ) {
    if (
      !isOfType<DataStatus<AuthData>, JsonObject>(authResponse, 'data') ||
      authResponse?.message?.code !== HttpStatusCode.OK
    ) {
      if (
        isOfType<DataStatus<any>, JsonObject>(authResponse, 'data') &&
        isOfType<AuthData, JsonObject>(authResponse.data, 'token')
      ) {
        this._authDataService.updateToken(authResponse.data);
      } else if (
        !Object.prototype.hasOwnProperty.call(authResponse, 'error') &&
        isOfType<DataStatus<AuthData>, JsonObject>(authResponse, 'data') &&
        isOfType<AuthData, JsonObject>(authResponse.data, 'token')
      ) {
        this._dataStoreService.setAuthStatus(authResponse);
      } else if (
        !isOfType<DataStatus<AuthData | JsonObject>, JsonObject>(authResponse, 'data') &&
        (authResponse as { error: MfaRequiredApiResponse })?.error?.explanation === LoginError.MfaVerificationRequired
      ) {
        this._dataStoreService.setAuthStatus(
          new DataStatus<AuthData>(
            Status.error,
            new StatusMessage(HttpStatusCode.BAD_REQUEST, 'Need to validate via MFA'),
            new AuthData().deserialize((authResponse as { error: MfaRequiredApiResponse }).error.authentication)
          )
        );
      } else if (isOfType<DataStatus<AuthData>, JsonObject>(authResponse, 'data')) {
        this._dataStoreService.setAuthStatus(
          new DataStatus<AuthData>(
            Status.error,
            new StatusMessage(
              HttpStatusCode.INTERNAL_SERVER_ERROR,
              `There was a problem handling auth response for ${authType}`
            ),
            authResponse.data
          )
        );
      } else {
        this._dataStoreService.setAuthStatus(
          new DataStatus<AuthData>(
            Status.error,
            new StatusMessage(
              HttpStatusCode.INTERNAL_SERVER_ERROR,
              `There was a problem handling auth response for ${authType}, ${authResponse}`
            ),
            null
          )
        );
        console.warn(`There was an unhandled problem with auth response for ${authType}`);
      }
      if (authType === 'mfa') {
        this._verifyMfaStatus$.next(new DataStatus<boolean>(Status.error, false));
      }
      errorFunction?.(authResponse);
      return;
    }

    this.clearObsoleteStorage();
    const authData = new AuthData().deserialize(authResponse.data);
    this._authDataService.updateToken(authData);
    this._dataStoreService.setAuthData(authData);
    this._dataStoreService.setUser(authData.user);
    this._dataStoreService.setAuthStatus(
      new DataStatus<AuthData>(Status.done, new StatusMessage(HttpStatusCode.OK, 'OK'), authData)
    );
    if (authType === 'mfa') {
      this._verifyMfaStatus$.next(new DataStatus<boolean>(Status.done, true));
    }
    successFunction?.(authResponse);
  }

  verifyMfaCode(
    code: string,
    errorFunction?: (verifyMfaCodeResponse?: JsonObject) => void,
    successFunction?: (mfaVerificationResponse?: JsonObject) => void
  ) {
    this._authenticationApi
      .verifyMfaCode(code)
      .then((verifyMfaCodeResponse) => {
        void this._handleAuthResponse(verifyMfaCodeResponse, 'mfa', errorFunction, successFunction);
      })
      .catch((error) => errorFunction?.(error));
  }

  resendMfaVerificationCode() {
    this._authenticationApi
      .resendMfaVerificationCode()
      .then((codeSentStatus) => {
        void this._resendMfaVerificationCodeStatus.next(codeSentStatus);
      })
      .catch((error) => void this._resendMfaVerificationCodeStatus.next(error));
  }

  saveExistingAuthData(pulseSurveyAuthData: JsonObject, extraCleanup: () => void) {
    // get the original user authData if it existed from the dataStore,
    // not via loadToken() where it could get stored authData from a previous pulse survey
    const existingAuthData = this._dataStoreService.authData;
    extraCleanup();
    if (existingAuthData?.token && existingAuthData?.user?.email && existingAuthData?.user?.type === UserType.user) {
      this.browserStorage.setObject('existingAuthData', existingAuthData, 30, true);
    }
    this._authDataService.clear({ authData: true, user: false, theme: false });
    this._dataStoreService.setPulseSurveyAuthData(pulseSurveyAuthData);
    this._updateTokenAndStatus(
      new DataStatus<AuthData>(
        Status.done,
        new StatusMessage(HttpStatusCode.OK, 'OK'),
        new AuthData().deserialize(pulseSurveyAuthData)
      )
    );
  }

  restoreExistingAuthData(): AuthData {
    const existingAuthData = this.browserStorage.getObject('existingAuthData', true) as AuthData;
    if (existingAuthData) {
      const authData = new AuthData().deserialize(existingAuthData);
      const authDataStatus = new DataStatus<AuthData>(Status.done, new StatusMessage(200, 'OK'), authData);
      this._updateTokenAndStatus(authDataStatus);
      this.browserStorage.remove('existingAuthData', true);
      return authData;
    }
    return null;
  }

  clearExistingAuthData() {
    this.browserStorage.remove('lastReflection');
    this.browserStorage.remove('existingAuthData', true);
    this.browserStorage.remove('authData', true);
    this.browserStorage.remove('user', true);
  }

  clearObsoleteStorage() {
    this.browserStorage.remove('authData');
    this.browserStorage.remove('existingAuthData', true);
    this.browserStorage.remove('firstVisit');
    this.browserStorage.remove('stayLoggedIn');
    this.browserStorage.remove('user');
  }

  requestPasswordReset(email: string): void {
    if (!this.changePasswordReturnUrl && !this._dataStoreService?.authData?.user?.id) {
      this._dataStoreService.setAuthStatus(
        new DataStatus<AuthData>(Status.starting, new StatusMessage(Status.starting, ''), null)
      );
      this._passwordResetRequest.next(
        new DataStatus<AuthData>(Status.starting, new StatusMessage(Status.starting, ''), null)
      );
    }
    this._authenticationApi
      .requestPasswordReset(email)
      .then((response) => {
        if (response) {
          if (
            response.message?.code === HttpStatusCode.BAD_REQUEST &&
            response.message?.message === LoginError.EmailVerificationRequired &&
            response.data
          ) {
            this._authDataService.updateToken(response.data);
          } else {
            this._setEmail(email);
          }
          this._passwordResetRequest.next(response);
        } else {
          this._dataStoreService.setAuthStatus(
            new DataStatus<AuthData>(
              Status.error,
              new StatusMessage(Status.error, 'There was a problem with requesting password reset'),
              null
            )
          );
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  changePassword(
    passwordCode: string,
    newPassword: string,
    errorFunction?: () => void,
    successFunction?: () => void
  ): void {
    if (!this.changePasswordReturnUrl && !this._dataStoreService?.authData?.user?.id) {
      this._dataStoreService.setAuthStatus(new DataStatus<AuthData>(Status.starting));
    }
    this._passwordReset.next(new DataStatus<AuthData>(Status.starting));

    this._authenticationApi
      .changePassword(this._resetEmail, newPassword, passwordCode)
      .then((changePasswordResponse) => {
        if (changePasswordResponse?.status === Status.done) {
          successFunction?.();
          void this._handleAuthResponse(changePasswordResponse, 'reset-password', errorFunction, successFunction);
          this._passwordReset.next(new DataStatus<AuthData>(Status.done, null, changePasswordResponse.data));
        } else {
          this._passwordReset.next(
            new DataStatus<AuthData>(
              Status.error,
              new StatusMessage(
                changePasswordResponse?.message?.code || HttpStatusCode.INTERNAL_SERVER_ERROR,
                changePasswordResponse?.message?.message || 'There was an error trying to reset your password.'
              ),
              null
            )
          );
        }
      })
      .catch((error) => {
        errorFunction?.();
        console.warn(error);
      });
  }

  logout(redirect = true): void {
    // we do not do anything with the revokeToken result at this time, good or bad
    const request = this._authenticationApi.revokeToken(this._dataStoreService.authData?.token);
    firstValueFrom(request)
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        // always clear any local auth/user data on logout, even if revokeToken fails
        this.clearAuthData();
        this.onboardingUtilities.removeOnboardingData();
        if (redirect) {
          // wait a short time to ensure user data has been cleared
          setTimeout(() => {
            this._window.location.href = '/';
          }, 250);
        }
      });
  }

  clearAuthData() {
    this._authDataService.clear({});
    this._dataStoreService.setAuthStatus(null);
  }

  refreshToken() {
    this._authenticationApi.refreshToken().subscribe((refreshTokenResponse: DataStatus<AuthData>) => {
      this._authDataService.updateToken(refreshTokenResponse.data);
    });
  }

  isTokenExpiredLocally() {
    const authData = this._dataStoreService.authData;
    const hasToken = authData.token != null;
    const isExpired = compareAsc(new Date(authData.dateExpires), new Date()) !== 1;
    return !hasToken || isExpired;
  }

  isLoggedIn(): boolean {
    // TODO: Re-evaluate when there is a better way to determine logged in status
    return (
      this._dataStoreService?.authData?.user?.type === UserType.user &&
      this._dataStoreService?.authData?.user?.email != null
    );
  }

  captureAuthErrors(error: HttpApiErrorResponse) {
    if (error?.explanation === 'PasswordResetRequiredException') {
      this.setPasswordReset(
        new DataStatus<null>(
          Status.error,
          new StatusMessage(error?.status || HttpStatusCode.FORBIDDEN, error?.explanation),
          null
        )
      );
    }
  }

  clearRefreshToken() {
    const authData = new AuthData().deserialize(this._dataStoreService.authData);
    authData.refreshToken = 'unset';
    this._authDataService.updateToken(authData);
  }

  checkForExistingAccount(eligibilityId?: string) {
    if (this._isCheckingForExistingAccount) {
      return;
    }
    this._isCheckingForExistingAccount = true;
    this._existingAccountSubscription?.unsubscribe();
    this._existingAccountSubscription = combineLatest([this._dataStoreService.authData$, this.existingAccount$])
      .pipe(takeUntil(this._checkForExistingAccountDestroyer$))
      .subscribe(([authData, existingAccountStatus]) => {
        if (!authData && !existingAccountStatus) {
          return;
        }
        if (existingAccountStatus?.status !== Status.starting && !existingAccountStatus?.data) {
          this._existingAccount.next(new DataStatus<boolean>(Status.starting, null, null));
          if (authData.user?.type === UserType.user) {
            this._existingAccount.next(new DataStatus<boolean>(Status.done, null, true));
            this._checkingForExistingCleanup();
          } else {
            this._authenticationApi
              .checkForExistingAccount(authData.user?.id, eligibilityId)
              .then((hasExistingAccount) => {
                this._existingAccount.next(hasExistingAccount);
              })
              .catch((error) => {
                this._existingAccount.next(new DataStatus<boolean>(Status.error, null, null));
                console.warn(error);
              })
              .finally(() => {
                this._checkingForExistingCleanup();
              });
          }
        } else {
          this._checkingForExistingCleanup();
        }
      });
  }

  private _setEmail(email: string) {
    this._resetEmail = email;
  }

  private _checkingForExistingCleanup() {
    this._checkForExistingAccountDestroyer$.next();
    this._isCheckingForExistingAccount = false;
  }

  private _updateTokenAndStatus(status: DataStatus<AuthData>) {
    // If the authentication succeeded, store the credentials
    if (status.status === Status.done && status.data != null) {
      const authData = new AuthData().deserialize(status.data);
      this._authDataService.updateToken(authData);
    }

    // Add the status to the stream
    this._dataStoreService.setAuthStatus(status);
  }
}
