import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export class Habit implements Deserializable<JsonObject, Habit>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public value?: string
  ) {}

  deserialize(json: JsonObject): Habit {
    if (json == null) {
      return null;
    }

    this.id = json['id'] as string;
    this.value = json['habit'] as string;
    return this;
  }

  serialize() {
    return {
      id: this.id,
      habit: this.value,
    };
  }
}
