import { Component, Input } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-dashes',
  templateUrl: './dashes.component.html',
  styleUrls: ['./dashes.component.scss'],
})
export class DashesComponent {
  @Input() isTrendingDown = false;
}

export const MockDashesComponent = MockComponent({ selector: 'kp-dashes', inputs: ['isTrendingDown'] });
