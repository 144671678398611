<header class="detail-view-header" [ngClass]="{ 'header-shadow': showHeaderShadow }">
  @if (card?.contentType !== cardContentType.domain) {
    <kp-feedback-buttons
      class="hidden-print"
      [card]="card"
      [preference]="card?.userState?.preference"
      [screen]="screen"
      [requestType]="requestType"
      [feedbackTitleInfo]="card?.title"
      [color]="(themeService.theme$ | async)?.primary"
      (feedbackClicked)="sendCardEvent($event)"></kp-feedback-buttons>
  }
</header>

@if (card) {
  <div class="card-container" [ngClass]="{ 'is-safari': isSafari }">
    <section #mediaContainer class="media-container hidden-print">
      <kui-media-player
        class="card-media hidden-print"
        [ngClass]="{
          'has-transcript': !!card.primaryMedia?.rawTranscript,
          'big-image': card.cardImage && !card.primaryMedia?.isVideo
        }"
        [analyticsPageName]="analyticsPageName"
        [detailCardData]="cardBloc.detailViewClicked | async"
        [media]="card.primaryMedia"
        [image]="card.cardImage"
        imageWidth="1218"
        [renderTranscript]="true"
        [transcriptKey]="card.id"
        [transcriptInitiallyOpen]="false"></kui-media-player>
    </section>
    <section class="paragraph-container">
      <div class="card-body">
        <h3 class="card-topic" [ngClass]="{ 'card-topic-domain': card.contentType === cardContentType.domain }">
          {{ card.topic }}
        </h3>
        <h3 class="card-title" [ngClass]="{ 'domain-title': card.contentType === cardContentType.domain }">
          {{ card.title }}
        </h3>
        <kui-rich-text [richText]="card.rawBody" [translate]="false"></kui-rich-text>
      </div>
      @if (card.userState?.isTracking && this.isPurposeful) {
        @if (!card.userState?.isQuestComplete && !(card.userState.isCompleted && !card.isRepeatable)) {
          <kp-action-reminder-detailed-view
            class="reminders-container"
            [card]="card"></kp-action-reminder-detailed-view>
        }
        @if (!card?.isQuestCard && card?.isRepeatable) {
          <kp-habit-pairing class="reminders-container" [card]="card"></kp-habit-pairing>
        }
      }
      @if (card.why || card.how) {
        <div class="breakout-container">
          @if (card.why) {
            <h2>{{ 'Why It Matters' | translate | async }}</h2>
          }
          @if (card.why) {
            <p class="breakout-paragraph">{{ card.why }}</p>
          }
          @if (card.how) {
            <h2>{{ 'Pro Tip' | translate | async }}</h2>
          }
          @if (card.how) {
            <p class="breakout-paragraph">{{ card.how }}</p>
          }
        </div>
      }
    </section>
    @if (card.userState && card.contentType !== cardContentType.domain) {
      <section class="link-container hidden-print">
        @if (isPurposeful && card?.isTrackingAndActionable) {
          <button (click)="addToOrRemoveFromActions($event, cardEventType.UNTRACK)" class="icon-action icon-btn remove">
            <svg-icon svgClass="link-svg" src="/assets/icons/minus-circle.svg"></svg-icon>
            <span class="link-text">{{ 'Remove from Take Action' | translate | async }}</span>
          </button>
        }
      </section>
    }
    @if (
      card.contentType !== cardContentType.domain &&
      card.userState?.availableItemCount &&
      card.userState?.completedItemCount > 0
    ) {
      <section class="progress-bar-container">
        {{
          card.userState.completedItemCount + ' of ' + card.userState.availableItemCount + ' Complete'
            | translate
            | async
        }}
        <kp-progress-bar
          class="progress-bar"
          [value]="card.userState.completedItemCount"
          [max]="card.userState.availableItemCount"
          [hasPrimaryColors]="true"></kp-progress-bar>
      </section>
    }
    @if (
      card.contentType !== cardContentType.domain &&
      (card.cardLink.hasLink ||
        (card?.userState?.isCompleted && !card?.inQuest) ||
        card?.isActionable ||
        (card?.inQuest && !card?.userState?.isCompleted))
    ) {
      <section class="button-container hidden-print">
        @if (card?.cardLink?.hasLink || ((card?.isQuestCard || card?.inQuest) && !card?.userState?.isCompleted)) {
          <kp-call-to-action
            class="detail-button detail-button-reverse"
            (click)="linkClicked($event)"
            [link]="card && card.cardLink.hasLink ? card.cardLink : questActionType"
            [callToAction]="
              card.callToAction ||
              ((card?.inQuest && !card?.cardLink?.hasLink ? 'Mark Complete' : 'Reflect') | translate | async)
            "
            [card]="card"
            [isQuestCard]="card?.isQuestCard || card?.inQuest"
            [requestType]="requestType"
            [returnUrl]="returnUrl"
            (closeCard)="modalClose.emit(true)"
            data-e2e="call-to-action"></kp-call-to-action>
        }
        <!--   Note: Quest screen check is needed here as there is nothing to signal that card is inside quest   -->
        @if (!card?.inQuest && card?.userState?.isCompleted && isPurposeful && !card.isQuestCard) {
          <button class="button button-reverse button-pill uncomplete button-cta" (click)="markUncomplete($event)">
            {{ 'Mark Not Done' | translate | async }}
          </button>
        }
        @if (!card?.userState?.isCompleted && isPurposeful && card?.isTrackingAndActionable && !card.isQuestCard) {
          <button class="button button-reverse button-pill button-cta" (click)="markComplete($event)">
            {{ 'Did It!' | translate | async }}
          </button>
        }
        @if (!card?.userState?.isCompleted && card?.isActionableAndNotTracking && isPurposeful) {
          <button
            class="button button-reverse button-pill button-cta"
            (click)="addToOrRemoveFromActions($event, cardEventType.TRACK)">
            {{ 'Commit to Try It' | translate | async }}
          </button>
        }
      </section>
    }
    <kp-copyright class="hidden-screen" [product]="product"></kp-copyright>
  </div>
} @else {
  {{ 'Loading' | translate | async }}&hellip;
}
