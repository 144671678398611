import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AnalyticsPageName } from '@kanalytics';
import { ContentImage, ContentMedia, DetailCardData } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
})
export class MediaPlayerComponent implements OnInit, OnChanges {
  @Input() analyticsPageName: AnalyticsPageName;
  @Input() detailCardData: DetailCardData;
  @Input() image: ContentImage;
  @Input() imageWidth: string;
  @Input() imageHeight: string;
  @Input() media: ContentMedia;
  @Input()
  @HostBinding('class.on-background')
  isOnBackground = false;
  @Input() primaryColorVideoPlay = true;
  @Input() renderTranscript = false; // TODO: default to true?
  @Input() showAudioControls = true;
  @Input() showTranscript = false;
  @Input() transcriptKey: string;
  @Input() transcriptInitiallyOpen = false;

  @Output() transcriptToggled = new EventEmitter<boolean>();

  imageAdjustments = '';
  imageWithFallback: ContentImage;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.imageWidth || changes?.imageHeight) {
      this.imageAdjustments = setImageAdjustments(
        this.imageWithFallback?.url,
        changes.imageWidth?.currentValue,
        changes.imageHeight?.currentValue
      );
    }
    if (
      changes?.image?.currentValue ||
      (changes?.media?.currentValue as ContentMedia)?.placeholderImage ||
      (changes?.media?.currentValue as ContentMedia)?.asImage
    ) {
      this.imageWithFallback = this._getImageFallback();
    }
  }

  ngOnInit() {
    this.imageWithFallback = this._getImageFallback();
    this.imageAdjustments = setImageAdjustments(this.imageWithFallback?.url, this.imageWidth, this.imageHeight) ?? '';
    this.showTranscript = this.transcriptInitiallyOpen;
  }

  transcriptAccordionChange(isOpen: boolean) {
    if (this.showTranscript !== isOpen) {
      this.showTranscript = isOpen;
      this.transcriptToggled.emit(isOpen);
    }
  }

  private _getImageFallback() {
    return this.media?.placeholderImage || this.image || this.media?.asImage;
  }
}

export function setImageAdjustments(url: string, imageWidth?: string, imageHeight?: string): string {
  const width = imageWidth ? `w=${imageWidth}` : '';
  const height = imageHeight ? `h=${imageHeight}` : '';
  const joined = [width, height].filter((i) => i).join('&');
  const joiner = url?.includes('?') ? '&' : '?';
  return joined ? `${joiner}${joined}` : '';
}

export const MockMediaPlayerComponent = MockComponent({
  selector: 'kui-media-player',
  inputs: [
    'analyticsPageName',
    'detailCardData',
    'image',
    'imageWidth',
    'imageHeight',
    'media',
    'isOnBackground',
    'primaryColorVideoPlay',
    'renderTranscript',
    'showTranscript',
    'transcriptKey',
    'transcriptInitiallyOpen',
  ],
});
