import { Injectable } from '@angular/core';
import { TagBloc } from '@kbloc';
import { SharedConstants } from '@kservice';
import { combineLatest, firstValueFrom } from 'rxjs';
import { Translation } from './models/translation.model';
import { TranslationApi } from './translation.api';
import { TranslationBloc } from './translation.bloc';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(
    private _tagBloc: TagBloc,
    private _translationApi: TranslationApi,
    private _translationBloc: TranslationBloc
  ) {}

  async translate(
    text: string | string[],
    languageCode?: string,
    format: 'text' | 'html' = 'text'
  ): Promise<Translation[]> {
    const [allowLanguageTranslation, currentLanguage] = await firstValueFrom(
      combineLatest([this._tagBloc.languageTranslation$, this._translationBloc.currentLanguage$])
    );
    const normalizedLanguageCode = await this._normalizeLanguageCode(languageCode, currentLanguage);
    if (!allowLanguageTranslation || normalizedLanguageCode === SharedConstants.DEFAULT_LANGUAGE) {
      return getTranslations(text);
    }
    const translations = await this._translationApi.translate(text, normalizedLanguageCode, format);
    // if API fails or translation doesn't work, just resupply original text
    return translations || getTranslations(text);
  }

  async translateSingle(text: string, languageCode?: string, format: 'text' | 'html' = 'text'): Promise<string> {
    const translation = await this.translate(text, languageCode, format);
    return (translation?.[0] || {})?.translatedText || text;
  }

  private async _normalizeLanguageCode(languageCode: string, currentLanguage: string) {
    let normalizedLanguageCode = languageCode || currentLanguage;
    if (!normalizedLanguageCode) {
      // fallback check cause sometimes currentLanguage is undefined the first time
      normalizedLanguageCode =
        (await firstValueFrom(this._translationBloc.currentLanguage$)) || SharedConstants.DEFAULT_LANGUAGE;
    }
    return normalizedLanguageCode;
  }
}

function getTranslations(text: string | string[]) {
  return Array.isArray(text)
    ? text.map((t) => new Translation().deserialize({ translatedText: t }))
    : [new Translation().deserialize({ translatedText: text })];
}
