<button
  [attr.aria-label]="
    'Like Button - ' + (liked ? 'liked' : '') + (likeCount > 0 ? ' - Like count: ' + likeCount : '') | translate | async
  "
  (click)="likeButtonClicked($event)"
  class="icon-action">
  <div class="like-container">
    <kp-simple-icon-animation
      [ngClass]="{ liked }"
      [options]="options"
      [iconShouldFill]="liked"
      [iconClicked]="likeClicked"
      [color]="color"></kp-simple-icon-animation>
    @if (likeCount > 0) {
      <span class="link-text" aria-hidden="true" [ngStyle]="{ color: color }">
        {{ likeCount | translate | async }}
      </span>
    }
  </div>
</button>
