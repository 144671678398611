import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LocalResourcesProgram } from './local-resources-program.model';

export class LocalResourcesSearchItem implements Deserializable<JsonObject, LocalResourcesSearchItem> {
  constructor(
    public count?: number,
    public programs?: LocalResourcesProgram[],
    public zipCode?: string
  ) {}

  deserialize(input: JsonObject): LocalResourcesSearchItem {
    if (input == null) {
      return null;
    }
    this.count = input['count'] as number;
    this.programs = (input['programs'] as LocalResourcesProgram[])?.map((program) =>
      new LocalResourcesProgram().deserialize(program)
    );
    this.zipCode = (input['userZip'] as string) || (input['zipCode'] as string); // prefer userZip; zipCode is via server response
    return this;
  }
}
