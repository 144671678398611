import { Deserializable, JsonObject, Link } from '@ktypes/models';

export enum PromoContentType {
  card = 'card',
  dialogue = 'dialogue',
  external_link = 'external_link',
  internal_link = 'internal_link',
  pulse_survey = 'pulse_survey',
}

export class PromoCallToActionContent implements Deserializable<JsonObject, PromoCallToActionContent> {
  constructor(
    public type?: PromoContentType,
    public link?: Link
  ) {}

  // TODO: Once we add more PromoContentTypes, we'll want to segment the data here similar to DialogueBlockModel
  deserialize(input: JsonObject): PromoCallToActionContent {
    if (input == null) {
      return null;
    }
    this.type = input['contentType'] as PromoContentType;
    this.link = new Link().deserialize(input['link']);

    return this;
  }
}
