import { Deserializable, JsonObject, Serializable } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';

export class DailyChallenge implements Deserializable<JsonObject, DailyChallenge>, Serializable<JsonObject> {
  constructor(
    public date?: Date,
    public actionCompleted?: boolean,
    public challengeComplete?: boolean,
    public showedUp?: boolean,
    public checkedIn?: boolean
  ) {}

  public challengeCompleted() {
    return this.challengeComplete || (this.showedUp && this.checkedIn && this.actionCompleted);
  }

  public deserialize(input: JsonObject): DailyChallenge {
    if (input == null) {
      return null;
    }

    this.date = DateTimeUtil.convertUTCDateToLocalDate(new Date(input.date));
    this.actionCompleted = !!input['actionCompleted'];
    this.challengeComplete = !!input['challengeComplete'];
    this.showedUp = !!input['showedUp'];
    this.checkedIn = !!input['checkedIn'];

    return this;
  }

  public serialize(): JsonObject {
    const json: JsonObject = {};

    json['date'] = this.date;
    json['actionCompleted'] = this.actionCompleted;
    json['challengeComplete'] = this.challengeComplete;
    json['showedUp'] = this.showedUp;
    json['checkedIn'] = this.checkedIn;

    return json;
  }
}
