export enum CardRequestType {
  AVAILABLE = 'AVAILABLE',
  DOMAIN_ACTION = 'DOMAIN_ACTION',
  DOMAIN_AVAILABLE = 'DOMAIN_AVAILABLE',
  QUEST = 'QUEST',
  RECOMMENDED = 'RECOMMENDED',
  REFLECTION = 'REFLECTION',
  REMOVED = 'REMOVED', // Needed for re-adding removed cards to actions
  RESOURCES = 'RESOURCES',
  TRACKING = 'TRACKING',
  SAVED = 'SAVED',
  SEARCH = 'SEARCH',
  SOLO = 'SOLO',
  TAKE_ACTION = 'TAKE_ACTION',
  TOTD = 'TOTD',
}

export enum FilterCardRequestType {
  DOMAIN_ACTION = 'DOMAIN_ACTION',
  DOMAIN_AVAILABLE = 'DOMAIN_AVAILABLE',
  RECOMMENDED = 'RECOMMENDED',
}
