import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { KumanuKeyType } from '@kp/dialogue/models/dialogue.model';
import { Answer } from '@kp/question-set/models/answer.model';
import { Question, QuestionLogicKey, QuestionType } from '@kp/question-set/models/question.model';
import { QuestionSetBloc } from '@kp/question-set/question-set.bloc';
import { QuestionBloc } from '@kp/question-set/question/question.bloc';
import { TitleService } from '@kservice';
import { CardScreen } from '@ktypes/enums';
import { JsonObject, Status } from '@ktypes/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-single-question',
  templateUrl: './single-question.component.html',
  styleUrls: ['./single-question.component.scss'],
})
export class SingleQuestionComponent implements OnInit, OnDestroy {
  @Input() isEmbedded = false;
  @Input() key: string | QuestionLogicKey;
  @Input() keyType: KumanuKeyType;
  @Input() returnUrl: string;
  @Input() screen: CardScreen;

  answer: Answer;
  currentQuestion: Question;
  isEmbeddedNow: boolean;
  isQuestionAnswered = false;
  isSubmitted = false;
  questionType = QuestionType;
  showDoneAnimation = false;

  private _analyticsJson: JsonObject = {};
  private _destroy$ = new Subject<void>();
  private _title = 'Question';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _analyticsBloc: AnalyticsBloc,
    private _questionBloc: QuestionBloc,
    private _questionSetBloc: QuestionSetBloc,
    private _router: Router,
    private _titleService: TitleService
  ) {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    // need to reset reflectionCompleteSubject on init
    this._questionSetBloc.resetReflectionCompleteSubject();

    if (this.screen !== CardScreen.today && this.screen !== CardScreen.discover) {
      this._titleService.set(this._title);
    }

    this.isEmbeddedNow = this.isEmbedded;

    if (this._activatedRoute.queryParamMap != null) {
      this._activatedRoute.queryParamMap.pipe(takeUntil(this._destroy$)).subscribe((queryParamsMap) => {
        if (!this.key) {
          this.key = queryParamsMap.get('key');
        }
        if (!this.keyType) {
          this.keyType = queryParamsMap.get('keyType') as KumanuKeyType;
        }
        if (!this.returnUrl) {
          this.returnUrl = queryParamsMap.get('returnUrl') ?? '/';
        }

        this._questionBloc.getQuestion(this.keyType, this.key);
      });
    }

    this._questionBloc.currentQuestion$.pipe(takeUntil(this._destroy$)).subscribe((question) => {
      if (
        question.status === Status.done &&
        ((this.keyType === KumanuKeyType.logicKey && question.data?.logicKey === this.key) ||
          (this.keyType === KumanuKeyType.refKey && question.data?.refKey === this.key))
      ) {
        this.currentQuestion = question.data;
      }
    });

    this._questionSetBloc.reflectionCompleteSubject$.pipe(takeUntil(this._destroy$)).subscribe((isAnswerSaved) => {
      if (isAnswerSaved) {
        this.showDoneAnimation = true;
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  completeQuestionSet(isAnimating: boolean) {
    if (!isAnimating) {
      return this._router.navigateByUrl(decodeURIComponent(this.returnUrl));
    }
    return null;
  }

  async updateAnswer(answer: Answer) {
    this.answer = answer;
  }

  async onSubmit() {
    this.isSubmitted = true;
    await this._submitAnswer();
  }

  private async _submitAnswer() {
    this.currentQuestion.answer = this.answer;
    this._sendQuestionEndEvent();
    // Needs await due to completion event sometimes occurring after routing
    await this._questionSetBloc.updateQuestion(this.currentQuestion);
    this.isSubmitted = false;
  }

  private _sendQuestionEndEvent() {
    this._analyticsJson['questionId'] = this.currentQuestion.id;
    this._analyticsJson[this.keyType] = this.key;
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.question_end, meta: this._analyticsJson })
    );
  }
}
