import { Props } from 'tippy.js';

export class TooltipVariations {
  static insightful: Partial<Props> = {
    theme: 'insightful',
    arrow: true,
    trigger: 'mouseenter focus click',
    offset: [0, 5],
    hideOnClick: true,
    onTrigger: (instance, event) => {
      instance.popper.setAttribute('aria-live', 'polite');
    },
  };

  static insightfulInfo: Partial<Props> = {
    theme: 'insightful',
    arrow: true,
    trigger: 'mouseenter focus click',
    offset: [0, 5],
    hideOnClick: true,
    onTrigger: (instance, event) => {
      instance.popper.setAttribute('aria-live', 'polite');
      if (event.type === 'click') {
        instance.reference.classList.add('tippy-info-clicked');
      } else {
        instance.reference.classList.add('tippy-info-triggered');
      }
    },
    onUntrigger: (instance, event) => {
      instance.reference.classList.remove('tippy-info-clicked', 'tippy-info-triggered');
    },
  };

  static primary: Partial<Props> = {
    theme: 'primary',
    arrow: true,
    trigger: 'mouseenter focus click',
    offset: [0, 5],
    hideOnClick: true,
    onTrigger: (instance, event) => {
      instance.popper.setAttribute('aria-live', 'polite');
    },
  };

  static primaryInfo: Partial<Props> = {
    theme: 'primary',
    arrow: true,
    trigger: 'mouseenter focus click',
    offset: [0, 5],
    hideOnClick: true,
    onTrigger: (instance, event) => {
      instance.popper.setAttribute('aria-live', 'polite');
      if (event.type === 'click') {
        instance.reference.classList.add('tippy-info-clicked');
      } else {
        instance.reference.classList.add('tippy-info-triggered');
      }
    },
    onUntrigger: (instance, event) => {
      instance.reference.classList.remove('tippy-info-clicked', 'tippy-info-triggered');
    },
  };

  static challengeMenu: Partial<Props> = {
    theme: 'challenge-menu',
    arrow: false,
    interactive: true,
    appendTo: 'parent',
    trigger: 'focus click',
    placement: 'bottom-end',
    offset: [-16, 6],
    animation: 'scale-subtle',
    duration: 400,
    hideOnClick: true,

    onTrigger: (instance, event) => {
      instance.popper.setAttribute('aria-live', 'polite');
    },
  };
}
