import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { DataStatus, LegalDocument, LegalDocumentItem, LegalDocumentType, Status, StatusMessage } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom } from 'rxjs';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class LegalDocumentApi extends BaseApi {
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  public async getCurrentLegalDocumentStatus(): Promise<DataStatus<LegalDocumentItem[]>> {
    const url = this.buildUrl(`/documents/signed`, true);
    const request$ = this.performRequest<LegalDocumentItem[]>(RequestType.GET, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<LegalDocumentItem[]>>(request$).catch((error): null => {
      console.warn('Error fetching document: ', error);
      return null;
    });

    if (!response?.ok) {
      return generateError<LegalDocumentItem[]>(response);
    }

    return new DataStatus(
      Status.done,
      new StatusMessage(response.status, ''),
      (response.body || []) as LegalDocumentItem[]
    );
  }

  public async getLegalDocument(groupId: string, type: LegalDocumentType): Promise<DataStatus<LegalDocument>> {
    const url = this.buildUrl(`/group/${groupId}/documents/${type}`);
    const request$ = this.performRequest<LegalDocument>(RequestType.GET, url);

    const response = await firstValueFrom<HttpResponse<LegalDocument>>(request$).catch((error): null => {
      console.warn('Error fetching document: ', error);
      return null;
    });

    if (!response?.ok) {
      return generateError<LegalDocument>(response);
    }

    return new DataStatus(
      Status.done,
      new StatusMessage(response.status, ''),
      new LegalDocument().deserialize(response.body)
    );
  }

  /**
   * Accepts a legal document on the server-side.
   *
   * @param documentId the id of the exact document the user sees
   */
  public async acceptLegalDocument(documentId: string): Promise<boolean> {
    const url = this.buildUrl(`/documents/${documentId}`, true);

    if (!this._token || !this._userId) {
      console.warn('Missing token or userId - unable to accept legal document.');
      return false;
    }

    const requestBody = {
      dateDeviceCreated: DateTimeUtil.formatInLocal(),
    };
    const request$ = this.performRequest<boolean>(RequestType.POST, url, { includeToken: true, requestBody });
    const response = await firstValueFrom<HttpResponse<boolean>>(request$).catch((error): null => {
      console.warn('Error accepting document: ', error);
      return null;
    });

    return response?.ok;
  }
}

function generateError<T>(response?: HttpResponse<unknown>): DataStatus<T> {
  const defaultMessage = StatusMessage.generic();

  return new DataStatus<T>(
    Status.error,
    new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, response?.statusText || defaultMessage.message),
    null
  );
}
