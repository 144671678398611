<div class="action-container">
  <h3 class="action-topic" kfLocTranslate format="text">
    @switch (cardType) {
      @case (cardModalType.action) {
        Take Action
      }
      @case (cardModalType.quest) {
        Continue Quests
      }
    }
    on Today
  </h3>
  <p class="action-content" kfLocTranslate format="text">
    You've committed to
    @switch (cardType) {
      @case (cardModalType.action) {
        trying your first Action! See how it goes, then come back to Today to check it off as complete.
      }
      @case (cardModalType.quest) {
        starting your first Quest! Find it on Today under "Take Action" to make progress.
      }
    }
  </p>
  <a class="today-link" routerLink="/today" (click)="closeClicked.emit()">{{ 'Go to Today' | translate | async }}</a>
  <button class="button button-reverse button-pill action-button" (click)="closeClicked.emit()">
    {{ 'Got It' | translate | async }}
  </button>
</div>
