<div class="purposeful-days-modal-container">
  <h2 class="title">{{ 'Purposeful Day' | translate | async }}</h2>
  <div class="subtitle">{{ 'complete' | translate | async }}</div>
  <div class="items">
    <div class="line-item show-up">
      <div class="diamond-icon show-up">
        <div class="diamond-icon-inner show-up"></div>
      </div>
      <h3 class="show-up">{{ 'Show up' | translate | async }}</h3>
    </div>
    <div class="line-item check-in">
      <div class="diamond-icon check-in">
        <div class="diamond-icon-inner check-in"></div>
      </div>
      <h3 class="check-in">{{ 'Check in' | translate | async }}</h3>
    </div>
    <div class="line-item take-action">
      <div class="diamond-icon take-action">
        <div class="diamond-icon-inner take-action"></div>
      </div>
      <h3 class="take-action">{{ 'Take action' | translate | async }}</h3>
    </div>
  </div>
  <div class="streak">
    <svg-icon src="/assets/icons/streak-flame.svg"></svg-icon>
    <h3 class="streak-text" (animationstart)="onAnimationStart()">{{ streakText | translate | async }}</h3>
  </div>
</div>
