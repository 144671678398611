import { JsonObject, Serializable } from '@ktypes/models';

export enum DialogueBlockEventType {
  completed = 'user_dialogue_block_completed',
  undone = 'user_dialogue_block_undone',
}

export class DialogueBlockEvent implements Serializable<JsonObject> {
  constructor(
    public eventKey: DialogueBlockEventType,
    public deviceCreatedTimestamp: string,
    public eventJson?: JsonObject
  ) {}

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['eventKey'] = this.eventKey;
    json['deviceCreatedTimestamp'] = this.deviceCreatedTimestamp;
    json['eventJson'] = this.eventJson;
    return json;
  }
}
