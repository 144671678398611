import { EligibilityCandidate } from '@kp/eligibility/eligibility-candidate.model';
import { Deserializable, JsonObject, Serializable, Theme } from '@ktypes/models';

export class SharingValidation implements Serializable<JsonObject>, Deserializable<JsonObject, SharingValidation> {
  constructor(
    public id?: string,
    public token?: string,
    public tokenType?: string,
    public groupId?: string,
    public groupCode?: string,
    public hasEligibilityFile?: boolean,
    public needsVerification?: boolean,
    public eligibilityCandidate?: EligibilityCandidate,
    public login?: string,
    public organizationName?: string,
    public theme?: Theme
  ) {}

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['token'] = this.token;
    json['tokenType'] = this.tokenType;
    json['groupId'] = this.groupId;
    json['groupCode'] = this.groupCode;
    json['hasEligibilityFile'] = this.hasEligibilityFile;
    json['needsVerification'] = this.needsVerification;
    json['candidate'] = this.eligibilityCandidate;
    json['login'] = this.login;
    json['organizationName'] = this.organizationName;
    json['theme'] = this.theme;

    return json;
  }

  deserialize(input: JsonObject): SharingValidation {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.token = input['token'] as string;
    this.tokenType = input['tokenType'] as string;
    this.groupId = input['groupId'] as string;
    this.groupCode = input['groupCode'] as string;
    this.hasEligibilityFile = !!input['hasEligibilityFile'];
    this.needsVerification = !!input['needsVerification'];
    this.eligibilityCandidate = new EligibilityCandidate().deserialize(input['candidate']);
    this.login = input['login'] as string;
    this.organizationName = input['organizationName'] as string;
    this.theme = new Theme().deserialize(input['theme']);

    return this;
  }
}
