@if (accountCreationModelVisible) {
  <kui-modal
    [isOpen]="accountCreationModelVisible"
    (modalCloseMethod)="isAccountCreationModalOpen($event)"
    [canDismiss]="!requireAccountCreation"
    type="info">
    <kp-account-creation-modal
      (closeClicked)="isAccountCreationModalOpen($event)"
      [canDismiss]="!requireAccountCreation"></kp-account-creation-modal>
  </kui-modal>
}

<div class="banner-container">
  <div class="banner-content">
    <div class="modal-content">
      {{ 'Welcome to Resourceful!' | translate | async }}
      <button class="learn-more" (click)="learnMore()">{{ 'Learn More' | translate | async }}</button>
    </div>
    <a
      routerLink="/welcome/signup"
      class="button button-pill button-reverse account-creation"
      data-test="create-account-link"
      (click)="createAccount()">
      {{ 'Create Account' | translate | async }}
    </a>
  </div>
</div>
