import { Role } from '../enums/role.enum';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class UserRole implements Deserializable<JsonObject, UserRole> {
  public key: Role;
  public displayName: string;

  constructor(key?: string, displayName?: string) {
    this.key = key as Role;
    this.displayName = displayName;
  }

  deserialize(input: JsonObject): UserRole {
    if (input == null) {
      return null;
    }

    this.key = input['key'] as Role;
    this.displayName = input['displayName'] as string;

    return this;
  }
}
