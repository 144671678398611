import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AnswerValue } from '@kp/question-set/models/answer-value.model';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import { Question } from '@kp/question-set/models/question.model';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-diamond-likert',
  templateUrl: './diamond-likert.component.html',
  styleUrls: ['./diamond-likert.component.scss'],
})
export class DiamondLikertComponent implements OnChanges {
  @Input() question: Question;

  @Output() likertAnswer: EventEmitter<AnswerValue> = new EventEmitter<AnswerValue>();

  currentOption: string;
  displayOptions: DisplayOption[];
  labelMin: string;
  labelMax: string;

  ngOnChanges(): void {
    this.labelMin = this.question?.display?.valueMinLabel || this.question?.display?.options?.[0]?.text;
    this.labelMax =
      this.question?.display?.valueMaxLabel ||
      this.question?.display?.options?.[this.question?.display?.options?.length - 1]?.text;
    if (this.question?.hasAnswer) {
      this.displayOptions = this.question?.display?.optionsWithAnswers(this.question?.answer?.values);
      this.likertAnswer.emit(this.question?.answer?.values[0]);
    } else {
      this.question?.display?.options?.forEach((option) => {
        option.isSelected = false;
      });
      this.displayOptions = this.question?.display?.options;
      this.likertAnswer.emit(new AnswerValue());
    }
    // get selected answer text for currentOption, or reset if nothing selected
    this.optionChanged(this.displayOptions?.find((displayOption) => displayOption?.isSelected));
  }

  optionChanged(currentOption?: DisplayOption) {
    this.currentOption = currentOption?.text ?? '';
    if (!this.currentOption) {
      return;
    }
    this.likertAnswer.emit(
      new AnswerValue().deserialize({
        optionId: currentOption.id,
        value: true,
      })
    );
    this.question.display.options.forEach((option) => {
      option.isSelected = option.id === currentOption.id;
    });
    this.displayOptions.forEach((option) => {
      option.isSelected = option.id === currentOption.id;
    });
  }
}

export const MockDiamondLikertComponent = MockComponent({
  selector: 'kp-diamond-likert',
  inputs: ['question'],
});
