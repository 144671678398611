import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';
import { Theme } from './theme.model';
import { User } from './user.model';

export class AuthData implements Deserializable<JsonObject, AuthData>, Serializable<JsonObject> {
  id?: string;
  token?: string;
  refreshToken?: string;
  dateExpires?: string;
  failedUses?: number;
  user?: User;
  error?: string;

  get userId() {
    return this.user?.id;
  }

  deserialize(input: JsonObject): AuthData {
    if (input == null) {
      return null;
    }

    const userId = ((input.user as User)?.id as string) || (input.userId as string) || '';

    this.id = input['id'] as string;
    this.token = input['token'] as string;
    this.refreshToken = input['refreshToken'] as string;
    this.dateExpires = input['dateExpires'] as string;
    this.user = new User().deserialize({ ...{ id: userId }, ...(input.user || {}) });
    this.failedUses = input['failedUses'] as number;

    if ((input.user as User)?.group?.theme != null && this.user?.group != null) {
      // Copy the Group Theme to the User, once the User object returns the theme, this should be removed
      this.user.group.theme = { ...(input.user as User)?.group?.theme } as Theme;
    }

    return this;
  }

  serialize(forStorage = false): JsonObject {
    if (forStorage) {
      return pick(this, ['dateExpires', 'refreshToken', 'token', 'userId']);
    }
    return cloneDeep(this);
  }

  combine(updatedAuthData: AuthData): AuthData {
    const { userId, ...partialAuthData } = this;
    let user = updatedAuthData?.user;
    if (this.user) {
      user = this.user.combine(updatedAuthData?.user);
    }
    return new AuthData().deserialize({ ...partialAuthData, ...(updatedAuthData || {}), user });
  }
}
