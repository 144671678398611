<label class="slider-option" [attr.for]="question?.id || 'slider'">
  <h2>{{ currentOption | translate | async }}</h2>
  @if (question?.dataType === dataType.ordinal) {
    <div class="slider-diamond-row">
      @if (currentOption !== initialCallToAction) {
        @for (option of question?.display?.options | slice: 0 : optionIndex + 1; track option) {
          <div class="slider-diamond"></div>
        }
      }
    </div>
  }
</label>
<!-- NOTE: Need change event for IE and ARIA-->
<div class="slider-container">
  <input
    [attr.id]="question?.id || 'slider'"
    class="slider"
    type="range"
    #slider
    [min]="valueMin"
    [max]="valueMax"
    [step]="step"
    [value]="initialValue"
    (change)="getSliderValue(slider.value)"
    (click)="getSliderValue(slider.value)"
    [attr.aria-valuemin]="valueMin"
    [attr.aria-valuemax]="valueMax"
    [attr.aria-valuetext]="currentOption | translate | async" />
  <hr class="glow" />
  <div class="diamond-ticks">
    @for (option of options; track option) {
      <div class="ticks"></div>
    }
  </div>
</div>
<div class="slider-labels">
  <div class="slider-min">{{ labelMin | translate | async }}</div>
  <div class="slider-max">{{ labelMax | translate | async }}</div>
</div>
