import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LiveSupportInformation } from './live-support-information.model';

export class LiveSupport implements Deserializable<JsonObject, LiveSupport> {
  constructor(
    public purposeful?: LiveSupportInformation,
    public resourceful?: LiveSupportInformation
  ) {}

  deserialize(input: JsonObject): LiveSupport {
    if (input == null) {
      return null;
    }

    this.purposeful = new LiveSupportInformation().deserialize(input['purposeful']);
    this.resourceful = new LiveSupportInformation().deserialize(input['resourceful']);
    return this;
  }
}
