import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { ContentEventCreatedResponse } from '@ktypes/enums';
import { ContentEvent } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class ContentEventApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async createContentEvent(event: ContentEvent): Promise<ContentEventCreatedResponse> {
    const url = this.buildUrl(`/content/${event.contentId}/event`, true);
    const request$ = this.performRequest<ContentEventCreatedResponse>(RequestType.POST, url, {
      includeToken: true,
      requestBody: event.serialize(),
    }).pipe(
      catchError((error /*, caught*/) => {
        console.warn('Failed creating content event: ', error);
        return of(null);
      })
    );

    const response = await firstValueFrom<HttpResponse<ContentEventCreatedResponse>>(request$).catch((error): null => {
      console.warn('Error creating content event: ', error);
      return null;
    });

    if (response?.ok) {
      return { contentId: event.contentId, eventId: response.body?.eventId };
    }
    return { contentId: event.contentId };
  }
}
