import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CardDisplayType, CardEventType, CardRequestType, CardScreen } from '@ktypes/enums';
import { CardItem, LocalResourcesProgram, UserCardPreference } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-feedback-buttons',
  templateUrl: './feedback-buttons.component.html',
  styleUrls: ['./feedback-buttons.component.scss'],
})
export class FeedbackButtonsComponent {
  @Input() card: CardItem | LocalResourcesProgram;
  @Input() color: string;
  @Input() feedbackTitleInfo: string;
  @Input() screen: CardScreen = CardScreen.discover;
  @Input() preference = UserCardPreference.NEUTRAL;
  @Input() requestType: CardRequestType;

  @HostBinding('class.is-local-resource')
  @Input()
  isLocalResource = false;
  @Output() feedbackClicked = new EventEmitter<CardEventType>(true);
  @Output() cardUnsaved = new EventEmitter();

  cardScreen = CardScreen;
  cardEventType = CardEventType;
  cardDisplayType = CardDisplayType;

  displaySavedTooltip = false;

  moreOrLess(eventType: CardEventType) {
    this.feedbackClicked.emit(eventType);
  }

  resetTooltips(shouldDisplayTooltip: boolean) {
    this.displaySavedTooltip = shouldDisplayTooltip;
  }

  showSaveTooltip(shouldDisplayTooltip: boolean) {
    this.displaySavedTooltip = shouldDisplayTooltip;
  }
}

export const MockFeedbackButtonsComponent = MockComponent({
  selector: 'kp-feedback-buttons',
  inputs: ['card', 'color', 'feedbackTitleInfo', 'screen', 'preference', 'requestType'],
});
