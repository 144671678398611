import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '@kp/shared/constants.service';
import { LocalResourceLocation } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-local-resource-location',
  templateUrl: './local-resource-location.component.html',
  styleUrls: ['./local-resource-location.component.scss'],
})
export class LocalResourceLocationComponent implements OnInit {
  @Input() office: LocalResourceLocation;
  @Input() websiteUrl: string;

  googleMapsDirectionsLink: string;

  ngOnInit(): void {
    this.googleMapsDirectionsLink =
      Constants.googleMapsDirectionsLinkBase +
      'destination=' +
      encodeURIComponent(this.office.address1 + ' ' + this.office.city + ' ' + this.office.postal);
  }
}

export const MockLocalResourceLocationComponent = MockComponent({
  selector: 'kp-local-resource-location',
  inputs: ['office', 'websiteUrl'],
});
