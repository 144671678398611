import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AnalyticsBloc } from '@kanalytics';
import { EnvironmentVariablesService } from '@kenv';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';
import { GroupValidationBloc } from '@kp/auth/group-validation.bloc';
import { OnboardingBloc } from '@kp/onboarding/onboarding.bloc';
import { UserBloc } from '@kp/user/user.bloc';
import { BrowserStorage, DataStoreService, RouterHelperService } from '@kservice';
import { Product, UserType } from '@ktypes/enums';
import { OnboardingData } from '@ktypes/models';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotAuthenticatedGuard {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private authBloc: AuthenticationBloc,
    private browserStorage: BrowserStorage,
    private _dataStoreService: DataStoreService,
    private environmentVariablesService: EnvironmentVariablesService,
    private onboardingBloc: OnboardingBloc,
    private groupValidationBloc: GroupValidationBloc,
    public router: Router,
    private routerHelper: RouterHelperService,
    private _userBloc: UserBloc
  ) {}

  canActivate(route: ActivatedRouteSnapshot /*state: RouterStateSnapshot*/): boolean | Observable<boolean | UrlTree> {
    const onboardingData: OnboardingData = this.browserStorage.getObject('onboarding') as OnboardingData;
    const groupCode = (route?.queryParams?.groupCode as string) || (route?.params?.groupCode as string);
    const { dataToken, source } = route?.queryParams || {};

    // if there is a source or dataToken query param, navigation will be handled with other guards, exit
    if (source || dataToken) {
      return false;
    }

    return combineLatest([
      this._userBloc.fetchingUser$,
      this._dataStoreService.user$,
      this._dataStoreService.authData$,
    ]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      map(([_, user, authData]) => {
        // if group code and no onboarding data (or Resourceful), validate group code and go to landing
        if (
          groupCode &&
          (!onboardingData ||
            onboardingData.groupCode !== groupCode ||
            this.environmentVariablesService.product === Product.resourceful)
        ) {
          if (user?.type !== UserType.user) {
            this.groupValidationBloc.startValidatingGroupCode(groupCode);
          }
          void this.router.navigate(['/welcome'], { replaceUrl: true });
          return false;
        } else if (
          groupCode &&
          onboardingData &&
          onboardingData.groupCode === groupCode &&
          this.environmentVariablesService.product === Product.purposeful
        ) {
          // if group code and onboarding data, restore onboarding position
          this.onboardingBloc.restoreSavedOnboardingValues(onboardingData);
          void this.router.navigate(
            [`${onboardingData.latestPage?.startsWith?.('/') ? '' : '/'}${onboardingData.latestPage}`],
            { replaceUrl: true }
          );
          return false;
        } else if (authData?.token && user?.type === UserType.user) {
          this._analyticsBloc.userReadyForAnalytics();
          const isRouteValid = this.routerHelper.isRouteValid(
            route.url
              ?.filter((url) => url.path !== 'initial')
              .map((url) => url.path)
              .join('/')
          );
          return this.router.parseUrl(!isRouteValid ? '/error' : '');
        }

        // TODO: ensure this check tries to refresh the authData if expired
        // TODO: maintain path for proper redirect after login?
        // otherwise, just allow the non-authenticated user to continue on
        if (
          !authData ||
          (!authData.token && (!user || !(user.type === UserType.user))) ||
          this.authBloc.isTokenExpiredLocally()
        ) {
          return true;
        }
        // fallback, just resend to root path and following routing rules from there
        return this.router.parseUrl('');
      })
    );
  }
}
