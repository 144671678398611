import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType, TokenType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { PulseSurveyEvent } from '@kp/pulse-survey/models/pulse-survey-event.model';
import { PulseSurvey } from '@kp/pulse-survey/models/pulse-survey.model';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { JsonObject } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PulseSurveyApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async createPulseSurvey(tokenType: TokenType = TokenType.standard, pulseSurveyId = ''): Promise<PulseSurvey | any> {
    const body: JsonObject = {};
    const url = this.buildUrl(`/pulse-survey${pulseSurveyId ? `/${pulseSurveyId}` : ''}`, true);
    body['deviceCreatedTimestamp'] = DateTimeUtil.formatInLocal();
    const request$ = this.performRequest<PulseSurvey>(RequestType.POST, url, {
      requestBody: body,
      includeToken: true,
      tokenType,
    }).pipe(
      map((response: HttpResponse<PulseSurvey>): PulseSurvey => {
        if (response.status === HttpStatusCode.OK) {
          return new PulseSurvey().deserialize(response.body);
        }
        return null;
      }),
      catchError((err) => {
        console.warn('Failed creating pulse survey: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error) => {
      console.warn('Error creating pulse survey: ', error);
      return null;
    });
  }

  async postPulseSurveyEvent(pulseSurveyId: string, pulseSurveyEvent: PulseSurveyEvent): Promise<void> {
    const request$ = this.performRequest(
      RequestType.POST,
      this.buildUrl(`/pulse-survey/${pulseSurveyId}/event`, true),
      {
        requestBody: pulseSurveyEvent.serialize(),
        includeToken: true,
      }
    ).pipe(
      map((response: HttpResponse<any>): any => {
        if (response.status === HttpStatusCode.OK) {
          return null;
        }
      }),
      catchError((err) => {
        console.warn('Failed posting pulse survey event: ', err);
        return of(null);
      })
    );
    firstValueFrom(request$).catch((error) => {
      console.warn('Error posting pulse survey event: ', error);
      return null;
    });
  }
}
