/* Barrel-file for HTTP Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthRefreshInterceptor } from '@kp/auth/auth-refresh.interceptor';
import { LogInterceptor } from '@kp/shared/log.interceptor';

/**
 *  HTTP interceptor providers in outside-in order
 *  Interceptors will run from top to bottom for the request
 *  And then from bottom to top with the response
 */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, multi: true, useClass: LogInterceptor },
  { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthRefreshInterceptor },
];
