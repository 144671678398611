import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { RequestType } from '@ktypes/enums';
import { Tag } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class TagApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async getUserTags(): Promise<Tag[]> {
    const request$ = this.performRequest<Tag[]>(RequestType.GET, this.buildUrl('/tags', true), {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Tag[]>): Tag[] =>
        response?.body?.map?.((userTags) => new Tag().deserialize(userTags))
      ),
      catchError((err) => {
        console.warn('Failed getting user tags: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting user tags: ', error);
      return null;
    });
  }
}
