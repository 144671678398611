<div class="reflection-content">
  @if (!questionSet) {
    <div>
      <p>{{ 'No Question Data' | translate | async }}</p>
    </div>
  }
  @if (questionSet) {
    <kui-accordion>
      @for (question of questions; track question) {
        <div>
          @if (
            question.answer &&
            question.answer.values &&
            question.inputType !== QuestionType.image_icon_selection &&
            question.inputType !== QuestionType.image_word_list
          ) {
            <kui-accordion-group [id]="question.id">
              <h3 class="title" acc-open>{{ question.display?.title | translate | async }}</h3>
              <span acc-collapsible>{{ question | answerValue }}</span>
            </kui-accordion-group>
          }
          @if (question.answer && question.answer.values && question.inputType === QuestionType.image_icon_selection) {
            <kui-accordion-group [id]="question.id">
              <h3 class="title" acc-open>{{ question.display?.title | translate | async }}</h3>
              <kp-smiley-row acc-collapsible [selectedIndex]="question | imageAnswerIndex"></kp-smiley-row>
            </kui-accordion-group>
          }
          @if (question.answer && question.answer.values && question.inputType === QuestionType.image_word_list) {
            <kui-accordion-group [id]="question.id">
              <h3 class="title" acc-open>{{ question.display?.title | translate | async }}</h3>
              <kp-image-icon-list acc-collapsible [imageAndIcons]="question | imageWordListAnswer"></kp-image-icon-list>
            </kui-accordion-group>
          }
        </div>
      }
    </kui-accordion>
  }
</div>
