<div
  class="image-option-label"
  [ngClass]="{
    'selected': isSelected,
    'disabled': isDisabled && !isSelected,
    'appear-disabled': appearDisabled && !isSelected
  }">
  <button
    class="image-button"
    name="{{ option?.text }}"
    [attr.aria-label]="option?.text | translate | async"
    [attr.aria-pressed]="isSelected"
    [disabled]="isDisabled && !isSelected"
    (click)="onImageSelect(option)">
    <img class="image-option" [src]="imageIconSrc" [alt]="option?.text | translate | async" />
    @if (questionType === questionTypes.image_tile && isSelected) {
      <svg-icon class="icon-selected" aria-hidden="true" src="/assets/icons/checkmark-filled-circle.svg"></svg-icon>
    }
  </button>
  <!-- explicitly removing this label from aria as the words are read via aria-label on the button -->
  @if (showTextUnderImage) {
    <span class="image-label" aria-hidden="true">{{ option?.text | translate | async }}</span>
  }
</div>
