<nav class="core-nav-container">
  @for (navItem of navBloc.coreNavSubject$ | async; track navItem) {
    <div class="nav-item">
      <kp-core-nav-image
        [navItem]="navItem"
        [quickActionsOpen]="quickActionsOpen"
        (navItemClicked)="quickActionsToggled.emit(false)"
        (quickActionsToggled)="quickActionsToggled.emit($event)"></kp-core-nav-image>
    </div>
  }
</nav>
