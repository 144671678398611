import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { Intention } from '@kp/intentions/intention.model';
import { DataStoreService } from '@kservice';
import { RequestType } from '@ktypes/enums';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IntentionApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async getIntention(date?: Date): Promise<Intention> {
    const urlOptions = date ? { queryParams: { date: DateTimeUtil.formatDate(date) } } : undefined;
    const url = this.buildUrl('/intention', true, urlOptions);
    const request$ = this.performRequest<Intention>(RequestType.GET, url, {
      includeToken: true,
    }).pipe(map((response: HttpResponse<Intention>): Intention => new Intention().deserialize(response.body)));
    return await firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting intention: ', error);
      return null;
    });
  }
}
