import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DataStoreService } from '@kservice';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard {
  constructor(
    private _dataStoreService: DataStoreService,
    private _router: Router
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const hasToken =
      this._dataStoreService.authData?.token != null || activatedRouteSnapshot.queryParams?.token != null;
    const loginRoute = this._router.parseUrl('/login');
    loginRoute.queryParams = {
      redirectUrl: encodeURIComponent(state.url),
    };
    return hasToken || loginRoute;
  }
}
