import { Injectable } from '@angular/core';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { UserEventApi } from '@kapi';
import { GroupValidationBloc } from '@kp/auth/group-validation.bloc';
import { ValidationApi } from '@kp/core/api/validation.api';
import { EligibilityCandidate } from '@kp/eligibility/eligibility-candidate.model';
import { EligibilityVerificationApi } from '@kp/eligibility/eligibility-verification.api';
import { EligibilityVerification } from '@kp/eligibility/eligibility-verification.model';
import { LegalDocumentsBloc } from '@kp/legal-document/legal-documents.bloc';
import { SharingValidation } from '@kp/sharing/sharing-validation';
import { UserBloc } from '@kp/user/user.bloc';
import { BrowserStorage, DataStoreService, OnboardingUtilities } from '@kservice';
import { UserEvent } from '@ktypes/enums';
import { AuthData, DataStatus, Group, OnboardingData, Status, StatusMessage } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingBloc {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _browserStorage: BrowserStorage,
    private _dataStoreService: DataStoreService,
    private _eligibilityVerificationApi: EligibilityVerificationApi,
    private _groupValidationBloc: GroupValidationBloc,
    private _userEventApi: UserEventApi,
    private _legalDocumentsBloc: LegalDocumentsBloc,
    private _onboardingUtil: OnboardingUtilities,
    private _userBloc: UserBloc,
    private _validationApi: ValidationApi
  ) {}

  get isOnboarding(): boolean {
    return this._browserStorage.getObject('onboarding') != null;
  }

  // Eligibility Candidate
  private _eligibilityCandidateStatus = new BehaviorSubject<DataStatus<EligibilityCandidate>>(null);

  get eligibilityCandidateStatus$(): Observable<DataStatus<EligibilityCandidate>> {
    return this._eligibilityCandidateStatus;
  }

  // Eligibility Token Validation
  private _eligibilityTokenValidationStatus = new BehaviorSubject<DataStatus<boolean>>(null);

  get eligibilityTokenValidationStatus$(): Observable<DataStatus<boolean>> {
    return this._eligibilityTokenValidationStatus.asObservable();
  }

  // Sharing Validation

  private _sharingValidationStatus = new BehaviorSubject(null);

  get sharingValidationStatus$(): Observable<DataStatus<SharingValidation>> {
    return this._sharingValidationStatus.asObservable();
  }

  get guestUserDataStatus$(): Observable<DataStatus<AuthData>> {
    return this._dataStoreService.authStatus$;
  }

  get guestUserDataStatus(): DataStatus<AuthData> {
    return this._dataStoreService.authStatus;
  }

  // Instance methods - Eligibility Token Validation
  validateEligibilityToken(token: string, source: string, candidateId: string): void {
    this._eligibilityTokenValidationStatus.next(
      new DataStatus(Status.starting, new StatusMessage(Status.starting, ''), null)
    );

    this._validationApi.validateEligibilityToken(token, source).then((tokenResponse) => {
      if (tokenResponse?.data?.tokenId) {
        this.sendOnboardingUserEvent(UserEvent.onboarding_link_used_web);
        this._eligibilityTokenValidationStatus.next(
          new DataStatus(Status.done, new StatusMessage(Status.done, ''), true)
        );
        this._eligibilityCandidateStatus.next(
          new DataStatus(Status.done, new StatusMessage(Status.done, ''), new EligibilityCandidate(candidateId))
        );
        this.setEligibilityCandidateData(new EligibilityCandidate(candidateId));
      } else {
        this._eligibilityTokenValidationStatus.next(
          new DataStatus(Status.done, new StatusMessage(Status.done, ''), false)
        );
      }
    });
  }

  // Instance methods - Eligibility Verification
  verifyEligibility(verificationObject: EligibilityVerification) {
    this._eligibilityCandidateStatus.next(
      new DataStatus(Status.starting, new StatusMessage(Status.starting, ''), null)
    );

    const sharingValidation = this.getSharingValidationData();
    if (sharingValidation) {
      verificationObject.sharingToken = sharingValidation.token;
    }

    this._eligibilityVerificationApi.verifyEligibility(verificationObject).then((eligibilityCandidate) => {
      if (eligibilityCandidate) {
        this.setEligibilityCandidateData(eligibilityCandidate);
      } else {
        this._analyticsBloc.sendEvent(
          this._analyticsBloc.createEventFromEvent({
            event: AnalyticEvent.eligibility_method_failed,
            meta: { type: verificationObject.verificationType },
          })
        );
      }
      this._eligibilityCandidateStatus.next(
        new DataStatus(Status.done, new StatusMessage(Status.done, ''), eligibilityCandidate)
      );
    });
  }

  updateEligibilityCandidateFromStorage() {
    const eligibilityCandidate = this.getEligibilityCandidateData();
    if (eligibilityCandidate && !this._eligibilityCandidateStatus.getValue()) {
      this._eligibilityCandidateStatus.next(
        new DataStatus(Status.done, new StatusMessage(Status.done, ''), eligibilityCandidate)
      );
    }
  }

  clearEligibilityStream() {
    this._eligibilityCandidateStatus.next(null);
  }

  // Instance methods - Sharing Validation
  validateSharingToken(token: string, source: string): void {
    this._sharingValidationStatus.next(new DataStatus(Status.starting, new StatusMessage(Status.starting, ''), null));
    this._validationApi.validateSharingToken(token, source).then((sharingValidation) => {
      if (sharingValidation?.data?.token) {
        this.setSharingValidationData(sharingValidation.data);
        this._sharingValidationStatus.next(sharingValidation);
      } else {
        this._sharingValidationStatus.next(
          new DataStatus<SharingValidation>(Status.error, new StatusMessage(400, ''), null)
        );
      }
    });
  }

  // Instance methods - Onboarding User Events
  sendOnboardingUserEvent(eventKey: UserEvent): void {
    this._userEventApi.postUserEvent(eventKey);
  }

  // Signup
  get registerUserDataStatus$(): Observable<DataStatus<AuthData>> {
    return this._dataStoreService.authStatus$;
  }

  // Instance methods - Guest User
  createGuestUser() {
    this._userBloc.createGuestUser(
      new Group().deserialize(this._groupValidationBloc.groupValidationDataStatus?.data),
      this.getEligibilityCandidateData()
    );
  }

  restoreSavedOnboardingValues(savedOnboardingData?: OnboardingData, validateGroupCode = true) {
    if (!savedOnboardingData) {
      savedOnboardingData = (this._browserStorage.getObject('onboarding') as OnboardingData) || {};
    }
    if (validateGroupCode) {
      this._groupValidationBloc.startValidatingGroupCode(savedOnboardingData.groupCode);
    }
    this._legalDocumentsBloc.fetchLegalDocuments();
  }

  setLatestPage(latestPage: string) {
    this._onboardingUtil.saveOnboardingData({ latestPage });
  }

  setEligibilityCandidateData(eligibilityData?: EligibilityCandidate) {
    if (eligibilityData) {
      const eligibilityCandidate =
        (this._browserStorage.getObject('eligibilityCandidate') as EligibilityCandidate) ?? {};
      this._browserStorage.setObject('eligibilityCandidate', { ...eligibilityCandidate, ...eligibilityData });
    } else {
      this._browserStorage.remove('eligibilityCandidate');
    }
  }

  getEligibilityCandidateData(): EligibilityCandidate {
    const eligibilityData = this._browserStorage.getObject('eligibilityCandidate') as EligibilityCandidate;
    return eligibilityData && new EligibilityCandidate().deserialize(eligibilityData);
  }

  getSharingValidationData() {
    const sharingData = this._browserStorage.getObject('sharingValidation') as SharingValidation;
    return sharingData && new SharingValidation().deserialize(sharingData);
  }

  setSharingValidationData(sharingValidationData: SharingValidation) {
    const sharingValidation = this._browserStorage.getObject('sharingValidation') as SharingValidation;
    this._browserStorage.setObject('sharingValidation', Object.assign({}, sharingValidation, sharingValidationData));
  }
}
