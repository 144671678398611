import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownConfiguration, Option } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() config: DropdownConfiguration = {
    color: 'white',
    leadIcon: null,
    dropIcon: true,
    borderColor: 'primary',
  };
  @Input() options: Option[];
  @Input() semanticLabel: string;

  @Output() optionChanged = new EventEmitter<Option>();

  optionsChanged(selectedOption: EventTarget) {
    const value = (selectedOption as HTMLOptionElement)?.value;
    this.options = this.options.map((option) => {
      option.selected = option.value === value;
      return option;
    });
    this.optionChanged.emit(this.options.find((option) => option.value === value));
  }
}

export const MockDropdownComponent = MockComponent({
  selector: 'kui-dropdown',
  inputs: ['config', 'options', 'semanticLabel'],
});
