import { Injectable } from '@angular/core';
import { IntentionApi } from '@kp/intentions/intention.api';
import { Intention } from '@kp/intentions/intention.model';
import { DataStatus, Status, StatusMessage } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IntentionBloc {
  constructor(private _intentionApi: IntentionApi) {}

  private _intentionSubject = new BehaviorSubject<DataStatus<Intention>>(null);

  get intentionStatus$(): Observable<DataStatus<Intention>> {
    return this._intentionSubject.asObservable();
  }

  get intention$(): Observable<Intention> {
    return this._intentionSubject.pipe(
      map((intentionStatus) => (intentionStatus?.status === Status.done ? intentionStatus.data : null))
    );
  }

  getIntention(date?: Date): void {
    this._intentionSubject.next(
      new DataStatus<Intention>(Status.starting, new StatusMessage(Status.starting, ''), null)
    );
    this._intentionApi.getIntention(date).then((intention) => {
      this._intentionSubject.next(
        new DataStatus<Intention>(
          intention ? Status.done : Status.error,
          intention ? new StatusMessage(Status.done, '') : new StatusMessage(Status.error, ''),
          intention
        )
      );
    });
  }

  getIntentionByDate(date = new Date()): void {
    this.getIntention(date);
  }
}
