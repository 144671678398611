import { Component, HostBinding, Input } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrls: ['./shimmer.component.scss'],
})
export class ShimmerComponent {
  @Input() class = '';
  @Input() template = 'block';

  @HostBinding('class') get hostClass() {
    return [this.class, 'shimmer', this.template].join(' ');
  }
}

export const MockShimmerComponent = MockComponent({
  selector: 'kp-shimmer',
  inputs: ['class', 'template'],
});
