import { Feature, Product, Role } from '../enums';
import { JsonObject } from './json-object.model';
import { TagKey } from './tag.model';

enum NavTypes {
  core = 'core',
  side = 'side',
}

export enum NavItemType {
  // TODO: Once all users have access to social challenges, remove challenges navItemType
  challenges = 'challenges',
  child = 'child',
  elevatedAccess = 'elevatedAccess',
  invites = 'invites',
  quickActions = 'quickActions',
  standard = 'standard',
  tertiary = 'tertiary',
}

export enum MapCategory {
  main = 'main',
  quickActions = 'quickActions',
  feature = 'feature',
  support = 'support',
}

export interface NavItem {
  type: NavItemType;
  link?: string;
  queryParams?: JsonObject<string>;
  icon?: string;
  imageType?: string;
  title?: string;
  mapTitle?: string;
  mapCategory?: MapCategory | { [product in Product]?: MapCategory };
  displayOrder?: { [product in Product]?: { [navType in NavTypes]?: number } };
  external?: boolean;
  feature?: Feature;
  roles?: Role[];
  tags?: TagKey[];
  hideFromNav?: boolean;
  hideFromMap?: boolean;
  children?: NavItem[];
}
