import { JsonObject } from '../models';

export enum ContentEventType {
  challenge_join = 'challenge_join',
  challenge_leave = 'challenge_leave',
  promo_completed = 'promo_completed',
  promo_dismissed = 'promo_dismissed',
  promo_featured_cta = 'promo_featured_cta',
  promo_not_now = 'promo_not_now',
}

export interface ContentEventCreatedResponse {
  contentId: string;
  eventId?: string;
  eventInfo?: JsonObject;
}
