@if (isDiamondCard && index % 2 !== 0 && !isMilestone) {
  <kp-dashes class="quest-dashes" [isTrendingDown]="true" aria-hidden="true"></kp-dashes>
}
@if (isMilestone && milestoneIndex % 2 === 1) {
  <kp-dashes class="quest-dashes-milestone-odd" [isTrendingDown]="true" aria-hidden="true"></kp-dashes>
}
@if (!milestoneDiamondIndicator) {
  <button
    class="diamond"
    [ngClass]="{
      'selected': isSelected,
      'diamondCard': isDiamondCard,
      'milestoneCard': isMilestone,
      'appear-disabled': appearDisabled && !isSelected
    }"
    [attr.aria-pressed]="isSelected"
    [attr.aria-label]="text"
    [disabled]="isDisabled && !isSelected">
    <span class="diamond-text">{{ text }}</span>
    @if (isDiamondCard) {
      @if (!isMilestone && !isSelected) {
        <div [ngClass]="{ uncompletedCircle: !isMilestone && !isSelected }">
          {{ order }}
        </div>
      }
      @if (isSelected && !isMilestone) {
        <svg-icon class="completedCircle" src="/assets/icons/checkmark-filled-circle.svg"></svg-icon>
      }
      @if (isSelected && isMilestone) {
        <svg-icon class="completedMilestoneCircle" src="/assets/icons/checkmark-filled-circle.svg"></svg-icon>
      }
      @if (isMilestone && !isSelected) {
        <kp-diamond class="uncompleted-milestone-diamond" [milestoneDiamondIndicator]="true"></kp-diamond>
      }
    }
  </button>
}
@if (isMilestone && milestoneIndex % 2 === 0) {
  <kp-dashes class="quest-dashes-milestone-even" aria-hidden="true"></kp-dashes>
}
@if (isDiamondCard && index > 0 && index % 2 === 0 && !isMilestone) {
  <kp-dashes class="quest-dashes" aria-hidden="true"></kp-dashes>
}
