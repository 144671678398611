import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { CardDisplayType, CardRequestType, CardScreen, HttpStatusCode } from '@ktypes/enums';
import {
  CardCollection,
  CardEvent,
  CardItem,
  DataStatus,
  Habit,
  JsonObject,
  LocalResourcesProgram,
  Status,
} from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import pick from 'lodash/pick';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CardApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, _dataStoreService, _environmentVariablesService);
  }

  async getCard(currentUserTimestamp: string, contentId: string): Promise<CardItem> {
    const params = {
      currentUserTimestamp: currentUserTimestamp || DateTimeUtil.formatInLocal(),
    };
    const url = this.buildUrl(`/card/${contentId}`, true, params);
    const request = this.performRequest<CardItem>(RequestType.GET, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<CardItem>>(request).catch((error): null => {
      console.warn('Error getting card: ', error);
      return null;
    });
    if (response?.status === HttpStatusCode.OK) {
      const content: JsonObject = response.body;
      return new CardItem().deserialize(content);
    }
    return null;
  }

  async getCards(
    type: CardRequestType,
    currentUserTimestamp: string,
    refKey?: string,
    domain?: string
  ): Promise<CardCollection[]> {
    // HACK to allow the use of "takeAction" instead of "take_action" and "challengeAction" instead of "challenge_action"
    let actionType: string;
    if (type === CardRequestType.TAKE_ACTION) {
      actionType = 'takeAction';
    } else if (type === CardRequestType.DOMAIN_ACTION) {
      actionType = 'domainAction';
    } else if (type === CardRequestType.DOMAIN_AVAILABLE) {
      actionType = 'domainAvailable';
    }
    const params: JsonObject = {
      type: actionType || type?.toLowerCase(),
      currentUserTimestamp: currentUserTimestamp || DateTimeUtil.formatInLocal(),
      questionSetRefKey: refKey,
    };
    if (
      domain &&
      [CardRequestType.DOMAIN_AVAILABLE, CardRequestType.DOMAIN_ACTION, CardRequestType.RECOMMENDED].includes(type)
    ) {
      params['domain'] = domain;
    }

    const url = this.buildUrl('/card/sets', true, { queryParams: params });
    const request = this.performRequest<CardCollection[]>(RequestType.GET, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<CardCollection[]>>(request).catch((error): null => {
      console.warn('Error getting card sets: ', error);
      return null;
    });
    if (response?.status === HttpStatusCode.OK) {
      const content = response.body as CardCollection[];
      return content?.map((collection) => {
        return new CardCollection().deserialize(collection);
      });
    }
    return null;
  }

  async createCard(card: CardItem): Promise<JsonObject> {
    const url = this.buildUrl('/card', true);
    const cardJson = {
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      cardId: card.id,
      isRepeated: card.isRepeated,
    };
    const request = this.performRequest<JsonObject>(RequestType.POST, url, {
      requestBody: cardJson,
      includeToken: true,
    });
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
      console.warn('Error creating tracking card: ', error);
      return null;
    });

    if (response?.status === HttpStatusCode.CREATED) {
      return { message: 'success' };
    }
    return null;
  }

  async deleteCard(card: CardItem): Promise<JsonObject> {
    const url = this.buildUrl(`/card/${card.id}`, true);
    const request = this.performRequest<JsonObject>(RequestType.DELETE, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
      console.warn('Error deleting tracking card: ', error);
      return null;
    });
    if (response?.status === HttpStatusCode.NO_CONTENT) {
      return { message: 'success' };
    }
    return null;
  }

  async createCardEvent(card: CardItem | LocalResourcesProgram, event: CardEvent): Promise<JsonObject> {
    if (card != null) {
      const requestType = this._normalizeCardRequestType(card, event);
      const queryParams = requestType && { type: requestType?.toLowerCase() };
      const url = this.buildUrl(`/card/${card.id}/event`, true, { queryParams });
      const eventJson = event.serialize() as JsonObject<CardEvent>;
      const model = ['deviceCreatedTimestamp'].concat(
        eventJson.eventType ? ['eventType', 'eventInfo'] : ['isRepeated', 'cardId']
      );
      const requestBody = pick(eventJson, model);
      const request = this.performRequest<JsonObject>(RequestType.POST, url, {
        requestBody,
        includeToken: true,
      });
      const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
        console.warn('Error creating card event: ', error);
        return null;
      });

      if (response?.status === HttpStatusCode.CREATED) {
        return response.body;
      }
      return null;
    }
    return null;
  }

  async createCardEvents(events: CardEvent[]): Promise<JsonObject> {
    const requestBody = events.map((event) => {
      const eventJson = event.serialize() as JsonObject;
      const model = ['deviceCreatedTimestamp'].concat(
        eventJson.eventType ? ['eventType', 'eventInfo'] : ['isRepeated', 'cardId']
      );
      const payload = pick(eventJson, model);
      payload['cardId'] = event.card.id;
      payload['requestType'] = this._normalizeCardRequestType(event.card, event)?.toLowerCase();
      return payload;
    });
    const url = this.buildUrl('/card/events', true);
    const request = this.performRequest<JsonObject>(RequestType.POST, url, {
      requestBody,
      includeToken: true,
    });
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
      console.warn('Error creating card events: ', error);
      return null;
    });

    if (response?.status === HttpStatusCode.CREATED) {
      return response.body;
    }
    return null;
  }

  async deleteCardEvent(card: CardItem, event: CardEvent): Promise<JsonObject> {
    const requestType = this._normalizeCardRequestType(card, event);
    const queryParams = requestType && { type: requestType?.toLowerCase() };
    const url = this.buildUrl(`/card/${card.id}/event/${event.id}`, true, { queryParams });
    const request = this.performRequest<JsonObject>(RequestType.DELETE, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
      console.warn('Error deleting card event: ', error);
      return null;
    });
    if (response?.ok) {
      return { message: 'success' };
    }
    return null;
  }

  async updateCard(card: CardItem): Promise<JsonObject> {
    const url = this.buildUrl(`/card/${card.id}`, true);
    const cardJson: JsonObject = {};
    if (card.isRepeated != null) {
      cardJson['isRepeated'] = card.isRepeated;
    }
    const request = this.performRequest(RequestType.PUT, url, {
      includeToken: true,
      requestBody: cardJson as JsonObject,
    });
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
      console.warn('Error updating tracking card: ', error);
      return null;
    });
    if (response?.status === HttpStatusCode.OK) {
      return { message: 'success' };
    }
    return null;
  }

  async saveCard(
    cardId: string,
    screen: CardScreen,
    cardType: CardDisplayType,
    zip: string
  ): Promise<DataStatus<JsonObject>> {
    if (cardId != null) {
      const url = this.buildUrl(`/save-card`, true, {});
      const body = {
        cardId,
        deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
        isRepeated: false,
        postalCode: zip,
        product: this._environmentVariablesService.product,
        eventInfo: { screen, cardType },
      };
      const request = this.performRequest<DataStatus<JsonObject>>(RequestType.POST, url, {
        requestBody: body,
        includeToken: true,
      });
      const response = await firstValueFrom(request).catch((error) => {
        console.warn('Error creating card event: ', error);
        return new DataStatus(Status.error, null, error);
      });
      if (response?.status === HttpStatusCode.CREATED) {
        return new DataStatus(Status.done, null, response?.body);
      }
    }
    return new DataStatus(Status.error, null, null);
  }

  async deleteSavedCard(cardId: string): Promise<JsonObject> {
    if (cardId != null) {
      const url = this.buildUrl(`/save-card/${cardId}`, true);
      const request = this.performRequest<JsonObject>(RequestType.DELETE, url, { includeToken: true });
      const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
        console.warn('Error deleting tracking card: ', error);
        return null;
      });
      if (response?.status === HttpStatusCode.NO_CONTENT) {
        return { message: 'success' };
      }
      return null;
    }
    return null;
  }

  async getTipOfTheDayCard(date?: string): Promise<CardItem> {
    let queryParams: JsonObject;
    if (date != null) {
      queryParams = { date };
    }
    const url = this.buildUrl(`/card/tip`, true, { queryParams });
    const request = this.performRequest<JsonObject>(RequestType.GET, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request).catch((error): null => {
      console.warn(`Error getting tip of the day card${date != null ? ` for ${date}` : ''}: `, error);
      return null;
    });
    if (response?.status === HttpStatusCode.OK) {
      return new CardItem().deserialize(response.body);
    }
    return null;
  }

  private _normalizeCardRequestType(card: CardItem | LocalResourcesProgram, event: CardEvent) {
    if (
      !event?.requestType ||
      event?.requestType === CardRequestType.TOTD ||
      event?.requestType === CardRequestType.SOLO
    ) {
      // NOTE: This is a hack to ensure CardRequestType is TRACKING or AVAILABLE if missing or set to TOTD/SOLO
      return card?.userState?.isTracking ? CardRequestType.TRACKING : CardRequestType.AVAILABLE;
    }
    return event?.requestType;
  }

  async addHabit(cardId: string, habitText: string): Promise<Habit> {
    if (cardId == null) {
      console.warn('Error adding habit, no cardId passed');
      return null;
    }
    const body = {
      habit: habitText,
    };
    const url = this.buildUrl(`/card/${cardId}/habit`, true);
    const request$ = this.performRequest<Habit>(RequestType.POST, url, { includeToken: true, requestBody: body }).pipe(
      map((response: HttpResponse<Habit>): Habit => new Habit().deserialize(response?.body)),
      catchError((err) => {
        console.warn('Failed adding habit: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error adding habit: ', error);
      return null;
    });
  }

  async updateHabit(cardId: string, habitId: string, habitText: string): Promise<Habit> {
    if (cardId == null || habitId == null) {
      console.warn('Error updating habit, no cardId passed or no habitId passed');
      return null;
    }
    const body = {
      habit: habitText,
    };
    const url = this.buildUrl(`/card/${cardId}/habit/${habitId}`, true);
    const request$ = this.performRequest<Habit>(RequestType.PUT, url, {
      includeToken: true,
      requestBody: body,
    }).pipe(
      map((response: HttpResponse<Habit>): Habit => new Habit().deserialize(response?.body)),
      catchError((err) => {
        console.warn('Failed updating habit: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error updating habit: ', error);
      return null;
    });
  }

  async removeHabit(cardId: string, habitId: string): Promise<JsonObject> {
    if (cardId == null || habitId == null) {
      console.warn('Error removing habit, no cardId passed or no habitId passed');
      return null;
    }
    const url = this.buildUrl(`/card/${cardId}/habit/${habitId}`, true);
    const request$ = this.performRequest(RequestType.DELETE, url, { includeToken: true }).pipe(
      catchError((err) => {
        console.warn('Failed deleting habit: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error deleting habit: ', error);
      return null;
    });
  }
}
