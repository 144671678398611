import { Deserializable } from '@ktypes/models';

export interface TranslationLanguageResponse {
  english?: string;
  language: string;
  name: string;
}

export class TranslationLanguage implements Deserializable<TranslationLanguageResponse, TranslationLanguage> {
  constructor(
    public language?: string,
    public name?: string,
    public english?: string
  ) {}

  deserialize(input: TranslationLanguageResponse): TranslationLanguage {
    if (input == null) {
      return null;
    }

    this.english = input['english'];
    this.language = input['language'];
    this.name = input['name'];

    return this;
  }
}
