import { Injectable } from '@angular/core';
import { FirstVisitBloc } from '@kbloc';
import { FirstVisitCase } from '@ktypes/enums';
import { FirstVisit, JsonObject } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { BehaviorSubject, Observable, distinctUntilChanged, interval } from 'rxjs';
import { debounce, take } from 'rxjs/operators';

export enum ModalKey {
  enableNotifications = 'enableNotifications',
  customizeNotifications = 'customizeNotifications',
  featuredPromo = 'featuredPromo',
  feedback = 'feedback',
}
export interface ModalRequirementsTest {
  testCase: FirstVisitCase | string | boolean | number; // test case
  testValue: string | boolean | number; // value to test against
  operator?: Operators;
  isFirstVisitRequirement?: boolean; // use case to get value from FirstVisit
}
export interface ModalDetails {
  attemptOpen?: boolean;
  data?: JsonObject;
  modalKey: ModalKey;
  isOpen?: boolean;
  priority?: number;
  requirements?: ModalRequirementsTest[];
}

const OPEN_DELAY = 750;
const VISITS_BEFORE_CUSTOMIZE_NOTIFICATIONS_POPUP = 1;
const CHECK_IN_COUNT_BEFORE_FEEDBACK = 2;

// Map of the modals whose priority is controlled via this service; lower priority comes first
const modalsMap = new Map<ModalKey, ModalDetails>(getDefaultModalMapState());

@Injectable({
  providedIn: 'root',
})
export class UiModalCoordinatorService {
  constructor(private _firstVisitBloc: FirstVisitBloc) {}

  private _modalIsOpen = false;
  private _modalToOpen$ = new BehaviorSubject<ModalDetails>(null);

  get modalsMap() {
    // make available for testing
    return modalsMap;
  }

  get modalToOpen$(): Observable<ModalDetails> {
    return this._modalToOpen$.pipe(
      distinctUntilChanged(),
      debounce(() => interval(OPEN_DELAY))
    );
  }

  attemptDisplayModal(modalToOpen: ModalDetails) {
    if (modalToOpen.modalKey && !this._modalIsOpen) {
      if (!modalsMap.has(modalToOpen.modalKey)) {
        // assume if no priority it's the highest priority
        modalsMap.set(modalToOpen.modalKey, {
          modalKey: modalToOpen.modalKey,
          attemptOpen: true,
          priority: modalToOpen.priority || 0,
          data: modalToOpen.data ?? {},
        });
      } else {
        modalToOpen.attemptOpen = true;
        modalsMap.set(modalToOpen.modalKey, { ...modalsMap.get(modalToOpen.modalKey), ...modalToOpen });
      }
      const sortedModals = [...(modalsMap?.values() || [])]
        .filter((modal) => modal.attemptOpen)
        .sort((m1, m2) => m1.priority - m2.priority);
      // check requirements before adding to _modalToOpen
      this._firstVisitBloc.hasSeen$.pipe(take(1)).subscribe((hasSeen): void => {
        const filteredModals = sortedModals.filter((modalDetails) => {
          return (
            !modalDetails.requirements ||
            modalDetails.requirements.every((requirement) => {
              if (requirement.isFirstVisitRequirement) {
                return performOperation(
                  hasSeen?.[requirement.testCase as keyof FirstVisit],
                  requirement.testValue,
                  requirement.operator
                );
              }

              // TODO: this has not been tested as there have been no non-firstVisit requirements;
              //  one of testCase or testValue needs to be dynamic
              return performOperation(requirement.testCase, requirement.testValue, requirement.operator);
            })
          );
        });
        if (filteredModals.length > 0) {
          filteredModals[0].isOpen = true;
          this._modalToOpen$.next(filteredModals[0]);
        }
      });
    }
  }

  clearModal() {
    this._modalIsOpen = false;
    this._modalToOpen$.next(null);
    modalsMap.clear();
    getDefaultModalMapState().forEach(([modalKey, modalDetails]) => modalsMap.set(modalKey, modalDetails));
  }

  openingModal() {
    this._modalIsOpen = true;
  }
}

type Operators = 'gt' | 'gte' | 'lt' | 'lte' | 'not_equals' | 'equals';

function performOperation<ValueType = number>(valueA: ValueType, valueB: ValueType, operator?: Operators): boolean {
  switch (operator) {
    case 'gt':
      return valueA > valueB;
    case 'gte':
      return valueA >= valueB;
    case 'lt':
      return valueA < valueB;
    case 'lte':
      return valueA <= valueB;
    case 'not_equals':
      return valueA !== valueB;
    case 'equals':
    default:
      return valueA === valueB;
  }
}

function getDefaultModalMapState(): [ModalKey, ModalDetails][] {
  return [
    [
      ModalKey.enableNotifications,
      {
        attemptOpen: false,
        isOpen: false,
        modalKey: ModalKey.enableNotifications,
        priority: 10,
        requirements: [
          { testCase: FirstVisitCase.notificationPrompt as string, testValue: false, isFirstVisitRequirement: true },
        ],
      },
    ],
    [
      ModalKey.customizeNotifications,
      {
        attemptOpen: false,
        isOpen: false,
        modalKey: ModalKey.customizeNotifications,
        priority: 80,
        requirements: [
          {
            testCase: FirstVisitCase.shownNotificationsModal as string,
            testValue: false,
            isFirstVisitRequirement: true,
          },
          {
            testCase: FirstVisitCase.purposefulVisits as string,
            testValue: VISITS_BEFORE_CUSTOMIZE_NOTIFICATIONS_POPUP,
            isFirstVisitRequirement: true,
            operator: 'gte',
          },
        ],
      },
    ],
    [
      ModalKey.featuredPromo,
      {
        attemptOpen: false,
        isOpen: false,
        modalKey: ModalKey.featuredPromo,
        priority: 20,
        requirements: [
          {
            testCase: FirstVisitCase.shownFeaturedPromoOn as string,
            testValue: DateTimeUtil.formatDate(),
            operator: 'not_equals',
            isFirstVisitRequirement: true,
          },
        ],
      },
    ],
    [
      ModalKey.feedback,
      {
        attemptOpen: false,
        isOpen: false,
        modalKey: ModalKey.feedback,
        priority: 50,
        requirements: [
          {
            testCase: FirstVisitCase.shownPurposefulFeedbackModal as string,
            testValue: false,
            isFirstVisitRequirement: true,
          },
          {
            testCase: FirstVisitCase.checkInCount as string,
            testValue: CHECK_IN_COUNT_BEFORE_FEEDBACK,
            isFirstVisitRequirement: true,
            operator: 'gte',
          },
        ],
      },
    ],
  ];
}
