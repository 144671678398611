@if (product) {
  <kp-branding-icon class="hidden-screen" [product]="product"></kp-branding-icon>
}
<span>&copy;&nbsp;{{ includeProMedica ? 'ProMedica and ' : '' }}Kumanu, {{ year }}.</span>
<span>All rights reserved.</span>
@if (includeKumanuTrademark) {
  <span>{{ productDisplayName }} by Kumanu is a trademark of Kumanu Inc.</span>
}
@if (includePromedicaJointTrademark) {
  <span class="promedica-joint-trademark">
    Resourceful is a joint solution of ProMedica and Kumanu. Resourceful is a trademark of ProMedica.
  </span>
}
