import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService, OnboardingUtilities } from '@kservice';
import { HttpStatusCode, LoginError } from '@ktypes/enums';
import {
  AuthData,
  Credentials,
  DataStatus,
  JsonObject,
  MfaRequiredApiResponse,
  Status,
  StatusMessage,
} from '@ktypes/models';
import { BehaviorSubject, Observable, firstValueFrom, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApi extends BaseApi {
  constructor(
    http: HttpClient,
    dataStoreService: DataStoreService,
    private onboardingUtilities: OnboardingUtilities,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(http, dataStoreService, _environmentVariablesService);
  }

  async login(
    credentials: Credentials
  ): Promise<DataStatus<AuthData> | DataStatus<AuthData | JsonObject> | { error: MfaRequiredApiResponse }> {
    const url = this.buildUrl('/auth/login');
    const requestBody = {
      email: credentials.email,
      password: credentials.password,
    };
    const request$ = this.performRequest<AuthData | { error: MfaRequiredApiResponse }>(RequestType.POST, url, {
      requestBody,
    }).pipe(
      map((result) => {
        if (result.body) {
          this.onboardingUtilities.cleanupOnboardingStorage();
          return new DataStatus<AuthData>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            new AuthData().deserialize(result.body)
          );
        }
        const newAuthData = new AuthData();
        newAuthData.error = 'Valid login response with no auth/user information';
        return new DataStatus<AuthData>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, newAuthData.error),
          newAuthData
        );
      }),
      catchError((error: { error: MfaRequiredApiResponse }) => {
        console.warn('Failed logging in: ', error);
        return of(error);
      })
    );
    return firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error logging in: ', error);
      if ((error?.error as MfaRequiredApiResponse)?.explanation === LoginError.MfaVerificationRequired) {
        return error as { error: MfaRequiredApiResponse };
      }
      const authentication = (error?.error as JsonObject)?.authentication as AuthData;
      const data = authentication ? new AuthData().deserialize(authentication) : null;

      return new DataStatus<AuthData | JsonObject>(
        Status.error,
        new StatusMessage(
          error?.status,
          (error?.error as JsonObject)?.explanation ||
            (error?.error as JsonObject)?.message ||
            'There was a problem logging in'
        ),
        data
      );
    });
  }

  async verifyMfaCode(
    code: string
  ): Promise<DataStatus<AuthData> | DataStatus<AuthData | JsonObject> | HttpErrorResponse> {
    const url = this.buildUrl('/auth/token');
    const requestBody = {
      code,
    };
    const request$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody, includeToken: true }).pipe(
      map((result) => {
        // TODO: extract common code from this and non-MFA login
        if (result.body) {
          this.onboardingUtilities.cleanupOnboardingStorage();
          return new DataStatus<AuthData>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            new AuthData().deserialize(result.body)
          );
        }
        const newAuthData = new AuthData();
        newAuthData.error = 'Valid mfa verification response with no auth/user information';
        return new DataStatus<AuthData>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, newAuthData.error),
          newAuthData
        );
      }),
      catchError((error) => {
        console.warn('Failed MFA code verification: ', error);
        return of(error as HttpErrorResponse);
      })
    );
    return firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error validating MFA code: ', error);
      const authentication = (error?.error as JsonObject)?.authentication as AuthData;
      const data = authentication ? new AuthData().deserialize(authentication) : error;

      return new DataStatus<AuthData | JsonObject>(
        Status.error,
        new StatusMessage(
          error?.status,
          (error?.error as JsonObject)?.explanation ||
            (error?.error as JsonObject)?.message ||
            'There was a problem validating MFA code'
        ),
        data
      );
    });
  }

  async resendMfaVerificationCode(): Promise<DataStatus<boolean>> {
    const url = this.buildUrl('/auth/token/resend-code');
    const request$ = this.performRequest<{ message: string }>(RequestType.POST, url, { includeToken: true }).pipe(
      map((result) => {
        return new DataStatus<boolean>(
          result?.ok ? Status.done : Status.error,
          new StatusMessage(result?.status, result?.body?.message),
          result?.ok
        );
      }),
      catchError((error: HttpErrorResponse) => {
        console.warn('Failed resending MFA code: ', error);
        return of(
          new DataStatus<boolean>(
            Status.error,
            new StatusMessage(
              error?.status,
              (error?.error as JsonObject)?.explanation ||
                (error?.error as JsonObject)?.message ||
                'There was a problem resending MFA code'
            ),
            false
          )
        );
      })
    );
    return firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error resending MFA code: ', error);
      return new DataStatus<boolean>(
        Status.error,
        new StatusMessage(
          error?.status,
          (error?.error as JsonObject)?.explanation ||
            (error?.error as JsonObject)?.message ||
            'There was a problem resending MFA code'
        ),
        false
      );
    });
  }

  refreshToken(authenticationData?: AuthData): Observable<DataStatus<AuthData>> {
    const authData = authenticationData || this.dataStoreService.authData;
    const { userId, token } = authData;
    const url = this.buildUrl('/auth/token', false, { userId, token, queryParams: {} });
    const requestBody = {
      token: authData.token,
      refreshToken: authData.refreshToken,
    };
    return this.performRequest<AuthData>(RequestType.PUT, url, { requestBody }).pipe(
      take(1), // ensure only a single response is sent
      map((response: HttpResponse<AuthData>): DataStatus<AuthData> => {
        const tokenData = new AuthData().deserialize(response?.body);
        if (tokenData) {
          return new DataStatus<AuthData>(Status.done, new StatusMessage(200, 'OK'), tokenData);
        }
        return null;
      }),
      catchError((error: string) => {
        console.warn('Failed refreshing token: ', error);
        return new BehaviorSubject<DataStatus<AuthData>>(new DataStatus<AuthData>(Status.error));
      })
    );
  }

  revokeToken(tokenToDelete: string): Observable<DataStatus<boolean>> {
    const url = this.buildUrl(`/auth/token/${tokenToDelete}`);
    return this.performRequest<boolean>(RequestType.DELETE, url).pipe(
      map(processRevokeToken),
      catchError(handleObservableError('Failed revoking token: '))
    );

    function processRevokeToken(response: HttpResponse<boolean>) {
      const status = response?.ok ? Status.done : Status.error;
      const statusMessage = response?.ok
        ? new StatusMessage(HttpStatusCode.OK, 'Token Revoked')
        : new StatusMessage(HttpStatusCode.BAD_REQUEST, 'Error revoking token');
      return new DataStatus<boolean>(status, statusMessage, response?.ok);
    }
  }

  async requestPasswordReset(email: string): Promise<DataStatus<AuthData>> {
    const credentials = new Credentials(email);
    const url = this.buildUrl('/auth/reset/request');
    const requestBody = {
      email: credentials.email,
    };
    const dataStatus$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
      map((response: HttpResponse<AuthData>): DataStatus<AuthData> => {
        if (response?.ok) {
          return new DataStatus<AuthData>(
            Status.done,
            new StatusMessage(200, 'OK'),
            new AuthData().deserialize(response.body)
          );
        }
        const newAuthData = new AuthData();
        newAuthData.error = 'Valid response with no email information';
        return new DataStatus<AuthData>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, newAuthData.error),
          newAuthData
        );
      }),
      catchError((error: HttpErrorResponse) => {
        const authentication = (error?.error as JsonObject)?.authentication as AuthData;
        const data = authentication ? new AuthData().deserialize(authentication) : null;
        console.warn('Failed requesting password reset: ', error);
        return of(
          new DataStatus<AuthData>(
            Status.error,
            new StatusMessage(
              error?.status,
              (error?.error as JsonObject)?.explanation ||
                (error?.error as JsonObject)?.message ||
                'There was a problem resetting the password'
            ),
            data
          )
        );
      })
    );
    return firstValueFrom(dataStatus$).catch((error): null => {
      console.warn('Error requesting password reset: ', error);
      return null;
    });
  }

  changePassword(email: string, password: string, code: string): Promise<DataStatus<AuthData>> {
    const credentials = new Credentials().deserialize({ email, password, code });
    const url = this.buildUrl('/auth/reset');
    const requestBody = {
      email: credentials.email,
      password: credentials.password,
      code: credentials.code,
    };
    const dataStatus$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
      map((response: HttpResponse<AuthData>): DataStatus<AuthData> => {
        return new DataStatus<AuthData>(
          response?.ok ? Status.done : Status.error,
          response?.ok
            ? new StatusMessage(HttpStatusCode.OK, 'OK')
            : new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'Valid response with no code information'),
          new AuthData().deserialize(response?.body)
        );
      }),
      catchError(handleObservableError('Error resetting password'))
    );

    return firstValueFrom(dataStatus$).catch(handlePromiseError('Error changing password'));
  }

  checkForExistingAccount(userId?: string, eligibilityId?: string): Promise<DataStatus<boolean>> {
    const queryParams: JsonObject = {};
    if (userId) {
      queryParams['userId'] = userId;
    }
    if (eligibilityId) {
      queryParams['eligibilityId'] = eligibilityId;
    }
    const request$ = this.performRequest<boolean>(
      RequestType.GET,
      this.buildUrl('/user/check-existing', false, queryParams)
    ).pipe(
      map((response: HttpResponse<any>) =>
        response?.ok
          ? new DataStatus<boolean>(Status.done, null, true)
          : new DataStatus<boolean>(Status.done, null, false)
      ),
      catchError(handleObservableError('Failed getting existing user status'))
    );
    return firstValueFrom(request$).catch(handlePromiseError('Error getting existing user status'));
  }
}

function handleObservableError(errorMessage: string) {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return of(
      new DataStatus<null>(Status.error, new StatusMessage(error.status, error?.message ?? errorMessage), null)
    );
  };
}

function handlePromiseError(errorMessage: string) {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return new DataStatus<null>(Status.error, new StatusMessage(error.status, error?.message ?? errorMessage), null);
  };
}
