import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const enabled = false;
    // NOTE: Uncomment the desired logs
    // console.log('--- LogInterceptor ---');
    if (req && enabled) {
      if (req.url) {
        // console.log('Url:', req.url);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (req.body && req.body.operationName) {
        // console.log('Operation:', req.body.operationName);
      }
      if (req.headers) {
        if (req.headers.get('accept')) {
          //   console.log('Accept:', req.headers.get('accept'));
        }
        if (req.headers.get('Authorization')) {
          // console.log('Authorization:', req.headers.get('Authorization'));
        }
      }
    }
    return next.handle(req);
  }
}
