import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Directive({
  selector: '[kutilBackButton]',
})
export class BackButtonDirective {
  constructor(private _navigation: NavigationService) {}

  @Input()
  preventDefault: boolean;

  @HostListener('click')
  onClick(event: Event): void {
    if (this.preventDefault) {
      event.preventDefault();
    }
    this._navigation.back();
  }
}
