<div class="icon-word-list">
  @for (pair of imageAndIcons; track pair) {
    <div class="icon-word-pair">
      <div class="image-background">
        @if (pair.icon) {
          <img class="smiley-img" src="{{ pair.icon }}" [alt]="'smiley for ' + pair.word | translate | async" />
        }
      </div>
      @if (!pair.icon) {
        <svg-icon
          class="smiley-svg-icon"
          src="/assets/icons/smiley-meh.svg"
          [attr.aria-label]="'smiley for ' + pair.word"></svg-icon>
      }
      <p class="text">{{ pair.word | translate | async }}</p>
    </div>
  }
</div>
