import { UserType } from '@ktypes/enums';
import { Deserializable, JsonObject } from '@ktypes/models';

export enum DataLinkSource {
  // Note: classic (query param) links may have "sharing" instead of "sharing_onboarding"
  sharing_onboarding = 'sharing_onboarding',
  // Note: classic (query param) links may have "sms" or "eligibility" instead of "account_creation"
  account_creation = 'account_creation',
  pulse_survey = 'pulse_survey',
  payment = 'payment',
  resources = 'resources',
  test = 'test', // for testing
}

interface CoreLinkData {
  userId: string;
  groupCode: string;
  token: string;
  refreshToken: string;
}
export interface AccountCreationData extends CoreLinkData {
  eligibilityCandidateId: string;
  passwordSetRequired: boolean;
}
export interface SharingData {
  sharingToken: string;
}
export interface PulseSurveyData extends CoreLinkData {
  eligibilityCandidateId: string;
  pulseSurveyId: string;
  userType: UserType;
}
export interface TestingData {
  testing: string;
}
export type PaymentData = CoreLinkData;
export type DataLinkData = AccountCreationData | SharingData | PulseSurveyData | PaymentData | TestingData;

export class DataLink<DataLinkType = DataLinkData> implements Deserializable<JsonObject, DataLink<DataLinkType>> {
  constructor(
    public source?: DataLinkSource,
    public data?: DataLinkType
  ) {}

  deserialize(json: JsonObject): DataLink<DataLinkType> {
    if (json == null) {
      return null;
    }

    this.source = json['source'] as DataLinkSource;
    this.data = json['data'] as DataLinkType;

    return this;
  }
}
