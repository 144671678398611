import { JsonValue } from '@angular-devkit/core';

export interface StringMap<Value = string> {
  [key: string]: Value;
}

// Created separate JsonObject interface to avoid circular dependency
interface JsonObject<T = any> {
  [key: string]: T;
}

export class StringUtil {
  static capitalizeFirstLetter(text: string) {
    if (!text) {
      return '';
    }
    return text.substring(0, 1).toUpperCase() + text.substring(1);
  }

  static contentAsParsedHtml(unparsedHtml: string): string {
    const html = document.createElement('html');
    html.innerHTML = unparsedHtml;
    const content = document.createElement('div');
    content.innerHTML = html.getElementsByTagName('body')[0].innerHTML;
    const filteredStyles = html.getElementsByTagName('style')[0];
    if (filteredStyles !== undefined) {
      filteredStyles.innerHTML = filteredStyles.textContent.replace(/body\s?\{[^}]*\}/, '');
      content.appendChild(filteredStyles);
    }
    return content.innerHTML || '';
  }

  static getKeyFromEncodedQueryString(encoded: string, key: string, URIComponent = false): string {
    const decoded = URIComponent ? decodeURI(encoded) : decodeURIComponent(encoded);
    const uriParts = decoded?.split('?');
    const queryParams = uriParts?.length > 1 ? uriParts[1] : '';
    const keyValuePairs: JsonObject = queryParams?.split('&')?.reduce((kvPairs, kvPair) => {
      const parts = kvPair.split('=');
      if (parts?.length > 1) {
        // @ts-ignore TODO: Revisit lint issues
        kvPairs[parts[0]] = parts[1];
      }
      return kvPairs;
    }, {});
    return (keyValuePairs?.[key] as string) ?? '';
  }
}

export function matchesInAppLink(uri: string, environmentSubDomain: string, hostname: string): boolean {
  if (!uri || !uri.includes('http')) {
    // false if it can't be parsed as a URL
    return false;
  }
  const targetUrl = new URL(uri);
  const targetUrlHostname = targetUrl?.hostname;
  const hostBase = 'kumanu.com';
  const purposefulEnvHostname = `${environmentSubDomain}.purposeful.${hostBase}`;
  const resourcefulEnvHostname = `${environmentSubDomain}.resourceful.${hostBase}`;
  const purposefulHostname = `purposeful.${hostBase}`;
  const resourcefulHostname = `resourceful.${hostBase}`;
  // inAppLinks must either match the hostname or a purposeful/resourceful hostname (in existing environment) exactly
  return [hostname, purposefulEnvHostname, resourcefulEnvHostname, purposefulHostname, resourcefulHostname].some(
    (uriPart) => targetUrlHostname === uriPart
  );
}

export function getInAppLink(uri: string): string {
  if (!uri || !uri.includes('http')) {
    // just return the uri if it can't be parsed as a URL
    return uri;
  }
  const url = new URL(uri);
  return `${url.pathname}${url.search}`;
}

export function extractStyleTags(htmlString: string): string[] {
  if (!htmlString) {
    return [];
  }
  const regex = /(<style[^>]*>[\s\S]*?<\/style>)/gi;
  return htmlString.match(regex) || [];
}

export function removeStyleTags(htmlString: string): string {
  if (!htmlString) {
    return '';
  }
  const regex = /<style[^>]*>[\s\S]*?<\/style>/gi;
  return htmlString.replace(regex, '');
}

export function getQueryParamsFromString(queryParamsString: string): Record<string, any> {
  // remove '?' if exists as first char of string
  const cleanedString = queryParamsString?.[0] === '?' ? queryParamsString.substring(1) : queryParamsString;
  const queryParams = cleanedString
    ?.split('&') // split into query param key/value pairs
    ?.reduce((aggregator: Record<string, any>, pairs: string) => {
      // split key & value
      const [key, ...values] = pairs.split('=');
      // assign to object if key is truthy
      if (key) {
        // rejoin the values back together, only want to split on the first instance of `=`
        aggregator[key] = values?.join('=');
      }
      return aggregator as Record<string, any>;
    }, {} as any) as Record<string, any>;
  return queryParams || {};
}

export function isJSON(str: string | Error | JsonObject): boolean {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    const json = JSON.parse(str) as JsonObject;
    // will return true if successfully parses and is a validly parsed item (object, array, or parsed primitive value)
    return ['object', 'boolean', 'string', 'number'].includes(typeof json) || Array.isArray(json);
  } catch (e) {
    /* intentionally left blank */
  }
  return false;
}

export function tryParseJSON(json: string | Error | JsonObject): JsonValue {
  if (!isJSON(json)) {
    return false;
  }
  return JSON.parse(json as string) as JsonValue;
}
