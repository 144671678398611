import { Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { Feature, Role } from '@ktypes/enums';
import {
  AuthData,
  DataStatus,
  Group,
  JsonObject,
  LiveSupport,
  Settings,
  Status,
  StatusMessage,
  Theme,
  User,
} from '@ktypes/models';
import { clearEmptyProps } from '@kutil';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { BehaviorSubject, distinctUntilChanged, Observable, skipWhile, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrowserStorage } from './browser-storage.service';
import { ThemeService } from './theme.service';

export interface Store {
  authData?: AuthData;
  pulseSurveyAuthData?: AuthData;
  user?: User;
}
export interface SignalStore {
  currentLanguage: WritableSignal<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class DataStoreService implements OnDestroy {
  /* NOTE: The Data Store should only be accessed via BLoC files, not directly by components. */
  constructor(
    private _browserStorage: BrowserStorage,
    private _themeService: ThemeService
  ) {
    this._loadAuthData();
  }

  private _store: Store = {};
  private _store$ = new BehaviorSubject<Store>({});
  private _latestAuthStatus$ = new BehaviorSubject<DataStatus<AuthData>>(null);
  private _settingChangeInProgress = false;

  store$: Observable<Store> = this._store$.pipe(distinctUntilChanged());
  authData$: Observable<AuthData> = this.store$.pipe(
    map((store) => store.pulseSurveyAuthData ?? store.authData),
    distinctUntilChanged()
  );
  user$: Observable<User> = this.store$.pipe(
    map((store) => store.user),
    distinctUntilChanged()
  );
  featureKeys$: Observable<Feature[]> = this.store$.pipe(
    map((store) => store.user?.features?.map((feature) => feature?.key)),
    distinctUntilChanged()
  );
  roleKeys$: Observable<Role[]> = this.store$.pipe(
    map((store) => store.user?.roles?.map((role) => role?.key)),
    distinctUntilChanged()
  );

  // TODO: should signalStore itself be a Signal? Should it be private and only the properties exposed?
  signalStore: SignalStore = {
    // Default currentLanguage to browser language
    currentLanguage: signal<string | null>(null),
  };

  ngOnDestroy(): void {
    this._store$.complete();
  }

  // Get access to the stream of authentication events
  get authStatus$(): Observable<DataStatus<AuthData>> {
    return this._latestAuthStatus$.asObservable();
  }

  get authStatus(): DataStatus<AuthData> {
    return this._latestAuthStatus$.getValue();
  }

  setAuthStatus(dataStatus: DataStatus<AuthData>) {
    this._latestAuthStatus$.next(dataStatus);
  }

  resetStatus(starting = false) {
    if (starting) {
      this.setAuthStatus(
        new DataStatus<AuthData>(Status.starting, new StatusMessage(Status.starting, ''), this._store.authData)
      );
    } else {
      this.setAuthStatus(null);
    }
  }

  /// NOTE: This will only get the current value of the store, it does not stream the changes
  get store(): Store {
    // return a clone of the store, so the underlying data cannot be
    // manipulated outside the methods available in this service
    return cloneDeep(this._store);
  }

  get authData(): AuthData {
    return cloneDeep(this._store.pulseSurveyAuthData ?? this._store.authData);
  }

  setAuthData(authData: JsonObject) {
    // TODO: This also updates user, we should separate the two and store token data and user data separately
    if (
      !authData ||
      isEqual(new AuthData().deserialize({ ...authData }), new AuthData().deserialize({ ...this._store.authData }))
    ) {
      return;
    }
    if (authData?.token != null && typeof authData.token !== 'string') {
      // has token, but it's an invalid token type (not a string), don't store/process it
      console.warn('has token, but is an invalid token type (not a string) - aborting processing in setAuthData');
      return;
    }
    authData.user = this._setUserInternally(authData.user);
    this._store = merge(
      {},
      this._store,
      {
        authData: new AuthData().deserialize(
          merge({}, this._store.authData, { ...{ userId: (authData as Partial<AuthData>).user?.id } }, authData)
        ),
      },
      {
        pulseSurveyAuthData: this._store.pulseSurveyAuthData
          ? new AuthData().deserialize(merge({}, this._store.pulseSurveyAuthData))
          : null,
      },
      {
        user: (authData as Partial<AuthData>).user,
      }
    );
    this._store$.next(this._store);
    this._saveToStorage();

    // ensure AuthStatus is updated if null
    if (
      (this._latestAuthStatus$.value == null && this._store?.user?.group != null) ||
      this._store?.user?.features?.length > 0
    ) {
      this.setAuthStatus(new DataStatus<AuthData>(Status.done, this.store?.authData));
    }
  }

  removeAuthData() {
    this._store = merge({}, this._store);
    if (this._store.authData) {
      delete this._store.authData;
      this._browserStorage.remove('authData', true);
    }
    if (this._store.user) {
      delete this._store.user;
      this._browserStorage.remove('user', true);
    }
    this._store$.next(this._store);
  }

  setPulseSurveyAuthData(pulseSurveyAuthData: any) {
    if (
      isEqual(
        new AuthData().deserialize({ ...pulseSurveyAuthData }),
        new AuthData().deserialize({ ...this._store.pulseSurveyAuthData })
      )
    ) {
      return;
    }
    if (!pulseSurveyAuthData) {
      delete this._store.pulseSurveyAuthData;
      const authData = new AuthData().deserialize(
        merge({}, this._store.authData, {
          user: new User().deserialize(merge({}, this._store.authData?.user, this._store.user)),
        })
      );
      this._store = merge(
        {},
        this._store,
        { authData },
        { user: new User().deserialize(merge({}, authData.user, this._store.user)) }
      );
    } else {
      this._store = merge({}, this._store, {
        pulseSurveyAuthData: new AuthData().deserialize(
          merge({}, this._store.pulseSurveyAuthData ? this._store.pulseSurveyAuthData : {}, pulseSurveyAuthData)
        ),
      });
    }
    this._store$.next(this._store);
    this._saveToStorage();
  }

  get user(): User {
    return cloneDeep(this._store.user);
  }

  setUser(data: JsonObject): void | User {
    const existingUser = this._store.user || this._store.authData?.user;
    if (!data) {
      // if no data passed, don't do anything
      return;
    }
    if (isEqual(new User().deserialize({ ...data }), new User().deserialize({ ...(existingUser || {}) }))) {
      // if data matches existing user information, maintain existing
      return existingUser;
    }
    this._setUserInternally(data, existingUser);
    this._store$.next(this._store);
    this._saveToStorage();
  }

  removeUser() {
    this._store = merge({}, this._store);
    if (this._store.authData?.user) {
      delete this._store.authData.user;
    }
    if (this._store.user) {
      delete this._store.user;
      this._browserStorage.remove('user', true);
    }
    this._store$.next(this._store);
  }

  private _saveToStorage() {
    const lifespan = this.stayLoggedIn ? 30 : new Date(new Date().getTime() + 60 * 60 * 1000);
    this._browserStorage.setObject('authData', this._store.authData?.serialize(true), lifespan, true);
    this._browserStorage.setObject('user', this._store.user?.serialize(true), lifespan, true);
  }

  updateOnFocus() {
    const storedAuthData: AuthData = (this._browserStorage.getObject('authData', true) ?? {}) as AuthData;
    const storedUser = (this._browserStorage.getObject('user', true) ?? {}) as User;
    if (!storedAuthData || !storedUser) {
      // don't update if there is not any storedAuthData/storedUser (i.e. after deleted by logout)
      return;
    }
    if (
      storedAuthData?.dateExpires !== this.authData?.dateExpires ||
      storedAuthData?.token !== this.authData?.token ||
      storedUser?.id !== this.user?.id ||
      storedUser?.type !== this.user?.type
    ) {
      this.setAuthData({ ...storedAuthData, user: { ...storedUser }, userId: storedUser.id });
    }
  }

  updateOnStorageChange(event: StorageEvent) {
    const keysToWatch: string[] = [];
    if (Object.keys(this.store)?.includes(event?.key) && keysToWatch.includes(event?.key)) {
      // do stuff on storage change
    }
  }

  setUserSettings(settings: Partial<Settings>) {
    if (settings) {
      settings.staySignedIn = this.stayLoggedIn;
    }
    if (Object.prototype.hasOwnProperty.call(settings, 'userMfaEnabledSetting')) {
      // userMfaEnabledSetting is on user object, not in settings :(
      this.setUser(merge({}, { ...this._store.user }, settings));
    } else {
      const userSettings = new Settings().deserialize({ ...this._store.user.settings, ...settings });
      this.setUser(merge({}, { ...this._store.user }, { settings: userSettings }));
      this._themeService.forceHighContrast(userSettings.darkMode);
      this.signalStore.currentLanguage.set(userSettings.language);
    }
  }

  setLoggedIn(stayLoggedIn: boolean) {
    this._browserStorage.setObject('stayLoggedIn', stayLoggedIn, 30, true);
    if (!this._settingChangeInProgress) {
      this._settingChangeInProgress = true;
      // This subscription is needed for setting stayLoggedIn on ScreenLogin
      this.authData$
        .pipe(
          skipWhile((authData) => authData == null || !authData?.token || !authData?.user?.id),
          take(1)
        )
        .subscribe((authData) => {
          this.setUserSettings(
            new Settings().deserialize({
              ...(authData.user?.settings || {}),
              staySignedIn: !!this._browserStorage.getObject('stayLoggedIn', true),
            })
          );
          this._settingChangeInProgress = false;
        });
    }
  }

  get stayLoggedIn(): boolean {
    return !!this._browserStorage.getObject('stayLoggedIn', true);
  }

  // NOTE: This function updates the store without updating the stream, and returns the user object
  private _setUserInternally(
    data: JsonObject = {},
    existingUser: User = this._store.user || this._store.authData?.user
  ) {
    // remove error if not explicitly retained on new data
    if (existingUser?.error && !data.error) {
      delete existingUser.error;
    }
    // only maintain existingUser data if user.id matches (and is defined)
    const user: User =
      !(data as Partial<User>).id || existingUser?.id === (data as Partial<User>).id
        ? new User().deserialize(merge({}, existingUser, data))
        : new User().deserialize(data);
    // if no group or the group id matches, maintain the group data
    if (
      user &&
      (!user.group ||
        (user.group?.id && user.group.id === existingUser?.group?.id) ||
        (user.groupId && user.groupId === existingUser?.group?.id))
    ) {
      const group = merge({}, existingUser?.group, user.group);
      if (Object.keys(group)?.length > 0) {
        user.group = group;
      }
    }
    // maintain live support data (sometimes it doesn't come back in API)
    if (user?.group && existingUser?.group?.liveSupport && user.group.id === existingUser.group.id) {
      user.group.liveSupport = this._mergeLiveSupport(existingUser.group.liveSupport, user.group.liveSupport);
    }
    if (((data as User).group?.theme || (data as Group).theme) && !user.settings?.darkMode) {
      // NOTE: Possible side effect (changing theme)
      const theme = new Theme().deserialize((data as User).group?.theme || (data as Group).theme);
      this._themeService.changeTheme(theme);
    }
    this._store = merge(
      {},
      this._store,
      { user },
      {
        authData: new AuthData().deserialize(merge({}, { ...this.store.authData }, { ...{ userId: user?.id, user } })),
      },
      {
        pulseSurveyAuthData: this._store.pulseSurveyAuthData
          ? new AuthData().deserialize(
              merge({}, { ...this._store.pulseSurveyAuthData }, { ...{ userId: user?.id, user } })
            )
          : null,
      }
    );
    return user;
  }

  private _loadAuthData(): void {
    const loadedAuthData = this._browserStorage.getObject('authData', true) as AuthData;
    const loadedUser = this._browserStorage.getObject('user', true) as User;
    const user = new User().deserialize(
      merge({}, this._store.authData?.user, this.user, loadedAuthData?.user, loadedUser)
    );
    const authData = new AuthData().deserialize(merge({}, this.authData, { user }, loadedAuthData));
    // NOTE: currently user will be set as part of the call to setAuthData, so does not need to be set separately
    this.setAuthData(authData);
  }

  private _mergeLiveSupport(existingLiveSupport: LiveSupport, newLiveSupport: LiveSupport) {
    if (!isEqual(existingLiveSupport, newLiveSupport)) {
      const liveSupport = merge(clearEmptyProps(existingLiveSupport), clearEmptyProps(newLiveSupport));
      return new LiveSupport().deserialize(liveSupport);
    }
    return existingLiveSupport;
  }
}
