import { AsyncPipe } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@kf-loc';
import { VerifyType } from '@ktypes/enums';
import { UtilsModule } from '@kutil';
import { MockComponent } from '@kutil/test';
import { SvgIconComponent } from 'angular-svg-icon';
import { EmailScreensBaseComponent } from '../../auth/email-screens-base/email-screens-base.component';

@Component({
  selector: 'kui-verify-no-code',
  standalone: true,
  templateUrl: './verify-no-code.component.html',
  styleUrls: ['./verify-no-code.component.scss'],
  imports: [RouterLink, TranslatePipe, AsyncPipe, SvgIconComponent, EmailScreensBaseComponent, UtilsModule],
})
export class VerifyNoCodeComponent /* implements OnInit, OnDestroy  */ {
  verifyType = input<VerifyType>();
  resendCode = output<void>();

  protected readonly VerifyType = VerifyType;
}

export const MockVerifyNoCodeComponent = MockComponent({
  selector: 'kui-verify-no-code',
  standalone: true,
  inputs: ['verifyType'],
});
