import cloneDeep from 'lodash/cloneDeep';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export class ContentImage implements Deserializable<any, ContentImage>, Serializable<JsonObject> {
  constructor(
    public url?: string,
    public fileName?: string,
    public contentType?: string,
    public title?: string,
    public description?: string,
    public size?: number,
    public width?: number,
    public height?: number
  ) {}

  deserialize(json: JsonObject) {
    const isContentImage = json instanceof ContentImage;
    if (!isContentImage && (json == null || json['file'] == null)) {
      return null;
    }
    const file = (isContentImage ? json : json['file']) as JsonObject;
    const details = (isContentImage ? json : file?.['details'] ?? {}) as JsonObject;
    const image = (isContentImage ? json : details?.['image'] ?? {}) as JsonObject;

    this.url = file['url'] as string;
    this.fileName = file['fileName'] as string;
    this.contentType = file['contentType'] as string;
    this.title = (json['title'] as string) ?? '';
    this.description = (json['description'] as string) ?? (json['altText'] as string) ?? '';
    this.size = (details['size'] as number) ?? 0;
    this.width = (image['width'] as number) ?? 0;
    this.height = (image['height'] as number) ?? 0;

    return this;
  }

  clone(contentImage: ContentImage) {
    return cloneDeep(contentImage);
  }

  serialize() {
    return {
      file: {
        url: this.url,
        details: {
          size: this.size,
          image: {
            width: this.width,
            height: this.height,
          },
        },
        fileName: this.fileName,
        contentType: this.contentType,
      },
      title: this.title,
      description: this.description,
    };
  }
}
