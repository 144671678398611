import { DateTimeUtil } from '@kutil';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class LocalResourceDay {
  constructor(
    public day?: string,
    public isOpen?: boolean,
    public allDay?: boolean,
    public finish?: string,
    public start?: string
  ) {}
}

export class LocalResourceHours implements Deserializable<JsonObject, LocalResourceHours> {
  constructor(
    public days?: LocalResourceDay[],
    public timezone?: string,
    public timezoneName?: string
  ) {}

  deserialize(input: JsonObject): LocalResourceHours {
    if (input == null) {
      return null;
    }
    this.days = DateTimeUtil.allDays.map(
      (dayName) =>
        new LocalResourceDay(
          dayName,
          input[dayName],
          input[`${dayName}_all_day`],
          input[`${dayName}_finish`] ? (input[`${dayName}_finish`] as string).replace(/(^0)|\s/g, '') : null,
          input[`${dayName}_start`] ? (input[`${dayName}_start`] as string).replace(/(^0)|\s/g, '') : null
        )
    );
    this.timezone = input['timezone'] as string;
    this.timezoneName = input['timezone_name'] as string;
    return this;
  }
}
