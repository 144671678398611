export class EnumUtil {
  // @ts-ignore ignoring to handle enum data types
  static getByValue<T, V>(enumToSearch, enumValue: V): T {
    if (enumValue == null) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return Object.keys(enumToSearch).find((enumKey) => enumToSearch?.[enumKey] === enumValue) as unknown as T;
  }
}
