import { Component, input } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-user-email',
  standalone: true,
  templateUrl: './user-email.component.html',
  styleUrls: ['./user-email.component.scss'],
})
export class UserEmailComponent {
  email = input<string>();
}

export const MockUserEmailComponent = MockComponent({
  selector: 'kui-user-email',
  standalone: true,
  inputs: ['email'],
});
