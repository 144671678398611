import { Injectable } from '@angular/core';
import { LandingDynamicApi } from '@kapi';
import { BrowserStorage } from '@kservice';
import { DataStatus, LandingPage, Status, StatusMessage } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LandingDynamicBloc {
  constructor(
    private _browserStorage: BrowserStorage,
    private _landingDynamicApi: LandingDynamicApi
  ) {}

  private _landingPage = new BehaviorSubject<DataStatus<LandingPage>>(null);
  private _landingPageByProduct = new BehaviorSubject<Record<string, LandingPage>>(null);

  get landingPage$(): Observable<DataStatus<LandingPage>> {
    return this._landingPage.asObservable();
  }
  get landingPageByProduct$(): Observable<Record<string, LandingPage>> {
    return this._landingPageByProduct.asObservable();
  }

  getLandingPage(groupCode: string, product?: string) {
    this._landingPage.next(new DataStatus<LandingPage>(Status.starting, new StatusMessage(Status.starting, ''), null));
    this._landingDynamicApi.getLandingPage(groupCode, product).then((landingPage) => {
      this._landingPage.next(landingPage);
      if (typeof product === 'string') {
        this._landingPageByProduct.next({ ...this._landingPageByProduct.value, [product]: landingPage?.data });
      }
    });
  }

  resetLanding() {
    this._browserStorage.removeItem('completedResourcefulSurvey');
    this._browserStorage.removeItem('externalLinkData');
    this._browserStorage.removeItem('isExternalPulseSurvey');
    this._browserStorage.removeItem('pulseSurveyId');
  }
}

export type LandingButtonAnalyticsData = {
  contentId?: string;
  index?: number;
  title: string;
};
