import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  SimpleIconAnimationType,
  SimpleIconSvgAnimationOptions,
} from '@kp/shared/components/animations/simple-icon-animation-options';
import { UserCardPreference } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { Subject, connect, merge, throttleTime } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-like-icon',
  templateUrl: './like-icon.component.html',
  styleUrls: ['./like-icon.component.scss'],
})
export class LikeIconComponent implements OnInit, OnChanges, OnDestroy {
  @Input() color: string;
  @Input() likeCount: number;
  @Input() preference = UserCardPreference.NEUTRAL;

  @Output() likeChanged = new EventEmitter<UserCardPreference>();

  /* NOTE: for SvgAnimations to animate, the animations must be defined in the CSS */
  options: SimpleIconSvgAnimationOptions = {
    duration: 333,
    fileName: '/assets/icons/like-animatable.svg',
    height: 60,
    type: SimpleIconAnimationType.svg,
    width: 60,
  };
  liked: boolean;
  likeClicked = false;

  private _debouncedClick$ = new Subject<void>();
  private _destroy$ = new Subject<void>();
  private _ANIMATION_TIME = 333;

  ngOnInit(): void {
    this._debouncedClick$
      .pipe(
        connect((shared$) =>
          merge(
            shared$.pipe(
              throttleTime(this._ANIMATION_TIME),
              map(() => EventLimiter.throttle)
            ),
            shared$.pipe(
              debounceTime(this._ANIMATION_TIME),
              map(() => EventLimiter.debounce)
            )
          )
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(this._handleLikeButtonClick.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.likeCount = this.likeCount ?? 0;
    this.liked = this.preference === UserCardPreference.MORE;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  likeButtonClicked(event: Event) {
    this._debouncedClick$.next();
    event.stopPropagation();
    event.preventDefault();
  }

  private _handleLikeButtonClick(throttleOrDebounce: EventLimiter) {
    switch (throttleOrDebounce) {
      case EventLimiter.throttle: // before timer
        // emit the appropriate UserCardPreference
        this.likeChanged.emit(this.liked ? UserCardPreference.NEUTRAL : UserCardPreference.MORE);
        this.likeClicked = true;
        break;
      case EventLimiter.debounce: // after timer
        this.likeClicked = false;
        break;
    }
  }
}

enum EventLimiter {
  throttle,
  debounce,
}

export const MockLikeIconComponent = MockComponent({
  selector: 'kp-like-icon',
  inputs: ['preference', 'likeCount', 'color'],
});
