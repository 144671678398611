import { Injectable } from '@angular/core';
import { TokenType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { PulseSurveyEventInfo } from '@kp/pulse-survey/models/pulse-survey-event-info.model';
import { PulseSurveyEvent, PulseSurveyEventType } from '@kp/pulse-survey/models/pulse-survey-event.model';
import { PulseSurvey } from '@kp/pulse-survey/models/pulse-survey.model';
import { PulseSurveyApi } from '@kp/pulse-survey/pulse-survey.api';
import { BrowserStorage } from '@kservice';
import { Product } from '@ktypes/enums';
import { DataStatus, Status } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PulseSurveyBloc {
  constructor(
    private _browserStorage: BrowserStorage,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _pulseSurveyApi: PulseSurveyApi
  ) {}

  private _pulseSurveySubject = new BehaviorSubject<DataStatus<PulseSurvey>>(null);
  private _survey: PulseSurvey;

  private _pulseSurveyCheckAllowed = new BehaviorSubject<boolean>(true);
  private _hasPulseSurvey = new Subject<boolean>();

  get pulseSurveySubject$(): Observable<DataStatus<PulseSurvey>> {
    return this._pulseSurveySubject.asObservable();
  }

  get pulseSurveyCheckAllowed$(): Observable<boolean> {
    return this._pulseSurveyCheckAllowed.asObservable();
  }

  get hasPulseSurvey$(): Observable<boolean> {
    return this._hasPulseSurvey.asObservable();
  }

  getPulseSurvey(tokenType: TokenType = TokenType.standard, pulseSurveyId?: string): void {
    this._pulseSurveySubject.next(new DataStatus<PulseSurvey>(Status.starting, null, null));
    this._pulseSurveyApi.createPulseSurvey(tokenType, pulseSurveyId).then((survey: PulseSurvey) => {
      const status = survey == null ? Status.error : Status.done;
      this._publishPulseSurvey(new DataStatus<PulseSurvey>(status, null, survey));
    });
  }

  private _publishPulseSurvey(pulseSurvey: DataStatus<PulseSurvey>): void {
    this._survey = pulseSurvey?.data;
    this._pulseSurveySubject.next(new DataStatus<PulseSurvey>(pulseSurvey?.status, null, this._survey));
    this._hasPulseSurvey.next(this._survey != null);
  }

  allowPulseSurveyCheckToday(shouldAllow: boolean) {
    this._pulseSurveyCheckAllowed.next(shouldAllow);
  }

  postSurveyEvent(eventType: PulseSurveyEventType, dialogueId?: string): void {
    if (this._survey != null) {
      const pulseSurveyEvent = new PulseSurveyEvent().deserialize({
        eventType,
        eventInfo: new PulseSurveyEventInfo().deserialize({
          presentedInAppId: this._survey.presentedInAppId,
          product: this._environmentVariablesService.product,
          dialogueId,
        }),
        deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      });
      void this._pulseSurveyApi.postPulseSurveyEvent(this._survey.id, pulseSurveyEvent);
      if (pulseSurveyEvent.eventType !== PulseSurveyEventType.pulse_survey_opened) {
        this._publishPulseSurvey(null);
      }
    }
  }

  setPulseSurveyId(pulseSurveyId: string) {
    if (pulseSurveyId != null) {
      this._browserStorage.setItem('pulseSurveyId', pulseSurveyId);
    }
  }

  retrievePulseSurveyId(): string {
    return this._browserStorage.getItem('pulseSurveyId') as string;
  }

  clearPulseSurveyId() {
    this._browserStorage.removeItem('pulseSurveyId');
  }

  getHasCompletedResourcefulSurvey() {
    return !!this._browserStorage.getObject('completedResourcefulSurvey');
  }

  setHasCompletedResourcefulSurvey() {
    if (this._environmentVariablesService.product === Product.resourceful) {
      this._browserStorage.setObject('completedResourcefulSurvey', true);
    }
  }
}
