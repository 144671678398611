import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { DataLinkBloc } from '@kp/data-link/data-link.bloc';
import { DataLink, DataLinkSource } from '@kp/data-link/data-link.model';
import { LegalDocumentsBloc } from '@kp/legal-document/legal-documents.bloc';
import { DataStatus, Status } from '@ktypes/models';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataLinkGuard {
  constructor(
    private _dataLinkBloc: DataLinkBloc,
    private _legalDocumentsBloc: LegalDocumentsBloc,
    private _router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot /*, state: RouterStateSnapshot*/
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    // look for dataToken=[token] - if not there, pass through; otherwise exchange for data
    if (Object.keys(route.queryParams).includes('dataToken')) {
      this._dataLinkBloc.validateDataLink(route.queryParams['dataToken']);
    } else if (
      ['source', 'refreshToken', 'token'].some((param) => Object.keys(route.queryParams || {}).includes(param))
    ) {
      // look for classic links - if one of above params is not there, pass through; otherwise exchange for dataLink
      this._dataLinkBloc.parseQueryParams(route.queryParams);
    } else {
      // check for stored link data and retrieve it if it exists
      // loadSavedLinkData will populate the dataLinkStatus$ observable if data exists
      const hasSaveLinkData = this._dataLinkBloc.loadSavedLinkData();
      if (!hasSaveLinkData) {
        // no link related query params found or saved, continue on
        return true;
      }
    }
    return this._dataLinkBloc.dataLinkStatus$.pipe(
      skipWhile(
        (dataLinkStatus: DataStatus<DataLink>) => dataLinkStatus == null || dataLinkStatus?.status === Status.starting
      ),
      map((dataLinkStatus: DataStatus<DataLink>) => {
        const dataLink = dataLinkStatus?.data;
        if (dataLinkStatus?.status === Status.done && dataLink?.data && dataLink?.source) {
          if ([DataLinkSource.pulse_survey, DataLinkSource.resources].includes(dataLink?.source)) {
            // store link data for some sources to allow it to be retrieved if the page refreshes
            this._dataLinkBloc.saveLinkData(dataLink);
          }
          if (
            this._legalDocumentsBloc.privacyPledgeAccepted &&
            route.routeConfig.path === 'pulse_survey' &&
            dataLink?.source
          ) {
            // reset privacyPledgeAccepted flag
            this._legalDocumentsBloc.resetPrivacyPledgeAccepted();
            // loop back from privacy-pledge, continue to pulse survey now if there is a dataLink still
            return true;
          }
          return this._redirectBySource(dataLink?.source);
        }
        // if error parsing link or no source or data exists, just go to error route
        return this._router.parseUrl('welcome/error');
      }),
      catchError((error) => {
        console.warn('There was an error with the data link: ', error);
        // if error parsing link or no data exists, just go to error route
        return of(this._router.parseUrl('welcome/error'));
      })
    );
  }

  private _redirectBySource(source: DataLinkSource) {
    // use data link source to figure out where to send user
    switch (source) {
      case DataLinkSource.account_creation:
        return this._router.parseUrl('data-link/account_creation');
      case DataLinkSource.payment:
        return this._router.parseUrl('data-link/payment');
      case DataLinkSource.pulse_survey:
      case DataLinkSource.resources:
        return this._router.parseUrl('data-link/pulse_survey');
      case DataLinkSource.sharing_onboarding:
        return this._router.parseUrl('data-link/sharing_onboarding');
      default:
        // if it has a link and a source, but no valid source, send to error
        return this._router.parseUrl('data-link/error');
    }
  }
}
