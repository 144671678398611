<div class="reminders-modal-container">
  <div class="icon">
    @if (showInitialCase) {
      <img
        src="/assets/images/night-sky-icon.png"
        [alt]="
          'A diamond encompassed by 4 smaller diamonds against a backdrop of a magenta to purple gradient'
            | translate
            | async
        " />
    }
    @if (showNotNowCase) {
      <svg-icon src="/assets/icons/notification.svg"></svg-icon>
    }
  </div>
  @if (showInitialCase) {
    <div class="title">
      {{ 'Get the most out of your Purposeful experience with notifications.' | translate | async }}
    </div>
  }
  @if (showInitialCase) {
    <p class="description">
      {{
        'Purposeful users who turn on notifications are much more likely to succeed in building positive habits and reaching their goals.'
          | translate
          | async
      }}
    </p>
    <p class="description incognito">
      {{ "Be aware that notifications will not work in browsers' incognito / private modes." | translate | async }}
    </p>
  }
  @if (showNotNowCase) {
    <p class="description">
      {{ "You can enable push notifications under 'Notifications' if you change your mind." | translate | async }}
    </p>
  }
  @if (showInitialCase) {
    <div class="button-row">
      <button class="button button-pill button-reverse disable" (click)="notNow()">
        {{ 'Not now' | translate | async }}
      </button>
      <button class="button button-pill button-reverse enable" (click)="enable()">
        {{ 'Turn on' | translate | async }}
      </button>
    </div>
  }
  @if (showNotNowCase) {
    <div class="button-row">
      <button class="button button-pill button-reverse enable" (click)="dismissModal()">
        {{ 'Got it' | translate | async }}
      </button>
    </div>
  }
</div>
