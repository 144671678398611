import { Location } from '@angular/common';
import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { FirstVisitBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { SocialChallengeBloc } from '@kf-sc';
import { AppLifecycleService } from '@kp/app-lifecycle.service';
import { LocalResourcesBloc } from '@kp/local-resources/local-resources.bloc';
import { PastReflectionsBloc } from '@kp/past-reflections/past-reflections.bloc';
import { QuestionSetBloc } from '@kp/question-set/question-set.bloc';
import { SearchBloc } from '@kp/search/search.bloc';
import { CardBloc, CardType } from '@kp/shared/components/cards/card.bloc';
import { ImageBloc } from '@kp/shared/image/image.bloc';
import { GlobalElementsService } from '@kp/shared/services/global-elements.service';
import { VersionBloc } from '@kp/version/version.bloc';
import { DataStoreService, ThemeService, productDisplayName } from '@kservice';
import { CardEventType, CardRequestType, CardScreen, FirstVisitCase, Product } from '@ktypes/enums';
import {
  CardContentType,
  CardData,
  CardEvent,
  CardItem,
  LocalResourceDetailCardData,
  LocalResourcesProgram,
} from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { Subject, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  cardType: CardType;
  firstCardModalOpen: boolean;
  firstCompletedReflection: boolean;
  navActive = false;
  productName = productDisplayName;
  skipLinkPath: string;

  @HostBinding('class.product-purposeful')
  productPurposeful = false;

  @HostBinding('class.product-resourceful')
  productResourceful = false;

  quickActionsEnabled = false;

  private _actionStatus: boolean;
  private _destroy$ = new Subject<void>();
  private _firstCardModalDestroy$ = new Subject<void>();
  private _firstVisitDestroy$ = new Subject<void>();
  private _questStatus: boolean;
  private _reflectionStatus: boolean;

  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _appLifecycleService: AppLifecycleService,
    public cardBloc: CardBloc,
    private _dataStoreService: DataStoreService,
    public environmentVariablesService: EnvironmentVariablesService,
    private _firstVisitBloc: FirstVisitBloc,
    public globalElementsService: GlobalElementsService,
    public imageBloc: ImageBloc,
    public localResourcesCardBloc: LocalResourcesBloc,
    private _location: Location,
    public pastReflectionsBloc: PastReflectionsBloc,
    private _questionSetBloc: QuestionSetBloc,
    private _router: Router,
    private _searchBloc: SearchBloc,
    public socialChallengeBloc: SocialChallengeBloc,
    private _themeService: ThemeService, // do not delete, here to trigger ThemeService init
    public versionBloc: VersionBloc
  ) {}

  ngOnInit() {
    this._appLifecycleService.startup();

    // set up class name variables
    this.productPurposeful = Product.purposeful === this.environmentVariablesService.product;
    this.productResourceful = Product.resourceful === this.environmentVariablesService.product;
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        if (!this._router.url.endsWith('#main')) {
          this.skipLinkPath = `${this._router.url}#main`;
        }
      });

    this._firstVisitBloc.hasSeen$
      .pipe(takeUntil(merge(this._destroy$, this._firstVisitDestroy$)))
      .subscribe((firstVisit) => {
        this._actionStatus = firstVisit.firstActionAdded;
        this._questStatus = firstVisit.firstQuestAdded;
        this._reflectionStatus = firstVisit?.completedReflection;
        if (this._actionStatus && this._questStatus && this._reflectionStatus) {
          this._firstVisitDestroy$.next();
          this._firstVisitDestroy$.complete();
        }
      });

    this.cardBloc.firstCardStatus$
      .pipe(takeUntil(merge(this._destroy$, this._firstCardModalDestroy$)))
      .subscribe((firstCard) => {
        if (firstCard) {
          this.firstCardModalOpen = true;
          this.cardType = firstCard;

          if (firstCard === CardType.action && !this._actionStatus) {
            this._firstVisitBloc.updateFirstVisit(FirstVisitCase.firstActionAdded);
          } else if (firstCard === CardType.quest && !this._questStatus) {
            this._firstVisitBloc.updateFirstVisit(FirstVisitCase.firstQuestAdded);
          }
        } else {
          this.firstCardModalOpen = false;
        }
        if (this._questStatus && this._actionStatus && !this.firstCardModalOpen) {
          this._firstCardModalDestroy$.next();
          this._firstCardModalDestroy$.complete();
        }
      });

    this._questionSetBloc.firstReflectionCompleted$
      .pipe(takeUntil(this._destroy$))
      .subscribe((completedReflectionStatus) => {
        if (completedReflectionStatus && !this._reflectionStatus) {
          this.firstCompletedReflection = true;
          this._firstVisitBloc.updateFirstVisit(FirstVisitCase.completedReflection);
        }
      });
  }

  ngOnDestroy(): void {
    this._appLifecycleService.shutdown();
    this._destroy();
  }

  private _destroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._firstVisitDestroy$.next();
    this._firstVisitDestroy$.complete();
    this._firstCardModalDestroy$.next();
    this._firstCardModalDestroy$.complete();
  }

  @HostListener('window:storage', ['$event'])
  private _onStorageChange(event: StorageEvent) {
    // something changed in localStorage while user was not on the current tab
    this._dataStoreService.updateOnStorageChange(event);
  }

  @HostListener('window:focus')
  private _onFocus() {
    // user left tab and came back, check cookies and update if changed
    this._dataStoreService.updateOnFocus();
  }

  closeDetailModal(cardData?: CardData | LocalResourceDetailCardData, linkClicked = false) {
    if (cardData?.card instanceof CardItem && cardData?.card.contentType === CardContentType.card) {
      const searchScreens = [CardScreen.discover, CardScreen.resources];
      const isFromQuest = cardData.screen === CardScreen.quest;
      if (this._searchBloc.isSearchState && searchScreens.includes(cardData.screen)) {
        void this.cardBloc.handleCardEvents(
          new CardEvent(cardData.card.id, cardData.card, CardEventType.CLOSE, CardRequestType.SEARCH)
        );
        this._location.go(`/${cardData.screen}`);
      } else {
        const eventInfo = isFromQuest &&
          (cardData as CardData).quest?.presentedId && {
            quest_presented_id: (cardData as CardData).quest?.presentedId,
          };
        void this.cardBloc.handleCardEvents(
          new CardEvent(
            cardData.card.id,
            cardData.card,
            isFromQuest ? CardEventType.QUEST_CARD_CLOSE : CardEventType.CLOSE,
            cardData.requestType,
            eventInfo || {}
          )
        );
        if (!isFromQuest && !linkClicked) {
          // Currently card deep linking is not triggering the detail card view to open after using location.go()
          // The fix below using router.navigate should be taken out once the route change itself triggers the detail view to open
          // Update 07/25/23: this.activatedRoute.parent is null at this instance, so can't use relative routing
          if (cardData.requestType === CardRequestType.SOLO) {
            const currentUrl = this._router.url;
            void this._router.navigateByUrl(currentUrl.substring(0, currentUrl.lastIndexOf('/')));
          } else {
            let location: string;
            switch (cardData.screen) {
              case CardScreen.saved:
              case CardScreen.category:
                location = `/cards/${cardData.customCardCategory}`;
                break;
              case CardScreen.recommended:
              case CardScreen.today:
                location = '/today';
                break;
              case CardScreen.challenges:
                location = this._router.url;
                break;
              default:
                location = `/${cardData.screen}`;
                break;
            }
            this._location.go(location);
          }
        }
      }
    } else if (cardData?.card instanceof LocalResourcesProgram) {
      this.localResourcesCardBloc.handleCardEvents(
        new CardEvent(cardData.card.id, cardData.card, CardEventType.LOCAL_RESOURCE_CLOSED, CardRequestType.SEARCH)
      );
    }

    // Just close any/all detail views
    this.cardBloc.detailViewClicked.next({ detailViewClicked: false });
    this.cardBloc.detailViewClosed.next(true);
    this.localResourcesCardBloc.detailViewClicked.next({ detailViewClicked: false });
    this.pastReflectionsBloc.pastReflectionViewClicked.next(false);
  }

  navBtnClicked() {
    this.navActive = !this.navActive;
    this.quickActionsEnabled = false;
  }

  quickActionsToggled(isEnabled: boolean) {
    this.quickActionsEnabled = isEnabled;
    // TODO: Move these analytics events to the quick actions component once we can ensure no duplicate analytic events (from header/footer)
    if (this.quickActionsEnabled) {
      this.navActive = false;
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromInteraction({
          page: this._analyticsBloc.getDynamicPageNameIfMatch(),
          event: AnalyticEvent.quick_actions_opened,
        })
      );
    } else {
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromInteraction({
          page: this._analyticsBloc.getDynamicPageNameIfMatch(),
          event: AnalyticEvent.quick_actions_closed,
        })
      );
    }
  }

  sidebarClicked(shouldClose: boolean) {
    this.navActive = !shouldClose && !this.navActive;
  }

  closeOverlay() {
    this.quickActionsEnabled = !this.quickActionsEnabled;
    // TODO: Move these analytics events to the quick actions component once we can ensure no duplicate analytic events (from header/footer)
    if (!this.quickActionsEnabled) {
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromInteraction({
          page: this._analyticsBloc.getDynamicPageNameIfMatch(),
          event: AnalyticEvent.quick_actions_closed,
        })
      );
    }
  }

  closeFirstCardModal() {
    this.firstCardModalOpen = false;
    this.cardBloc.firstCardAdded(null);
  }

  closeFirstReflectionCompleteModalIfOpen() {
    this.firstCompletedReflection = false;
  }
}

export const MockAppComponent = MockComponent({ selector: 'kp-app' });
