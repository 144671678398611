import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackButtonDirective } from './directives/back-button.directive';
import { HoverDirectionDirective } from './directives/hover-direction.directive';
import { RerenderDirective } from './directives/rerender.directive';
import { BypassUrlSafetyPipe } from './pipes/bypass-url-security.pipe';
import { ClickableTelephoneNumberPipe } from './pipes/clickable-telephone-number.pipe';
import { EmailLinkPipe } from './pipes/email-link.pipe';
import { ListFormatPipe } from './pipes/list-format.pipe';
import { PreventNegativePipe } from './pipes/prevent-negative.pipe';
import { ReplaceTextPipe } from './pipes/replace-text.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SortPipe } from './pipes/sort.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    BackButtonDirective,
    BypassUrlSafetyPipe,
    ClickableTelephoneNumberPipe,
    EmailLinkPipe,
    HoverDirectionDirective,
    ListFormatPipe,
    PreventNegativePipe,
    ReplaceTextPipe,
    RerenderDirective,
    SanitizeHtmlPipe,
    SortPipe,
  ],
  exports: [
    BackButtonDirective,
    BypassUrlSafetyPipe,
    ClickableTelephoneNumberPipe,
    EmailLinkPipe,
    HoverDirectionDirective,
    ListFormatPipe,
    PreventNegativePipe,
    ReplaceTextPipe,
    RerenderDirective,
    SanitizeHtmlPipe,
    SortPipe,
  ],
})
export class UtilsModule {}
