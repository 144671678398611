import { PromoCallToActionContent } from '@kp/shared/components/promo/promo-content.model';
import { ContentImage, Deserializable, JsonObject } from '@ktypes/models';

export class Promo implements Deserializable<JsonObject, Promo> {
  public shownAsFeatured: boolean; // UI only

  constructor(
    public id?: string,
    public title?: string,
    public description?: string,
    public ctaText?: string,
    public labelText?: string,
    public dismissible = false,
    public image?: ContentImage,
    public ctaContent?: PromoCallToActionContent,
    public isFeatured?: boolean,
    public featuredForToday?: boolean
  ) {}

  deserialize(input: JsonObject): Promo {
    if (input == null) {
      return null;
    }

    this.id = input['promoContentId'] as string;
    this.title = input['title'] as string;
    this.description = input['description'] as string;
    this.ctaText = input['callToActionText'] as string;
    this.labelText = input['labelText'] as string;
    this.dismissible = !!input['dismissible'];
    this.image = new ContentImage().deserialize(input['image']);
    this.ctaContent = new PromoCallToActionContent().deserialize(input['ctaContent']);
    this.isFeatured = !!input['isFeatured'];
    this.featuredForToday = !!input['featuredForToday'];

    return this;
  }
}
