import { Document } from '@contentful/rich-text-types';
import cloneDeep from 'lodash/cloneDeep';
import { ContentImage } from './content-image.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export class ContentMedia implements Deserializable<JsonObject, ContentMedia>, Serializable<JsonObject> {
  constructor(
    public url?: string,
    public fileName?: string,
    public contentType?: string,
    public title?: string,
    public description?: string,
    public size?: number,
    public rawTranscript?: Document,
    public placeholderImage?: ContentImage
  ) {}

  deserialize(json: JsonObject) {
    if (
      json == null ||
      (json['media'] == null && json['file'] == null) ||
      (json['media'] != null && (json['media'] as JsonObject)['file'] == null)
    ) {
      return null;
    }
    const media = (json['media'] || json) as JsonObject;
    const file = (json['file'] || media['file']) as JsonObject;
    const details = (file['details'] || {}) as JsonObject;

    this.url = file['url'] as string;
    this.fileName = file['fileName'] as string;
    this.contentType = file['contentType'] as string;
    this.title = (media['title'] as string) || '';
    this.description = (media['description'] as string) || '';
    this.size = (details['size'] as number) || 0;
    this.rawTranscript = json['transcript'] as Document;
    this.placeholderImage = new ContentImage().deserialize(json['placeholderImage'] || json['placeholder_image']);

    return this;
  }

  get asImage(): ContentImage {
    return this.isAudio || this.isVideo ? this.placeholderImage : new ContentImage().deserialize(this.serialize());
  }

  clone(contentMedia: ContentMedia) {
    return cloneDeep(contentMedia);
  }

  serialize() {
    if (this == null) {
      return {};
    }
    return {
      file: {
        url: this.url,
        details: { size: this.size },
        fileName: this.fileName,
        contentType: this.contentType,
      },
      title: this.title,
      description: this.description,
      placeholderImage: this.placeholderImage?.serialize?.(),
    };
  }

  get isVideo(): boolean {
    return ['video/mp4'].indexOf(this.contentType) > -1;
  }

  get isAudio(): boolean {
    return ['audio/mpeg', 'audio/mp3'].indexOf(this.contentType) > -1;
  }
}
