import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DialogueBlockEvent } from '@kp/dialogue/models/dialogue-block-event.model';
import { DialogueDataStatus, DialogueStatusApiResponse } from '@kp/dialogue/models/dialogue-data-status.model';
import { Dialogue, DialogueLogicKey, KumanuKeyType } from '@kp/dialogue/models/dialogue.model';
import { Constants } from '@kp/shared/constants.service';
import { DataStoreService } from '@kservice';
import { DataStatus, JsonObject, Status, StatusMessage } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogueApi extends BaseApi {
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  async createDialogueSession(
    dialogueLogicKey: DialogueLogicKey,
    keyType: KumanuKeyType,
    refKey?: string,
    showPreviousAnswers = false
  ): Promise<Dialogue> {
    const requestBody: JsonObject = {
      logicKey: dialogueLogicKey,
      systemKey: Constants.dialogueSystemKey,
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
    };
    if (keyType === KumanuKeyType.refKey) {
      requestBody['refKey'] = refKey;
    }
    if (showPreviousAnswers) {
      requestBody['showPreviousAnswers'] = true;
    }
    const request$ = this.performRequest<Dialogue>(RequestType.POST, this.buildUrl('/dialogue', true), {
      includeToken: true,
      requestBody,
    }).pipe(
      map(
        (response: HttpResponse<Dialogue>): Dialogue =>
          response?.ok ? new Dialogue().deserialize({ ...(response?.body || {}), refKey }) : null
      ),
      catchError(handleObservableError('Failed creating dialogue session: '))
    );
    return firstValueFrom(request$).catch(handleError('Error creating dialogue session: '));
  }

  async getDialogue(dialogueId: string): Promise<Dialogue> {
    const request$ = this.performRequest<Dialogue>(RequestType.GET, this.buildUrl(`/dialogue/${dialogueId}`, true), {
      includeToken: true,
    }).pipe(
      map(
        (response: HttpResponse<Dialogue>): Dialogue =>
          response?.ok ? new Dialogue().deserialize(response?.body) : null
      ),
      catchError(handleObservableError('Failed getting dialogue session: '))
    );
    return firstValueFrom(request$).catch(handleError('Error getting dialogue session: '));
  }

  async createDialogueBlockEvent(
    event: DialogueBlockEvent,
    dialogueId: string,
    dialogueBlockId: string
  ): Promise<Dialogue> {
    const request$ = this.performRequest<Dialogue>(
      RequestType.POST,
      this.buildUrl(`/dialogue/${dialogueId}/block/${dialogueBlockId}/event`, true),
      {
        includeToken: true,
        requestBody: event.serialize(),
      }
    ).pipe(
      map((response: HttpResponse<JsonObject>) =>
        response?.ok ? new Dialogue().deserialize(response?.body?.userDialogue) : null
      ),
      catchError(handleObservableError('Failed creating dialogue event: '))
    );
    return firstValueFrom(request$).catch(handleError('Error creating dialogue event: '));
  }

  async getDialogueStatus(logicKey: DialogueLogicKey): Promise<DataStatus<DialogueDataStatus>> {
    const params = {
      logicKey,
      systemKey: Constants.dialogueSystemKey,
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
    };
    const request$ = this.performRequest<DialogueStatusApiResponse>(
      RequestType.GET,
      this.buildUrl('/dialogue/status', true, params),
      {
        includeToken: true,
      }
    ).pipe(
      map((response: HttpResponse<DialogueStatusApiResponse>) => {
        const hasDialogueData = response?.ok && response?.body != null;
        const status = hasDialogueData ? Status.done : Status.error;
        const statusMessage = new StatusMessage(
          response?.status,
          hasDialogueData ? 'OK' : 'Error getting dialogue status'
        );
        const data = hasDialogueData ? new DialogueDataStatus().deserialize(response?.body) : null;
        return new DataStatus<DialogueDataStatus>().deserialize({ status, statusMessage, data });
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = 'Error getting dialogue status';
        console.warn(errorMessage, error);
        return of(
          new DataStatus<DialogueDataStatus>(Status.error, new StatusMessage(error?.status, errorMessage), null)
        );
      })
    );
    return firstValueFrom(request$).catch(handleError('Error getting dialogue status: '));
  }
}

function handleObservableError(errorMessage: string): (error: HttpErrorResponse) => Observable<null> {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return of(null);
  };
}

function handleError(errorMessage: string): (error: HttpErrorResponse) => Promise<null> {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return new Promise(null);
  };
}
