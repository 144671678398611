import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EnvironmentVariablesService } from '@kenv';
import { PulseSurveyBloc } from '@kp/pulse-survey/pulse-survey.bloc';
import { ReturnUrlService } from '@kp/shared/services/return-url.service';
import { Product } from '@ktypes/enums';
import { Observable, of, withLatestFrom } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PulseSurveyAvailableGuard {
  constructor(
    private _environmentVariablesService: EnvironmentVariablesService,
    private _pulseSurveyBloc: PulseSurveyBloc,
    private _returnUrlService: ReturnUrlService,
    private _router: Router
  ) {}

  canActivate(/*route: ActivatedRouteSnapshot, state: RouterStateSnapshot*/): Observable<boolean | UrlTree> {
    // Short circuit this guard if user has a non-default returnUrl
    if (!this._returnUrlService.isDefaultUrl) {
      return of(true);
    }
    return this._pulseSurveyBloc.pulseSurveyCheckAllowed$.pipe(
      // only take the first once because setting allowPulseSurveyCheck to false
      // would fire this off again, and we only care about the initial state
      // until the next time through the guard via a route
      first(),
      switchMap((isAllowed) => {
        if (isAllowed) {
          this._pulseSurveyBloc.getPulseSurvey();
          this._pulseSurveyBloc.allowPulseSurveyCheckToday(false);
          return this._pulseSurveyBloc.hasPulseSurvey$.pipe(withLatestFrom(of(isAllowed)));
        }
        // may not have values if getPulseSurvey wasn't fired if
        // pulse survey check was not allowed, but allow user to proceed
        return of([true, isAllowed]);
      }),
      map(([hasPulseSurvey, isAllowed]) => {
        if (hasPulseSurvey && isAllowed) {
          if (this._environmentVariablesService.product === Product.resourceful) {
            return void this._router.navigate(['/pulse_survey']);
          }
        }
        // allow user to proceed regardless of Pulse Survey existence
        return true;
      }),
      catchError((error) => {
        console.warn('There was an error trying to check for Pulse Survey', error);
        // still allow to the page if there is an error determining if there is a Pulse Survey
        return of(true);
      })
    );
  }
}

export class MockPulseSurveyAvailableGuard {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return of(true);
  }
}
