import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class SubscriptionPlan implements Deserializable<JsonObject, SubscriptionPlan> {
  constructor(
    public id?: string,
    public planId?: string,
    public name?: string
  ) {}

  deserialize(input: JsonObject): SubscriptionPlan {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.planId = input['planId'] as string;
    this.name = input['name'] as string;

    return this;
  }
}
