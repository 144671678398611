import { Document } from '@contentful/rich-text-types';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { getTypedDeepLinkData, QuestDeepLinkData } from './link.model';
import { QuestItem } from './quest-item.model';

export enum QuestKeyType {
  logicKey = 'logicKey',
  refKey = 'refKey',
}

export class Quest implements Deserializable<JsonObject, Quest> {
  constructor(
    public id?: string,
    public presentedId?: string,
    public questItems?: QuestItem[],
    public title?: string,
    public shortSummary?: string,
    public longSummary?: Document,
    public completedId?: string
  ) {}

  deserialize(input: JsonObject): Quest {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.presentedId = input['presentedId'] as string;
    this.questItems = (input['items'] as QuestItem[]).map((item) => new QuestItem().deserialize(item));
    this.title = input['title'] as string;
    this.shortSummary = input['shortSummary'] as string;
    this.longSummary = input['longSummary'] as Document;
    this.completedId = input['completedId'] as string;
    return this;
  }

  get itemCount(): number {
    return this.questItems ? this.questItems.length : 0;
  }

  get completedItemCount(): number {
    return this.questItems ? this.questItems.filter((questItem) => questItem.card.userState.isCompleted).length : 0;
  }

  get refKeys(): string[] {
    return this.questItems
      ? (this.questItems.map((item) => {
          if (item.card.cardLink.deepLinkData != null) {
            return getTypedDeepLinkData<QuestDeepLinkData>(item.card.cardLink.deepLinkData)?.refKey;
          }
          return '';
        }) as string[])
      : [];
  }
}
