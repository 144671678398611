import { Insight } from '@kp/insights/insight.model';
import { Deserializable, Domain, JsonObject, Serializable } from '@ktypes/models';

export class Focus implements Deserializable<JsonObject, Focus>, Serializable<JsonObject> {
  constructor(
    public focus?: string,
    public focusAnswerOptionChoiceId?: string,
    public reason?: string,
    public image?: string,
    public numberOfDays?: number,
    public insights?: Insight[],
    public domain?: Domain
  ) {}

  deserialize(input: JsonObject): Focus {
    if (input == null) {
      return null;
    }
    this.focus = input['focus'] as string;
    this.focusAnswerOptionChoiceId = input['focusAnswerOptionChoiceId'] as string;
    this.reason = input['reason'] as string;
    this.image = input['image'] as string;
    this.numberOfDays = input['numberOfDays'] as number;
    this.insights = (input['insights'] as Insight[])?.map((insight) => new Insight().deserialize(insight));
    this.domain = new Domain().deserialize(input['domain']);
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['focus'] = this.focus;
    json['focusAnswerOptionChoiceId'] = this.focusAnswerOptionChoiceId;
    json['reason'] = this.reason;
    json['image'] = this.image;
    json['numberOfDays'] = this.numberOfDays;
    json['insights'] = this.insights.map((insight) => insight.serialize());
    json['domain'] = this.domain;

    return json;
  }
}
