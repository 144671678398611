import { Component, Inject, Input } from '@angular/core';
import { WINDOW } from '@kservice';
import { Product } from '@ktypes/enums';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-version-expired',
  templateUrl: './version-expired.component.html',
  styleUrls: ['./version-expired.component.scss'],
})
export class VersionExpiredComponent {
  constructor(@Inject(WINDOW) private _window: Window) {
    if (!this.appUrl) {
      this.appUrl = `${this._window?.location?.href}?t=${new Date().getTime()}&ngsw-bypass`;
    }
  }

  @Input() product: Product;
  @Input() productName: string;

  appUrl = '';
}

export const MockVersionExpiredComponent = MockComponent({
  selector: 'kui-version-expired',
  inputs: ['product', 'productName'],
});
