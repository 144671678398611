import { Injectable } from '@angular/core';
import { SharingApi } from '@kapi';
import { Invite } from '@ktypes/models';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharingBloc {
  constructor(private _sharingApi: SharingApi) {}

  private _invites$ = new BehaviorSubject<Invite[]>(null);
  private _shortLink$ = new BehaviorSubject<string>(null);
  private _challengeSharingLink$ = new BehaviorSubject<string>(null);

  get invites$(): Observable<Invite[]> {
    return this._invites$.asObservable();
  }

  get shortLink$(): Observable<string> {
    return this._shortLink$.asObservable();
  }

  get challengeSharingLink$(): Observable<string> {
    return this._challengeSharingLink$.pipe(
      filter((link) => link != null),
      distinctUntilChanged()
    );
  }

  getInvites() {
    this._sharingApi.getSharingOptions().then((invites) => {
      this._invites$.next(invites);
    });
  }

  getShortLink(link: string) {
    this._sharingApi.getShortenedSharingLink(link).then((shortLink) => {
      this._shortLink$.next(shortLink ? shortLink : link);
    });
  }

  getChallengeSharingLink(challengeId: string) {
    this._sharingApi.getChallengeSharingLink(challengeId).then((shortLink) => {
      this._challengeSharingLink$.next(shortLink);
    });
  }
}
