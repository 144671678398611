import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalResourceHoursComponent } from '@kp/local-resources/local-resource-hours/local-resource-hours.component';
import { LocalResourceLocationComponent } from '@kp/local-resources/local-resource-location/local-resource-location.component';
import { LocalResourcesCardDetailViewComponent } from '@kp/local-resources/local-resources-card-detail-view/local-resources-card-detail-view.component';
import { LocalResourcesCardMediumViewComponent } from '@kp/local-resources/local-resources-card-medium-view/local-resources-card-medium-view.component';
import { LocalResourcesSearchComponent } from '@kp/local-resources/local-resources-search/local-resources-search.component';
import { NextStepsComponent } from '@kp/local-resources/next-steps/next-steps.component';
import { SharedModule } from '@kp/shared/shared.module';

@NgModule({
  declarations: [
    LocalResourcesCardDetailViewComponent,
    LocalResourcesCardMediumViewComponent,
    LocalResourcesSearchComponent,
    LocalResourceHoursComponent,
    LocalResourceLocationComponent,
    NextStepsComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [LocalResourcesCardDetailViewComponent, LocalResourcesSearchComponent],
})
export class LocalResourcesModule {}
