import { Injectable } from '@angular/core';

// Created separate JsonObject interface to avoid circular dependency
interface JsonObject<T = any> {
  [key: string]: T;
}

export interface WindowFeatures {
  centerscreen?: boolean;
  status?: string;
  fullscreen?: boolean;
  toolbar?: boolean;
  location?: boolean;
  menubar?: boolean;
  scrollbars?: boolean;
  resizeable?: boolean;
  width?: number;
  height?: number;
  left?: number;
  top?: number;
  center?: boolean;
  position?: string;
}

export interface PopupOptions {
  url?: string;
  data?: JsonObject;
  name?: string;
  closeOnParentUnload?: boolean;
  modal?: boolean;
  autoOpen?: boolean;
  windowFeatures?: WindowFeatures;
  onOpen?: () => void;
  onClose?: () => void;
  onError?: () => void;
}
const FEATURES = ['toolbar', 'location', 'status', 'menubar', 'scrollbars', 'resizable', 'fullscreen'];
const SIZE_FEATURES = ['width', 'height', 'left', 'top'];

@Injectable()
export class Popup {
  private _options: PopupOptions;
  private _windowFeatures: string;
  popupWin: Window;

  initialize(options: PopupOptions = {}): Popup {
    if (options == null) {
      return null;
    }
    this._options = options;
    this._windowFeatures = this._stringifyWindowFeatures(options.windowFeatures);

    if (options.url && options.data != null) {
      // Add params to URL
      const urlParams = (options.data as string[]).reduce((prevValue: string, curValue: string) => {
        if (Object.prototype.hasOwnProperty.call(options.data, curValue)) {
          return (prevValue.indexOf('?') === -1 ? '?' : '&') + escape(curValue) + '=' + escape(options.data[curValue]);
        }
        return null;
      }, '');
      this._options.url += urlParams;
    }

    if (this._options.name == null || this._options.name === '') {
      this._options.name = 'popup_' + new Date().getTime();
    }

    if (this._options.windowFeatures.width == null) {
      this._options.windowFeatures.width = 500;
    }

    if (this._options.windowFeatures.height == null) {
      this._options.windowFeatures.height = 400;
    }

    if (typeof options.onClose === 'function' || options.modal) {
      const checkIfPopupWinClosed = () => {
        if (this?.popupWin?.closed) {
          const onCloseFn = this?._options?.onClose;
          clearInterval(closedCheckInterval);
          if (typeof onCloseFn === 'function') {
            onCloseFn.apply(this);
          }
        }
      };
      const closedCheckInterval = setInterval(checkIfPopupWinClosed.bind(this), 50);
    }

    // On Parent Unload
    if (options.closeOnParentUnload) {
      window.addEventListener('unload', this.close, false);
    }

    // Open
    if (options.autoOpen !== false) {
      this.open();
    }

    return this;
  }

  get closed() {
    return this.popupWin ? this.popupWin.closed : null;
  }
  open() {
    this.popupWin = window.open(this._options.url, this._options.name, this._windowFeatures);

    // On Open
    if (this.popupWin && typeof this._options.onOpen === 'function') {
      this._options.onOpen.apply(this);
    }

    // On Error
    if (!this.popupWin && typeof this._options.onError === 'function') {
      this._options.onError.apply(this);
    }
  }
  change_url(url: string) {
    try {
      this.popupWin.location.href = url;
    } catch (e) {
      console.log('Could not update url');
    }
  }
  replace_url(url: string) {
    try {
      this.popupWin.location.replace(url);
    } catch (e) {
      console.log('Could not replace url');
    }
  }
  close() {
    if (this.popupWin) {
      this.popupWin.close();
    }
  }
  focus() {
    if (this.popupWin) {
      this.popupWin.focus();
    }
  }
  center() {
    if (this.popupWin) {
      try {
        const screenWidth = screen.width;
        const screenHeight = screen.height;
        const outerWidth = this.popupWin.outerWidth;
        const outerHeight = this.popupWin.outerHeight;
        let xPos = screenWidth / 2 - outerWidth / 2;
        let yPos = screenHeight / 2 - outerHeight / 2;
        if (xPos < 0) {
          xPos = 0;
        }
        if (yPos < 0) {
          yPos = 0;
        }
        if (xPos >= screenWidth - 10) {
          xPos = screenWidth - outerWidth;
        }
        if (yPos >= screenHeight - 10) {
          yPos = screenHeight - outerHeight;
        }
        // Check permission
        this.popupWin.moveTo(xPos, yPos);
      } catch (e) {
        console.log('Could not center popup window');
      }
    }
  }
  resizeTo(width: number, height: number) {
    if (this.popupWin) {
      try {
        this.popupWin.resizeTo(width, height);
      } catch (e) {
        console.log(`Could not resize popup window to (w/h): ${width} x ${height}`);
      }
    }
  }
  maximize() {
    try {
      this.popupWin.moveTo(0, 0);
      this.popupWin.resizeTo(window.screen.availWidth, window.screen.availHeight);
    } catch (e) {
      console.log('Could not maximize popup window');
    }
  }

  /// stringify window features
  private _stringifyWindowFeatures(windowFeatures: WindowFeatures): string {
    if (!windowFeatures) {
      return '';
    }
    let featuresList = FEATURES.reduce(
      // @ts-ignore TODO: Revisit lint errors
      (prevValue, curValue) => prevValue + this._stringifyFeature(curValue, windowFeatures[curValue], false),
      ''
    );
    let sizeFeaturesList = SIZE_FEATURES.reduce(
      // @ts-ignore TODO: Revisit lint errors
      (prevValue, curValue) => prevValue + this._stringifyFeature(curValue, windowFeatures[curValue], true, '0'),
      ''
    );

    if (!this._options.windowFeatures.centerscreen) {
      featuresList += 'centerscreen=1,';
    }
    if (featuresList.substring(featuresList.length - 1) === ',') {
      featuresList = featuresList.substring(0, featuresList.length - 1);
    }
    if (sizeFeaturesList.substring(sizeFeaturesList.length - 1) === ',') {
      sizeFeaturesList = sizeFeaturesList.substring(0, sizeFeaturesList.length - 1);
    }

    if (featuresList.length > 0 && sizeFeaturesList.length > 0) {
      return `${featuresList},${sizeFeaturesList}`;
    } else if (featuresList.length > 0 && sizeFeaturesList.length === 0) {
      return featuresList;
    } else if (featuresList.length === 0 && sizeFeaturesList.length > 0) {
      return sizeFeaturesList;
    }
    return '';
  }

  private _stringifyFeature(key: string, value: string, setDefault: boolean, defaultValue?: string) {
    let keyValue = '';
    if (key != null && key !== '') {
      keyValue += `${key}=`;
      if (value != null && value !== '') {
        if (FEATURES.includes(key)) {
          if (key === 'status') {
            keyValue += value;
          } else {
            keyValue += `${value ? 1 : 0},`;
          }
        } else if (SIZE_FEATURES.includes(key)) {
          keyValue += `${value},`;
        }
      } else if (setDefault && FEATURES.includes(key)) {
        keyValue = '';
      } else if (setDefault && SIZE_FEATURES.includes(key)) {
        if (key === 'top' || key === 'left') {
          keyValue += `${defaultValue || 0},`;
        } else if (key === 'width' || key === 'height') {
          keyValue += `${defaultValue || 500},`;
        }
      } else {
        keyValue = '';
      }
    }
    return keyValue;
  }
}

@Injectable()
export class MockPopup extends Popup {
  constructor() {
    super();
  }
  initialize(options: PopupOptions = {}) {
    super.initialize(options);
    return this;
  }
  get closed() {
    return false;
  }
  /* eslint-disable @typescript-eslint/no-empty-function */
  open() {}
  change_url(url: string) {}
  replace_url(url: string) {}
  close() {}
  focus() {}
  center() {}
  maximize() {}
  /* eslint-enable @typescript-eslint/no-empty-function */
}
