import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataLink, DataLinkData } from '@kp/data-link/data-link.model';
import { DataStoreService } from '@kservice';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataLinkApi extends BaseApi {
  constructor(
    httpClient: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(httpClient, dataStoreService, environmentVariablesService);
  }

  async validateLink<DataLinkType = DataLinkData>(dataToken: string): Promise<DataLink<DataLinkData>> {
    const url = this.buildUrl('/validate/link', false, { token: dataToken });
    const request$ = this.performRequest<DataLink<DataLinkType>>(RequestType.GET, url).pipe(
      map((response) => {
        if (response?.ok) {
          return new DataLink().deserialize(response.body);
        }
        console.warn('Invalid response for data link', response);
        return null;
      }),
      catchError((error) => {
        console.warn('Caught error trying to validate link data', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error trying to validate link data', error);
      return null;
    });
  }
}
