import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class FirstVisit implements Deserializable<JsonObject, FirstVisit> {
  constructor(
    public clearedIoNotifications = false,
    public checkInCount = 0,
    public collapsedChallengeInfoIndividual = false,
    public collapsedChallengeInfoGroup = false,
    public completedReflection = false,
    public dismissedAccountCreationModal = false,
    public discover = false,
    public firstActionAdded = false,
    public firstQuestAdded = false,
    public firstTimeAppVisit = false,
    public lastModalLaunch: string = new Date(0).toISOString(),
    public notificationPrompt = false,
    public progress = false,
    public reminders = false,
    public purposefulVisits = 0, // Starts counting after acknowledging Today first visit messages
    public resourcefulVisits = 0,
    public shownExpandedLiveSupport = false,
    public shownCrossPromotionModal = false,
    public shownFeaturedPromoOn?: string,
    public shownNotificationsModal = false,
    public shownPurposefulFeedbackModal = false,
    public shownResourcefulFeedbackModal = false,
    public today = false,
    public webMigrated = false
  ) {}

  deserialize(json?: JsonObject, previousValue?: FirstVisit): FirstVisit {
    if (json != null || previousValue != null) {
      Object.assign(this, previousValue || {}, json);
    }
    return this;
  }
}
