import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnswerValuePipe } from '@kp/past-reflections/answer-value.pipe';
import { ImageAnswerIndexPipe } from '@kp/past-reflections/image-answer-index.pipe';
import { ImageWordListAnswerPipe } from '@kp/past-reflections/image-word-list-answer.pipe';
import { ImageIconListComponent } from '@kp/past-reflections/reflection-history/image-icon-list/image-icon-list.component';
import { ReflectionHistoryComponent } from '@kp/past-reflections/reflection-history/reflection-history.component';
import { ReflectionQuestionSetComponent } from '@kp/past-reflections/reflection-history/reflection-question-set/reflection-question-set.component';
import { SharedModule } from '@kp/shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    ReflectionHistoryComponent,
    ReflectionQuestionSetComponent,
    ImageIconListComponent,
    AnswerValuePipe,
    ImageWordListAnswerPipe,
    ImageAnswerIndexPipe,
  ],
  exports: [ReflectionHistoryComponent, ReflectionQuestionSetComponent],
})
export class PastReflectionsModule {
  static components = [ReflectionHistoryComponent, ReflectionQuestionSetComponent];
}
