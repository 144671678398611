import { Injectable } from '@angular/core';
import { Constants } from '@kp/shared/constants.service';
import { BrowserStorage } from '@kservice';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class BrowserCheckService {
  constructor(
    private _deviceDetector: DeviceDetectorService,
    private _browserStorage: BrowserStorage
  ) {}

  checkBrowser(): boolean {
    const browser = this.deviceInfo.browser;
    const os = this.deviceInfo.os;
    const version = this.deviceInfo.browser_version;

    const filteredSupportedBrowsers = Constants.supportedBrowsers.filter(
      (data) =>
        ((data.os.includes(os) || data.os.length === 0) &&
          data.name === browser &&
          data.matchExact &&
          data.version === parseInt(version, 10)) ||
        ((data.os.includes(os) || data.os.length === 0) &&
          data.name === browser &&
          !data.matchExact &&
          parseInt(version, 10) >= data.version)
    );

    const unsupportedBrowserStorage = !!this._browserStorage.getObject('unsupportedBrowser');

    if (filteredSupportedBrowsers.length === 0 && !unsupportedBrowserStorage) {
      this._browserStorage.setObject('unsupportedBrowser', true);
      return false;
    } else if (filteredSupportedBrowsers.length === 0 && unsupportedBrowserStorage) {
      return true;
    } else {
      this._browserStorage.remove('unsupportedBrowser');
      return true;
    }
  }

  get deviceInfo(): DeviceInfo {
    return this._deviceDetector.getDeviceInfo();
  }
}
