import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService {
  /**
   * To use for any route: const isRouteValid = this.routerHelperService.isRouteValid(ROUTE);
   * For the current route: const isRouteValid = this.routerHelperService.isRouteValid();
   *
   * NOTE: This may not pick up child routes that are loaded asynchronously
   */
  private validRouteRegices: string[];

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router
  ) {
    const validRoutes: string[] = [];

    // router.config will not change so lets cache
    // get all routes and child routes
    this.router.config.forEach((route) => {
      const routePath: string = route.path;
      validRoutes.push(routePath);
      const routeChildren = route.children || [];
      routeChildren.forEach((routeChild) => {
        const routeChildPath: string = route.path + '/' + routeChild.path;
        validRoutes.push(routeChildPath);
      });
    });

    // swap routes for regices to support URL params and tidy up a little
    this.validRouteRegices = validRoutes
      .map((route) => (route.startsWith('/') ? route.replace('/', '') : route))
      .map((route) => route.replace(/\/:[a-zA-Z]+/g, '/[a-zA-Z0-9]+'))
      .filter((route) => route !== '' && route !== '**')
      .map((route) => '^' + route + '$');
  }

  // call this to check if a route exists or not
  isRouteValid(pathname = this.window.location.pathname): boolean {
    let match = false;
    const locationPathname = pathname.startsWith('/') ? pathname.replace('/', '') : pathname;
    this.validRouteRegices.forEach((strValidRouteRegex: string) => {
      const validRouteRegex = new RegExp(strValidRouteRegex);
      if (validRouteRegex.test(locationPathname)) match = true;
    });
    return match;
  }
}
