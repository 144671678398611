<div class="site-map-container">
  <h1 class="site-map-title">{{ 'Site Map' | translate | async }}</h1>
  <p class="site-map-instructions">
    {{
      'Select a link from the options below to navigate directly to the corresponding page in the app.'
        | translate
        | async
    }}
  </p>
  <div class="section-wrap">
    @if (mainCategory$ | async; as mainCategory) {
      <section class="section-main">
        <h2 class="section-heading">{{ 'Main ' + productDisplayName + ' Sections' | translate | async }}</h2>
        <ul>
          @for (navItem of mainCategory; track navItem) {
            <li>
              <h3 class="page-main">
                @if (navItem.icon) {
                  <svg-icon class="map-link-icon" [src]="navItem.icon"></svg-icon>
                }
                @if (navItem.link) {
                  <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                    {{ navItem.mapTitle || navItem.title | translate | async }}
                  </a>
                } @else {
                  <span class="map-link-text">
                    {{ navItem.mapTitle || navItem.title | translate | async }}
                  </span>
                }
              </h3>
              @if (navItem.children?.length > 0) {
                <ul>
                  @for (navChild of navItem.children; track navChild) {
                    <li>
                      @if (navChild.link) {
                        <a class="map-link" [routerLink]="navChild.link" [queryParams]="navChild.queryParams">
                          {{ navChild.mapTitle || navChild.title | translate | async }}
                        </a>
                      } @else {
                        <span class="map-link-text">
                          {{ navChild.mapTitle || navChild.title | translate | async }}
                        </span>
                      }
                    </li>
                  }
                </ul>
              }
            </li>
          }
        </ul>
      </section>
    }
    @if (
      {
        quickActions: (quickActions$ | async),
        features: (features$ | async),
        support: (support$ | async)
      };
      as secondary
    ) {
      @if (secondary.quickActions?.length > 0 || secondary.features?.length > 0 || secondary.support?.length > 0) {
        <section class="section-secondary">
          <h2 class="section-heading">{{ 'Additional Sections' | translate | async }}</h2>
          <ul>
            @if (secondary.quickActions?.[0]; as navItem) {
              <li>
                <h3 class="page-main">
                  @if (navItem.link) {
                    <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                      {{ navItem.mapTitle || navItem.title | translate | async }}
                    </a>
                  } @else {
                    <span class="map-link-text">
                      {{ navItem.mapTitle || navItem.title | translate | async }}
                    </span>
                  }
                </h3>
                @if (navItem.children?.length > 0) {
                  <ul>
                    @for (navChild of navItem.children; track navChild) {
                      <li>
                        @if (navChild.link) {
                          <a class="map-link" [routerLink]="navChild.link" [queryParams]="navChild.queryParams">
                            {{ navChild.mapTitle || navChild.title | translate | async }}
                          </a>
                        } @else {
                          <span class="map-link-text">
                            {{ navChild.mapTitle || navChild.title | translate | async }}
                          </span>
                        }
                      </li>
                    }
                  </ul>
                }
              </li>
            }
            @if (secondary.features?.length > 0) {
              <li>
                <h3 class="page-main">{{ 'Features' | translate | async }}</h3>
                <ul>
                  @for (navItem of secondary.features; track navItem) {
                    <li>
                      @if (navItem.link) {
                        <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                          {{ navItem.mapTitle || navItem.title | translate | async }}
                        </a>
                      } @else {
                        <span class="map-link-text">
                          {{ navItem.mapTitle || navItem.title | translate | async }}
                        </span>
                      }
                    </li>
                  }
                </ul>
              </li>
            }
            @if (secondary.support?.length > 0) {
              <li>
                <h3 class="page-main">{{ 'Support' | translate | async }}</h3>
                <ul>
                  @for (navItem of secondary.support; track navItem) {
                    <li>
                      @if (navItem.link) {
                        <a class="map-link" [routerLink]="navItem.link" [queryParams]="navItem.queryParams">
                          {{ navItem.mapTitle || navItem.title | translate | async }}
                        </a>
                      } @else {
                        <span class="map-link-text">
                          {{ navItem.mapTitle || navItem.title | translate | async }}
                        </span>
                      }
                    </li>
                  }
                </ul>
              </li>
            }
          </ul>
        </section>
      }
    }
  </div>
</div>
