import { JsonValue } from '@angular-devkit/core';
import { Component, Directive, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import Mock = jest.Mock;
import SpyInstance = jest.SpyInstance;

export class SpecUtil {
  static setupMock<MockObject, ReturnValue>(
    objectToMock: MockObject,
    key: any,
    returnValue: ReturnValue,
    isProperty = false
  ) {
    // Note / Todo: using explicit any instead of ReturnValue due to typing issues
    // but should try to figure out so the return value is properly typed and remove the explicit any usages
    return isProperty
      ? jest.spyOn<MockObject, typeof key>(objectToMock, key, 'get').mockReturnValue(returnValue as any)
      : jest.spyOn<MockObject, typeof key>(objectToMock, key).mockReturnValue(returnValue as any);
  }

  /**
   * Source:
   *  - https://stackoverflow.com/a/64560773/228429 (info)
   *  - https://stackoverflow.com/a/64785113/228429 (function)
   */
  static spyPropertyGetter<T, K extends keyof T>(
    spyInstance: jest.SpyInstance<T>,
    propName: K
  ): jest.SpyInstance<() => T[K]> {
    return Object.getOwnPropertyDescriptor(spyInstance, propName)?.get as unknown as jest.SpyInstance<() => T[K]>;
  }
}

/**
 * Examples:
 * MockComponent({ selector: 'k-mock' });
 */
export function MockComponent(options: Component): Component {
  const metadata = { ...options };
  metadata.template = metadata.template || '';
  metadata.inputs = metadata.inputs || [];
  metadata.outputs = metadata.outputs || [];
  metadata.exportAs = metadata.exportAs || '';
  metadata.providers = options.providers || [];

  class Mock {}

  metadata.outputs?.forEach?.((method) => {
    // @ts-ignore TODO: Revisit lint issues
    Mock.prototype[method] = new EventEmitter<any>();
  });

  return Component(metadata)(Mock as any) as Component;
}

/**
 * Examples:
 * MockDirective({ selector: '[k-mock]' });
 */
export function MockDirective(options: Component): Directive {
  const metadata: Directive = { ...options };
  metadata.selector = options.selector || '';
  metadata.inputs = options.inputs || [];
  metadata.outputs = options.outputs || [];
  metadata.providers = options.providers || [];

  class Mock {}

  metadata.outputs?.forEach?.((method) => {
    // @ts-ignore TODO: Revisit lint issues
    Mock.prototype[method] = new EventEmitter<any>();
  });

  return Directive(metadata)(Mock as any) as Directive;
}

/**
 * Examples:
 * MockPipe('kPipe');
 */
export function MockPipe(name: string): Pipe {
  const metadata: Pipe = { name };

  class Mock implements PipeTransform {
    transform(input: any): any {
      return input;
    }
  }

  return Pipe(metadata)(Mock as any) as Pipe;
}

/**
 * Examples:
 * MockAsyncPipe('kAsyncPipe', 'Promise');
 */
export function MockAsyncPipe(name: string, asyncType: 'Promise' | 'Observable' = 'Observable'): Pipe {
  const metadata: Pipe = { name };

  class Mock implements PipeTransform {
    transform(input: any): any {
      if (asyncType === 'Promise') {
        return Promise.resolve(input);
      } else if (asyncType === 'Observable') {
        return of(input);
      }
    }
  }

  return Pipe(metadata)(Mock as any) as Pipe;
}

export interface CreateSpyObjOptions {
  methodsCallThrough: boolean;
}
/**
 * @name createObjWithMocks
 *  - pass an optional base object, methods and properties to mock, and get back an object with mocks
 *  - options: methodsCallThrough (defaults false)
 *
 * @param baseObj
 * @param methodsToMock
 * @param propertiesToMock
 * @param options
 *
 * @example
 *   createObjWithMocks(console, ['log', 'warn']);
 */
export function createObjWithMocks(
  baseObj: any,
  methodsToMock?: string[],
  propertiesToMock?: { [key: string]: JsonValue | Observable<any> },
  options?: CreateSpyObjOptions
): SpyInstance<typeof baseObj, []>;
export function createObjWithMocks(
  baseObj: any,
  methodsToMock?: { [key: string]: JsonValue | Observable<any> },
  propertiesToMock?: string[],
  options?: CreateSpyObjOptions
): SpyInstance<typeof baseObj, []>;
export function createObjWithMocks(
  baseObj: any,
  methodsToMock?: string[],
  propertiesToMock?: string[],
  options?: CreateSpyObjOptions
): SpyInstance<typeof baseObj, []>;
export function createObjWithMocks(
  baseObj: any,
  methodsToMock?: { [key: string]: JsonValue | Observable<any> },
  propertiesToMock?: { [key: string]: JsonValue | Observable<any> },
  options?: CreateSpyObjOptions
): SpyInstance<typeof baseObj, []>;
export function createObjWithMocks(
  baseObj: any = {},
  methodsToMock?: string[] | { [key: string]: JsonValue | Observable<any> },
  propertiesToMock?: string[] | { [key: string]: JsonValue | Observable<any> },
  options: CreateSpyObjOptions = { methodsCallThrough: false }
): SpyInstance<typeof baseObj, []> {
  const propertiesHaveValues = !Array.isArray(propertiesToMock);
  const propertyKeys = propertiesHaveValues ? Object.keys(propertiesToMock ?? {}) : [...(propertiesToMock ?? [])];
  const methodsHaveValues = !Array.isArray(methodsToMock);
  const methodKeys = methodsHaveValues ? Object.keys(methodsToMock || {}) : [...(methodsToMock ?? [])];
  const properties: { [key: string]: Mock<any> } = propertyKeys.reduce((propertyAcc, propertyName) => {
    const prop = Object.defineProperty(propertyAcc, propertyName, {
      get: propertiesHaveValues ? jest.fn(() => propertiesToMock[propertyName]) : jest.fn(),
    });
    return { ...propertyAcc, [propertyName]: prop };
  }, {});
  const methods: { [key: string]: Mock<any> } = methodKeys.reduce((methodAcc, methodName) => {
    return {
      ...methodAcc,
      [methodName]:
        options.methodsCallThrough && !methodsHaveValues
          ? jest.fn()
          : jest.fn(() => {
              if (methodsHaveValues) {
                return methodsToMock[methodName];
              }
              return null;
              /* else no-op; return void; */
            }),
    };
  }, {});
  return jest.mocked<typeof baseObj>({ ...baseObj, ...methods, ...properties }) as SpyInstance<typeof baseObj, []>;
}
