<div class="options">
  @if (!isImageIconSelection) {
    <div class="select-options">
      <p class="select-statement">{{ getSelectStatementString(min, max) | translate | async }}</p>
    </div>
  }
  @if (isWordlist) {
    <div class="diamond-answer-widget">
      @for (optionRow of optionRows; track optionRow) {
        <div
          class="diamond-row"
          [ngClass]="{
            'word-list-top-row': optionRow.length < endRow1 && optionRow.length % 2 !== 0,
            'two-diamond-top-row': optionRow.length < endRow1 && optionRow.length % 2 === 0
          }">
          @for (option of optionRow | slice: startRow1 : endRow1; track option) {
            <kp-diamond
              [text]="option.text | translate | async"
              [isSelected]="option.isSelected"
              [isDisabled]="!isLessThanMax() && max !== 1"
              [appearDisabled]="!isLessThanMax() && !option.isSelected"
              (click)="onSelect(option)"></kp-diamond>
          }
        </div>
        @if (optionRow[startRow2] != null) {
          <div
            class="diamond-row"
            [ngClass]="{ 'word-list-bottom-row': optionRow.length < endRow2 && optionRow.length % 2 === 0 }">
            @for (option of optionRow | slice: startRow2 : endRow2; track option) {
              <kp-diamond
                [text]="option.text | translate | async"
                [isSelected]="option.isSelected"
                [isDisabled]="!isLessThanMax() && max !== 1"
                [appearDisabled]="!isLessThanMax() && !option.isSelected"
                (click)="onSelect(option)"></kp-diamond>
            }
          </div>
        }
      }
    </div>
  }

  @if (!isWordlist && (isImageIconSelection || isImageWordList || isImageTile)) {
    <div
      class="wordlist-icon-states"
      role="region"
      [attr.aria-label]="'icon buttons' | translate | async"
      [ngClass]="isExpanded ? 'is-expanded' : rowsToShow > 0 ? 'rows-' + rowsToShow : ''">
      @for (option of displayOptions; track option) {
        <kp-image-icon
          class="wordlist-icon-item"
          [showTextUnderImage]="isImageWordList || isImageTile"
          [option]="option"
          [isSelected]="option.isSelected"
          [isDisabled]="!isLessThanMax() && max !== 1"
          [questionType]="questionType"
          [appearDisabled]="!isLessThanMax() && !option.isSelected"
          (imageAnswer)="onSelect(option)"></kp-image-icon>
      }
    </div>
  }
  @if (isImageWordList && !isExpanded && displayOptions?.length > minOptions) {
    <button class="button button-pill button-secondary" (click)="isExpanded = true">
      {{ 'Show all' | translate | async }}
      <svg-icon class="dropdown-arrow" src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
    </button>
  }
</div>
