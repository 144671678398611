<div class="time-picker-container">
  @if (timeOfDay) {
    <div class="title">
      <svg-icon class="icon" [src]="svgURL"></svg-icon>
      {{ timeOfDay + ' Reminders' | translate | async }}
    </div>
  }
  <div class="picker">
    <div class="select-time">
      <button
        class="time-adjust"
        [attr.aria-label]="'Increment hour' | translate | async"
        (click)="adjustTime(timeProperty.hour, 1)">
        <svg-icon src="/assets/icons/chevron-left-nocircle.svg"></svg-icon>
      </button>
      @if (showHourInput) {
        <input
          class="time-input"
          #hourInput
          type="number"
          [value]="hour"
          [min]="minHour"
          [max]="maxHour"
          (blur)="inputTime(timeProperty.hour, hourInput.value)"
          (keyup.enter)="inputTime(timeProperty.hour, hourInput.value)" />
      }
      @if (!showHourInput) {
        <button
          class="time-adjust"
          [attr.aria-label]="'Edit hour' | translate | async"
          (click)="showTimePropertyInput(timeProperty.hour)">
          {{ hour | translate | async }}
        </button>
      }
      <button
        class="time-adjust"
        [attr.aria-label]="'Decrement hour' | translate | async"
        (click)="adjustTime(timeProperty.hour, -1)">
        <svg-icon src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
      </button>
    </div>
    <div class="select-time">:</div>
    <div class="select-time">
      <button
        class="time-adjust"
        [attr.aria-label]="'Increment minutes (15 minute increments)' | translate | async"
        (click)="adjustTime(timeProperty.minutes, 1)">
        <svg-icon src="/assets/icons/chevron-left-nocircle.svg"></svg-icon>
      </button>
      @if (showMinInput) {
        <input
          class="time-input"
          #minutesInput
          type="number"
          [value]="minutes"
          min="00"
          max="45"
          step="15"
          (blur)="inputTime(timeProperty.minutes, minutesInput.value)"
          (keyup.enter)="inputTime(timeProperty.minutes, minutesInput.value)" />
      }
      @if (!showMinInput) {
        <button
          class="time-adjust"
          [attr.aria-label]="'Edit minutes (15 minute increments)' | translate | async"
          (click)="showTimePropertyInput(timeProperty.minutes)">
          {{ minutes | translate | async }}
        </button>
      }
      <button
        class="time-adjust"
        [attr.aria-label]="'Decrement minutes (15 minute increments)' | translate | async"
        (click)="adjustTime(timeProperty.minutes, -1)">
        <svg-icon src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
      </button>
    </div>
    @if (!is24HrFormat) {
      <div class="select-time">
        <button
          class="time-adjust"
          [attr.aria-label]="'Toggle AM PM' | translate | async"
          (click)="adjustTime(timeProperty.amPM, 1)">
          <svg-icon src="/assets/icons/chevron-left-nocircle.svg"></svg-icon>
        </button>
        <button
          class="time-adjust"
          [attr.aria-label]="'Toggle AM PM' | translate | async"
          (click)="adjustTime(timeProperty.amPM, 1)">
          {{ AMPM | translate | async }}
        </button>
        <button
          class="time-adjust"
          [attr.aria-label]="'Toggle AM PM' | translate | async"
          (click)="adjustTime(timeProperty.amPM, -1)">
          <svg-icon src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
        </button>
      </div>
    }
    <kui-a11y-live-announce [announcement]="timeChangeAnnouncement"></kui-a11y-live-announce>
  </div>
  <div class="button-row">
    <button class="button-cancel" (click)="closeClicked.emit()">{{ 'Close' | translate | async }}</button>
    <button
      class="button button-reverse button-pill button-save"
      [attr.aria-label]="'Save reminder time: ' + hour + ':' + minutes + (is24HrFormat ? '' : ' ' + AMPM)"
      (click)="save()">
      {{ 'Save' | translate | async }}
    </button>
  </div>
</div>
