import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LandingSection, LandingSectionApiResponse } from './landing-section.model';

export interface LandingPageApiResponse {
  buttonText?: string;
  pulseSurveyId?: string;
  content?: LandingSectionApiResponse[];
}

export class LandingPage implements Deserializable<JsonObject, LandingPage> {
  constructor(
    public buttonText?: string,
    public pulseSurveyId?: string,
    public content?: LandingSection[]
  ) {}

  deserialize(input: LandingPageApiResponse): LandingPage {
    if (input == null) {
      return null;
    }

    this.buttonText = input['buttonText'];
    this.pulseSurveyId = input['pulseSurveyId'];

    if (Array.isArray(input['content'])) {
      this.content = input['content'].map((section) => new LandingSection().deserialize(section));
    }

    return this;
  }
}
