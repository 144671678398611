@if (options) {
  @if (options.type === simpleIconAnimationType.rive) {
    <canvas
      class="animation-canvas"
      [ngStyle]="{
        filter: 'brightness(0) saturate(100%) ' + filterString
      }"
      [riv]="options.fileName"
      [artboard]="options.artboardName"
      [height]="options.height"
      [width]="options.width">
      <riv-player
        [name]="options.animationName"
        [play]="iconClicked"
        [speed]="iconShouldFill ? options.forwardSpeed : options.reverseSpeed"
        [time]="isInitiallyFilled ? options.endTime : options.startTime"></riv-player>
    </canvas>
  }
  @if (options.type === simpleIconAnimationType.svg) {
    <div class="animation-canvas" [ngClass]="{ 'fill-icon': iconShouldFill }">
      <svg-icon class="icon" [src]="options.fileName"></svg-icon>
    </div>
  }
}
