import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Answer } from '@kp/question-set/models/answer.model';
import { QuestionSetHistoryRequest } from '@kp/question-set/models/question-set-history-request.model';
import { QuestionSetSource } from '@kp/question-set/models/question-set-source.model';
import { QuestionSet } from '@kp/question-set/models/question-set.model';
import { Question } from '@kp/question-set/models/question.model';
import { QuestionSetApi } from '@kp/question-set/question-set.api';
import { QuestionApi } from '@kp/question-set/question/question.api';
import { DataStatus, JsonObject, Status, StatusMessage } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionSetBloc {
  constructor(
    private _questionSetApi: QuestionSetApi,
    private _questionApi: QuestionApi
  ) {}

  get reflectionCompleteSubject$(): Observable<boolean> {
    return this._reflectionCompleteSubject.asObservable();
  }

  get questionSetHistoryRequestSubject$(): Observable<DataStatus<QuestionSet[]>> {
    return this._questionSetHistoryRequestSubject.asObservable();
  }

  private _reflectionCompleteSubject = new BehaviorSubject<boolean>(null);
  private _updateSubject = new BehaviorSubject<Status>(Status.done);
  private _questionSetHistoryRequestSubject = new BehaviorSubject<DataStatus<QuestionSet[]>>(null);
  private _answerSavingSubject = new BehaviorSubject<DataStatus<JsonObject<Question>>>(
    new DataStatus<JsonObject<Question>>(Status.done, null, null)
  );
  private _localQuestionAnswerJson: JsonObject<Question> = {};

  private _completedReflectionStatus = new BehaviorSubject<boolean>(false);

  getQuestionSetHistory(questionSetHistoryRequest: QuestionSetHistoryRequest): void {
    this._questionSetHistoryRequestSubject.next(new DataStatus(Status.starting, null, null));
    this._questionSetApi
      .getQuestionSets(
        questionSetHistoryRequest.refKeys,
        questionSetHistoryRequest.distinctOnRefKey,
        questionSetHistoryRequest.filter,
        questionSetHistoryRequest.dateStart,
        questionSetHistoryRequest.dateEnd,
        questionSetHistoryRequest.page,
        questionSetHistoryRequest.count,
        questionSetHistoryRequest.type
      )
      .then((questionSets: QuestionSet[]) => {
        this._questionSetHistoryRequestSubject.next(new DataStatus(Status.done, null, questionSets));
      })
      .catch((error: HttpErrorResponse) => {
        this._questionSetHistoryRequestSubject.next(
          new DataStatus(Status.error, new StatusMessage(Status.error, error.error), null)
        );
      });
  }

  getDailyReflectionHistory(): void {
    this._questionSetHistoryRequestSubject.next(new DataStatus(Status.starting, null, null));
    this._questionSetApi
      .getNearestQuestionSets(
        new Date(),
        DateTimeUtil.earliestReflectionToday(),
        QuestionSetSource.dailyReflections,
        1,
        1
      )
      .then((questionSets) => {
        this._questionSetHistoryRequestSubject.next(new DataStatus(Status.done, null, questionSets));
      });
  }

  async updateQuestion(question: Question, skipped = false): Promise<void> {
    if (question) {
      this._updateSubject.next(Status.starting);
      this._answerSavingSubject.next(new DataStatus(Status.starting, null, null));
      if (!question.answer || skipped) {
        // Send empty answer with timestamp for analytics and data integrity purposes
        question.answer = new Answer().deserialize({
          deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
        });
      }
      await this._questionApi.updateQuestion(question);
      this._localQuestionAnswerJson[question.id] = question;
      this._answerSavingSubject.next(new DataStatus(Status.done, null, this._localQuestionAnswerJson));
      this._updateSubject.next(Status.done);
      this._reflectionCompleteSubject.next(true);
    }
  }

  firstReflectionCompleted() {
    if (!this._completedReflectionStatus.value) {
      this._completedReflectionStatus.next(true);
    }
  }

  get firstReflectionCompleted$(): Observable<boolean> {
    return this._completedReflectionStatus.asObservable();
  }

  resetReflectionCompleteSubject() {
    this._reflectionCompleteSubject.next(false);
  }
}
