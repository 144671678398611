import { CardItem, Deserializable, JsonObject } from '@ktypes/models';
import { EnumUtil } from '@kutil';

export enum SearchType {
  reflect_generated = 'reflect_generated',
  intention = 'intention',
  user_entered = 'user_entered',
  predefined = 'predefined',
}

export class Search implements Deserializable<JsonObject, Search> {
  constructor(
    public id?: string,
    public foundMatch?: boolean,
    public searchText?: string,
    public searchResults?: CardItem[],
    public searchType?: SearchType,
    public searchDisplayText?: string
  ) {}

  deserialize(json: JsonObject) {
    if (json == null) {
      return null;
    }

    this.id = json['id'] as string;
    this.foundMatch = !!json['foundMatch'];
    this.searchText = json['searchText'] as string;
    this.searchResults =
      (Array.isArray(json['searchResults']) &&
        json['searchResults'].map((searchResult) => new CardItem().deserialize(searchResult))) ||
      null;
    this.searchType = EnumUtil.getByValue<SearchType, string>(SearchType, json['searchType']);
    this.searchDisplayText = json['searchDisplayText'] as string;
    return this;
  }

  copyWith(searchType: SearchType) {
    return new Search(
      this.id,
      this.foundMatch,
      this.searchText,
      this.searchResults,
      searchType || this.searchType,
      this.searchDisplayText
    );
  }
}
