export enum LoginError {
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  InvalidPasswordException = 'InvalidPasswordException',
  LimitExceededException = 'LimitExceededException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  UsernameExistsException = 'UsernameExistsException',
  CodeDeliveryFailureException = 'CodeDeliveryFailureException',
  AliasExistsException = 'AliasExistsException',
  EmailVerificationRequired = 'EmailVerificationRequired',
  MfaVerificationRequired = 'MfaVerificationRequired',
}

export const LOGIN_ERRORS: string[] = Object.values(LoginError);
