import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentVariablesService } from '@kenv';
import { DataLinkApi } from '@kp/data-link/data-link.api';
import { DataLink, DataLinkData, DataLinkSource, PulseSurveyData } from '@kp/data-link/data-link.model';
import { BrowserStorage } from '@kservice';
import { HttpStatusCode, Product } from '@ktypes/enums';
import { DataStatus, JsonObject, Status, StatusMessage } from '@ktypes/models';
import { assertIsOfType } from '@kutil';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataLinkBloc {
  constructor(
    private _browserStorage: BrowserStorage,
    private _dataLinkApi: DataLinkApi,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {}

  private static _isExternalPulseSurvey: boolean = null;
  public static set isExternalPulseSurvey(value: boolean) {
    if (value !== DataLinkBloc._isExternalPulseSurvey) {
      // using window directly due to static nature
      if (value) {
        DataLinkBloc._isExternalPulseSurvey = value;
        if (typeof window?.localStorage?.setItem === 'function') {
          window.localStorage.setItem('isExternalPulseSurvey', JSON.stringify(value));
        }
      } else {
        delete DataLinkBloc._isExternalPulseSurvey;
        if (typeof window?.localStorage?.removeItem === 'function') {
          window.localStorage.removeItem('isExternalPulseSurvey');
        }
      }
    }
  }
  public static get isExternalPulseSurvey() {
    if (DataLinkBloc._isExternalPulseSurvey == null) {
      // using window directly due to static nature
      DataLinkBloc._isExternalPulseSurvey =
        !!JSON.parse(window?.localStorage?.getItem?.('isExternalPulseSurvey')) ?? false;
    }
    return DataLinkBloc._isExternalPulseSurvey;
  }

  private _dataLinkStatus = new BehaviorSubject<DataStatus<DataLink>>(null);

  private _justFollowedADataLink = new BehaviorSubject<boolean>(false);

  get dataLinkStatus$(): Observable<DataStatus<DataLink>> {
    return this._dataLinkStatus.asObservable();
  }

  get currentDataLink(): DataLink {
    return this._dataLinkStatus.value?.data;
  }

  get hasDataLink(): boolean {
    return this._dataLinkStatus.value != null;
  }

  get justFollowedADataLink(): boolean {
    return this._justFollowedADataLink.value;
  }

  validateDataLink(dataToken: string) {
    this._dataLinkStatus.next(new DataStatus(Status.starting, null, null));
    this._dataLinkApi.validateLink(dataToken).then(this._dataLinkValid.bind(this), this._dataLinkError.bind(this));
  }

  parseQueryParams(queryParams: Params) {
    let { source, ...data } = queryParams;
    if (!source && Object.keys(queryParams || {})?.includes('pulseSurveyId')) {
      // Classic pulse survey links didn't include a source query param
      source = DataLinkSource.pulse_survey;
    }
    const dataLink = new DataLink().deserialize({ source: source as DataLinkSource, data });
    if (dataLink != null && dataLink.source != null && dataLink.data != null) {
      this._dataLinkValid(dataLink);
    } else {
      this._dataLinkError();
    }
  }

  saveLinkData(linkData: JsonObject) {
    if (!linkData) {
      return this.clearLinkData();
    }
    this._browserStorage.setObject('externalLinkData', linkData);
  }

  loadSavedLinkData() {
    const savedLinkData = this._browserStorage.getObject('externalLinkData') as DataLink;
    if (!savedLinkData) {
      return false;
    }
    this.parseQueryParams({ source: savedLinkData.source, ...savedLinkData.data });
    return true;
  }

  clearLinkData(clearIsExternalPulseSurveyFlag = true) {
    this._browserStorage.remove('externalLinkData');
    if (clearIsExternalPulseSurveyFlag) {
      DataLinkBloc.isExternalPulseSurvey = false;
    }
  }

  private _dataLinkValid(dataLink: DataLink) {
    const pulseSurveyId = this._browserStorage.getItem('pulseSurveyId') as string;
    if (pulseSurveyId && dataLink?.data) {
      assertIsOfType<PulseSurveyData, DataLinkData>(dataLink.data, 'pulseSurveyId');
      dataLink.data.pulseSurveyId = pulseSurveyId;
    }
    DataLinkBloc.isExternalPulseSurvey = this.getIsExternalPulseSurvey(dataLink);
    if (dataLink != null) {
      this._dataLinkStatus.next(new DataStatus(Status.done, new StatusMessage(HttpStatusCode.OK, 'OK'), dataLink));
      this._justFollowedADataLink.next(true);
    } else {
      this._dataLinkError();
    }
  }

  private getIsExternalPulseSurvey(dataLink: DataLink) {
    try {
      assertIsOfType<PulseSurveyData, DataLinkData>(dataLink.data, 'pulseSurveyId');
      return (
        dataLink?.source === DataLinkSource.pulse_survey ||
        ([DataLinkSource.account_creation, DataLinkSource.resources].includes(dataLink?.source) &&
          this._environmentVariablesService.product === Product.resourceful &&
          dataLink?.data?.pulseSurveyId != null)
      );
    } catch (e) {
      return false;
    }
  }

  private _dataLinkError() {
    DataLinkBloc.isExternalPulseSurvey = false;
    this._dataLinkStatus.next(
      new DataStatus(Status.error, new StatusMessage(HttpStatusCode.BAD_REQUEST, 'invalid token'), null)
    );
  }

  resetJustFollowedADataLink() {
    this._justFollowedADataLink.next(false);
  }
}
