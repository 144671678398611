@if (!showConfirmDeletion) {
  <button
    class="icon-delete-button"
    [attr.aria-label]="'Delete photo' | translate | async"
    (keyup.enter)="deleteImageIntent()"
    (keyup.space)="deleteImageIntent()"
    (click)="deleteImageIntent()">
    <svg-icon class="icon-delete" src="assets/icons/trash.svg"></svg-icon>
  </button>
}
<kp-carousel class="image-carousel" [options]="sliderOptions" [plugins]="[MutationPlugin, ResizePlugin]">
  @for (image of images; track image) {
    <div class="carousel-image-wrap">
      <img
        class="carousel-image"
        [src]="image?.urlOptimized"
        [alt]="('Uploaded image, original filename was' | translate | async) + ' ' + image?.originalFilename" />
    </div>
  }
</kp-carousel>
@if (!showConfirmDeletion) {
  <kp-bottom-arrow-nav
    class="bottom-nav"
    [showsBackButton]="showNavButtons"
    (backClick)="slider?.prev?.()"
    backAriaLabel="Previous image"
    [showsNextButton]="showNavButtons"
    (nextClick)="slider?.next?.()"
    nextAriaLabel="Next image"
    [showsContent]="true"
    [showFade]="false">
    <a
      routerLink="/me/photos"
      class="link-view-all"
      aria-label="View all user images"
      (click)="checkCurrentPage($event)">
      {{ 'View All' | translate | async }}
    </a>
  </kp-bottom-arrow-nav>
}

@if (showConfirmDeletion) {
  <div class="confirm-deletion-popup">
    <p class="confirm-remove-question" aria-live="polite">
      {{ 'Are you sure you want to remove this image from ' + product + '?' | translate | async }}
    </p>
    <div class="delete-confirmation-buttons">
      <button
        class="button-delete-cancel"
        [attr.aria-label]="'Cancel image deletion' | translate | async"
        (keyup.enter)="deleteImage(false)"
        (keyup.space)="deleteImage(false)"
        (click)="deleteImage(false)"
        [disabled]="deletingImage">
        {{ 'Cancel' | translate | async }}
      </button>
      <button
        class="button button-pill button-reverse button-w-icon button-delete-confirm"
        [attr.aria-label]="'Remove image' | translate | async"
        (keyup.enter)="deleteImage(true)"
        (keyup.space)="deleteImage(true)"
        (click)="deleteImage(true)"
        [disabled]="deletingImage">
        <svg-icon src="assets/icons/trash.svg" class="button-icon"></svg-icon>
        <span>{{ 'Remove' | translate | async }}</span>
      </button>
    </div>
  </div>
}
