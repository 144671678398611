import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService, SharedConstants } from '@kservice';
import { firstValueFrom, of, retry } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslationLanguage, TranslationLanguageResponse } from './models/translation-language.model';
import { Translation } from './models/translation.model';

interface TranslateApiRequest {
  text: string | string[];
  languageCode: string;
  format?: 'html' | 'text';
}

@Injectable({
  providedIn: 'root',
})
export class TranslationApi extends BaseApi {
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  async translate(
    text: string | string[],
    languageCode: string,
    format: 'text' | 'html' = 'text'
  ): Promise<Translation[]> {
    const requestBody: TranslateApiRequest = {
      text,
      languageCode,
      format,
    };
    const request$ = this.performRequest<Translation[]>(RequestType.POST, this.buildUrl('/translate', true), {
      requestBody,
      includeToken: true,
    }).pipe(
      retry(2),
      map((response: HttpResponse<Translation[]>): Translation[] =>
        response.body?.map?.((translation) => new Translation().deserialize(translation))
      ),
      catchError((err) => {
        console.warn('Failed getting translation: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting translation: ', error);
      return null;
    });
  }

  async getSupportedLanguages(languageCode = SharedConstants.DEFAULT_LANGUAGE): Promise<TranslationLanguage[]> {
    const queryParams = { languageCode };
    const request$ = this.performRequest<TranslationLanguageResponse[]>(
      RequestType.GET,
      this.buildUrl('/translate/languages', true, { queryParams }),
      {
        includeToken: true,
      }
    ).pipe(
      retry(2),
      map((response: HttpResponse<TranslationLanguageResponse[]>): TranslationLanguage[] =>
        response?.body?.map?.((language) => new TranslationLanguage().deserialize(language))
      ),
      catchError((err) => {
        console.warn('Failed getting supported translation languages: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting supported translation languages: ', error);
      return null;
    });
  }
}
