/* Plugins useful for the Keen Slider carousel */
import { KeenSliderInstance } from 'keen-slider';
import throttle from 'lodash/throttle';

export function MutationPlugin(slider: KeenSliderInstance): MutationObserver {
  const observer = new MutationObserver(
    throttle((mutations: MutationRecord[]) => mutationObserverCb(mutations, slider), 300, { leading: true })
  );
  const options = { childList: true };

  slider.on('created', () => {
    observer.observe(slider.container, options);
  });
  slider.on('destroyed', () => {
    observer.disconnect();
  });
  return observer;
}

// extracting callback function so tests don't need to mock native MutationObserver
export function mutationObserverCb(mutations: MutationRecord[], slider: KeenSliderInstance) {
  mutations.forEach(() => {
    slider.update();
  });
}

export function ResizePlugin(slider: KeenSliderInstance): ResizeObserver {
  const observer = new ResizeObserver(
    throttle(
      () => {
        resizeObserverCb(slider);
      },
      300,
      { leading: true }
    )
  );

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
  return observer;
}

// extracting callback function so tests don't need to mock native ResizeObserver
export function resizeObserverCb(slider: KeenSliderInstance) {
  slider.update();
}
