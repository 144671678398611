@if (dataStoreService.user$ | async; as user) {
  <div class="feedback">
    <h1 class="main-title">{{ 'Feedback' | translate | async }}</h1>
    <p class="main-body">{{ 'Help us improve ' + productName + '!' | translate | async }}</p>
    <div class="container">
      <a
        class="feed item-flex-container"
        href="{{ environment.feedbackLink }}?product={{ productName }}&platform=web&group={{
          getEncodedURI(user?.group?.name)
        }}{{ user?.group?.organization ? '&org=' + getEncodedURI(user?.group?.organization?.name) : '' }}"
        target="_blank">
        <div class="circle">
          <svg-icon src="/assets/icons/feedback.svg"></svg-icon>
        </div>
        <span class="bottom-text">{{ 'Feedback &amp; Ideas' | translate | async }}</span>
      </a>
      <a
        class="bugs item-flex-container"
        href="{{ environment.bugLink }}?product={{ productName }}&platform=web&group={{
          getEncodedURI(user?.group?.name)
        }}&hardware=web&os={{ getEncodedURI(window.navigator.platform) }}&versionNumber={{
          getEncodedURI(window.navigator.userAgent)
        }}{{ user?.group?.organization ? '&org=' + getEncodedURI(user?.group?.organization?.name) : '' }}"
        target="_blank">
        <div class="circle">
          <svg-icon src="/assets/icons/bug.svg"></svg-icon>
        </div>
        <span class="bottom-text">{{ 'Report a bug' | translate | async }}</span>
      </a>
    </div>
  </div>
}
