import { hexToRgb } from '@kutil';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class Theme implements Deserializable<JsonObject, Theme> {
  constructor(
    public backgroundImageUrl?: string,
    public primary?: string,
    public secondary?: string,
    public surface?: string,
    public onBackground?: string,
    public gradientColor1?: string,
    public gradientColor2?: string,
    public gradientColor3?: string,
    public iconUrl?: string,
    public iconUrlWithTag?: string,
    public onBackgroundIconUrl?: string,
    public onBackgroundIconUrlWithTag?: string,
    public backgroundImageUrlMobile?: string
  ) {
    if (backgroundImageUrl && !backgroundImageUrlMobile) {
      this.backgroundImageUrlMobile = backgroundImageUrl;
    }
  }

  deserialize(input: JsonObject): Theme {
    if (input == null) {
      return null;
    }

    this.backgroundImageUrl = input['backgroundImageUrl'] as string;
    this.backgroundImageUrlMobile = (input['backgroundImageUrlMobile'] || input['backgroundImageUrl']) as string;
    this.primary = (input['primaryColor'] || input['primary']) as string;
    this.secondary = (input['secondaryColor'] || input['secondary']) as string;
    // default surfaceColor to primaryColor from API if it doesn't exist
    this.surface = (input['surfaceColor'] || input['primaryColor'] || input['primary']) as string;
    this.onBackground = (input['onBackgroundColor'] || input['onBackground']) as string;
    this.gradientColor1 = input['gradientColor1'] as string;
    this.gradientColor2 = input['gradientColor2'] as string;
    this.gradientColor3 = input['gradientColor3'] as string;
    this.iconUrl = input['iconUrl'] as string;
    this.iconUrlWithTag = input['iconUrlWithTag'] as string;
    this.onBackgroundIconUrl = input['onBackgroundIconUrl'] as string;
    this.onBackgroundIconUrlWithTag = input['onBackgroundIconUrlWithTag'] as string;

    return this;
  }

  getColorWithTransparency(property: string, opacity: number): string {
    // @ts-ignore TODO: Revisit to fix lint issues
    return this[property]
      ? // @ts-ignore TODO: Revisit to fix lint issues
        `rgba(${hexToRgb(this[property]).r}, ${hexToRgb(this[property]).g}, ${hexToRgb(this[property]).b}, ${opacity})`
      : // @ts-ignore TODO: Revisit to fix lint issues
        (this[property] as string);
  }
}
