import { Component, Input, OnInit } from '@angular/core';
import { ColorSolver } from '@kp/css-filter/color-solver.model';
import { Color } from '@kp/css-filter/color.model';
import {
  SimpleIconAnimationOptions,
  SimpleIconAnimationType,
} from '@kp/shared/components/animations/simple-icon-animation-options';
import { hexToRgb } from '@kutil';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-simple-icon-animation',
  templateUrl: './simple-icon-animation.component.html',
  styleUrls: ['./simple-icon-animation.component.scss'],
})
export class SimpleIconAnimationComponent implements OnInit {
  @Input() options: SimpleIconAnimationOptions;
  @Input() color: string;
  @Input() iconShouldFill: boolean;
  @Input() iconClicked: boolean;

  filterString: string;
  isInitiallyFilled: boolean;
  simpleIconAnimationType = SimpleIconAnimationType;
  useAlternate = false;

  ngOnInit(): void {
    if (this.options?.type === SimpleIconAnimationType.rive) {
      // use generic alternate if no WASM support
      this.useAlternate = typeof WebAssembly !== 'object';

      this.isInitiallyFilled = this.iconShouldFill;
      const rgb = hexToRgb(this.color);
      if (rgb) {
        const color = new Color().set(rgb.r, rgb.g, rgb.b);
        const solver = new ColorSolver().set(color);
        const result = solver.solve();
        this.filterString = result.filter;
      }
    }
  }
}

export const MockSimpleIconAnimationComponent = MockComponent({
  selector: 'kp-simple-icon-animation',
  inputs: ['options', 'color', 'iconShouldFill', 'iconClicked'],
});
