import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class Url implements Deserializable<JsonObject, Url> {
  constructor(
    public url?: string,
    public type?: string,
    public dateCreated?: string
  ) {}

  deserialize(input: JsonObject): Url {
    if (input == null) {
      return null;
    }

    this.url = input['url'] as string;
    this.type = input['type'] as string;
    this.dateCreated = input['dateCreated'] as string;

    return this;
  }
}
