import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { EligibilityCandidate } from '@kp/eligibility/eligibility-candidate.model';
import { EligibilityVerification } from '@kp/eligibility/eligibility-verification.model';
import { DataStoreService } from '@kservice';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface EligibilityCandidateResponse {
  candidate: EligibilityCandidate;
  verificationLink: string;
}

@Injectable({
  providedIn: 'root',
})
export class EligibilityVerificationApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }
  async verifyEligibility(verificationObject: EligibilityVerification): Promise<EligibilityCandidate> {
    const url = this.buildUrl('/eligibility', true);
    const request$ = this.performRequest<EligibilityCandidateResponse>(RequestType.POST, url, {
      requestBody: {
        verificationObject: verificationObject.serialize(),
        deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      },
    }).pipe(
      catchError((error) => {
        console.warn('Failed verifying eligibility: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<EligibilityCandidateResponse>>(request$).catch((error): null => {
      console.warn('Error verifying eligibility: ', error);
      return null;
    });
    if (response?.ok) {
      //TODO: update API to return link as part of candidate object
      const candidate = new EligibilityCandidate().deserialize(response.body?.candidate);
      candidate.eligibilityLink = response.body?.verificationLink;
      return candidate;
    }
    return null;
  }
}
