export class Base64Util {
  static encode(normal: string): string {
    return btoa(normal);
  }

  static decode(encoded: string): string {
    return atob(encoded);
  }

  // Convert from normal to web-safe, strip trailing "="s
  static toWebSafe(base64: string, stripTrailingEquals = false) {
    const webSafe64 = base64.replace(/\+/g, '-').replace(/\//g, '_');
    if (stripTrailingEquals) {
      return webSafe64.replace(/=+$/, '');
    }
    return webSafe64;
  }

  // Convert from web-safe to normal, add trailing "="s
  static toNormal(base64: string) {
    return base64.replace(/-/g, '+').replace(/_/g, '/') + '=='.substring(0, (3 * base64.length) % 4);
  }
}
