@if (title) {
  <div class="title-container">
    <h2 class="title">{{ title | translate | async }}</h2>
    <button
      class="icon-button info"
      [attr.aria-label]="'Show information about Action Reminders' | translate | async"
      (click)="expandInfo()">
      <svg-icon src="assets/icons/info-circle.svg"></svg-icon>
    </button>
    @if (primaryIconUrl) {
      <button
        [attr.aria-label]="
          (primaryIconUrl.includes('plus-circle')
            ? 'Add a reminder'
            : primaryIconUrl.includes('x-circle')
              ? 'Cancel reminder add or edit'
              : 'Primary reminder action'
          )
            | translate
            | async
        "
        class="icon-button primary"
        (click)="primaryIconClicked.emit()">
        <svg-icon [src]="primaryIconUrl"></svg-icon>
      </button>
    }
  </div>
}
@if (showInfo) {
  <p class="info-text">
    {{ infoText | translate | async }}
    <kui-a11y-live-announce [announcement]="infoText"></kui-a11y-live-announce>
  </p>
}
@if (!showEditableAction && showCompactAction) {
  <div class="primary-container compact">
    <ng-content select="[compact]"></ng-content>
  </div>
}
@if (showEditableAction && !showCompactAction) {
  <div class="primary-container">
    <ng-content select="[expanded]"></ng-content>
    <div class="save-row">
      <button
        class="icon-button trash"
        [attr.aria-label]="'Delete reminder' | translate | async"
        (click)="trashIconClicked.emit()">
        <svg-icon src="assets/icons/trash.svg"></svg-icon>
      </button>
      <button
        class="button button-reverse button-pill button-save"
        (click)="saveClicked.emit()"
        [attr.aria-label]="'Save reminder' | translate | async"
        [disabled]="saveDisabled">
        {{ 'Save' | translate | async }}
      </button>
    </div>
  </div>
}
@if (!showEditableAction && !showCompactAction && !primaryIconUrl) {
  <div class="primary-container action-info">
    <ng-content select="[info]"></ng-content>
  </div>
}
