<kp-detail-view-action
  [title]="'Pair a Habit'"
  infoText="Boost your chances of success by pairing this with something you already do – like your morning coffee, your lunch
  break, or your bedtime routine."
  [showEditableAction]="showEditableHabit"
  [showCompactAction]="showCompactHabit"
  [primaryIconUrl]="primaryActionIcon"
  [showInfo]="card?.userState?.habits?.[0] == null"
  [saveDisabled]="habitText === card?.userState?.habits?.[0]?.value || habitText === ''"
  (primaryIconClicked)="updateHabit()"
  (trashIconClicked)="resetHabits()"
  (saveClicked)="saveHabit()">
  <ng-container expanded>
    <p class="habit-bold">{{ 'I will do this when' | translate | async }}&hellip;</p>
    <kp-text-field
      class="habit-text-field"
      [maxCharacters]="80"
      [textValue]="habitText"
      (textAreaAnswer)="habitText = $event"></kp-text-field>
  </ng-container>
  <p class="habit-instructions" compact>
    {{ 'I will do this when' | translate | async }}
    <strong class="habit-bold">{{ habitText }}</strong>
  </p>
</kp-detail-view-action>
