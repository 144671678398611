import { Component, Input, OnInit } from '@angular/core';
import { FirstVisitBloc, SharingBloc } from '@kbloc';
import { FirstVisitCase } from '@ktypes/enums';
import {
  InviteType,
  SocialChallengeGroupStatus,
  SocialChallengeIndividualStatus,
  SocialChallengeInfo,
  SocialChallengeState,
  SocialChallengeStatus,
} from '@ktypes/models';
import { StringUtil } from '@kutil';
import { MockComponent } from '@kutil/test';
import { take } from 'rxjs/operators';

@Component({
  selector: 'kf-sc-social-challenge-detail-progress',
  templateUrl: './social-challenge-detail-progress.component.html',
  styleUrls: ['./social-challenge-detail-progress.component.scss'],
})
export class SocialChallengeDetailProgressComponent implements OnInit {
  @Input() challengeGoalCompleted: boolean;
  @Input() challengeId: string;
  @Input() challengeInfo: SocialChallengeInfo<SocialChallengeGroupStatus | SocialChallengeIndividualStatus>;
  @Input() challengeObjective: string;
  @Input() challengeStatus: SocialChallengeStatus;
  @Input() challengeTitle: string;
  @Input() enrolled: boolean;
  @Input() inviteMessage: string;
  @Input() title: string;
  @Input() type: 'group' | 'individual';

  challengeState = SocialChallengeState;
  inviteType = InviteType.coworker;
  inviteModalOpened = false;
  showInfo = true;
  socialChallengeState = SocialChallengeState;

  private _firstVisitKey: string;
  private _hasCollapsedInfo: string;

  constructor(
    private _firstVisitBloc: FirstVisitBloc,
    public sharingBloc: SharingBloc
  ) {}

  ngOnInit() {
    this._firstVisitKey = `collapsedChallengeInfo${StringUtil.capitalizeFirstLetter(this.type)}`;
    this._firstVisitBloc.hasSeen$?.pipe(take(1)).subscribe((firstVisit) => {
      // @ts-ignore TODO: Revisit to fix lint errors
      this._hasCollapsedInfo = firstVisit[this._firstVisitKey] as string;
      // @ts-ignore TODO: Revisit to fix lint errors
      this.showInfo = !firstVisit[this._firstVisitKey];
    });
  }

  toggleInfo() {
    this.showInfo = !this.showInfo;

    if (!this._hasCollapsedInfo) {
      // @ts-ignore TODO: Revisit to fix lint errors
      this._firstVisitBloc.updateFirstVisit(FirstVisitCase[this._firstVisitKey], true);
    }
  }
}

export const MockSocialChallengeDetailProgressComponent = MockComponent({
  selector: 'kf-sc-social-challenge-detail-progress',
  inputs: [
    'challengeGoalCompleted',
    'challengeId',
    'challengeInfo',
    'challengeObjective',
    'challengeStatus',
    'challengeTitle',
    'enrolled',
    'inviteMessage',
    'title',
    'type',
  ],
  template: '<ng-content></ng-content>',
});
