import { DateTimeUtil } from '@kutil';
import { Deserializable } from './deserializable.model';
import { JSONValue, JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

// Default values, used only as a client-side backup in case no values are sent
const POSITIVE_BUTTON_OPTIONS_DEFAULT = ['Helpful', 'Relevant', 'Supportive', 'Inspiring'] as const;
const NEGATIVE_BUTTON_OPTIONS_DEFAULT = ['Not true', 'Not helpful', 'Repetitive', 'Harmful or unsafe'] as const;

interface FeedbackMeta {
  additional?: string;
  [key: string]: JSONValue;
}

export class AiFeedback implements Deserializable<JsonObject, AiFeedback>, Serializable<JsonObject> {
  constructor(
    public dateDeviceCreated?: Date | string,
    public feedback?: string[],
    public isPositive?: null | boolean,
    public meta?: JsonObject,
    public negativeButtonOptions?: string[],
    public positiveButtonOptions?: string[],
    public responseId?: string,
    public wasSubmitted?: boolean
  ) {}

  deserialize(input: JsonObject): AiFeedback {
    if (input == null) {
      return null;
    }

    this.dateDeviceCreated = input['dateDeviceCreated'] ? new Date(input['dateDeviceCreated']) : null;
    this.feedback = input['feedback'] as string[];
    this.isPositive = input['isPositive'] != null ? !!input['isPositive'] : (input['isPositive'] as null);
    this.meta = (input['meta'] || { additional: '' }) as FeedbackMeta;
    this.negativeButtonOptions = (input['negativeButtonOptions'] ?? NEGATIVE_BUTTON_OPTIONS_DEFAULT) as string[];
    this.positiveButtonOptions = (input['positiveButtonOptions'] ?? POSITIVE_BUTTON_OPTIONS_DEFAULT) as string[];
    this.responseId = input['responseId'] as string;
    this.wasSubmitted = !!input['wasSubmitted'];

    return this;
  }

  serialize(): Partial<AiFeedback> {
    return {
      dateDeviceCreated:
        typeof this.dateDeviceCreated === 'string'
          ? this.dateDeviceCreated
          : DateTimeUtil.formatInLocal(this.dateDeviceCreated || new Date()),
      feedback: this.feedback,
      isPositive: this.isPositive,
      meta: this.meta,
    };
  }
}
