@if (imageWithFallback?.url !== null && ((image != null && media == null) || !media?.isVideo)) {
  <section class="image-container hidden-print">
    <img
      class="media-image"
      [src]="imageWithFallback?.url + (imageAdjustments ?? '')"
      [alt]="imageWithFallback?.description | translate | async" />
  </section>
}
@if (media) {
  @if (media.isAudio) {
    <kui-audio-player
      class="hidden-print"
      [analyticsPageName]="analyticsPageName"
      [showControls]="showAudioControls"
      [isOnBackground]="isOnBackground"
      [url]="media.url"
      [title]="media.title"
      [detailCardData]="detailCardData"></kui-audio-player>
  }
  @if (media.isVideo) {
    <kui-video-player
      class="hidden-print"
      [altText]="media.placeholderImage?.description"
      [analyticsPageName]="analyticsPageName"
      [primary]="primaryColorVideoPlay"
      [url]="media.url"
      [title]="media.title"
      [imageUrl]="imageWithFallback?.url + (imageAdjustments ?? '')"
      [detailCardData]="detailCardData"></kui-video-player>
  }
  @if (renderTranscript && media.rawTranscript) {
    <kui-accordion-group
      class="hidden-print"
      [key]="transcriptKey"
      [initialStateOpen]="transcriptInitiallyOpen"
      (didChange)="transcriptAccordionChange($event)">
      <div acc-open class="audio-container">
        @if (!showTranscript) {
          <span class="transcript-accordion-toggle">
            {{ 'Show Transcript' | translate | async }}
          </span>
        }
        @if (showTranscript) {
          <span class="transcript-accordion-toggle">
            {{ 'Hide Transcript' | translate | async }}
          </span>
        }
      </div>
      <div acc-collapsible class="body-transcript">
        <kui-rich-text [richText]="media.rawTranscript" [translate]="false"></kui-rich-text>
      </div>
    </kui-accordion-group>
  }
}
