import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { MockDirective } from '../utils/spec.util';

interface DirectionRevealOptions {
  animationName?: string;
  animationPostfixEnter?: string;
  animationPostfixLeave?: string;
  itemActiveClass?: string;
  itemSelector?: string;
  previousPrefix?: string;
  removeLeaveAfter?: number;
  selector?: string;
  setupPrefix?: string;
  setupTime?: number;
}

enum Direction {
  top,
  right,
  bottom,
  left,
}
type DirectionText = keyof typeof Direction;

const DEFAULT_SETUP_TIME = 50;
const DEFAULT_REMOVE_LEAVE_TIME = 250;

@Directive({
  selector: '[kutilHoverDirection]',
})
export class HoverDirectionDirective implements AfterViewInit, OnDestroy {
  constructor(private _elementRef: ElementRef) {}

  @Input() animationName: 'linear' | 'swing' = 'linear'; // swing is not implemented
  @Input() animationPostfixEnter = 'enter';
  @Input() animationPostfixLeave = 'leave';
  @Input() itemActiveClass = 'direction-reveal__active';
  @Input() itemSelector = '.direction-reveal__card';
  @Input() previousPrefix = 'last';
  @Input() removeLeaveAfter = DEFAULT_REMOVE_LEAVE_TIME;
  @Input() setupPrefix = 'setup';
  @Input() setupTime = DEFAULT_SETUP_TIME;

  private _observer = new MutationObserver(() => {
    this._instantiateDirectionReveal();
  });
  private _processing = false;

  ngAfterViewInit(): void {
    this._instantiateDirectionReveal();
    this._registerListenerForDomChanges();
  }

  ngOnDestroy() {
    this._observer.disconnect();
    this._instantiateDirectionReveal(false);
  }

  private _registerListenerForDomChanges() {
    const attributes = false;
    const childList = true;
    const subtree = true;
    this._observer.observe(this._elementRef.nativeElement, {
      attributes,
      childList,
      subtree,
    });
  }

  private _instantiateDirectionReveal(bind = true) {
    if (!this._processing) {
      this._processing = true;
      directionReveal(
        this._elementRef.nativeElement,
        {
          animationName: this.animationName,
          animationPostfixEnter: this.animationPostfixEnter,
          animationPostfixLeave: this.animationPostfixLeave,
          itemActiveClass: this.itemActiveClass,
          itemSelector: this.itemSelector,
          previousPrefix: this.previousPrefix,
          removeLeaveAfter: this.removeLeaveAfter,
          setupPrefix: this.setupPrefix,
          setupTime: this.setupTime,
        },
        bind
      );
      this._processing = false;
    }
  }
}

export const MockHoverDirectionDirective = MockDirective({
  selector: '[kutilHoverDirection]',
  inputs: [
    'animationName',
    'animationPostfixEnter',
    'animationPostfixLeave',
    'itemActiveClass',
    'itemSelector',
    'previousPrefix',
    'removeLeaveAfter',
    'setupPrefix',
    'setupTime',
  ],
});

// Adapted from https://github.com/NigelOToole/direction-reveal (MIT)
// If more functionality is needed over what is used here, i.e. different animations, etc.
// then consider importing the npm module
//
// Adds class for the direction the mouse cursor enters/leaves the block in the format:
//   1. setup-animationName--action--direction
//   2. animationName--action-direction, last--animationName--action-direction

// Set direction aware hover/touch events which will set appropriate classes on entry direction
function directionReveal(container: Element, options: DirectionRevealOptions, bind = true) {
  if (container) {
    handleEvents(container, options, bind);
  }
}

// bind events to container items
function handleEvents(containerItem: Element, options: DirectionRevealOptions, bind = true) {
  // if no itemSelectors, use self as target element
  const elements: NodeListOf<HTMLElement> = options.itemSelector
    ? containerItem.querySelectorAll(options.itemSelector)
    : ([containerItem] as unknown as NodeListOf<HTMLElement>);

  elements.forEach((element: HTMLElement) => {
    if (bind) {
      element.addEventListener('mouseenter', (event: MouseEvent) =>
        updateDirection(event, options.animationPostfixEnter, options)
      );
      element.addEventListener('mouseleave', (event: MouseEvent) =>
        updateDirection(event, options.animationPostfixLeave, options)
      );
    } else {
      element.removeEventListener('mouseenter', (event: MouseEvent) =>
        updateDirection(event, options.animationPostfixEnter, options)
      );
      element.removeEventListener('mouseleave', (event: MouseEvent) =>
        updateDirection(event, options.animationPostfixLeave, options)
      );
    }
  });
}

// Updates direction for hover effect and toggles classes
function updateDirection(event: MouseEvent, action: string, options: DirectionRevealOptions) {
  const currentItem: HTMLElement = event.currentTarget as HTMLElement;
  const direction = getDirection(event, currentItem);
  const directionString = translateDirection(direction);

  // Remove current animation classes
  // Uses string manipulation for removal in order to remove existing via string matching
  const currentCssClasses = currentItem.className.split(' ');
  const previousDirections = currentCssClasses
    .filter((cssClass: string) => cssClass.startsWith(options.animationName))
    .map((cssClass) => `${options.previousPrefix}--${cssClass}`);
  currentItem.className = currentCssClasses
    .filter(
      (cssClass: string) =>
        !cssClass.startsWith(options.animationName) &&
        !cssClass.startsWith(options.previousPrefix) &&
        !cssClass.startsWith(`${options.setupPrefix}`)
    )
    .join(' ');
  // add set up class for current action/direction
  // including any previous direction classes in case it's needed for the animation
  currentItem.classList.add(
    options.itemActiveClass,
    `${options.setupPrefix}-${options.animationName}--${action}-${directionString}`,
    ...previousDirections
  );

  // add classes for current action/direction after set up class was added
  setTimeout(() => {
    currentItem.classList.add(`${options.animationName}--${action}-${directionString}`);
    currentItem.classList.remove(`${options.setupPrefix}-${options.animationName}--${action}-${directionString}`);
  }, options.setupTime);

  const eventDetail = { action: action, direction: directionString };
  fireEvent(currentItem, 'directionChange', eventDetail);

  // remove leave / previous class(es) once done
  if (action === options.animationPostfixLeave) {
    setTimeout(() => {
      currentItem.className = currentItem.className
        .split(' ')
        .filter(
          (cssClass: string) =>
            !cssClass.startsWith(options.itemActiveClass) &&
            !cssClass.startsWith(options.animationName) &&
            !cssClass.startsWith(options.previousPrefix) &&
            !cssClass.startsWith(`${options.setupPrefix}`)
        )
        .join(' ');
    }, options.removeLeaveAfter);
  }
}

// Get direction data based on element and pointer positions
function getDirection(event: MouseEvent, element: HTMLElement) {
  // Width and height of current item
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  const position = getPosition(element);

  // Calculate the x/y value of the pointer entering/exiting, relative to the center of the item.
  const pointerX = (event.pageX - position.x - width / 2) * (width > height ? height / width : 1);
  const pointerY = (event.pageY - position.y - height / 2) * (height > width ? width / height : 1);

  // Calculate the angle the pointer entered/exited and convert to clockwise format (top/right/bottom/left = 0/1/2/3).
  // - https://stackoverflow.com/a/3647634
  return Math.round(Math.atan2(pointerY, pointerX) / 1.57079633 + 5) % 4;
}

// Gets an elements position - https://www.kirupa.com/html5/get_element_position_using_javascript.htm
function getPosition(element: HTMLElement) {
  let xPos = 0;
  let yPos = 0;

  while (element) {
    xPos += element.offsetLeft + element.clientLeft;
    yPos += element.offsetTop + element.clientTop;
    element = element.offsetParent as HTMLElement;
  }

  return {
    x: xPos,
    y: yPos,
  };
}

// translates the numeric direction (0-3) into textual direction
function translateDirection(direction: Direction): DirectionText {
  switch (direction) {
    case Direction.right:
      return 'right';
    case Direction.bottom:
      return 'bottom';
    case Direction.left:
      return 'left';
    case Direction.top:
    default:
      return 'top';
  }
}

// fires custom directionChange event
function fireEvent(
  item: { dispatchEvent: (arg0: CustomEvent<any>) => void },
  eventName: string,
  eventDetail: { action: string; direction: DirectionText }
) {
  const event = new CustomEvent(eventName, {
    bubbles: true,
    detail: eventDetail,
  });

  item.dispatchEvent(event);
}
