import { LoginError } from '../enums/login-error.enum';
import { AuthData } from './auth-data.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export interface MfaRequiredApiResponse {
  email?: string;
  explanation?: string;
  authentication?: AuthData;
}

// TODO: Refactor to be LoginErrorResponse
//  need to handle EmailVerificationRequired and maybe PasswordResetRequired?
export class MfaRequired implements Deserializable<JsonObject, MfaRequired> {
  constructor(
    public email?: string,
    public explanation?: LoginError,
    public authData?: AuthData
  ) {}

  deserialize(input: MfaRequiredApiResponse): MfaRequired {
    if (input == null) {
      return null;
    }

    this.email = input['email'];
    this.explanation = input['explanation'] as LoginError;
    this.authData = input['authentication'] ? new AuthData().deserialize(input['authentication']) : undefined;

    return this;
  }
}
