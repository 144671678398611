import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LocalFirstVisit } from './local-first-visit.model';
import { RemoteFirstVisit } from './remote-first-visit.model';

export class LegacyFirstVisit implements Deserializable<JsonObject, LegacyFirstVisit> {
  constructor(
    public addedAction = false,
    public addedQuest = false,
    public clearedIoNotifications = false,
    public completedReflection = false,
    public dismissedAccountCreationModal = false,
    public discover = false,
    public notificationPrompt = false,
    public progress = false,
    public reminders = false,
    public showUp = false,
    public shownPurposefulFeedbackModal = false,
    public shownResourcefulFeedbackModal = false,
    public today = false
  ) {}

  deserialize(json?: JsonObject, previousValue?: LegacyFirstVisit): LegacyFirstVisit {
    if (json != null || previousValue != null) {
      Object.assign(this, previousValue || {}, json);
    }
    return this;
  }

  mapToRemoteFirstVisit(): RemoteFirstVisit {
    return new RemoteFirstVisit().deserialize({
      addedAction: this.addedAction,
      addedQuest: this.addedQuest,
      completedReflection: this.completedReflection,
      dismissedAccountCreationModal: this.dismissedAccountCreationModal,
      discover: this.discover,
      progress: this.progress,
      shownPurposefulFeedbackModal: this.shownPurposefulFeedbackModal,
      shownResourcefulFeedbackModal: this.shownResourcefulFeedbackModal,
      today: this.today,
    });
  }

  mapToLocalFirstVisit(): LocalFirstVisit {
    return new LocalFirstVisit().deserialize({
      clearedIoNotifications: this.clearedIoNotifications,
      notificationPrompt: this.notificationPrompt,
      reminders: this.reminders,
    });
  }
}
