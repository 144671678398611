import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class Credentials implements Deserializable<JsonObject, Credentials> {
  email: string;
  password: string;
  nickname: string;
  code: string;

  constructor(email?: string, password?: string, nickname?: string, code?: string) {
    this.email = email && sanitizeString(removeEscapeSequences(email));
    this.password = password && sanitizeString(removeEscapeSequences(password));
    this.nickname = nickname && sanitizeString(removeEscapeSequences(nickname));
    this.code = code && sanitizeString(removeEscapeSequences(code));
  }

  deserialize(input: JsonObject, existingData?: Credentials): Credentials {
    if (input == null) {
      return null;
    }

    this.email = input['email'] && sanitizeString(removeEscapeSequences(input['email']));
    this.password = input['password'] && sanitizeString(removeEscapeSequences(input['password']));
    this.nickname = input['nickname'] && sanitizeString(removeEscapeSequences(input['nickname']));
    this.code = input['code'] && sanitizeString(removeEscapeSequences(input['code']));

    return this;
  }
}

function removeEscapeSequences(str: string): string {
  if (!str) {
    return str;
  }
  return str.replace(/\\/g, '\\\\');
}

function sanitizeString(str: string): string {
  if (!str) {
    return str;
  }
  // see https://gist.github.com/getify/3667624 as reference
  return str.replace(/\\([\s\S])|(["\\])/g, '\\$1$2');
}
