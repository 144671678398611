import { AnswerValue } from '@kp/question-set/models/answer-value.model';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class QuestionDisplay implements Deserializable<JsonObject, QuestionDisplay>, Serializable<JsonObject> {
  constructor(
    public title?: string,
    public valueMinLabel?: string,
    public valueMaxLabel?: string,
    public options?: DisplayOption[],
    public body?: string,
    public placeholder?: string,
    public subtitle?: string
  ) {}

  optionsWithAnswers(answerValues: AnswerValue[]) {
    if (Array.isArray(answerValues)) {
      const currentAnswers = answerValues.filter((value) => (value?.value as boolean) === true);
      if (this.options != null) {
        return this.options.map((option) => {
          const isSelected = currentAnswers.some((currentAnswer) => option?.id === currentAnswer?.optionId);
          return new DisplayOption().deserialize(Object.assign({}, option.serialize(), { isSelected }));
        });
      }
    }
    return [];
  }

  deserialize(input: JsonObject): QuestionDisplay {
    if (input == null) {
      return null;
    }
    this.title = input['title'] as string;
    this.valueMinLabel = input['valueMinLabel'] as string;
    this.valueMaxLabel = input['valueMaxLabel'] as string;
    this.options = (input?.['options'] as DisplayOption[])?.map((option) => new DisplayOption().deserialize(option));
    this.body = input['body'] as string;
    this.placeholder = input['placeholder'] as string;
    this.subtitle = input['subtitle'] as string;
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['title'] = this.title;
    json['valueMinLabel'] = this.valueMinLabel;
    json['valueMaxLabel'] = this.valueMaxLabel;
    json['options'] = this.options && this.options.map((option) => option.serialize());
    json['body'] = this.body;
    json['placeholder'] = this.placeholder;
    json['subtitle'] = this.subtitle;
    return json;
  }
}
