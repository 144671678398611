import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { Product } from '@ktypes/enums';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-core-nav',
  templateUrl: './core-nav.component.html',
  styleUrls: ['./core-nav.component.scss'],
})
export class CoreNavComponent {
  @Input() quickActionsOpen = false;
  @Output() quickActionsToggled = new EventEmitter<boolean>();
  product = Product;
  constructor(
    public navBloc: NavigationBloc,
    public environmentVariablesService: EnvironmentVariablesService
  ) {}
}

export const MockCoreNavComponent = MockComponent({
  selector: 'kp-core-nav',
  inputs: ['quickActionsOpen'],
});
