import cloneDeep from 'lodash/cloneDeep';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export enum LinkType {
  ACTION = 'ACTION',
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export enum DeepLinkBase {
  Card = 'Card',
  SocialChallenge = 'Social Challenge',
  SocialChallenges = 'Social Challenges',
  Dialogue = 'Dialogue',
  Discover = 'Discover',
  Explore = 'Explore',
  ExternalLink = 'External Link',
  Feedback = 'Feedback',
  Help = 'Help',
  Invite = 'Invite',
  Me = 'Me',
  MyActions = 'My Actions',
  MyQuests = 'My Quests',
  None = '',
  Notifications = 'Notifications',
  Progress = 'Progress',
  PulseSurvey = 'Pulse Survey',
  Quest = 'Quest',
  QuestionSet = 'Question Set',
  Report = 'Report',
  Saved = 'Saved',
  Settings = 'Settings',
  Sharing = 'Sharing',
  TakeAction = 'Take Action',
  Today = 'Today',
}

export interface ChallengeDeepLinkData {
  contentId: string;
  socialChallengeId: string;
}

export interface DialogueDeepLinkData {
  contentId: string;
  logicKey: string;
  refKey: string;
  reportId: string;
  userDialogueId: string;
}

export interface QuestDeepLinkData {
  contentId: string;
  refKey: string;
}

export type DeepLinkData = ChallengeDeepLinkData | DialogueDeepLinkData | QuestDeepLinkData;

export class Link implements Deserializable<JsonObject, Link>, Serializable<JsonObject> {
  constructor(
    public title?: string,
    public altText?: string,
    public buttonText?: string,
    public deepLinkBase?: DeepLinkBase,
    public deepLinkData?: DeepLinkData,
    public uri?: string,
    public type?: LinkType
  ) {}

  clone(cardLink: Link) {
    return cloneDeep(cardLink);
  }

  deserialize(json?: JsonObject, previousValue?: Link, buttonText?: string): Link {
    if (json == null) {
      return null;
    }
    this.title = (json?.['linkTitle'] || json?.['title']) as string;
    this.altText = (json?.['linkAltText'] || json?.['linkTitle']) as string;
    this.buttonText = (buttonText || json?.['buttonText']) as string;
    this.deepLinkBase = json?.['deepLinkBase'] as DeepLinkBase;
    const deepLinkData = clearEmptyProps(json?.['deepLinkData']);
    this.deepLinkData = {
      contentId: deepLinkData?.['contentId'] as string,
      logicKey: deepLinkData?.['logicKey'] as string,
      refKey: deepLinkData?.['refKey'] as string,
      reportId: deepLinkData?.['reportId'] as string,
      userDialogueId: deepLinkData?.['userDialogueId'] as string,
      socialChallengeId: deepLinkData?.['socialChallengeId'] as string,
    };
    this.uri = json?.['uri'] as string;
    this.type = this.uri ? LinkType.EXTERNAL : this.deepLinkBase ? LinkType.INTERNAL : (json?.['type'] as LinkType);
    return this;
  }

  serialize() {
    switch (this.type) {
      case LinkType.EXTERNAL:
        return {
          uri: this.uri,
          linkTitle: this.title,
          linkAltText: this.altText,
        };
      case LinkType.INTERNAL:
        return {
          linkTitle: this.title,
          linkAltText: this.altText,
          deepLinkBase: this.deepLinkBase,
          deepLinkData: clearEmptyProps({
            contentId: this.deepLinkData?.contentId,
            logicKey: (this.deepLinkData as DialogueDeepLinkData)?.logicKey,
            refKey: (this.deepLinkData as DialogueDeepLinkData | QuestDeepLinkData)?.refKey,
            reportId: (this.deepLinkData as DialogueDeepLinkData)?.reportId,
            userDialogueId: (this.deepLinkData as DialogueDeepLinkData)?.userDialogueId,
          }),
        };
      default:
        return null;
    }
  }

  get hasLink(): boolean {
    return this.uri != null || (this.deepLinkBase != null && this.deepLinkBase !== DeepLinkBase.None);
  }
}

export function getTypedDeepLinkData<T>(deepLinkData: DeepLinkData): T {
  return deepLinkData as T;
}

function clearEmptyProps(obj: JsonObject): JsonObject {
  if (!obj) {
    return null;
  }
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null && value !== ''));
}
