import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ImageRecord } from '@kp/shared/image/image-record.model';
import { ImageBloc, ImageType } from '@kp/shared/image/image.bloc';
import { DataStatus, NavItem, NavItemType, Status } from '@ktypes/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

enum NavImageState {
  notLoaded,
  loaded,
}
@Component({
  selector: 'kp-core-nav-image',
  templateUrl: './core-nav-image.component.html',
  styleUrls: ['./core-nav-image.component.scss'],
})
export class CoreNavImageComponent implements OnInit, OnDestroy {
  @Input() navItem: NavItem;
  @Input() quickActionsOpen: boolean;
  @Output() navItemClicked = new EventEmitter<void>();
  @Output() quickActionsToggled = new EventEmitter<boolean>();

  image: ImageRecord;
  navImageState = NavImageState;
  navItemType = NavItemType;
  state = NavImageState.notLoaded;

  private _destroy$ = new Subject<void>();

  constructor(public _imageBloc: ImageBloc) {}

  ngOnInit() {
    if (this.navItem.imageType) {
      this._imageBloc.fetchLatestImage(this.getImageType());

      this._imageBloc
        .imageStatus$(this.getImageType(), 1)
        .pipe(takeUntil(this._destroy$))
        .subscribe((imageStatus: DataStatus<ImageRecord>) => {
          this.image = imageStatus?.data || this.image;

          if (imageStatus?.status === Status.done) {
            this.state = this.image ? NavImageState.loaded : NavImageState.notLoaded;
          }
          // Keep previous states if status is local
        });
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getImageType() {
    switch (this.navItem.imageType) {
      case 'user_cover_image':
        return ImageType.user_cover_image;
      default:
        return ImageType.user_profile;
    }
  }
}
