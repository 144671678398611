import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { EnvironmentVariablesService } from '@kenv';
import { BrowserStorage, DataStoreService, SharedConstants, WINDOW } from '@kservice';
import { UserType } from '@ktypes/enums';
import { JsonObject } from '@ktypes/models';
import { getQueryParamsFromString } from '@kutil';
import { BehaviorSubject, Subject, auditTime, buffer } from 'rxjs';
import { filter, share, skipWhile, take } from 'rxjs/operators';
import { AnalyticsEvent, PAGE_MAPPING } from './analytics-event.model';
import { AnalyticsApi } from './analytics.api';
import { AnalyticEvent } from './enums/analytic-event.enum';
import { AnalyticsCategory } from './enums/analytics-category.enum';
import { AnalyticsPageName } from './enums/analytics-page-name.enum';
import { PageMappingData, PageMappingInfo } from './page-mapping-info.model';

interface CalculatedEventInfo {
  appVersion: string;
  page: string;
  platformVersion: string;
  meta: Record<string, any>;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsBloc {
  private _CACHE_NAME = 'Analytics_Cache';
  private _processingCachedEvents = false;
  private _userReadyForAnalytics$ = new BehaviorSubject<boolean>(false);
  private _batchAnalyticEvents$ = new Subject<AnalyticsEvent>();

  constructor(
    private _analyticsApi: AnalyticsApi,
    private _browserStorage: BrowserStorage,
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _location: Location,
    private _router: Router,
    @Inject(PAGE_MAPPING) private _pageMappingData: PageMappingData,
    @Inject(WINDOW) private _window: Window
  ) {
    this._userReadyForAnalytics$
      .pipe(
        skipWhile((userReadyForAnalytics) => !userReadyForAnalytics),
        take(1)
      )
      .subscribe((userReadyForAnalytics) => {
        if (userReadyForAnalytics) {
          this._processCacheEvents();
        }
      });

    // share observable to prevent multiple subscriptions to this._batchAnalyticsEvents$
    const batchedEvents$ = this._batchAnalyticEvents$.pipe(share());
    batchedEvents$
      .pipe(buffer(batchedEvents$.pipe(auditTime(SharedConstants.BATCH_THROTTLE_TIME))))
      .subscribe((events: AnalyticsEvent[]) => {
        if (events?.length > 0) {
          this._analyticsApi.post(events).then((result) => {
            if (this._processingCachedEvents) {
              // only clear cache if result was successful
              if (result) {
                this._browserStorage.remove(this._CACHE_NAME);
              }
              this._processingCachedEvents = false;
            }
          });
        }
      });
  }

  initialize() {
    // ENTER/EXIT PAGE: subscribe to router page visit nav start & end events and send page views to Analytics Api
    this._router.events
      .pipe(
        filter(
          (event: Event): event is NavigationEnd | NavigationStart =>
            event instanceof NavigationEnd || event instanceof NavigationStart
        )
      )
      .subscribe((navEvent: NavigationEnd | NavigationStart) => {
        // Default to NavigationEnd
        let page = navEvent.url;
        let event = AnalyticEvent.entering;

        if (navEvent instanceof NavigationStart) {
          page = this._router.url;
          event = AnalyticEvent.leaving;
        }
        if (page === '/') {
          page = this._location.path();
        }
        if (page.includes('dialogue')) {
          this.userReadyForAnalytics();
        }

        if (page && event && this._pageMappingData.pages.find((pages) => page.match(pages.regex))) {
          this.sendEvent(this.createEventFromPage({ page, event, activatedRoute: this._router.routerState.root }));
        }
      });
  }

  sendEvent(event: AnalyticsEvent, batch = true) {
    if (
      this._dataStoreService.authData?.token &&
      this._dataStoreService.authData?.user?.type !== UserType.deletion &&
      this._userReadyForAnalytics$.value
    ) {
      this._uploadEvent(event, batch);
      this._processCacheEvents();
    } else {
      this._cacheEvent(event);
    }
  }

  // NOTE: used for tracking page to page navigation through the site; should not need to call
  // this directly, it is called by listening to the Angular Router navigation events
  createEventFromPage(event: {
    page: string;
    section?: string;
    event: AnalyticEvent;
    label?: string;
    value?: number;
    meta?: JsonObject;
    activatedRoute?: ActivatedRoute;
  }): AnalyticsEvent {
    if (event) {
      return new AnalyticsEvent().deserialize({
        ...event,
        ...this._getCalculatedEventInfo(event),
        ...{
          url: event.page,
          category: AnalyticsCategory.page,
        },
      });
    }
    return new AnalyticsEvent().deserialize(this._getCalculatedEventInfo());
  }

  // NOTE: use this for tracking events that are not triggered by direct user interactions
  createEventFromEvent(event: {
    page?: string;
    section?: string;
    event?: AnalyticEvent;
    label?: string;
    value?: number;
    meta?: JsonObject;
    activatedRoute?: ActivatedRoute;
  }): AnalyticsEvent {
    return new AnalyticsEvent().deserialize({
      ...event,
      ...this._getCalculatedEventInfo(event),
      category: AnalyticsCategory.interaction,
    });
  }

  // NOTE: use this for tracking user interactions (button clicks, form changes, user selections, etc.)
  createEventFromInteraction(event: {
    page?: string;
    section?: string;
    event?: AnalyticEvent;
    label?: string;
    value?: number;
    meta?: JsonObject;
    activatedRoute?: ActivatedRoute;
  }): AnalyticsEvent {
    return new AnalyticsEvent().deserialize({
      ...event,
      ...this._getCalculatedEventInfo(event),
      category: AnalyticsCategory.interaction,
    });
  }

  userReadyForAnalytics() {
    if (!this._userReadyForAnalytics$.value) {
      // only set it true once
      this._userReadyForAnalytics$.next(true);
    }
  }

  getDynamicPageNameIfMatch(url: string = this._router?.url): AnalyticsPageName {
    const pageInfo = this._pageMappingData?.pages?.find((pageInfo) => url?.match?.(pageInfo.regex));
    return pageInfo?.pageName;
  }

  // TODO: should this and related PageInfo functionality move out of Analytics since now using for Accessibility also?
  getDynamicPageInfoIfMatch(url: string = this._router?.url): PageMappingInfo {
    return this._pageMappingData?.pages?.find((pageInfo) => url?.match?.(pageInfo.regex));
  }

  private _getCalculatedEventInfo(input?: {
    page?: string;
    section?: string;
    event?: AnalyticEvent;
    label?: string;
    value?: number;
    meta?: JsonObject;
    activatedRoute?: ActivatedRoute;
  }): CalculatedEventInfo {
    // TODO: normalize pages like Question Sets with additional metadata in the URLs
    const normalizedPageInfo = this._pageMappingData?.pages?.find((pageInfo) => input?.page?.match?.(pageInfo.regex));
    const platformVersion = this._window.navigator.userAgent;
    const [url, ...rest] = this._router?.url?.split('?') || [];
    const queryParams = getQueryParamsFromString(rest?.join('?'));
    const routeParams =
      input?.activatedRoute &&
      normalizedPageInfo.params?.reduce(
        (acc, param) => ({ [param]: findRouteParamValue(param, input.activatedRoute), ...acc }),
        {}
      );
    const meta: JsonObject = {
      routeParams,
      queryParams,
      url: url || '',
      circleTag: this._environmentVariablesService.circleTag,
      product: this._environmentVariablesService.product,
      ...(normalizedPageInfo?.meta || {}),
      ...(input?.meta || {}),
    };
    const appVersion = this._environmentVariablesService.appVersion;
    return {
      appVersion,
      page: (normalizedPageInfo?.pageName as string) || (input?.page as string) || '',
      platformVersion,
      meta,
    };
  }

  private _uploadEvent(event: AnalyticsEvent, batch = true): void {
    if (event.event && this._pageMappingData?.dontTrack?.indexOf(event.url) !== -1) {
      return;
    }
    if (batch) {
      // handle batching requests here
      this._batchAnalyticEvents$.next(event);
      return;
    }
    void this._analyticsApi.post(event);
  }

  private _cacheEvent(event: AnalyticsEvent) {
    if (
      event.event &&
      this._pageMappingData?.dontTrack?.indexOf(event.url) !== -1 &&
      this._pageMappingData?.dontCache?.indexOf(event.url) !== -1
    ) {
      return;
    }
    const cachedEvents: AnalyticsEvent[] = [
      ...((this._browserStorage.getObject(this._CACHE_NAME) as AnalyticsEvent[]) || []),
      event,
    ] as AnalyticsEvent[];
    this._browserStorage.setObject(this._CACHE_NAME, cachedEvents);
  }

  private _processCacheEvents(): void {
    if (this._processingCachedEvents) {
      return;
    }
    this._processingCachedEvents = true;
    const cachedEvents = (this._browserStorage.getObject(this._CACHE_NAME) as AnalyticsEvent[]) ?? [];
    cachedEvents.forEach((event) => {
      // passing true for batching to ensure processing the cache gets batched even if the default is changed
      this._uploadEvent(new AnalyticsEvent().deserialize(event), true);
    });
    // cleanup of the cached events from storage and flipping the
    // _processingCachedEvents flag will happen after the batch send
    // in the _batchAnalyticEvents$ subscription
  }
}

function findRouteParamValue(key: string, activatedRoute: ActivatedRoute, currentLevel = 0, maxLevels = 5): string {
  if (activatedRoute == null) {
    return null;
  }
  if (activatedRoute.snapshot.paramMap.has(key)) {
    // NOTE: This only gets a single param, not an array
    return activatedRoute.snapshot.paramMap.get(key);
  }
  if (currentLevel >= maxLevels - 1) {
    return null;
  }
  return findRouteParamValue(key, activatedRoute.firstChild, currentLevel + 1, maxLevels);
}
