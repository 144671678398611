import { Component } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { productDisplayName } from '@kservice';
import { Product } from '@ktypes/enums';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  constructor(private _environmentVariablesService: EnvironmentVariablesService) {}

  isPurposeful = this._environmentVariablesService.product === Product.purposeful;
  isResourceful = this._environmentVariablesService.product === Product.resourceful;
  productName = productDisplayName;
}

export const MockHelpComponent = MockComponent({
  selector: 'kp-help',
});
