<kui-email-screens-base headerTitle="Didn't Receive a Code?">
  <div icon class="header-icon">
    <svg-icon src="/assets/icons/question-filled-circle.svg"></svg-icon>
  </div>
  <div instructions>
    <ng-content select="[base-instructions]"></ng-content>
  </div>
  <ng-container footer>
    <button kutilBackButton class="button button-pill button-code-resend button-submit" (click)="resendCode.emit()">
      {{ 'Resend Code' | translate | async }}
    </button>
  </ng-container>
</kui-email-screens-base>
