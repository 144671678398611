import Stripe from 'stripe';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class SubscriptionSession implements Deserializable<JsonObject, SubscriptionSession> {
  constructor(public stripeCheckoutSession?: Stripe.Checkout.Session) {}

  deserialize(input: JsonObject): SubscriptionSession {
    if (input == null) {
      return null;
    }

    // Implementing the Stripe Checkout Session on a property because implementing it at the Class level
    // would require copying in all of the declarations and maintaining them as that changes.
    // As a property, we can rely on the types from Stripe and extend with our own metadata as needed.
    this.stripeCheckoutSession = input as Stripe.Checkout.Session;

    return this;
  }
}

/*
// Stripe.Checkout.Session as of 2020-03-02:
{
    "id": string;
    "object": "checkout.session";
    "billing_address_collection": string | null;
    "cancel_url": string; // `/checkout/cancel`
    "client_reference_id": string | null;
    "customer": string | Stripe.Customer | null;
    "customer_email": string | null;
    "display_items"?:  Session.DisplayItem[];
    "line_items"?: ApiList<Stripe.LineItem>;
    "livemode": boolean;
    "locale": Session.Locale | null;
    "metadata": Metadata | null;
    "mode": Session.Mode | null; // one of `payment`, `setup`, or `subscription`.
    "payment_intent": string | Stripe.PaymentIntent | null;
    "payment_method_types": string[]; // list of acceptable payment types, i.e. `card`
    "setup_intent": string | Stripe.SetupIntent | null;
    "shipping": Session.Shipping | null;
    "shipping_address_collection": Session.ShippingAddressCollection | null;
    "submit_type": Session.SubmitType | null;
    "subscription": string | Stripe.Subscription | null;
    "success_url": string; // `/checkout/success`
}

// Stripe.Checkout.Session.DisplayItem as of 2020-03-02:
{
    "amount"?: number;
    "currency"?: string; //  Three-letter [ISO currency code], `usd`
    "custom"?: DisplayItem.Custom;
    "plan"?: Stripe.Plan;,
    "quantity"?: number;
    "sku"?: Stripe.Sku;
    "type"?: string; // One of `custom`, `plan` or `sku`
}

// Stripe.Plan as of 2020-03-02:
{
        "id": string;
        "object": "plan";
        "active": boolean;
        "aggregate_usage": Plan.AggregateUsage | null;
        "amount": number | null;
        "amount_decimal": string | null;
        "billing_scheme": Plan.BillingScheme; //  Either `per_unit` or `tiered`
        "created": number;
        "currency": string; // Three-letter [ISO currency code], i.e. `usd`
        "deleted"?: void;
        "interval": Plan.Interval; // One of `day`, `week`, `month` or `year`.
        "interval_count": number;
        "livemode": boolean;
        "metadata": Metadata;
        "nickname": string | null;
        "product": string | Stripe.Product | Stripe.DeletedProduct | null;
        "tiers": Plan.Tier[] | null;
        "tiers_mode": Plan.TiersMode | null; // should be `graduated` or `volume` based
        "transform_usage": Plan.TransformUsage | null;
        "trial_period_days": number | null;
        "usage_type": Plan.UsageType; // Can be either `metered` or `licensed`
    }
*/
