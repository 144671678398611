import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { QuestionSet } from '@kp/question-set/models/question-set.model';
import { DataStoreService } from '@kservice';
import { JsonObject } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QuestionSetApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  getQuestionSets(
    refKeys: string[],
    distinctOnRefKey: boolean,
    filter?: string[],
    dateStart?: Date,
    dateEnd?: Date,
    page?: number,
    count?: number,
    type?: string
  ): Promise<QuestionSet[]> {
    const queryParams: JsonObject = {};

    queryParams['refKey'] = refKeys;

    if (distinctOnRefKey) {
      queryParams['distinctOnRefKey'] = distinctOnRefKey;
    }

    if (filter) {
      queryParams['createdLocation'] = filter;
    }

    if (dateStart) {
      queryParams['deviceCreatedTimestampMin'] = DateTimeUtil.formatInLocal(DateTimeUtil.stripTimeFromDate(dateStart));
    }

    if (dateEnd) {
      queryParams['deviceCreatedTimestampMax'] = DateTimeUtil.formatInLocal(DateTimeUtil.stripTimeFromDate(dateEnd));
    }
    if (page) {
      queryParams['page'] = page;
    }
    if (count) {
      queryParams['count'] = count;
    }
    if (type) {
      queryParams['type'] = type;
    }

    const request$ = this.performRequest<QuestionSet[]>(
      RequestType.GET,
      this.buildUrl('/question-sets', true, queryParams),
      {
        includeToken: true,
      }
    ).pipe(
      map((response: HttpResponse<QuestionSet[]>): QuestionSet[] =>
        response.body.map((questionSet) => new QuestionSet().deserialize(questionSet))
      ),
      catchError((error) => {
        console.warn('Failed getting question sets: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting question sets: ', error);
      return null;
    });
  }

  getQuestionSetsOnDate(date: Date, filter?: string[], useCreatedTimestamp?: false): Promise<QuestionSet[]> {
    const now = new Date();
    let start = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 1);
    if (!DateTimeUtil.isSameDay(now, date)) {
      start = date;
    }
    const end = new Date(+start);
    end.setDate(date.getDate() + 1);

    const timestampType = useCreatedTimestamp ? 'Created' : 'Completed';
    const timestampMin = `device${timestampType}TimestampMin`;
    const timestampMax = `device${timestampType}TimestampMax`;

    const queryParams: JsonObject = {};

    queryParams[timestampMin] = DateTimeUtil.formatInLocal(DateTimeUtil.stripTimeFromDate(start));
    queryParams[timestampMax] = DateTimeUtil.formatInLocal(DateTimeUtil.stripTimeFromDate(end));

    if (filter) {
      queryParams['createdLocation'] = filter;
    }

    const request$ = this.performRequest<QuestionSet[]>(
      RequestType.GET,
      this.buildUrl('/question-sets', true, queryParams),
      {
        includeToken: true,
      }
    ).pipe(
      map((response: HttpResponse<QuestionSet[]>): QuestionSet[] =>
        response.body.map((questionSet) => new QuestionSet().deserialize(questionSet))
      ),
      catchError((error) => {
        console.warn('Failed getting question sets on date: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting question sets on date: ', error);
      return null;
    });
  }

  getNearestQuestionSets(
    dateMax: Date,
    dateMin?: Date,
    filter?: string[],
    page?: number,
    count?: number
  ): Promise<QuestionSet[]> {
    const queryParams: JsonObject = {
      deviceCompletedTimestampMax: DateTimeUtil.formatInLocal(dateMax),
    };

    if (dateMin) {
      queryParams['deviceCompletedTimestampMin'] = DateTimeUtil.formatInLocal(dateMin);
    }

    if (filter) {
      queryParams['createdLocation'] = filter;
    }

    if (page) {
      queryParams['page'] = page;
    }

    if (count) {
      queryParams['count'] = count;
    }

    const request$ = this.performRequest<QuestionSet[]>(
      RequestType.GET,
      this.buildUrl('/question-sets', true, queryParams),
      {
        includeToken: true,
      }
    ).pipe(
      map(
        (response: HttpResponse<QuestionSet[]>): QuestionSet[] =>
          response?.body?.map((questionSet) => new QuestionSet().deserialize(questionSet)) || []
      ),
      catchError((error) => {
        console.warn('Failed getting nearest question set: ', error);
        return of(null);
      })
    );
    return firstValueFrom<QuestionSet[]>(request$).catch((error): null => {
      console.warn('Error getting nearest question set: ', error);
      return null;
    });
  }
}
