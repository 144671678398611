export type SimpleIconAnimationOptions = SimpleIconRiveAnimationOptions | SimpleIconSvgAnimationOptions;

export interface SimpleIconRiveAnimationOptions {
  animationName: string;
  artboardName?: string;
  duration?: number;
  endTime?: number;
  fileName: string;
  forwardSpeed?: number;
  height: number;
  mode?: 'one-shot' | 'loop' | 'ping-pong';
  reverseSpeed?: number;
  startTime?: number;
  type: SimpleIconAnimationType.rive;
  width: number;
}

export interface SimpleIconSvgAnimationOptions {
  duration: number;
  fileName: string;
  height: number;
  type: SimpleIconAnimationType.svg;
  width: number;
}

export enum SimpleIconAnimationType {
  rive = 'rive',
  svg = 'svg',
}
