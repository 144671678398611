import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { HttpStatusCode, RequestType } from '@ktypes/enums';
import { RemoteFirstVisit } from '@ktypes/models';
import { firstValueFrom } from 'rxjs';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class FirstVisitApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, _dataStoreService, _environmentVariablesService);
  }

  async getUiSettings(): Promise<RemoteFirstVisit> {
    const url = this.buildUrl('/ui-settings', true);
    const request$ = this.performRequest<RemoteFirstVisit>(RequestType.GET, url, { includeToken: true });
    const response = await firstValueFrom<HttpResponse<RemoteFirstVisit>>(request$).catch((error): null => {
      console.warn('Error getting UI Settings: ', error);
      return null;
    });
    if (response?.status === HttpStatusCode.OK) {
      return new RemoteFirstVisit().deserialize(response.body);
    }
    return null;
  }

  async setUiSettings(body: RemoteFirstVisit): Promise<RemoteFirstVisit> {
    const url = this.buildUrl('/ui-settings', true);
    const request$ = this.performRequest<RemoteFirstVisit>(RequestType.POST, url, {
      requestBody: body,
      includeToken: true,
    });
    const response = await firstValueFrom<HttpResponse<RemoteFirstVisit>>(request$).catch((error): null => {
      console.warn('Error setting UI Settings: ', error);
      return null;
    });
    if (response?.status === HttpStatusCode.OK) {
      return new RemoteFirstVisit().deserialize(response.body);
    }
    return null;
  }

  async updateUiSettings(body: Partial<RemoteFirstVisit>): Promise<RemoteFirstVisit> {
    const url = this.buildUrl('/ui-settings', true);
    const request$ = this.performRequest<RemoteFirstVisit>(RequestType.PATCH, url, {
      requestBody: body,
      includeToken: true,
    });
    const response = await firstValueFrom<HttpResponse<RemoteFirstVisit>>(request$).catch((error): null => {
      console.warn('Error updating UI Settings: ', error);
      return null;
    });
    if (response?.status === HttpStatusCode.OK) {
      return new RemoteFirstVisit().deserialize(response.body);
    }
    return null;
  }
}
