@if (challenge?.status.state === challengeState.upcoming && challenge?.startDate) {
  <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
    <svg-icon src="/assets/icons/clock.svg"></svg-icon>
    {{ 'Starts' | translate | async }} {{ challenge?.startDate | date: dateFormat }}
  </kui-label-flag>
}
@if (challenge?.status.state === challengeState.active && !isDetail) {
  <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
    <svg-icon src="/assets/icons/clock.svg"></svg-icon>
    {{ challenge?.status.daysLeft | number }} {{ 'days left' | translate | async }}
  </kui-label-flag>
}
@if (challenge?.status.state === challengeState.active && isDetail) {
  <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
    <svg-icon src="/assets/icons/clock.svg"></svg-icon>
    {{ 'Ends' | translate | async }} {{ challenge?.endDate | date: dateFormat }}
  </kui-label-flag>
}
@if (challenge?.status.state === challengeState.ended && challenge?.endDate) {
  <kui-label-flag [color]="color" [backgroundColor]="backgroundColor">
    <svg-icon src="/assets/icons/clock.svg"></svg-icon>
    {{ 'Ended' | translate | async }} {{ challenge?.endDate | date: dateFormat }}
  </kui-label-flag>
}
