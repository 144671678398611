import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { Invite, JsonObject } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class SharingApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, dataStoreService, _environmentVariablesService);
  }

  async getSharingOptions(): Promise<Invite[]> {
    const request$ = this.performRequest<Invite[]>(RequestType.GET, this.buildUrl('/sharing', true), {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Invite[]>): Invite[] =>
        response?.ok ? response.body.map((invite) => new Invite().deserialize(invite)) : null
      ),
      catchError(handleObservableError('Failed getting invites: '))
    );
    return await firstValueFrom(request$).catch(handleError('Error getting invites: '));
  }

  async getShortenedSharingLink(link: string): Promise<string> {
    const request$ = this.performRequest<string>(RequestType.POST, this.buildUrl('/sharing/link', true), {
      includeToken: true,
      requestBody: { link },
    }).pipe(
      map((response: HttpResponse<string>): string => (response?.ok ? response.body : null)),
      catchError(handleObservableError('Failed getting shortened sharing link: '))
    );
    return await firstValueFrom(request$).catch(handleError('Error getting shortened sharing link: '));
  }

  async getChallengeSharingLink(id: string): Promise<string> {
    const url = this.buildUrl(`/social-challenge/${id}/link`, true, {
      queryParams: {
        currentUserTimestamp: DateTimeUtil.formatInLocal(),
      },
    });
    const request$ = this.performRequest(RequestType.GET, url, { includeToken: true }).pipe(
      map((response: HttpResponse<JsonObject>) => {
        return response?.body?.link as string;
      })
    );
    return firstValueFrom(request$).catch(handleError(`Error getting sharing link for challenge ${id}: `));
  }
}

function handleObservableError(errorMessage: string): (error: string) => Observable<null> {
  return (error: string) => {
    console.warn(errorMessage, error);
    return of(null);
  };
}

function handleError(errorMessage: string): (error: string) => Promise<null> {
  return (error: string) => {
    console.warn(errorMessage, error);
    return new Promise(null);
  };
}
