import { Pipe, PipeTransform } from '@angular/core';
import { MockPipe } from '../utils/spec.util';

@Pipe({ name: 'replaceText' })
export class ReplaceTextPipe implements PipeTransform {
  // by default, if not given any values except a string, will replace spaces with underscores
  // usage, simple: {{ text | replaceText }}
  // usage, complex: {{ text | replaceText : ' ' : '_' }}
  transform(value: string, strToReplace = ' ', replacementStr = '_'): string {
    if (!value || typeof value !== 'string' || typeof strToReplace !== 'string' || typeof replacementStr !== 'string') {
      return value;
    }

    return value?.replace(new RegExp(strToReplace, 'g'), replacementStr);
  }
}

export const MockReplaceTextPipe = MockPipe('replaceText');
