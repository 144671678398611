import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class PulseSurveyEventInfo
  implements Deserializable<JsonObject, PulseSurveyEventInfo>, Serializable<JsonObject>
{
  constructor(
    public presentedInAppId?: string,
    public questionSetId?: string,
    public product?: string,
    public dialogueId?: string
  ) {}

  deserialize(input: JsonObject): PulseSurveyEventInfo {
    if (input == null) {
      return null;
    }

    this.presentedInAppId = input['presentedInAppId'] as string;
    this.questionSetId = input['questionSetId'] as string;
    this.product = input['product'] as string;
    this.dialogueId = input['dialogueId'] as string;

    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {
      product: this.product,
      presentedInAppId: this.presentedInAppId,
    };
    if (this.questionSetId != null) {
      json.user_question_set_id = this.questionSetId;
    }
    if (this.dialogueId != null) {
      json.user_dialogue_id = this.dialogueId;
    }

    return json;
  }
}
