import { Document } from '@contentful/rich-text-types';
import { Deserializable, JsonObject, Link, Serializable } from '@ktypes/models';

export class PulseSurveyDisplay implements Deserializable<JsonObject, PulseSurveyDisplay>, Serializable<JsonObject> {
  constructor(
    public rawBody?: Document,
    public callToActionText?: string,
    public isSponsored?: boolean,
    public title?: string,
    public link?: Link
  ) {}

  deserialize(input: JsonObject): PulseSurveyDisplay {
    if (input == null) {
      return null;
    }
    this.rawBody = input['body'] as Document;
    this.callToActionText = input['callToActionText'] as string;
    this.isSponsored = !!input['isSponsored'];
    this.title = input['title'] as string;
    this.link = new Link().deserialize(input['link']);
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['rawBody'] = this.rawBody;
    json['callToActionText'] = this.callToActionText;
    json['isSponsored'] = this.isSponsored;
    json['title'] = this.title;
    json['link'] = this.link.serialize();
    return json;
  }
}
