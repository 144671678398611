import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class QuestionSettings implements Deserializable<JsonObject, QuestionSettings>, Serializable<JsonObject> {
  constructor(
    public valueMax?: number,
    public valueMin?: number,
    public characterLimitMax?: number,
    public characterLimitMin?: number,
    public countMax?: number,
    public countMin?: number,
    public required?: boolean,
    public sliderSnaps?: boolean
  ) {}

  deserialize(input: JsonObject): QuestionSettings {
    if (input == null) {
      return null;
    }
    this.valueMax = input['valueMax'] as number;
    this.valueMin = input['valueMin'] as number;
    this.characterLimitMax = input['characterLimitMax'] as number;
    this.characterLimitMin = input['characterLimitMin'] as number;
    this.countMax = input['countMax'] as number;
    this.countMin = input['countMin'] as number;
    this.required = !!input['required'];
    this.sliderSnaps = !!input['sliderSnaps'];
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['valueMax'] = this.valueMax;
    json['valueMin'] = this.valueMin;
    json['characterLimitMax'] = this.characterLimitMax;
    json['characterLimitMin'] = this.characterLimitMin;
    json['countMax'] = this.countMax;
    json['countMin'] = this.countMin;
    json['required'] = this.required;
    json['sliderSnaps'] = this.sliderSnaps;
    return json;
  }
}
