import { ImageBloc, ImageType } from '@kp/shared/image/image.bloc';
import { Deserializable, JsonObject } from '@ktypes/models';

export class ImageRecord implements Deserializable<JsonObject, ImageRecord> {
  constructor(
    public id?: string,
    public userId?: string,
    public type?: ImageType,
    public uuid?: string,
    public originalFilename?: string,
    public originalImageUrl?: string,
    public signedUrl?: string,
    public thumbnailUrl?: string,
    public signedUrlExpirationDate?: string,
    public dateCreated?: string,
    public dateLastUpdated?: string
  ) {
    this.type = ImageBloc.translateImageType(type);
  }

  get urlSmallest() {
    return this.thumbnailUrl || this.urlOptimized;
  }

  get urlOptimized() {
    return this.signedUrl || this.originalImageUrl;
  }

  deserialize(input: JsonObject): ImageRecord {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.userId = input['userId'] as string;
    this.type = ImageBloc.translateImageType(input['imageType']);
    this.uuid = input['imageUUID'] as string;
    this.originalFilename = input['originalFileName'] as string;
    this.originalImageUrl = input['originalImageUrl'] as string;
    this.signedUrl = input['signedUrl'] as string;
    this.thumbnailUrl = input['thumbnailUrl'] as string;
    this.signedUrlExpirationDate = input['signedUrlExpirationDate'] as string;
    this.dateCreated = input['dateCreated'] as string;
    this.dateLastUpdated = input['dateLastUpdated'] as string;

    return this;
  }
}
