@if (showDoneAnimation) {
  <kp-done-animation (animationStateChange)="completeQuestionSet($event)"></kp-done-animation>
}
@if (currentQuestion) {
  <div class="question-set-container" [ngClass]="{ 'embedded-now': isEmbeddedNow }">
    @if (!showDoneAnimation) {
      <kp-question
        [isEmbedded]="isEmbeddedNow"
        [question]="currentQuestion"
        (updatedQuestionAnswer)="updateAnswer($event)"
        (canAdvance)="isQuestionAnswered = $event"></kp-question>
    }
    @if (!isEmbeddedNow && !showDoneAnimation) {
      <div class="bottom-nav-container">
        @if (currentQuestion?.inputType !== questionType.image_icon_selection) {
          <kp-bottom-arrow-nav
            [showsNextButton]="isQuestionAnswered"
            [disableNextButton]="isSubmitted"
            [showsSkip]="!currentQuestion?.settings?.required && !isQuestionAnswered"
            [showLargeFade]="false"
            (nextClick)="onSubmit()"></kp-bottom-arrow-nav>
        }
      </div>
    }
  </div>
}
