import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserModule, Title } from '@angular/platform-browser';
import { UrlSerializer } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PAGE_MAPPING } from '@kanalytics';
import { NAV_ITEM_LIST } from '@kbloc';
import { CONFIG } from '@kenv';
import { SocialChallengesModule } from '@kf-sc';
import { environment } from '@kp/../environments/environment';
import { AppRoutingModule } from '@kp/app-routing.module';
import { AppComponent } from '@kp/app.component';
import { PAGE_MAPPING_DATA } from '@kp/core/metrics/page-mapping-info';
import { CustomErrorHandler } from '@kp/error-handler/custom-error-handler.service';
import { httpInterceptorProviders } from '@kp/http-interceptors';
import { LocalResourcesModule } from '@kp/local-resources/local-resources.module';
import { hasNotificationSupport } from '@kp/notifications/notifications.bloc';
import { PastReflectionsModule } from '@kp/past-reflections/past-reflections.module';
import { Constants } from '@kp/shared/constants.service';
import { SharedModule } from '@kp/shared/shared.module';
import { WINDOW_PROVIDERS } from '@kservice';
import { TooltipVariations } from '@ktypes/props';
import { VersionTagComponent } from '@kui';
import { LowerCaseUrlSerializer } from '@kutil';
import { TIPPY_CONFIG, popperVariation, tooltipVariation } from '@ngneat/helipopper';
import { UserIdleModule } from 'angular-user-idle';

const supportedImports = [
  AppRoutingModule,
  BrowserModule,
  HttpClientModule,
  LocalResourcesModule,
  PastReflectionsModule,
  SocialChallengesModule,
  SharedModule,
  UserIdleModule.forRoot({
    idle: environment.idleTimeConfig.timeIdle,
    timeout: environment.idleTimeConfig.timeOut,
    ping: environment.idleTimeConfig.timePing,
  }),
  VersionTagComponent,
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
];

if (hasNotificationSupport) {
  // webservices and notifications supported, allow Firebase initialization
  supportedImports.push(
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging())
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: supportedImports,
  providers: [
    WINDOW_PROVIDERS,
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    Title,
    httpInterceptorProviders,
    { provide: CONFIG, useValue: environment },
    { provide: NAV_ITEM_LIST, useValue: Constants.navItemList },
    { provide: PAGE_MAPPING, useValue: PAGE_MAPPING_DATA },
    {
      provide: TIPPY_CONFIG,
      useValue: {
        defaultVariation: 'primary',
        variations: {
          tooltip: tooltipVariation,
          popper: popperVariation,
          primary: TooltipVariations.primary,
          primaryInfo: TooltipVariations.primaryInfo,
          challengeMenu: TooltipVariations.challengeMenu,
        },
      },
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
