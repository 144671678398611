import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export class NotificationDevice implements Deserializable<JsonObject, NotificationDevice>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public userId?: string,
    public platformName?: string,
    public notificationPlatformId?: string,
    public registrationToken?: string,
    public dateCreated?: string,
    public dateUpdated?: string,
    public dateDeleted?: string
  ) {}

  public deserialize(input: JsonObject): NotificationDevice {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.userId = input['userId'] as string;
    this.platformName = input['platformName'] as string;
    this.notificationPlatformId = input['notificationPlatformId'] as string;
    this.registrationToken = input['registrationToken'] as string;
    this.dateCreated = input['dateCreated'] as string;
    this.dateUpdated = input['dateUpdated'] as string;
    this.dateDeleted = input['dateDeleted'] as string;
    return this;
  }

  public serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['userId'] = this.userId;
    json['platformName'] = this.platformName;
    json['notificationPlatformId'] = this.notificationPlatformId;
    json['registrationToken'] = this.registrationToken;
    json['dateCreated'] = this.dateCreated;
    json['dateUpdated'] = this.dateUpdated;
    json['dateDeleted'] = this.dateDeleted;
    return json;
  }
}
