@if (showRemindersModal) {
  <kui-modal [isOpen]="showRemindersModal" (isOpenChange)="isRemindersModalOpen()" type="info">
    <kp-modal-enable-notifications (closeClicked)="handlePermissionsFromModal($event)"></kp-modal-enable-notifications>
  </kui-modal>
}

<kp-detail-view-action
  [title]="'Reminders'"
  infoText="A nudge at just the right time can make all the difference. Purposeful will send you notifications to help you take
  action."
  [primaryIconUrl]="setRemindersOn ? primaryIcon : null"
  [showInfo]="setRemindersOn && notifications?.[0] == null"
  (primaryIconClicked)="primaryIconClick()">
  @if (!setRemindersOn) {
    <ng-container info>
      @if (showPflNotificationDialog) {
        <kp-permission-dialog
          [canEnable]="true"
          description="You haven't enabled web notifications on this browser."
          (enableButtonClicked)="toggleWebReminders(true)"></kp-permission-dialog>
      }
      @if (showBrowserNotificationDialog) {
        <kp-permission-dialog
          description="Notifications from Purposeful are currently blocked on this browser. Please allow them in your browser settings to receive web reminders."></kp-permission-dialog>
      }
      @if (showBrowserUnsupported) {
        <kp-permission-dialog
          description="The browser you are using does not support web notifications. To receive web reminders, try updating your browser or using a different one."></kp-permission-dialog>
      }
    </ng-container>
  }
</kp-detail-view-action>
@if (setRemindersOn) {
  @for (notification of notifications; track notification) {
    <kp-action-reminder
      [cardId]="card.id"
      [notification]="notification"
      (notificationDeleted)="handleNotificationDeletion($event)"
      (notificationEdited)="handlePrimaryIconChange()"
      (notificationUpdated)="handleNotificationSave()"></kp-action-reminder>
  }
}
