<h2 class="selected-option" [attr.aria-label]="(currentOption ? 'Selected, ' + currentOption : '') | translate | async">
  {{ currentOption | translate | async }}
</h2>
<div class="likert-wrap">
  <div class="likert-container">
    @for (option of displayOptions; track option) {
      <div class="likert-wrapper">
        <input
          class="likert"
          type="radio"
          name="likert"
          aria-hidden="true"
          [checked]="option.isSelected"
          [id]="option.id"
          (change)="optionChanged(option)" />
        <label [for]="option.id" [ngClass]="{ 'option-selected': !currentOption }">
          <p class="label-text sr-only">
            {{ option.text | translate | async }}
          </p>
        </label>
      </div>
    }
  </div>
  <div class="labels">
    <!-- Added aria-hidden="true" in order to have only one text element read by screen-reader (from "label-text") -->
    <span class="label label-min" aria-hidden="true">{{ labelMin | translate | async }}</span>
    <span class="label label-max" aria-hidden="true">{{ labelMax | translate | async }}</span>
  </div>
</div>
