import { Component, Input } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-label-flag',
  templateUrl: './label-flag.component.html',
  styleUrls: ['./label-flag.component.scss'],
})
export class LabelFlagComponent {
  @Input() backgroundColor: string;
  @Input() color: string;
  @Input() flip: boolean;
}

export const MockLabelFlagComponent = MockComponent({
  selector: 'kui-label-flag',
  inputs: ['backgroundColor', 'color', 'flip'],
});
