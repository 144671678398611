import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { LocalResourcesApi } from '@kp/local-resources/local-resources.api';
import { CardDisplayType, CardEventType, HttpApiErrorResponse, HttpStatusCode } from '@ktypes/enums';
import {
  CardEvent,
  CardItem,
  DataStatus,
  LocalResourceDetailCardData,
  LocalResourcesProgram,
  LocalResourcesSearchItem,
  Status,
  StatusMessage,
  UserCardPreference,
} from '@ktypes/models';
import { isOfType } from '@kutil';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalResourcesBloc {
  constructor(
    private _localResourcesApi: LocalResourcesApi,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {}

  private _localResourceId = new BehaviorSubject<DataStatus<string>>(new DataStatus<string>(Status.done, null, null));

  private _singleLocalResource = new BehaviorSubject<DataStatus<LocalResourcesProgram>>(
    new DataStatus(Status.done, null, null)
  );

  private _searchedLocalResources = new BehaviorSubject<DataStatus<LocalResourcesSearchItem>>(null);

  // exposed publicly to allow for modal to be opened in app component
  detailViewClicked = new BehaviorSubject<LocalResourceDetailCardData>(null);

  get detailViewCard$(): Observable<LocalResourceDetailCardData> {
    return this.detailViewClicked.asObservable();
  }

  get localResourcesSearch$(): Observable<DataStatus<LocalResourcesSearchItem>> {
    return this._searchedLocalResources.asObservable();
  }

  get localResourceDetails$(): Observable<DataStatus<LocalResourcesProgram>> {
    return combineLatest([this._localResourceId, this._searchedLocalResources, this._singleLocalResource]).pipe(
      map(([localResourceId, searchedLocalResources, singleResource]) => {
        if (searchedLocalResources && singleResource.status !== Status.done) {
          return new DataStatus<LocalResourcesProgram>(
            localResourceId?.status,
            null,
            searchedLocalResources?.data?.programs.find((program) => {
              return program.id === localResourceId.data;
            })
          );
        } else {
          return singleResource;
        }
      })
    );
  }

  handleCardEvents(event: CardEvent) {
    if (event != null) {
      event.eventInfo.product = this._environmentVariablesService.product;
      this._handleInteractionAPIEvent(event, event.card);
    }
  }

  private _handleInteractionAPIEvent(event: CardEvent, localResourcesProgram: LocalResourcesProgram) {
    const eventToSend = event;
    if (
      event.type === CardEventType.LOCAL_RESOURCE_MORE &&
      localResourcesProgram.userState.preference !== UserCardPreference.MORE
    ) {
      localResourcesProgram.userState.preference = UserCardPreference.MORE;
      localResourcesProgram.userState.likeCount += 1;
    } else if (event.type === CardEventType.LOCAL_RESOURCE_MORE) {
      localResourcesProgram.userState.preference = UserCardPreference.NEUTRAL;
      eventToSend.type = CardEventType.LOCAL_RESOURCE_NEUTRAL;
      localResourcesProgram.userState.likeCount -= 1;
    }
    if (isOfType<LocalResourcesProgram, CardItem>(event.card, 'contentId')) {
      this._localResourcesApi.createCardEvent(event.card.contentId, eventToSend);
      if (event.type !== CardEventType.LOCAL_RESOURCE_CLOSED) {
        const updatedSearchList = this._searchedLocalResources.value?.data?.programs?.map((program) =>
          program.id === localResourcesProgram.id ? localResourcesProgram : program
        );
        this._searchedLocalResources.next(
          new DataStatus<LocalResourcesSearchItem>(
            Status.done,
            null,
            new LocalResourcesSearchItem().deserialize({
              ...(this._searchedLocalResources.value?.data || {}),
              programs: updatedSearchList,
            })
          )
        );
      }
    } else {
      this._localResourcesApi.createCardEvent(event.card.id, eventToSend);
    }
  }
  performLocalResourceSearch(searchTerm: string, zipCode?: string, setDefault?: boolean): void {
    if (this._searchedLocalResources.getValue()?.status === Status.starting) {
      // don't start another search if one is already in flight
      return;
    }
    this._searchedLocalResources.next(new DataStatus<LocalResourcesSearchItem>(Status.starting, null, null));
    this._localResourcesApi
      .performLocalResourceSearch(searchTerm, zipCode, setDefault)
      .then((resourceDataStatus) => {
        this._searchedLocalResources.next(resourceDataStatus);
      })
      .catch((error: HttpErrorResponse) => {
        console.warn(`There was an error searching for local resources: `, error);
        this._searchedLocalResources.next(
          new DataStatus<LocalResourcesSearchItem>(
            Status.error,
            new StatusMessage(
              error?.status ?? HttpStatusCode.BAD_REQUEST,
              (error?.error as HttpApiErrorResponse)?.explanation ?? error?.statusText ?? 'Bad Request'
            ),
            null
          )
        );
      });
  }

  getLocalResource(resourceId: string, zip?: string): void {
    this._localResourceId.next(
      new DataStatus<string>(Status.starting, new StatusMessage(Status.starting, ''), resourceId)
    );
    this._singleLocalResource.next(
      new DataStatus<LocalResourcesProgram>(Status.starting, new StatusMessage(Status.starting, ''), null)
    );
    this._localResourcesApi
      .getLocalResourceDetails(resourceId, zip || this._searchedLocalResources.value?.data?.zipCode)
      .then((resource) => {
        if (resource?.status === Status.done) {
          this._localResourceId.next(
            new DataStatus<string>(Status.done, new StatusMessage(Status.done, ''), resourceId)
          );
          this._singleLocalResource.next(
            new DataStatus<LocalResourcesProgram>(Status.done, new StatusMessage(Status.done, ''), resource.data)
          );
          if (this._searchedLocalResources.value?.data) {
            this.updateSearchResultsWithModifiedSingleResource(resource.data);
          }
        }
      });
  }

  handleLocalResourceSavedOrUnsaved(isSaved: boolean, cardType: CardDisplayType, localResourceId: string) {
    const originalResourceData =
      cardType === CardDisplayType.detailed
        ? this._singleLocalResource?.value?.data
        : this._searchedLocalResources.value?.data?.programs?.find((program) => {
            return program.id === localResourceId;
          });
    const updatedLocalResource = new LocalResourcesProgram().deserialize({
      ...(originalResourceData || {}),
      userState: {
        ...(originalResourceData.userState || {}),
        saveState: { ...originalResourceData.userState.saveState, isSaved },
      },
    });
    this._singleLocalResource.next(
      new DataStatus<LocalResourcesProgram>(Status.done, new StatusMessage(Status.done, ''), updatedLocalResource)
    );
    if (this._searchedLocalResources.value?.data) {
      this.updateSearchResultsWithModifiedSingleResource(updatedLocalResource);
    }
  }

  updateSearchResultsWithModifiedSingleResource(updatedResource: LocalResourcesProgram) {
    const updatedSearchList = this._searchedLocalResources.value?.data?.programs?.map((program) =>
      program.id === updatedResource.id ? updatedResource : program
    );
    this._searchedLocalResources.next(
      new DataStatus<LocalResourcesSearchItem>(
        Status.done,
        null,
        new LocalResourcesSearchItem().deserialize({
          ...(this._searchedLocalResources.value?.data || {}),
          programs: updatedSearchList,
        })
      )
    );
  }
}
