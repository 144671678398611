import { PulseSurveyEventInfo } from '@kp/pulse-survey/models/pulse-survey-event-info.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';

export enum PulseSurveyEventType {
  pulse_survey_popup_closed = 'pulse_survey_popup_closed',
  pulse_survey_opened = 'pulse_survey_opened',
  pulse_survey_completed = 'pulse_survey_completed',
  pulse_survey_closed_early = 'pulse_survey_closed_early',
}

export class PulseSurveyEvent implements Deserializable<JsonObject, PulseSurveyEvent>, Serializable<JsonObject> {
  public deviceCreatedTimestamp: string;

  constructor(
    public eventType?: PulseSurveyEventType,
    public eventInfo?: PulseSurveyEventInfo
  ) {
    this.deviceCreatedTimestamp = DateTimeUtil.formatInLocal();
    if (!eventInfo) {
      this.eventInfo = new PulseSurveyEventInfo();
    }
  }

  deserialize(input: JsonObject): PulseSurveyEvent {
    if (input == null) {
      return null;
    }
    this.eventType = input['eventType'] as PulseSurveyEventType;
    this.eventInfo = input['eventInfo'] as PulseSurveyEventInfo;
    this.deviceCreatedTimestamp = input['deviceCreatedTimestamp'] as string;
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json.eventType = this.eventType;
    json.eventInfo = this.eventInfo.serialize();
    json.deviceCreatedTimestamp = this.deviceCreatedTimestamp;
    return json;
  }
}
