import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { productDisplayName } from './product.service';

@Injectable({
  providedIn: 'root',
})
export class TitleStrategyDefaultService extends TitleStrategy {
  constructor(private readonly _title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title != null && title !== '') {
      // TODO: Translate - beware of circular dependencies with features/localization though
      this._title.setTitle(`${title} | ${productDisplayName}`);
    } else {
      this._title.setTitle(`${productDisplayName}`);
    }
  }
}
