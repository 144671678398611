import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CenterPath } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { Selection, select } from 'd3';

const ANIMATION_DURATION = 1200;
const PATHS = {
  ARROW:
    'M2.9774 12.2876C4.2306 12.2876 5.2422 11.276 5.2346 10.0303 5.2346 9.819 5.1969 9.6302 5.144 9.4415L9.9755 4.61 9.9831 9.7963C9.9831 10.4758 10.5342 11.0268 11.2136 11.0268 11.893 11.0268 12.4441 10.4758 12.4441 9.7963L12.4441.4202 3.068.4202C2.3886.4202 1.8375.9713 1.8375 1.6508 1.8375 2.3302 2.3886 2.8813 3.068 2.8813L8.2317 2.8813 3.3096 7.8033C3.2039 7.7882 3.0982 7.7731 2.9774 7.7731 1.7243 7.7731.7127 8.7847.7202 10.0303.7202 11.2835 1.7318 12.2951 2.9774 12.2876Z',
  CHECK:
    'M12.4931 1.04394C12.853 0.665806 13.4518 0.65048 13.8305 1.0097C14.2093 1.36893 14.2247 1.96667 13.8648 2.3448L5.39006 11.25L0.510159 6.12226C0.150305 5.74413 0.165659 5.14639 0.544452 4.78717C0.923245 4.42794 1.52204 4.44327 1.88189 4.8214L5.39006 8.50774L12.4931 1.04394Z',
  GROUP:
    'M15.2879 7.0697C16.2507 6.4665 16.8975 5.3556 16.8975 4.0903 16.8975 2.1776 15.4275.618 13.6195.618 13.3034.618 13.0021.6694 12.7154.7577 13.0976 1.0594 13.421 1.4345 13.6709 1.8612 14.8102 1.8906 15.7215 2.8764 15.7215 4.0829 15.7215 5.2894 14.7734 6.312 13.6121 6.312 13.5386 6.312 13.4651 6.2973 13.3916 6.2899 13.1123 6.6504 12.7815 6.9667 12.4067 7.2021 12.7963 7.3934 13.1638 7.6361 13.5018 7.9304 13.5386 7.9304 13.5754 7.923 13.6195 7.923 14.8616 7.923 15.9346 8.7765 16.4785 10.0123H14.9718C15.1262 10.3802 15.2511 10.7627 15.3393 11.1747H18C17.7942 9.3134 16.7284 7.7612 15.2879 7.0697ZM10.9955 7.2095C12.1494 6.5327 12.9359 5.2968 12.9359 3.8769 12.9359 1.7362 11.1719 0 8.9963 0 6.8208 0 5.0568 1.7362 5.0568 3.8769 5.0568 5.2968 5.8358 6.5327 6.9972 7.2095 5.2626 7.9819 3.9837 9.7107 3.7338 11.8H14.2662C14.0163 9.7181 12.7375 7.9819 11.0029 7.2095H10.9955ZM6.4606 3.8769C6.4606 2.5012 7.5998 1.383 8.9963 1.383 10.3928 1.383 11.532 2.5012 11.532 3.8769 11.532 5.2526 10.3928 6.3708 8.9963 6.3708 7.5998 6.3708 6.4606 5.2526 6.4606 3.8769ZM5.5492 10.4979C6.2034 9.1149 7.497 8.1659 8.989 8.1659 10.481 8.1659 11.7746 9.1149 12.4288 10.4979H5.5565 5.5492ZM0 11.1747H2.6607C2.7489 10.77 2.8738 10.3802 3.0282 10.0123H1.5214C2.0653 8.7691 3.1384 7.923 4.3806 7.923 4.4173 7.923 4.4541 7.9304 4.4982 7.9304 4.8289 7.6361 5.1964 7.3934 5.5933 7.2021 5.2111 6.9667 4.8804 6.6504 4.6084 6.2899 4.5349 6.2973 4.4614 6.312 4.3879 6.312 3.2266 6.312 2.2785 5.3114 2.2785 4.0829 2.2785 2.8544 3.1972 1.8906 4.3291 1.8612 4.579 1.4272 4.9024 1.052 5.2846.7577 4.998.6694 4.6966.618 4.3806.618 2.5725.618 1.1025 2.1776 1.1025 4.0903 1.1025 5.3556 1.7493 6.4665 2.7121 7.0697 1.2715 7.7612.2058 9.3134 0 11.1747Z',
  INDIVIDUAL:
    'M4.78528 17.0221C5.78747 14.9089 7.75964 13.458 10.0433 13.458C12.327 13.458 14.297 14.9089 15.2992 17.0221H4.78528ZM10.0433 3.11205C12.1766 3.11205 13.9114 4.81859 13.9114 6.91587C13.9114 9.01314 12.1766 10.7197 10.0433 10.7197C7.90898 10.7197 6.17313 9.01314 6.17313 6.91587C6.17313 4.81859 7.90898 3.11205 10.0433 3.11205ZM13.1015 12.0006C14.8696 10.9689 16.0597 9.07967 16.0597 6.91587C16.0597 3.6538 13.3604 1 10.0433 1C6.72525 1 4.0248 3.6538 4.0248 6.91587C4.0248 9.07967 5.21498 10.9689 6.98305 12.0006C4.33416 13.1749 2.38133 15.8224 2 19.0011H18.0845C17.7032 15.8224 15.7515 13.1749 13.1015 12.0006Z',
  EMPTY: '',
};

@Component({
  selector: 'kf-sc-social-challenge-progress',
  templateUrl: './social-challenge-progress.component.html',
  styleUrls: ['./social-challenge-progress.component.scss'],
})
export class SocialChallengeProgressComponent implements AfterViewInit, OnChanges {
  // specifically not grouping so that changes to data is immediately reflected in SVG data
  @Input() centerPathRef: CenterPath;
  @Input() groupExclusive: boolean;
  @Input() groupGoal: number;
  @Input() groupProgress = 0;
  @Input() individualCompleted: boolean;
  @Input() individualDayComplete: boolean;
  @Input() individualExclusive: boolean;
  @Input() individualGoal: number;
  @Input() individualProgress = 0;
  @Input() initGroupProgress = 0;
  @Input() initIndividualProgress = 0;
  @Input() isOnBackground = false;

  private _progressSvg: Selection<SVGElement, unknown, null, undefined>;
  private _progressSvgCenter: Selection<any, unknown, SVGElement, undefined>;
  private _svgInitialized = false;

  @ViewChild('progress') private set progressRef(value: ElementRef<SVGElement>) {
    this._progressSvg = select(value?.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initGroupProgress < 0) {
      this.initGroupProgress = 0;
    }
    if (this.initIndividualProgress < 0) {
      this.initIndividualProgress = 0;
    }
    if (this._svgInitialized) {
      this._updateProgressData();
    }
  }

  ngAfterViewInit() {
    this._waitForProgressSvg();
  }

  private _waitForProgressSvg() {
    if (this._progressSvg == null) {
      setTimeout(() => {
        this._waitForProgressSvg();
      }, 50);
      return;
    }
    this._setupProgressSVG();
  }

  private _setupProgressSVG() {
    this._progressSvgCenter = this._progressSvg.append('g');
    this._progressSvgCenter.classed('progress-inner', true);

    this._updateProgressData();

    this._svgInitialized = true;
  }

  private _updateProgressData() {
    this._drawCenter();
  }

  private _drawCenter() {
    // Reset Center
    this._progressSvgCenter.selectChildren('g').remove();
    const centerG = this._progressSvgCenter.append('g');

    // Draw Center
    centerG
      .attr('opacity', 0)
      .transition()
      .duration(ANIMATION_DURATION * 0.8)
      .attr('opacity', 1);
    if (this.centerPathRef) {
      centerG.classed(this.centerPathRef, true);
      centerG.attr('transform', 'translate(16.5, 16.5)').append('path').attr('d', PATHS[this.centerPathRef]);
    } else if (this.groupExclusive) {
      centerG.classed(CenterPath.GROUP, true);
      centerG.attr('transform', 'translate(21, 24)').append('path').attr('d', PATHS[CenterPath.GROUP]);
    } else if (this.individualExclusive) {
      centerG.classed(CenterPath.INDIVIDUAL, true);
      centerG.attr('transform', 'translate(20, 20)').append('path').attr('d', PATHS[CenterPath.INDIVIDUAL]);
    } else if (this.individualDayComplete || this.individualCompleted) {
      centerG.classed(CenterPath.CHECK, true);
      centerG
        .append('circle')
        .attr('cx', 30)
        .attr('cy', 30)
        .attr('r', 27 / 2);
      centerG
        .append('g')
        .attr('transform', 'translate(22.5, 24)')
        .append('path')
        .attr('d', PATHS[CenterPath.CHECK])
        .classed('progress-completed-check', true);
    } else {
      centerG.classed(CenterPath.ARROW, true);
      centerG.attr('transform', 'translate(23.5, 23.5)').append('path').attr('d', PATHS[CenterPath.ARROW]);
    }
  }
}

export const MockSocialChallengeProgressComponent = MockComponent({
  selector: 'kf-sc-social-challenge-progress',
  inputs: [
    'centerPathRef',
    'groupExclusive',
    'groupGoal',
    'groupProgress',
    'individualCompleted',
    'individualDayComplete',
    'individualExclusive',
    'individualGoal',
    'individualProgress',
    'initGroupProgress',
    'initIndividualProgress',
    'isOnBackground',
  ],
});
