import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsPageName } from '@kanalytics';
import { FirstVisitBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { SocialChallengeBloc } from '@kf-sc';
import { DialogueBloc } from '@kp/dialogue/dialogue.bloc';
import { DialogueLogicKey } from '@kp/dialogue/models/dialogue.model';
import { QuestBloc } from '@kp/quest/quest.bloc';
import { SearchBloc } from '@kp/search/search.bloc';
import { CardBloc, CardType } from '@kp/shared/components/cards/card.bloc';
import { BrowserCheckService } from '@kp/shared/services/browser-check.service';
import { FeatureFilterService } from '@kp/shared/services/feature-filter.service';
import { ThemeService } from '@kservice';
import { CardEventType, CardRequestType, CardScreen, Product } from '@ktypes/enums';
import { CardContentType, CardEvent, CardItem, Link, LinkType, Quest, QuestItem } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-detail-card-view',
  templateUrl: './detail-card-view.component.html',
  styleUrls: ['./detail-card-view.component.scss'],
})
export class DetailCardViewComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private _browserCheckService: BrowserCheckService,
    public cardBloc: CardBloc,
    private _dialogueBloc: DialogueBloc,
    @Inject(DOCUMENT) private _document: Document,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _featureFilterService: FeatureFilterService,
    private _firstVisitBloc: FirstVisitBloc,
    private _questBloc: QuestBloc,
    private _router: Router,
    private _searchBloc: SearchBloc,
    private _socialChallengeBloc: SocialChallengeBloc,
    public themeService: ThemeService
  ) {}

  @Input() card: CardItem;
  @Input() quest?: Quest;
  @Input() questItem?: QuestItem;
  @Input() requestType?: CardRequestType;
  @Input() screen = CardScreen.discover; // default to discover to fix compile

  @Output() modalClose = new EventEmitter<boolean>(); // True if deep link clicked, false otherwise

  analyticsPageName = AnalyticsPageName.cardDetail;
  cardContentType = CardContentType;
  cardScreen = CardScreen;
  cardEventType = CardEventType;
  challengeDomainKey?: string;
  isSafari = false;
  product = this._environmentVariablesService.product;
  returnUrl: string;
  isPurposeful = true;
  showHeaderShadow = false;

  @ViewChild('mediaContainer') mediaContainer: ElementRef;

  private _actionAddedStatus: boolean;
  private _questAddedStatus: boolean;
  private _destroy$ = new Subject<void>();
  private _observer: IntersectionObserver;

  ngOnInit() {
    this.isSafari = this._browserCheckService.deviceInfo.browser === 'Safari';
    this.isPurposeful = this._environmentVariablesService.product === Product.purposeful;
    this.returnUrl = this.cardBloc.getReturnUrlFromCardScreen(this.screen, this._router.url);
    this._firstVisitBloc.hasSeen$.pipe(takeUntil(this._destroy$)).subscribe((objStart) => {
      this._actionAddedStatus = objStart.firstActionAdded;
      this._questAddedStatus = objStart.firstQuestAdded;
    });
    this._socialChallengeBloc.currentChallenge$.pipe(takeUntil(this._destroy$)).subscribe((currentChallenge) => {
      this.challengeDomainKey = currentChallenge?.domain?.key;
    });
  }

  ngAfterViewInit() {
    this._observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.showHeaderShadow = entry.intersectionRatio !== 1;
        });
      },
      {
        threshold: 1,
      }
    );
    if (this.mediaContainer) {
      this._observer.observe(this.mediaContainer.nativeElement);
    }
  }

  ngOnDestroy() {
    this._observer?.disconnect?.();
    this._destroy$.next();
    this._destroy$.complete();
  }

  async linkClicked(event: Event) {
    if (this.card?.inQuest && !this.card?.cardLink?.hasLink && !this.card?.userState?.isCompleted) {
      await this.cardBloc.completeQuestCard(this.card, this.quest);
      this._dialogueBloc.getDialogueStatus(DialogueLogicKey.focus_aware_daily_checkin_dialogue);
      this._questBloc.completeQuestIfDone(this.card, this.quest);
    }
    this.cardBloc.detailViewClicked.next({ detailViewClicked: false, card: null, screen: null });
    this.cardBloc.detailViewClosed.next(true);
    event.stopPropagation();
  }

  async markUncomplete(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    await this.cardBloc.handleCardEvents(
      new CardEvent(this.card.id, this.card, CardEventType.UNCOMPLETE, this.requestType),
      false
    );
    this._dialogueBloc.getDialogueStatus(DialogueLogicKey.focus_aware_daily_checkin_dialogue);
  }

  async markComplete(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.modalClose.emit(false);
    await this.cardBloc.handleCardEvents(
      new CardEvent(this.card.id, this.card, CardEventType.COMPLETE, this.requestType),
      false
    );
    this._dialogueBloc.getDialogueStatus(DialogueLogicKey.focus_aware_daily_checkin_dialogue);
  }

  addToOrRemoveFromActions(event: Event, eventType: CardEventType) {
    event.preventDefault();
    event.stopPropagation();

    if (eventType === CardEventType.TRACK) {
      if (!this._actionAddedStatus && !this.card.isQuestCard) {
        this.cardBloc.firstCardAdded(CardType.action);
      }
      if (!this._questAddedStatus && this.card.isQuestCard) {
        this.cardBloc.firstCardAdded(CardType.quest);
      }
    }

    this.sendCardEvent(eventType);
  }

  async sendCardEvent(eventType: CardEventType) {
    await this.cardBloc.handleCardEvents(
      new CardEvent(
        this.card.id,
        this.card,
        eventType,
        !this._searchBloc.isSearchState ? this.requestType : CardRequestType.SEARCH
      )
    );
    if (
      [CardEventType.TRACK, CardEventType.UNTRACK, CardEventType.COMPLETE, CardEventType.UNCOMPLETE].includes(eventType)
    ) {
      this._featureFilterService.refreshCardsForFilterAndDomain();
    }
    if (this.challengeDomainKey) {
      this.cardBloc.refresh(CardRequestType.DOMAIN_ACTION, null, this.challengeDomainKey);
    }
  }

  get questActionType() {
    return this.card?.inQuest ? new Link().deserialize({ type: LinkType.ACTION }) : null;
  }
}

export const MockDetailCardViewComponent = MockComponent({
  selector: 'kp-detail-card-view',
  inputs: ['card', 'quest', 'questItem', 'requestType', 'screen'],
});
