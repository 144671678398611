import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { KumanuKeyType } from '@kp/dialogue/models/dialogue.model';
import { AnswerValue } from '@kp/question-set/models/answer-value.model';
import { Answer } from '@kp/question-set/models/answer.model';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import {
  Question,
  QuestionType,
  questionTypeMultipleOptions,
  questionTypeSingleOption,
  questionTypeText,
} from '@kp/question-set/models/question.model';
import { JsonObject } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnChanges {
  @Input() isEmbedded = false;
  @Input() key: string;
  @Input() keyType: KumanuKeyType;
  @Input() individualBlockKey: string;
  @Input() question: Question;
  @Input() userDialogueBlockId: string;

  @Output() canAdvance = new EventEmitter<boolean>();
  @Output() updatedQuestionAnswer = new EventEmitter<Answer>();

  private _analyticsJson: JsonObject = {};
  private _localAnswer: Answer;
  private _max: number;
  private _min: number;
  private _options: DisplayOption[] = [];

  constructor(private _analyticsBloc: AnalyticsBloc) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this._questionChanged(changes, this._localAnswer)) {
      // ensure local state is cleared if the question has changed
      this._clearLocalState();
    }
    if (this.question) {
      this._setQuestionVariables();
      this._sendQuestionStartEvent();
    }
  }

  private _sendQuestionStartEvent() {
    this._analyticsJson['questionId'] = this.question?.id;
    this._analyticsJson[this.keyType] = this.key;
    this._analyticsJson['questionLogicKey'] = this.individualBlockKey;
    this._analyticsJson['userDialogueBlockId'] = this.userDialogueBlockId;
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromEvent({ event: AnalyticEvent.question_start, meta: this._analyticsJson })
    );
  }

  private _setQuestionVariables() {
    this._setExistingOptions();
    this._min = this.question?.settings?.countMin;
    this._max = this.question?.settings?.countMax;
    this._setAnswered();
  }

  private _setExistingOptions() {
    if (Array.isArray(this.question?.answer?.values)) {
      this._options = this.question.display.optionsWithAnswers(this.question.answer.values);
    }
  }

  private _setAnswered() {
    // set question answered defaults and for various input types
    if (this._localAnswer && !this.question?.answer) {
      this.question.answer = this._localAnswer;
      this.canAdvance.emit(false);
    } else if (
      !this._localAnswer &&
      this.question?.answer &&
      this.question?.inputType !== QuestionType.image_icon_selection
    ) {
      this._localAnswer = this.question.answer;
    }
    this.updatedQuestionAnswer.emit(this._localAnswer);
    switch (this.question?.inputType) {
      case QuestionType.long_text:
      case QuestionType.short_text:
        this._setLongTextAnswered();
        break;
      case QuestionType.slider:
      case QuestionType.radio_button:
        this._setSingleAnswered();
        break;
      case QuestionType.image_icon_selection:
      case QuestionType.image_word_list:
      case QuestionType.word_list:
      case QuestionType.checkbox:
        this._setMultipleChoiceQuestionAnswered();
        break;
    }
  }

  private _setLongTextAnswered(): void {
    this.canAdvance.emit(this._localAnswer?.values?.[0]?.value && this._localAnswer?.values?.[0]?.value !== '');
  }

  private _setSingleAnswered() {
    // TODO: should the first condition here includes sliders with the following?
    //  questionTypeSingleOption.includes(this.question.inputType)
    this.canAdvance.emit(
      !!(
        (this.question?.inputType === QuestionType.radio_button && this._localAnswer?.values?.[0]?.optionId != null) ||
        this._localAnswer?.values?.[0]?.optionId ||
        this._localAnswer?.values?.[0]?.value
      )
    );
  }

  private _setMultipleChoiceQuestionAnswered(): void {
    let canAdvance;
    if (this.question?.settings?.required) {
      if (questionTypeMultipleOptions.includes(this.question.inputType)) {
        canAdvance = this._setMultipleOptionAnswer();
      }
    } else if (questionTypeMultipleOptions.includes(this.question.inputType) && this.numberOfOptionsSelected === 0) {
      canAdvance = false;
    } else if (questionTypeMultipleOptions.includes(this.question.inputType) && this._min) {
      canAdvance = this._setMultipleOptionAnswer();
    } else if (questionTypeMultipleOptions.includes(this.question.inputType) && this.numberOfOptionsSelected > 0) {
      canAdvance = true;
    } else if (this.question?.inputType === QuestionType.image_icon_selection && this._localAnswer) {
      canAdvance = true;
    }

    // canAdvance can be not set (undefined) or true/false. Only do stuff if it's been set.
    if (canAdvance != null) {
      this.canAdvance.emit(canAdvance);
    }
  }

  private _setMultipleOptionAnswer(): boolean {
    if (this._min != null && this._max != null) {
      return this.numberOfOptionsSelected >= this._min && this.numberOfOptionsSelected <= this._max;
    } else if (this._min != null) {
      return this.numberOfOptionsSelected >= this._min;
    } else if (this._max != null) {
      return this.numberOfOptionsSelected <= this._max && this.numberOfOptionsSelected !== 0;
    }
    return this.numberOfOptionsSelected !== 0;
  }

  get numberOfOptionsSelected() {
    return Array.isArray(this._options) ? this._options.filter((option) => option.isSelected).length : 0;
  }

  setAnswer(answer: string | AnswerValue | DisplayOption[]): void {
    // call the appropriate function for the question input type when answered
    switch (this.question?.inputType) {
      case QuestionType.long_text:
      case QuestionType.short_text:
        this._setTextAnswer(answer as string);
        break;
      case QuestionType.slider:
      case QuestionType.radio_button:
      case QuestionType.likert:
        this._setSingleAnswer(answer as AnswerValue);
        break;
      case QuestionType.image_icon_selection:
      case QuestionType.image_word_list:
      case QuestionType.image_tile:
      case QuestionType.word_list:
      case QuestionType.checkbox:
        this._changeOptions(answer as DisplayOption[]);
        this._setOptionsAnswer();
        break;
    }
    this.updatedQuestionAnswer.emit(this._localAnswer);
  }

  private _setTextAnswer(text: string): void {
    this._localAnswer = new Answer().deserialize({
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      values: [new AnswerValue(null, text)],
    });
    this._setLongTextAnswered();
  }

  private _setSingleAnswer(answer: AnswerValue): void {
    this._localAnswer = new Answer().deserialize({
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      values: [answer],
    });
    this._setSingleAnswered();
  }

  private _changeOptions(options: DisplayOption[]): void {
    this._options = options;
    this._setMultipleChoiceQuestionAnswered();
  }

  private _setOptionsAnswer() {
    const answerList: AnswerValue[] = [];
    this._options.map((option) => {
      if (option.isSelected) {
        answerList.push(
          new AnswerValue().deserialize({
            optionId: option.id,
            value: true,
          })
        );
      } else {
        answerList.push(
          new AnswerValue().deserialize({
            optionId: option.id,
            value: false,
          })
        );
      }
    });

    this._localAnswer = new Answer().deserialize({
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      values: answerList,
    });
  }

  private _questionChanged(changes: SimpleChanges, localAnswer: Answer) {
    // Question has "changed" when
    //  - the question id changes
    //  - when it does not match the question input type
    //  - when the existing answer option don’t exist in the question’s answer options.
    const currentQuestion: Question = changes?.question?.currentValue as Question;
    const previousQuestion: Question = changes?.question?.previousValue as Question;
    const idDiffers = currentQuestion?.id !== previousQuestion?.id;
    const inputDiffers = currentQuestion?.inputType !== previousQuestion?.inputType;
    let invalidAnswer = false;
    if (
      localAnswer &&
      (questionTypeSingleOption.includes(currentQuestion?.inputType) ||
        questionTypeMultipleOptions.includes(currentQuestion?.inputType))
    ) {
      const answerOptionIds = localAnswer.values?.map((value: AnswerValue) => value.optionId);
      invalidAnswer = !currentQuestion?.display?.options?.some(
        (option) => option.id != null && answerOptionIds?.includes(option.id)
      );
    } else if (localAnswer && questionTypeText.includes(currentQuestion?.inputType)) {
      invalidAnswer = localAnswer.values?.every(
        (answerValue) => answerValue.optionId != null || typeof answerValue.value !== 'string'
      );
    }
    return idDiffers || inputDiffers || invalidAnswer;
  }

  private _clearLocalState() {
    delete this._localAnswer;
    delete this._max;
    delete this._min;
    delete this._options;
  }
}

export const MockQuestionComponent = MockComponent({
  selector: 'kp-question',
  inputs: ['isEmbedded', 'key', 'keyType', 'individualBlockKey', 'question', 'userDialogueBlockId'],
});
