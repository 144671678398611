import { Injectable } from '@angular/core';
import { TagBloc } from '@kbloc';
import { SocialChallengeBloc } from '@kf-sc';
import { FocusBloc } from '@kp/focus/focus.bloc';
import { IntentionBloc } from '@kp/intentions/intention.bloc';
import { CardBloc } from '@kp/shared/components/cards/card.bloc';
import { FilterFeature } from '@kp/shared/constants.service';
import { CardRequestType } from '@ktypes/enums';
import { CardCollection, DataStatus, Domain, Status } from '@ktypes/models';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureFilterService {
  constructor(
    private _cardBloc: CardBloc,
    private _focusBloc: FocusBloc,
    private _intentionBloc: IntentionBloc,
    private _socialChallengeBloc: SocialChallengeBloc,
    private _tagBloc: TagBloc
  ) {}

  private _filterFeatures$ = new BehaviorSubject<FilterFeature>(FilterFeature.default);

  get filterDomain$(): Observable<Domain> {
    return this.filterFeatures$.pipe(
      switchMap((feature) => {
        switch (feature) {
          case FilterFeature.challenge:
            return this._socialChallengeBloc.mostRecentJoinedChallenge$.pipe(
              map((recentChallenge) => recentChallenge?.domain)
            );
          case FilterFeature.intention:
            return this._intentionBloc.intention$.pipe(map((intention) => intention?.domain));
          case FilterFeature.focus:
            return this._focusBloc.focus$.pipe(map((focusDataStatus) => focusDataStatus?.data?.domain));
          default:
            return of(null);
        }
      })
    );
  }

  get filterFeatures$(): Observable<FilterFeature> {
    return combineLatest([this.focus$, this.intention$, this.challenge$, this._filterFeatures$.asObservable()]).pipe(
      map(([focusStatus, intention, challenge, filterFeature]) => {
        const focus = focusStatus?.data;
        switch (filterFeature) {
          case FilterFeature.focus:
            return focus ? FilterFeature.focus : FilterFeature.default;
          case FilterFeature.challenge:
            return challenge ? FilterFeature.challenge : FilterFeature.default;
          case FilterFeature.intention:
            return intention ? FilterFeature.intention : FilterFeature.default;
          case FilterFeature.default:
          default:
            return FilterFeature.default;
        }
      })
    );
  }

  get filteredActionCards$(): Observable<DataStatus<CardCollection[]>> {
    return this.filterDomain$.pipe(
      switchMap((domain) => {
        if (domain) {
          return this._cardBloc.domainFilteredCardCollections$(domain?.key, CardRequestType.DOMAIN_ACTION);
        }
        return this._cardBloc.takeActionCollection$;
      })
    );
  }

  get filteredRecommendedCards$(): Observable<DataStatus<CardCollection[]>> {
    return this.filterDomain$.pipe(
      switchMap((domain) => {
        if (domain) {
          return this._cardBloc.domainFilteredCardCollections$(domain?.key, CardRequestType.RECOMMENDED);
        }
        return this._cardBloc.recommendedCollection$;
      })
    );
  }

  get focus$() {
    return this._focusBloc.focus$;
  }

  get intention$() {
    return this._intentionBloc.intention$;
  }

  get challenge$() {
    return this._socialChallengeBloc.mostRecentJoinedChallenge$;
  }

  get socialChallengeEnabled$() {
    return this._tagBloc.socialChallengeEnabled$;
  }

  getDefaultWidgetData(): void {
    this._focusBloc.getFocus();
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);
    this._intentionBloc.getIntentionByDate(currentDate);

    this._tagBloc.socialChallengeEnabled$.pipe(take(1)).subscribe((socialChallengeEnabled) => {
      if (socialChallengeEnabled) {
        this._socialChallengeBloc.getChallenges(false);
      }
    });
    this.refreshCardsForFilterAndDomain();
  }

  filterFeature(feature: FilterFeature) {
    this._filterFeatures$.next(feature);
  }

  refreshCardsForFilterAndDomain() {
    this._refreshCallArgs$.pipe(take(1)).subscribe((refreshCallData) => {
      Object.keys(refreshCallData).forEach((domainKey) => {
        this._cardBloc.refresh(refreshCallData[domainKey], null, domainKey === 'default' ? null : domainKey, true);
      });
    });
  }

  private get _refreshCallArgs$() {
    return combineLatest([
      this.focus$,
      this.intention$,
      this._intentionBloc.intentionStatus$,
      this.challenge$,
      this._tagBloc.socialChallengeEnabled$,
      this._socialChallengeBloc.hasAJoinedNonEndedChallenge$,
      this._socialChallengeBloc.challengesStatus$,
    ]).pipe(
      skipWhile(
        ([
          focusStatus,
          intention,
          intentionStatus,
          challenge,
          socialChallengeEnabled,
          hasAJoinedChallenge,
          challengesStatus,
        ]) =>
          focusStatus?.status === Status.starting ||
          intentionStatus?.status === Status.starting ||
          (socialChallengeEnabled &&
            (challengesStatus?.status === Status.starting || challengesStatus?.status === Status.local)) ||
          socialChallengeEnabled == null ||
          (socialChallengeEnabled && hasAJoinedChallenge && challenge == null)
      ),
      map(([focusStatus, intention, intentionStatus, challenge, socialChallengeEnabled]) => {
        const focus = focusStatus?.data;
        const refreshCallByDomain: { [domain: string]: CardRequestType[] } = {
          default: [CardRequestType.RECOMMENDED, CardRequestType.TAKE_ACTION],
        };

        if (focus) {
          [CardRequestType.RECOMMENDED, CardRequestType.DOMAIN_ACTION].forEach((requestType) => {
            refreshCallByDomain[focus?.domain?.key] = filterUniqueRequestKeys(
              refreshCallByDomain[focus?.domain?.key],
              requestType
            );
          });
        }

        if (intention?.domain) {
          [CardRequestType.RECOMMENDED, CardRequestType.DOMAIN_ACTION].forEach((requestType) => {
            refreshCallByDomain[intention?.domain?.key] = filterUniqueRequestKeys(
              refreshCallByDomain[intention?.domain?.key],
              requestType
            );
          });
        }

        if (socialChallengeEnabled && challenge) {
          [CardRequestType.RECOMMENDED, CardRequestType.DOMAIN_ACTION].forEach((requestType) => {
            refreshCallByDomain[challenge?.domain?.key] = filterUniqueRequestKeys(
              refreshCallByDomain[challenge?.domain?.key],
              requestType
            );
          });
        }

        return refreshCallByDomain;
      })
    );
  }
}

function filterUniqueRequestKeys(requestTypes: CardRequestType[] = [], requestType: CardRequestType) {
  return [...requestTypes.filter((existingRequestType) => existingRequestType !== requestType), requestType];
}
