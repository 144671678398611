<kui-email-screens-base [headerTitle]="headerTitle()">
  <div icon class="header-icon">
    <svg-icon src="/assets/icons/checkmark-empty-circle.svg"></svg-icon>
  </div>
  <div instructions class="instructions">
    <kui-user-email [email]="email()"></kui-user-email>
    <p class="instruction" kfLocTranslate>
      Check your email and
      <strong>enter the 6-digit code</strong>
      you receive below.
    </p>
    <p class="instruction">
      {{ customInstructionText() | translate | async }}
    </p>
  </div>
  <ng-container content>
    <form class="email-code-form" [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
      <div class="sub-container">
        <label>
          <span class="form-label">
            {{ 'Code' | translate | async }}
            <a class="code-show-help" [routerLink]="noCodeLink()" [state]="{ email: email() }">
              {{ "Didn't receive a code?" | translate | async }}
            </a>
          </span>
          <input
            class="code-input"
            type="tel"
            inputmode="number"
            maxlength="6"
            required
            formControlName="code"
            name="code"
            id="code"
            data-test="codeInput"
            (keydown)="handleKeydown($event)"
            (change)="codeChanged()"
            (input)="codeChanged()" />
        </label>
        @if (formatError) {
          <p class="error">
            <svg-icon src="/assets/icons/alert-circle.svg"></svg-icon>
            <span>{{ 'The code you entered should be 6 digits.' | translate | async }}</span>
          </p>
        }
        @if (verifyErrorMessage() !== '') {
          <p class="error">
            <svg-icon src="/assets/icons/alert-circle.svg"></svg-icon>
            {{ verifyErrorMessage() | translate | async }}
          </p>
        }
      </div>
      <button
        class="button button-pill button-submit"
        type="submit"
        [disabled]="!form?.valid || verifyErrorMessage() !== '' || verifyDisabled()">
        {{ 'Verify' | translate | async }}
      </button>
    </form>
  </ng-container>
  <ng-container footer>
    @if (verifyType() === VerifyType.register) {
      <p>{{ 'Already have a Kumanu account ?' | translate | async }}</p>
      <a [routerLink]="haveAccountLink()" class="button-text">{{ 'Sign in' | translate | async }}</a>
    }
  </ng-container>
</kui-email-screens-base>
