import { HttpStatusCode } from '../enums';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export enum Status {
  /// Beginning the download process
  starting,

  /// This is data from the local database or cache
  local,

  /// Remote data has finished downloading
  done,

  /// Error retrieving data
  error,
}

export class DataStatus<T> implements Deserializable<JsonObject, DataStatus<T>> {
  /// Data retrieval status
  status: Status;

  /// Error message from the server
  message: StatusMessage;

  /// Data retrieved from the server or local storage
  data: T;

  constructor();
  constructor(status: Status);
  constructor(status: Status, data: T);
  constructor(status: Status, message: StatusMessage, data: T);
  constructor(status?: Status, message?: StatusMessage, data?: T) {
    if (status == null && arguments?.length <= 1) {
      return;
    }

    this.status = status;
    if (!(message instanceof StatusMessage) && arguments.length === 2) {
      this.data = message as T;
      return;
    } else {
      this.message = message;
    }
    this.data = data;
  }

  deserialize(input: JsonObject): DataStatus<T> {
    if (input == null) {
      return null;
    }
    this.status = input['status'] as Status;
    this.message = new StatusMessage().deserialize(input['message']);
    this.data = input['data'] as T;

    return this;
  }
}

export class StatusMessage implements Deserializable<JsonObject, StatusMessage> {
  code: number;
  message: string;

  constructor(code?: number, message?: string) {
    this.code = code;
    this.message = message;
  }

  static generic() {
    return new StatusMessage(HttpStatusCode.CLIENT_UNKNOWN, 'Unknown error');
  }

  /**
   * Assigns the StatusMessage params from JSON
   */
  deserialize(input: JsonObject): StatusMessage {
    if (input == null) {
      return null;
    }

    this.message = input['message'] as string;
    this.code = (input['code'] as number) ?? HttpStatusCode.CLIENT_UNKNOWN;

    return this;
  }
}
