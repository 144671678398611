import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export class SavedCardState implements Deserializable<JsonObject, SavedCardState>, Serializable<JsonObject> {
  constructor(
    public isSaved = false,
    public postalCode?: string
  ) {}

  deserialize(json: JsonObject): SavedCardState {
    if (json == null) {
      return null;
    }

    this.isSaved = !!json['isSaved'];
    this.postalCode = json['postalCode'] as string;
    return this;
  }

  clone(card: SavedCardState) {
    return new SavedCardState().deserialize(card.serialize());
  }

  serialize() {
    return {
      isSaved: this.isSaved,
      postalCode: this.postalCode,
    };
  }
}
