<div class="accordion" aria-label="Accordion">
  <label class="caret" for="{{ key }}">
    <ng-content select="[acc-open]"></ng-content>
    <svg-icon
      src="/assets/icons/chevron-left-nocircle.svg"
      class="caret-arrow"
      [ngClass]="{ 'is-animating': isAnimating }"
      [attr.aria-label]="'… Click to ' + (isOpen ? 'close' : 'open') + ' accordion … ' | translate | async"
      (click)="accordionClick(input.checked)"></svg-icon>
  </label>
  <input
    type="checkbox"
    #input
    (click)="accordionClick(input.checked)"
    id="{{ key }}"
    [checked]="isOpen"
    [attr.aria-label]="'Accordion is ' + (isOpen ? 'open' : 'closed') + ' … … ' | translate | async" />
  <div class="body" #collapsibleBody>
    <ng-content select="[acc-collapsible]"></ng-content>
  </div>
</div>
