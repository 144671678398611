import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class AnswerValue implements Deserializable<JsonObject, AnswerValue>, Serializable<JsonObject> {
  constructor(
    public optionId?: string,
    public value?: string | number | boolean
  ) {}

  deserialize(input: JsonObject): AnswerValue {
    if (input == null) {
      return null;
    }
    this.optionId = input['optionId'] as string;
    this.value = input['value'] as string | number | boolean;
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['optionId'] = this.optionId;
    json['value'] = this.value;
    return json;
  }
}
