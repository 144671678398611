import { Component, Input } from '@angular/core';
import { ContentEventBloc, SharingBloc } from '@kbloc';
import { ContentEventType } from '@ktypes/enums';
import { InviteType, SocialChallenge, SocialChallengeState } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { SocialChallengeBloc } from '../../social-challenge.bloc';

@Component({
  selector: 'kf-sc-social-challenge-menu',
  templateUrl: './social-challenge-menu.component.html',
  styleUrls: ['./social-challenge-menu.component.scss'],
})
export class SocialChallengeMenuComponent {
  @Input() challenge: SocialChallenge;

  contentEventType = ContentEventType;
  inviteType = InviteType.coworker;
  inviteModalOpened = false;
  socialChallengeState = SocialChallengeState;

  constructor(
    private _contentEventBloc: ContentEventBloc,
    public sharingBloc: SharingBloc,
    private _socialChallengeBloc: SocialChallengeBloc
  ) {}

  toggleEnrolledStatus(type: ContentEventType): boolean {
    this._socialChallengeBloc.toggleEnrolledStateLocally(this.challenge.id);
    this._contentEventBloc.createContentEvent(this.challenge.contentId, type, {
      challengeId: this.challenge.id,
    });
    return true;
  }

  invite(): boolean {
    this.inviteModalOpened = true;
    return true;
  }
}

export const MockSocialChallengeMenuComponent = MockComponent({
  selector: 'kf-sc-social-challenge-menu',
  inputs: ['challenge'],
});
