import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

interface SettingsApiResponse {
  aiCoach?: boolean;
  autoAdvance?: boolean;
  darkMode?: boolean;
  language?: string;
  staySignedIn?: boolean;
}

export enum Setting {
  aiCoach = 'aiCoach',
  autoAdvance = 'autoAdvance',
  darkMode = 'darkMode',
  language = 'language',
  staySignedIn = 'staySignedIn',
  userMfaEnabledSetting = 'userMfaEnabledSetting', // Stored on USER object, not in UserSettings JSON block
}

export class Settings implements Deserializable<JsonObject, Settings> {
  constructor(
    public aiCoach?: boolean,
    public autoAdvance?: boolean,
    public darkMode?: boolean,
    public language?: string,
    public staySignedIn?: boolean
  ) {}

  deserialize(input: SettingsApiResponse): Settings {
    if (input == null) {
      return null;
    }
    this.aiCoach = input['aiCoach'];
    // Need to explicitly set autoAdvance to null for initial case
    this.autoAdvance = input['autoAdvance'] != null ? input['autoAdvance'] : null;
    this.darkMode = input['darkMode'];
    this.language = input['language'] ?? 'en';
    this.staySignedIn = input['staySignedIn'];

    return this;
  }
}
