import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { LocalResourcesBloc } from '@kp/local-resources/local-resources.bloc';
import { ThemeService, WINDOW } from '@kservice';
import { CardEventType, CardRequestType, CardScreen } from '@ktypes/enums';
import { CardEvent, CardItem, LocalResourcesProgram, Status } from '@ktypes/models';
import { assertIsOfType } from '@kutil';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-local-resources-card-detail-view',
  templateUrl: './local-resources-card-detail-view.component.html',
  styleUrls: ['./local-resources-card-detail-view.component.scss'],
})
export class LocalResourcesCardDetailViewComponent implements OnInit {
  @Input() card: LocalResourcesProgram | CardItem;
  @Input() screen: CardScreen;
  @Input() source: string;
  @Input() zipCode: string;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public localResourcesBloc: LocalResourcesBloc,
    private _environmentVariablesService: EnvironmentVariablesService,
    public themeService: ThemeService,
    @Inject(WINDOW) private _window: Window
  ) {}

  cardScreen = CardScreen;
  isPrint = this._window.matchMedia?.('print').matches;
  otherLocationsShown = false;
  status = Status;
  product = this._environmentVariablesService.product;

  ngOnInit(): void {
    if (this.source === 'search') {
      this.localResourcesBloc.getLocalResource(this.card.id);
    } else {
      // if this is from a compact card, then card is a CardItem
      assertIsOfType<CardItem, LocalResourcesProgram>(this.card, 'localResourceId');
      this.localResourcesBloc.getLocalResource(this.card.localResourceId, this.zipCode);
    }
  }

  moreOrLess(eventType: CardEventType, resource: LocalResourcesProgram) {
    const cardRequestType = this.screen === CardScreen.saved ? CardRequestType.SAVED : CardRequestType.SEARCH;
    this.localResourcesBloc.handleCardEvents(new CardEvent(resource.contentId, resource, eventType, cardRequestType));
  }

  showOtherLocations() {
    this.otherLocationsShown = true;
  }

  closeModalIfUnsaved() {
    if (this.screen === CardScreen.saved) {
      this.modalClose.emit(true);
    }
  }
}

export const MockLocalResourcesCardDetailViewComponent = MockComponent({
  selector: 'kp-local-resources-card-detail-view',
  inputs: ['card', 'screen', 'source', 'zipCode'],
});
