import { Injectable } from '@angular/core';
import { OnboardingData } from '@ktypes/models';
import { BrowserStorage } from './browser-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingUtilities {
  private _onboardingData: OnboardingData;

  constructor(private _browserStorage: BrowserStorage) {
    this._onboardingData = this._browserStorage.getObject('onboarding');
  }

  get onboardingData() {
    this._onboardingData = this._browserStorage.getObject('onboarding');
    return this._onboardingData;
  }

  saveOnboardingData(onboardingData?: OnboardingData) {
    if (onboardingData != null) {
      const onboarding = {
        ...this.onboardingData,
        ...(this._browserStorage.getObject('onboarding') ?? {}),
        ...onboardingData,
      } as OnboardingData;
      this._onboardingData = onboarding;
      this._browserStorage.setObject('onboarding', onboarding);
    }
  }

  removeOnboardingData() {
    this._onboardingData = null;
    this._browserStorage.removeItem('onboarding');
  }

  cleanupOnboardingStorage() {
    this.removeOnboardingData();
    this._browserStorage.remove('eligibilityCandidate');
    this._browserStorage.remove('sharingValidation');
    this._browserStorage.remove('existingAuthData', true);
  }

  cleanupResourcefulOnboarding() {
    this._browserStorage.removeItem('completedResourcefulSurvey');
  }
}
