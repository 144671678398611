import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class Version implements Deserializable<JsonObject, Version> {
  constructor(
    public id?: string,
    public product?: string,
    public minimumVersion?: string
  ) {}

  deserialize(input: JsonObject): Version {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.product = input['product'] as string;
    this.minimumVersion = input['minimumVersion'] as string;

    return this;
  }
}
