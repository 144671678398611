<div class="reflection-container">
  <svg-icon src="assets/icons/icons-nav-progress.svg" class="icon-progress"></svg-icon>
  <h3 class="reflection-topic">{{ 'Tracking Your Progress' | translate | async }}</h3>
  <p class="reflection-content" kfLocTranslate>
    Visit your
    <b>Progress</b>
    page any time to look back on your check-in history, get insights into your data, and see how you're improving.
  </p>
  <a class="button button-reverse button-pill link-progress" routerLink="/progress" (click)="closeClicked.emit()">
    {{ 'Go to Progress' | translate | async }}
  </a>
  <button class="button-close-modal" (click)="closeClicked.emit()">{{ 'Not Now' | translate | async }}</button>
</div>
