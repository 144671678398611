@if (translationBloc.supportedLanguagesAsOptions$ | async; as supportedLanguages) {
  <label class="label">
    <select
      class="dropdown"
      [ngClass]="{ 'has-icon': showIcon }"
      name="language"
      (change)="onLanguageChange($event.target)">
      @for (language of supportedLanguages; track language) {
        <option [selected]="language.selected" [value]="language.value">
          {{ language.name }}
        </option>
      }
    </select>
    @if (showIcon) {
      <svg-icon class="icon-globe" src="/assets/icons/globe.svg"></svg-icon>
    }
    <svg-icon class="dropdown-arrow" src="/assets/icons/chevron-right-nocircle.svg"></svg-icon>
  </label>
}
