<div class="radio-widget">
  <form>
    @for (option of displayOptions; track option; let i = $index) {
      <div class="radio-container">
        <kp-input-option
          [forId]="option.id"
          [isSelected]="option.isSelected"
          [answerText]="option.text"
          (selectInputOption)="onOptionChange($event, option)"
          [attr.data-e2e]="'radio-option-' + i"></kp-input-option>
      </div>
    }
  </form>
</div>
