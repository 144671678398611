import { Pipe, PipeTransform } from '@angular/core';
import { Question, QuestionType } from '@kp/question-set/models/question.model';

@Pipe({ name: 'answerValue' })
export class AnswerValuePipe implements PipeTransform {
  transform(question: Question) {
    switch (question?.inputType) {
      case QuestionType.checkbox:
      case QuestionType.word_list: {
        const selected = question.answer?.values?.filter((ans) => ans?.value === true) || [];
        return question.display?.options
          ?.filter((option) => selected.some((ans) => ans?.optionId === option.id))
          ?.map((option) => option.text.trim())
          ?.join(', ');
      }
      case QuestionType.long_text:
      case QuestionType.short_text:
        if (question.answer) {
          if (question.answer.values) {
            return question.answer?.values[0].value;
          }
          return '';
        }
        return '';
      case QuestionType.radio_button: {
        const answerId = question.answer.values.filter((qs) => qs.value === true)[0].optionId;
        return question.display.options.filter((opt) => opt.id === answerId)[0].text;
      }
      case QuestionType.slider:
      case QuestionType.likert:
        return this._getScaleAnswer(question);
      default:
        return '';
    }
  }

  private _getSingleAnswerIndex(question: Question): number {
    let answerId: string;
    if (question.answer && question.answer.values && question.answer.values[0]) {
      answerId = question.answer.values[0].optionId;
    }

    let index = -1;

    if (answerId && question && question.display && question.display.options) {
      index = question.display.options.findIndex((opt) => opt.id === answerId);
    }

    return index;
  }

  private _getScaleAnswer(question: Question): string {
    if (question.isNumeric && question.answer && question.answer.values) {
      return `${question.answer.values[0].value}`;
    }

    const answerIndex = this._getSingleAnswerIndex(question);
    if (question && question.display && question.display.options && question.display.options[answerIndex]) {
      return `${question.display.options[answerIndex].text}`;
    }
    return '';
  }
}
