import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { productDisplayName } from './product.service';

@Injectable({
  providedIn: 'root',
})
export class TitleService extends Title {
  setDefault() {
    this.setTitle(`${productDisplayName}`);
  }

  set(title: string) {
    if (title) {
      // TODO: Translate - beware of circular dependencies with features/localization though
      this.setTitle(`${title} | ${productDisplayName}`);
    } else {
      this.setDefault();
    }
  }
}
