@if (question != null) {
  <div
    role="region"
    [attr.aria-label]="
      (('Answers for question: ' | translate | async) || '') + ((question.display?.title | translate | async) || '')
    ">
    @switch (question.inputType) {
      @case (listQuestionTypes.includes(question.inputType) ? question.inputType : '') {
        <div class="options">
          <kp-word-list
            [min]="question.settings?.countMin"
            [max]="question.settings?.countMax"
            [displayOptions]="options"
            [questionType]="question.inputType"
            (answerSelected)="answer.emit($event)"></kp-word-list>
        </div>
      }
      @case (questionType.checkbox) {
        <div class="checkbox-options">
          <kp-checkbox-list
            [min]="question.settings?.countMin"
            [max]="question.settings?.countMax"
            [displayOptions]="options"
            (checkboxesChecked)="answer.emit($event)"></kp-checkbox-list>
        </div>
      }
      @case (textQuestionTypes.includes(question.inputType) ? question.inputType : '') {
        <div class="text-field">
          <kp-text-field
            [maxCharacters]="maxCharacters"
            [placeholderText]="placeholderText"
            [textValue]="answerValue"
            (textAreaAnswer)="answer.emit($event)"
            [isShortText]="question.inputType === questionType.short_text"
            data-e2e="answerTextField"></kp-text-field>
        </div>
      }
      @case (questionType.slider) {
        <div class="scale-question">
          <kp-slider [question]="question" (sliderAnswer)="answer.emit($event)"></kp-slider>
        </div>
      }
      @case (questionType.likert) {
        <div class="scale-question">
          <kp-diamond-likert [question]="question" (likertAnswer)="answer.emit($event)"></kp-diamond-likert>
        </div>
      }
      @case (questionType.radio_button) {
        <div class="radio-button">
          <kp-radio-button [question]="question" (radioAnswer)="answer.emit($event)"></kp-radio-button>
        </div>
      }
    }
  </div>
}
