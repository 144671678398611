import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class HeartbeatApi extends BaseApi {
  constructor(
    private client: HttpClient,
    public dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  isValid(includeAuth: boolean): Promise<boolean> {
    const request = this.isValid$(includeAuth);
    return firstValueFrom(request).catch((error): null => {
      console.warn(`Error getting heartbeat${includeAuth ? ' with auth' : ''} (ivP):`, error);
      return null;
    });
  }

  getHeartbeat(includeAuth: boolean): Promise<HttpResponse<string | { message: string }>> {
    const request = this.getHeartbeat$(includeAuth);
    return firstValueFrom(request).catch((error): null => {
      console.warn(`Error getting heartbeat${includeAuth ? ' with auth' : ''} (ghP):`, error);
      return null;
    });
  }

  isValid$(includeAuth: boolean): Observable<boolean> {
    return this.getHeartbeat$(includeAuth).pipe(
      map(
        (heartbeatResponse: HttpResponse<string | { message: string }>) =>
          heartbeatResponse?.['status'] === HttpStatusCode.OK
      ),
      catchError((error) => {
        console.warn(`Error getting heartbeat${includeAuth ? ' with auth' : ''} (iv$):`, error);
        return of(null);
      })
    );
  }

  getHeartbeat$(includeAuth: boolean): Observable<HttpResponse<string | { message: string }>> {
    const url = this.buildUrl('/heartbeat', includeAuth);
    return this.performRequest<string | { message: string }>(RequestType.GET, url, { includeToken: includeAuth }).pipe(
      catchError((error) => {
        console.warn(`Error getting heartbeat${includeAuth ? ' with auth' : ''} (_gh$):`, error);
        return of(null);
      })
    );
  }
}
