import { Injectable } from '@angular/core';
import { FocusApi } from '@kp/focus/focus.api';
import { Focus } from '@kp/focus/focus.model';
import { DataStatus, Status } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FocusBloc {
  constructor(private _focusApi: FocusApi) {}

  private _focus$ = new BehaviorSubject<DataStatus<Focus>>(null);

  get focus$(): Observable<DataStatus<Focus>> {
    return this._focus$.asObservable();
  }

  getFocus(): void {
    this._focus$.next(new DataStatus<Focus>(Status.starting, null, null));
    this._focusApi.getFocus().then((focusStatus) => {
      this._focus$.next(focusStatus);
    });
  }
}
