import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class RemoteFirstVisit implements Deserializable<JsonObject, RemoteFirstVisit> {
  constructor(
    public checkInCount = 0,
    public completedReflection = false,
    public dismissedAccountCreationModal = false,
    public discover = false,
    public firstActionAdded = false,
    public firstQuestAdded = false,
    public firstTimeAppVisit = false,
    public lastModalLaunch: string = new Date(0).toISOString(),
    public progress = false,
    public purposefulVisits = 0, // Starts counting after acknowledging Today first visit messages
    public resourcefulVisits = 0,
    public shownCrossPromotionModal = false,
    public shownExpandedLiveSupport = false,
    public shownFeaturedPromoOn?: string,
    public shownNotificationsModal = false,
    public shownPurposefulFeedbackModal = false,
    public shownResourcefulFeedbackModal = false,
    public today = false,
    public webMigrated = false
  ) {}

  deserialize(json?: JsonObject, previousValue?: RemoteFirstVisit): RemoteFirstVisit {
    if (json != null || previousValue != null) {
      Object.assign(this, previousValue || {}, json);
    }
    return this;
  }
}
