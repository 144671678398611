import { Component, EventEmitter, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-first-reflection-completed',
  templateUrl: './first-reflection-completed-modal.component.html',
  styleUrls: ['./first-reflection-completed-modal.component.scss'],
})
export class FirstReflectionCompletedComponent {
  @Output() closeClicked = new EventEmitter();
}

export const MockFirstReflectionCompletedComponent = MockComponent({
  selector: 'kp-first-reflection-completed',
});
