import { Inject, Injectable } from '@angular/core';
import { TagBloc } from '@kbloc';
import { DataStoreService, SharedConstants, WINDOW } from '@kservice';
import { Option } from '@ktypes/models';
import { hashMessage } from '@kutil';
import {
  BehaviorSubject,
  Observable,
  Subject,
  auditTime,
  buffer,
  combineLatest,
  distinctUntilChanged,
  filter,
  take,
} from 'rxjs';
import { map, share } from 'rxjs/operators';
import { TranslationLanguage } from './models/translation-language.model';
import { Translation } from './models/translation.model';
import { TranslationApi } from './translation.api';

export interface TranslationRequest {
  text: string;
  languageCode: string;
  format?: 'text' | 'html';
}

const SUPPORTED_LANGUAGES = SharedConstants.supportedLanguages.map((language) =>
  new TranslationLanguage().deserialize(language)
);

@Injectable({
  providedIn: 'root',
})
export class TranslationBloc {
  private _batchTranslateRequests$ = new Subject<TranslationRequest>();
  private _currentLanguage$ = new BehaviorSubject<string>(SharedConstants.DEFAULT_LANGUAGE);
  private _languages$ = new BehaviorSubject<TranslationLanguage[]>(null);
  private _translationsMap$ = new BehaviorSubject<Map<string, Translation>>(new Map<string, Translation>([]));

  constructor(
    private _dataStoreService: DataStoreService,
    private _tagBloc: TagBloc,
    private _translationApi: TranslationApi,
    @Inject(WINDOW) private _window: Window
  ) {
    // share observable to prevent multiple subscriptions to this._batchTranslateRequests$
    const batchedEvents$ = this._batchTranslateRequests$.pipe(share());
    batchedEvents$
      .pipe(buffer(batchedEvents$.pipe(auditTime(SharedConstants.BATCH_THROTTLE_TIME))))
      .subscribe(this._handleBatchedRequests.bind(this));
  }

  get currentLanguage$(): Observable<string> {
    return this._currentLanguage$.asObservable();
  }

  get currentLanguage(): string {
    return this._currentLanguage$.getValue();
  }

  get supportedLanguages$(): Observable<TranslationLanguage[]> {
    return this._languages$.asObservable();
  }

  get translationsMap$(): Observable<Map<string, Translation>> {
    return this._translationsMap$.pipe(distinctUntilChanged());
  }

  readonly supportedLanguagesAsOptions$: Observable<Option<string>[]> = combineLatest([
    this._languages$,
    this._dataStoreService.user$,
  ]).pipe(
    map(([supportedLanguages, user]) =>
      supportedLanguages?.map((supportedLanguage) => ({
        name: supportedLanguage.name,
        value: supportedLanguage.language,
        selected: supportedLanguage.language === (user?.settings?.language || SharedConstants.DEFAULT_LANGUAGE),
      }))
    ),
    distinctUntilChanged()
  );

  getSupportedLanguages(languageCode = SharedConstants.DEFAULT_LANGUAGE) {
    this._tagBloc.languageTranslation$?.subscribe((allowsLanguageTranslation) => {
      if (!allowsLanguageTranslation) {
        return; // do nothing if language translation is not allowed
      }
      this._translationApi.getSupportedLanguages(languageCode).then((googleLanguages) => {
        if (googleLanguages == null) {
          this._languages$.next(SUPPORTED_LANGUAGES);
        } else {
          const googleSupportedLanguageCodes = new Set(
            googleLanguages.map((googleLanguage) => googleLanguage.language)
          );
          this._languages$.next(
            SUPPORTED_LANGUAGES.filter((supportedLanguage) =>
              googleSupportedLanguageCodes.has(supportedLanguage.language)
            )
          );
        }
      });
    });
  }

  checkUserLanguage() {
    this._dataStoreService.signalStore?.currentLanguage.set(
      this._dataStoreService.user?.settings?.language ||
        this._window.navigator?.language?.split('-')[0] ||
        SharedConstants.DEFAULT_LANGUAGE
    );
    this._dataStoreService.user$
      .pipe(
        filter((user) => user?.settings != null),
        take(1)
      )
      .subscribe((user) => {
        if (user.settings?.language) {
          this._currentLanguage$.next(user.settings?.language);
        } else if (
          this._window.navigator?.language &&
          !['en-US', SharedConstants.DEFAULT_LANGUAGE].includes(this._window.navigator?.language)
        ) {
          this._currentLanguage$.next(this._window.navigator?.language?.split('-')[0]);
        }
      });
  }

  changeLanguage(translationLanguage: string) {
    if (translationLanguage != null) {
      this._dataStoreService.signalStore?.currentLanguage.set(translationLanguage);
    }
    this._currentLanguage$.next(translationLanguage);
  }

  batchTranslations(text: string | string[], languageCode: string, format: 'text' | 'html' = 'text'): void {
    this._tagBloc.languageTranslation$?.subscribe((allowsLanguageTranslation) => {
      if (!allowsLanguageTranslation) {
        return; // do nothing if language translation is not allowed
      }
      const normalizedText = Array.isArray(text) ? text : [text];
      normalizedText.forEach((text) => this._batchTranslateRequests$.next({ text, languageCode, format }));
    });
  }

  async getHashedKey(languageCode: string, text: string): Promise<string> {
    return hashMessage(`${languageCode}:${encodeURIComponent(text)}`);
  }

  private _handleBatchedRequests(translationRequests: TranslationRequest[]) {
    if (translationRequests?.length > 0) {
      const groupedByLanguageCode = translationRequests.reduce<{ [key: string]: { [key: string]: string[] } }>(
        (requestsByLang, translationRequest) => {
          if (!Object.keys(requestsByLang).includes(translationRequest.languageCode)) {
            requestsByLang[translationRequest.languageCode] = {};
          }
          if (!Object.keys(requestsByLang[translationRequest.languageCode]).includes(translationRequest.format)) {
            requestsByLang[translationRequest.languageCode][translationRequest.format] = [];
          }
          requestsByLang[translationRequest.languageCode][translationRequest.format].push(translationRequest.text);
          return requestsByLang;
        },
        {}
      );
      for (const languageCode in groupedByLanguageCode) {
        if (languageCode === SharedConstants.DEFAULT_LANGUAGE) {
          // do nothing on 'en' for now, eventually may need to translate from other languages but not now
          return;
        }
        for (const format in groupedByLanguageCode[languageCode]) {
          // ensure it doesn't send more than 128 at a time
          const textChunks = getTextChunks(groupedByLanguageCode[languageCode][format]);
          textChunks.forEach((textChunk) => {
            this._translationApi
              .translate(textChunk, languageCode, format as 'text' | 'html')
              .then((translations) =>
                this._handleTranslatedResponses(
                  translations,
                  groupedByLanguageCode,
                  languageCode,
                  format as 'text' | 'html',
                  translationRequests
                )
              );
          });
        }
      }
    }
  }

  private async _handleTranslatedResponses(
    translations: Translation[],
    groupedByLanguageCode: { [key: string]: { [key: string]: string[] } },
    languageCode: string,
    format: 'text' | 'html',
    translationRequests: TranslationRequest[]
  ) {
    // if API fails or translation doesn't work, just resupply original text
    const normalizedTranslations =
      translations ||
      translationRequests.map((tr) =>
        new Translation().deserialize({ translatedText: tr.text, originalText: tr.text, cacheKey: tr.text })
      );
    const translationMapItems: [string, Translation][] = await Promise.all(
      normalizedTranslations.map(async (translation, index) => {
        // Assumption is that Google and our API returns array in same order it was sent
        const originalText = translation.originalText || groupedByLanguageCode[languageCode][format][index];
        // key: hashed from `currentLanguage:encodedOriginalText` - e.g. "es:Hello%20World"
        const key = translation.cacheKey || (await this.getHashedKey(languageCode, originalText));
        return [key, new Translation().deserialize({ ...translation, originalText })];
      })
    );
    const updatedTranslationsMap = new Map<string, Translation>([
      ...this._translationsMap$.getValue().entries(),
      ...translationMapItems,
    ]);
    this._translationsMap$.next(updatedTranslationsMap);
  }
}

const MAX_TEXT_CHUNKS = 128;
function getTextChunks(text: string[]): string[][] {
  return text.length <= MAX_TEXT_CHUNKS
    ? [text]
    : [text.slice(0, MAX_TEXT_CHUNKS), ...getTextChunks(text.slice(MAX_TEXT_CHUNKS))];
}
