// Created separate JsonObject interface to avoid circular dependency
interface JsonObject<T = any> {
  [key: string]: T;
}

export function clearEmptyProps(obj: JsonObject): JsonObject {
  if (!obj) {
    return obj;
  }
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value != null && value !== ''));
}
