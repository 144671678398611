import { Document } from '@contentful/rich-text-types';
import { ContentImage, ContentMedia, Deserializable, JsonObject } from '@ktypes/models';

export class DialogueInformation implements Deserializable<JsonObject, DialogueInformation> {
  constructor(
    public headerMedia?: ContentImage,
    public header?: string,
    public body?: Document,
    public buttonText?: string,
    public media?: ContentMedia
  ) {}

  deserialize(input: JsonObject): DialogueInformation {
    if (input == null) {
      return null;
    }

    this.headerMedia = new ContentImage().deserialize(input['headerMedia']);
    this.header = input['header'] as string;
    this.body = input['body'] as Document;
    this.buttonText = input['buttonText'] as string;
    this.media = new ContentMedia().deserialize(input['mediaElement']);

    return this;
  }
}
