<div
  class="modal-container"
  [ngClass]="{ 'challenge-invite': challengeInviteMessage && challengeId && challengeTitle }">
  @if (!proceedToLink) {
    @switch (invite?.type) {
      @case (inviteType.spouse) {
        <h2 class="title">Inviting Family Members</h2>
        <p class="description">
          By continuing, you agree that the person you’re inviting is 13 years or older and a member of your family.
        </p>
      }
      @case (inviteType.coworker) {
        <h2 class="title">{{ 'Inviting Co-workers' | translate | async }}</h2>
        <p class="description">
          {{
            "By continuing, you agree that the person you're inviting to use Purposeful is an eligible employee of your sponsoring organization."
              | translate
              | async
          }}
        </p>
      }
      @case (inviteType.friend) {
        <h2 class="title">{{ 'Inviting Friends' | translate | async }}</h2>
        <p class="description">
          {{
            "This link lets others access Purposeful up to the number of available friend invites you've accumulated."
              | translate
              | async
          }}
        </p>
        <p class="description">
          {{
            'To prevent others from using your invites, it’s best to only share this link privately with those you wish to invite.'
              | translate
              | async
          }}
        </p>
      }
    }
    <div class="button-row">
      <button class="button button-cancel" (click)="closeClicked.emit()">{{ 'Cancel' | translate | async }}</button>
      <button class="button button-pill button-reverse button-proceed" (click)="proceedToLink = true">
        {{ 'Got It' | translate | async }}
      </button>
    </div>
  } @else {
    @if (!challengeId && !challengeInviteMessage) {
      <h2 class="title">{{ 'Your Invite Link' | translate | async }}</h2>
      <p class="description">
        {{
          'Share your unique invite link to give others access to Purposeful. Add a personal note to encourage others to give it a try!'
            | translate
            | async
        }}
      </p>
      @if (sharingBloc.shortLink$ | async; as shortenedUrl) {
        <input class="link-text" [value]="shortenedUrl" #sharingUrl readonly />
        <button
          [tp]="copyTooltip"
          tpTrigger="manual"
          [tpIsVisible]="showTooltip"
          class="button button-pill button-reverse button-invite"
          [ngClass]="{ 'button-appear-disabled': showTooltip }"
          (click)="copyLink(sharingUrl)">
          <svg-icon src="assets/icons/copy.svg"></svg-icon>
          {{ 'Copy to Clipboard' | translate | async }}
        </button>
        <a
          class="button button-pill button-secondary-reverse button-invite"
          href="mailto:?subject={{ constants.inviteEmails?.[invite?.type]?.subject }}&body={{
            constants.inviteEmails?.[invite?.type]?.body + '%0D%0A%0D%0A' + shortenedUrl
          }}%0D%0A%0D%0A{{ (dataStoreService?.user$ | async)?.nickname }}">
          <svg-icon src="assets/icons/mail.svg"></svg-icon>
          {{ 'Send via Email' | translate | async }}
        </a>
      }
    }
    @if (challengeId && challengeInviteMessage) {
      <h2 class="title">{{ 'Challenge Invite Link' | translate | async }}</h2>
      <p class="description">
        {{
          'Invite co-workers to join this Challenge with you on Purposeful. Add a personal note to encourage them to give it a try!'
            | translate
            | async
        }}
      </p>
      @if (sharingBloc.shortLink$ | async; as shortenedUrl) {
        @if (sharingBloc.challengeSharingLink$ | async; as challengeUrl) {
          <textarea class="link-text" readonly #challengeMessage
            >{{ challengeInviteMessage | translate | async }}&#13;&#10;&#13;&#10;{{
              challengeUrl
            }}&#13;&#10;&#13;&#10;{{ "Don't have an account? Sign up here:" | translate | async }}&#13;&#10;{{
              shortenedUrl
            }}&#13;&#10;&#13;&#10;{{ (dataStoreService?.user$ | async)?.nickname }}</textarea
          >
          <button
            [tp]="copyTooltip"
            tpTrigger="manual"
            [tpIsVisible]="showTooltip"
            class="button button-pill button-reverse button-invite"
            [ngClass]="{ 'button-appear-disabled': showTooltip }"
            (click)="copyLink(challengeMessage)">
            <svg-icon src="assets/icons/copy.svg"></svg-icon>
            {{ 'Copy to Clipboard' | translate | async }}
          </button>
          <a
            class="button button-pill button-secondary-reverse button-invite"
            href="mailto:?subject={{ challengeTitle }}&body={{
              challengeInviteMessage +
                '%0D%0A%0D%0A' +
                challengeUrl +
                '%0D%0A%0D%0A' +
                'Don\'t have an account? Sign up here:' +
                '%0D%0A' +
                shortenedUrl
            }}%0D%0A%0D%0A{{ (dataStoreService?.user$ | async)?.nickname }}">
            <svg-icon src="assets/icons/mail.svg"></svg-icon>
            {{ 'Send via Email' | translate | async }}
          </a>
        }
      }
    }
  }
</div>

<ng-template #copyTooltip>{{ 'Copied!' | translate | async }}</ng-template>
