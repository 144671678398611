import { Inject, Injectable, InjectionToken } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { Feature, Product } from '@ktypes/enums';
import { NavItem, ProductInfo } from '@ktypes/models';
import uniqBy from 'lodash/uniqBy';
import { BehaviorSubject, Observable, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';

export const NAV_ITEM_LIST = new InjectionToken<NavItem[]>('NavItemList');

@Injectable({
  providedIn: 'root',
})
export class NavigationBloc {
  constructor(
    @Inject(NAV_ITEM_LIST) private _navItemList: NavItem[],
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {}

  product = this._environmentVariablesService.product;

  private _coreNavSubject = new BehaviorSubject<NavItem[]>(
    this._navItemList.filter((item) => item.displayOrder?.[this.product]?.core).sort(this.productCoreSort.bind(this))
  );

  private _sideNavSubject = new BehaviorSubject<NavItem[]>(
    this._navItemList.filter((item) => item.displayOrder?.[this.product]?.side).sort(this.productSideSort.bind(this))
  );

  coreNavSubject$: Observable<NavItem[]> = this._coreNavSubject.asObservable().pipe(
    withLatestFrom(this._dataStoreService.featureKeys$, this._dataStoreService.roleKeys$),
    map(([coreNavItems, features, roles]) =>
      coreNavItems
        .filter((coreNavItem) => !coreNavItem?.hideFromNav)
        .filter((coreNavItem) => !coreNavItem?.feature || features?.includes(coreNavItem.feature))
        .filter(
          (coreNavItems) =>
            !coreNavItems?.roles ||
            coreNavItems?.roles?.length === 0 ||
            coreNavItems?.roles?.every((role) => roles?.includes(role))
        )
    )
  );

  sideNavSubject$: Observable<NavItem[]> = this._sideNavSubject.pipe(
    withLatestFrom(this._dataStoreService.featureKeys$, this._dataStoreService.roleKeys$),
    map(([sideNavItems, features, roles]) =>
      sideNavItems
        .filter((sideNavItem) => !sideNavItem?.hideFromNav)
        .filter((sideNavItem) => !sideNavItem?.feature || features?.includes(sideNavItem.feature))
        .filter(
          (sideNavItems) =>
            !sideNavItems?.roles ||
            sideNavItems?.roles?.length === 0 ||
            sideNavItems?.roles?.every((role) => roles?.includes(role))
        )
    )
  );

  moreApps$: Observable<ProductInfo[]> = this._dataStoreService.user$.pipe(
    map((user) => {
      if (user?.features) {
        const apps = user?.features.map((feature) => {
          switch (feature?.key) {
            case Feature.PURPOSEFUL:
              return {
                product: Product.purposeful,
                title: 'Purposeful',
                icon: '/assets/shared/images/icons/purposeful-icon.svg',
                whiteIcon: '/assets/shared/images/icons/purposeful-icon-white.svg',
                logo: '/assets/shared/images/logo/purposeful-logo-nav.svg',
                link: this._environmentVariablesService.config.appUrls.purposeful,
                currentApp: this._environmentVariablesService.product === Product.purposeful,
                homepage: '/today',
              };
            case Feature.RESOURCEFUL:
              return {
                product: Product.resourceful,
                title: 'Resourceful',
                icon: '/assets/shared/images/icons/resourceful-icon.svg',
                whiteIcon: '/assets/shared/images/icons/resourceful-icon-white.svg',
                logo: '/assets/shared/images/logo/resourceful-logo-nav.svg',
                link: this._environmentVariablesService.config.appUrls.resourceful,
                currentApp: this._environmentVariablesService.product === Product.resourceful,
                homepage: '/resources',
              };
            case Feature.INSIGHTFUL:
            case Feature.INSIGHTFUL_ADMIN:
              return {
                product: Product.insightful,
                title: 'Insightful',
                icon: '/assets/shared/images/icons/insightful-icon.svg',
                whiteIcon: '/assets/shared/images/icons/insightful-icon-white.svg',
                logo: '/assets/shared/images/logo/insightful-logo-nav.svg',
                link: this._environmentVariablesService.config.appUrls.insightful,
                currentApp: this._environmentVariablesService.product === Product.insightful,
                homepage: '/dashboard',
              };
            default:
              return null;
          }
        });
        return uniqBy(apps, 'title'); // Remove any duplicates
      } else {
        return [];
      }
    })
  );

  productCoreSort(a: NavItem, b: NavItem) {
    return a.displayOrder[this.product].core - b.displayOrder[this.product].core;
  }

  productSideSort(a: NavItem, b: NavItem) {
    return a.displayOrder[this.product].side - b.displayOrder[this.product].side;
  }
}
