import { Constants } from '@kp/shared/constants.service';
import { OauthResponseTypes } from '@ktypes/enums';

const domainBase = window?.location?.origin || `https://${Constants.rootDomain}`;
const isProduction = !window?.location?.origin?.includes('localhost'); // treat as production if not localhost

export const environment = {
  production: isProduction,
  environment: 'prod',
  apiHostUrl: 'https://api.kumanu.com',
  appUrls: {
    insightful: 'https://insightful.kumanu.com',
    purposeful: 'https://purposeful.kumanu.com',
    resourceful: 'https://resourceful.kumanu.com',
  },
  bugLink: 'https://surveys.kumanu.com/s3/bug-report',
  feedbackLink: 'https://surveys.kumanu.com/s3/feedback',
  version: 15,
  appVersion: '2',
  idleTimeConfig: {
    // time in sec
    timeIdle: 7200,
    timeOut: 1,
    timePing: 1,
  },
  firebase: {
    apiKey: 'AIzaSyBmHs0qi0Lr79t7Jdur35XWTvRKVqCVUpI',
    authDomain: 'flutter-jool.firebaseapp.com',
    databaseURL: 'https://flutter-jool.firebaseio.com',
    projectId: 'flutter-jool',
    storageBucket: 'flutter-jool.appspot.com',
    messagingSenderId: '719732968767',
    appId: '1:719732968767:web:1774dc1de8253640f9578a',
    measurementId: 'G-42BQM6BRPJ',
  },
  vapidKey: 'BKVqofxNBmJrOWR2UlLD7blvfzowEd2xpulZ0o-1z--WwYLHF_ZW8gyl3m5etCk5okvVJBh0PD4NPoQedoDJBkA',
  hostname: window?.location?.hostname,
  oauth: {
    authClientSsoId: '4aalojqq8dskm2knmtfqre8n2i',
    authClientSocialId: '7qogmffln6d2r7sljgbhst5hi3',
    clientId: 'ljpsqtct00pjl25l7ii26ghn2dcsjrjgeubf07ls19qve1dapsn68kemhck3etb2', // TODO: Android, do we need web?
    domain: 'https://auth.kumanu.com',
    redirectSignIn: 'https://api.kumanu.com/auth/token/sso/web',
    redirectSignOut: `${domainBase}/auth/sso/logout`,
    responseType: OauthResponseTypes.code, // 'code' or 'token'
  },
  PUBLISHABLE_KEY: 'pk_live_IkPOVcT4FBnj9WEdWB69rF3K00415fP0lg',
};
