import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';
import { LocalResourcesBloc } from '@kp/local-resources/local-resources.bloc';
import {
  SimpleIconAnimationType,
  SimpleIconSvgAnimationOptions,
} from '@kp/shared/components/animations/simple-icon-animation-options';
import { CardBloc } from '@kp/shared/components/cards/card.bloc';
import { CardDisplayType, CardRequestType, CardScreen } from '@ktypes/enums';
import { CardItem, LocalResourcesProgram } from '@ktypes/models';
import { isOfType } from '@kutil';
import { MockComponent } from '@kutil/test';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-save-icon',
  templateUrl: './save-icon.component.html',
  styleUrls: ['./save-icon.component.scss'],
})
export class SaveIconComponent implements OnInit, OnDestroy {
  @Input() card: CardItem | LocalResourcesProgram;
  @Input() cardDisplayType: CardDisplayType;
  @Input() color: string;
  @Input() isSaved = false;
  @Input() requestType: CardRequestType;
  @Input() screen: CardScreen;
  @Input() zip: string;
  @Output() showSaveTooltip = new EventEmitter<boolean>(true);
  @Output() emittedUnsavedState = new EventEmitter();

  /* NOTE: for SvgAnimations to animate, the animations must be defined in the CSS */
  options: SimpleIconSvgAnimationOptions = {
    duration: 333,
    fileName: '/assets/icons/save-animatable.svg',
    height: 60,
    type: SimpleIconAnimationType.svg,
    width: 60,
  };
  saveClicked: boolean;

  private _destroy$ = new Subject<void>();

  constructor(
    private _authBloc: AuthenticationBloc,
    private _cardBloc: CardBloc,
    private _localResourcesBloc: LocalResourcesBloc
  ) {}

  ngOnInit(): void {
    this.isSaved = this.isSaved || false;
    this._cardBloc.savedApiCallFailed.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.showSaveTooltip.emit(false);
    });
  }

  toggleSave(event: Event) {
    // TODO: should this use observables similar to LikeComponent?
    if (this.saveClicked) {
      return;
    }
    this.saveClicked = true;
    setTimeout(() => {
      this.saveClicked = false;
    }, 333);

    if (this.cardDisplayType !== CardDisplayType.detailed) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this._authBloc.isLoggedIn()) {
      if (isOfType<LocalResourcesProgram, CardItem>(this.card, 'contentId')) {
        this._localResourcesBloc.handleLocalResourceSavedOrUnsaved(!this.isSaved, this.cardDisplayType, this.card.id);
      }
      if (!this.isSaved) {
        this._cardBloc.saveCard(this.card, this.screen, this.requestType, this.cardDisplayType, this.zip);
        this.showSaveTooltip.emit(true);
      } else {
        this.emittedUnsavedState.emit();
        this._cardBloc.deleteSavedCard(this.card, this.requestType);
      }
      // setting manually despite being an @Input property as it doesn't properly detect the change
      this.isSaved = !this.isSaved;
    } else {
      this._cardBloc.storeCardUserWithoutAccountAttemptedToSave(this.card);
      this._cardBloc.detailViewClicked.next({ detailViewClicked: false });
      this._cardBloc.setShowAccountCreationModal(true);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

export const MockSaveIconComponent = MockComponent({
  selector: 'kp-save-icon',
  inputs: ['card', 'cardDisplayType', 'color', 'isSaved', 'requestType', 'screen', 'zip'],
});
