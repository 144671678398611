import { Feature } from '../enums/feature.enum';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class UserFeature implements Deserializable<JsonObject, UserFeature> {
  public key: Feature;
  public displayName: string;

  constructor(key?: string, displayName?: string) {
    this.key = key as Feature;
    this.displayName = displayName;
  }

  deserialize(input: JsonObject): UserFeature {
    if (input == null) {
      return null;
    }

    this.key = input['key'] as Feature;
    this.displayName = input['displayName'] as string;

    return this;
  }
}
