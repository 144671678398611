import {
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Options, documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Document, INLINES } from '@contentful/rich-text-types';
import { EnvironmentVariablesService } from '@kenv';
import { TranslationBloc, TranslationService } from '@kf-loc';
import { getInAppLink, matchesInAppLink } from '@kutil';
import { MockComponent } from '@kutil/test';

export const DOCUMENT_TO_HTML_STRING = new InjectionToken<
  (richTextDocument: Document, options?: Partial<Options>) => string
>('documentToHtmlString method', {
  factory: () => documentToHtmlString,
});

@Component({
  selector: 'kui-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    @Inject(DOCUMENT_TO_HTML_STRING)
    private _documentToHtmlString: (richTextDocument: Document, options?: Partial<Options>) => string,
    private _elementRef: ElementRef,
    private _environmentalVariablesService: EnvironmentVariablesService,
    private _renderer: Renderer2,
    private _router: Router,
    translationBloc: TranslationBloc,
    private _translationService: TranslationService
  ) {
    translationBloc.currentLanguage$?.pipe(takeUntilDestroyed()).subscribe(() => {
      this._renderTemplate();
    });
  }

  @Input() addTargetForExternalLinks = true;
  @Input() translate = true;
  @Input() onBackground = false;
  @Input() options?: Partial<Options>;
  @Input() richText: Document;

  richTextHtml: string;

  private _unlisten: () => void;

  ngOnInit() {
    this._unlisten = this._renderer.listen(this._elementRef?.nativeElement, 'click', this._handleInAppLinks.bind(this));
  }

  ngOnChanges() {
    this._renderTemplate();
  }

  ngOnDestroy() {
    // Remove click listener
    this._unlisten();
  }

  private _renderTemplate() {
    if (this.richText) {
      if (!this.options && this.addTargetForExternalLinks) {
        this.options = {
          renderNode: {
            [INLINES.HYPERLINK]: (node, next) => {
              const isExternalLink = /^https?:\/\//.test(node.data.uri);
              // may be formatted as an external link and still be an in-app link
              const isInAppLink = matchesInAppLink(
                node.data.uri,
                this._environmentalVariablesService.config?.environment,
                this._environmentalVariablesService.config?.hostname
              );
              if (isExternalLink && !isInAppLink) {
                return `<a href="${node.data.uri}" target="_blank">${next(node.content)}</a>`;
              } else if (isInAppLink) {
                // NOTE: using `rel` because the Rich Text render strips unknown tag attributes, like `data-inAppLink`
                return `<a href="${node.data.uri}" rel="internal">${next(node.content)}</a>`;
              }
              return `<a href="${node.data.uri}">${next(node.content)}</a>`;
            },
          },
        };
      }
      const richTextHtml = this._documentToHtmlString(this.richText, this.options);
      if (this.translate) {
        // using then/catch rather than await in order to only set this.richTextHtml once
        this._translationService
          .translate(richTextHtml)
          .then((translatedRichText) => {
            this.richTextHtml = translatedRichText[0].translatedText;
          })
          .catch((error) => {
            this.richTextHtml = richTextHtml;
          });
      } else {
        this.richTextHtml = richTextHtml;
      }
    }
  }

  private _handleInAppLinks(clickEvent: PointerEvent) {
    const anchor = (clickEvent.target as HTMLElement).closest('a[rel="internal"]');
    if (anchor != null) {
      clickEvent.preventDefault();
      clickEvent.stopPropagation();
      void this._router.navigateByUrl(getInAppLink(anchor.getAttribute('href')));
    }
    // if anchor is null, no-op; not an internal link
  }
}

export const MockRichTextComponent = MockComponent({
  selector: 'kui-rich-text',
  inputs: ['addTargetForExternalLinks', 'onBackground', 'options', 'richText', 'translate'],
});
