import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { RequestType } from '@ktypes/enums';
import { JsonObject, NotificationDevice, NotificationInfo } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApi extends BaseApi {
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  async getNotifications(): Promise<NotificationInfo[]> {
    const request$ = this.performRequest<NotificationInfo[]>(
      RequestType.GET,
      this.buildUrl('/notifications/all', true),
      {
        includeToken: true,
      }
    ).pipe(
      map((response: HttpResponse<NotificationInfo[]>): NotificationInfo[] =>
        response?.body?.map((notificationInfo) => new NotificationInfo().deserialize(notificationInfo))
      ),
      catchError((err) => {
        console.warn('Failed getting notifications: ', err);
        return of(null);
      })
    );
    return await firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting notifications: ', error);
      return null;
    });
  }

  async updateNotification(notification: NotificationInfo): Promise<NotificationInfo> {
    const request$ = this.performRequest<NotificationInfo>(
      RequestType.PUT,
      this.buildUrl(`/notification/${notification.id}`, true),
      {
        requestBody: notification.serialize(),
        includeToken: true,
      }
    ).pipe(
      map(
        (response: HttpResponse<NotificationInfo>): NotificationInfo =>
          new NotificationInfo().deserialize(response.body)
      ),
      catchError((err) => {
        console.warn('Failed updating notification: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error updating notifications: ', error);
      return null;
    });
  }

  async createNotification(notification: NotificationInfo): Promise<NotificationInfo> {
    const request$ = this.performRequest<NotificationInfo>(RequestType.POST, this.buildUrl('/notification', true), {
      requestBody: notification.serialize(),
      includeToken: true,
    }).pipe(
      map(
        (response: HttpResponse<NotificationInfo>): NotificationInfo =>
          new NotificationInfo().deserialize(response.body)
      ),
      catchError((err) => {
        console.warn('Failed creating notification: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error creating notifications: ', error);
      return null;
    });
  }

  async deleteNotification(notification: NotificationInfo): Promise<boolean> {
    const request$ = this.performRequest<void>(
      RequestType.DELETE,
      this.buildUrl(`/notification/${notification.id}`, true),
      {
        includeToken: true,
      }
    ).pipe(
      catchError((err) => {
        console.warn('Failed deleting notifications: ', err);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<void>>(request$).catch((error): null => {
      console.warn('Error deleting notifications: ', error);
      return null;
    });
    if (response.status === 204) {
      return true;
    }
    return null;
  }

  async createDevice(body: JsonObject): Promise<NotificationDevice> {
    const request$ = this.performRequest<NotificationDevice>(RequestType.POST, this.buildUrl('/device', true), {
      requestBody: body,
      includeToken: true,
    }).pipe(
      map(
        (response: HttpResponse<NotificationDevice>): NotificationDevice =>
          new NotificationDevice().deserialize(response.body)
      ),
      catchError((err) => {
        console.warn('Failed creating device: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error creating notification device: ', error);
      return null;
    });
  }

  async getDevices(): Promise<NotificationDevice[]> {
    const request$ = this.performRequest<NotificationDevice[]>(RequestType.GET, this.buildUrl('/devices', true), {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<NotificationDevice[]>): NotificationDevice[] =>
        response?.body?.map((notificationDevice) => new NotificationDevice().deserialize(notificationDevice))
      ),
      catchError((err) => {
        console.warn('Failed getting devices: ', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting notification device: ', error);
      return null;
    });
  }

  async deleteDevice(deviceId: string): Promise<boolean> {
    const request$ = this.performRequest<void>(RequestType.DELETE, this.buildUrl(`/device/${deviceId}`, true), {
      includeToken: true,
    }).pipe(
      catchError((err) => {
        console.warn('Failed deleting notification device: ', err);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<void>>(request$).catch((error): null => {
      console.warn('Error deleting notification device: ', error);
      return null;
    });
    if (response.status === 204) {
      return true;
    }
    return null;
  }
}
