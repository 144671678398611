import { Document } from '@contentful/rich-text-types';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class LiveSupportInformation implements Deserializable<JsonObject, LiveSupportInformation> {
  constructor(
    public title?: string,
    public description?: Document,
    public linkUrl?: string,
    public linkText?: string,
    public email?: string,
    public phoneNumber?: string,
    public hours?: string
  ) {}

  deserialize(input: JsonObject): LiveSupportInformation {
    if (input == null) {
      return null;
    }

    this.title = input['title'] as string;
    this.description = input['description'] as Document;
    this.linkUrl = input['linkUrl'] as string;
    this.linkText = input['linkText'] as string;
    this.email = input['email'] as string;
    this.phoneNumber = input['phoneNumber'] as string;
    this.hours = input['hours'] as string;
    return this;
  }
}
