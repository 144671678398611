@if (isTrendingDown) {
  <div class="dash-down-1"></div>
  <div class="dash-middle"></div>
  <div class="dash-down-3"></div>
}
@if (!isTrendingDown) {
  <div class="dash-up-1"></div>
  <div class="dash-middle"></div>
  <div class="dash-up-3"></div>
}
