import { Component, Input, OnChanges } from '@angular/core';
import { SocialChallenge, SocialChallengeState } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

export const COLORS = {
  DEFAULT_LABEL_BACKGROUND: 'var(--secondary)',
  DEFAULT_LABEL_FOREGROUND: 'var(--onBackground)',
  ACTIVE_LABEL_BACKGROUND: 'var(--primary)',
  ENDED_LABEL_BACKGROUND: 'var(--greyMedium)',
};

@Component({
  selector: 'kf-sc-social-challenge-label',
  templateUrl: './social-challenge-label.component.html',
  styleUrls: ['./social-challenge-label.component.scss'],
})
export class SocialChallengeLabelComponent implements OnChanges {
  @Input() challenge: SocialChallenge;
  @Input() color = COLORS.DEFAULT_LABEL_FOREGROUND;
  @Input() isDetail = false;

  backgroundColor = COLORS.DEFAULT_LABEL_BACKGROUND;
  challengeState = SocialChallengeState;
  dateFormat: string;

  ngOnChanges(/* changes: SimpleChanges */) {
    if (this.challenge) {
      if (this.challenge.status.state !== SocialChallengeState.active) {
        this.dateFormat = calculateDateFormat(this.challenge);
      }
      if (this.challenge.status.state === SocialChallengeState.ended) {
        this.backgroundColor = COLORS.ENDED_LABEL_BACKGROUND;
      } else if (this.challenge.status.state === SocialChallengeState.active) {
        this.backgroundColor = COLORS.ACTIVE_LABEL_BACKGROUND;
        this.dateFormat = 'LLL d';
      } else {
        this.backgroundColor = COLORS.DEFAULT_LABEL_BACKGROUND;
      }
    }
  }
}

export const MockSocialChallengeLabelComponent = MockComponent({
  selector: 'kf-sc-social-challenge-label',
  inputs: ['challenge', 'color'],
});

// export so it's available for testing
export function calculateDateFormat(challenge: SocialChallenge) {
  let dateKey: 'endDate' | 'startDate';
  if (challenge.status.state === SocialChallengeState.ended) {
    dateKey = 'endDate';
  } else if (challenge.status.state === SocialChallengeState.upcoming) {
    dateKey = 'startDate';
  } else {
    return '';
  }
  return challenge?.[dateKey].getFullYear() !== new Date().getFullYear() ? 'LLL d, yyyy' : 'LLL d';
}
