export enum CustomCardCategory {
  actions = 'actions',
  challenges = 'challenges',
  discover = 'discover', // formerly explore
  quests = 'quests',
  recommended = 'recommended',
  reminders = 'reminders',
  resources = 'resources',
  saved = 'saved',
  takeAction = 'take-action', // take-action is the url needed for the detail card unloader
}
