import { QuestionSet } from '@kp/question-set/models/question-set.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class PastReflections implements Deserializable<JsonObject, PastReflections>, Serializable<JsonObject> {
  constructor(
    public date?: Date,
    public isLoading?: boolean,
    public hasMoreQuestions?: boolean,
    public availableDates?: QuestionSet[][]
  ) {}

  public empty(): PastReflections {
    return new PastReflections(new Date(), true, true, []);
  }

  questionSetAt(index: number) {
    if (index < this.availableDates?.length) {
      return this.availableDates?.[index];
    }
    return null;
  }

  copyWith(date?: Date, isLoading?: boolean, hasMoreQuestions?: boolean, availableDates?: QuestionSet[][]) {
    return new PastReflections(date, isLoading, hasMoreQuestions, availableDates);
  }

  deserialize(input: JsonObject): PastReflections {
    if (input == null || input.values == null) {
      return null;
    }
    this.date = input['date'] as Date;
    this.isLoading = !!input['isLoading'];
    this.hasMoreQuestions = !!input['hasMoreQuestions'];
    // TODO: Types do not match for available date deserialize. Revisit if needed
    /*    this.availableDates =
      Array.isArray(input['values']) && input['values'].map((value) => this.empty().deserialize(value));*/
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['date'] = this.date;
    json['available_dates'] = this.availableDates;
    return json;
  }
}
