import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AnalyticEvent, AnalyticsBloc, AnalyticsPageName } from '@kanalytics';
import { DetailCardData, JsonObject } from '@ktypes/models';
import { DateTimeUtil, isOfType } from '@kutil';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  constructor(private _analyticsBloc: AnalyticsBloc) {}

  @Input() analyticsPageName: AnalyticsPageName;
  @Input() showControls = true;
  @Input() detailCardData: DetailCardData;
  @Input()
  @HostBinding('class.on-background')
  isOnBackground = false;
  @Input() title: string;
  @Input() url: string;

  @Output() stateChanged = new EventEmitter<boolean>();
  @Output() progressPercentageChanged = new EventEmitter<number>();

  @ViewChild('bar') bar: ElementRef;
  @ViewChild('buffer') buffer: ElementRef;
  @ViewChild('player') player: ElementRef;
  @ViewChild('progress') progress: ElementRef;

  @HostBinding()
  currentTime = '00:00';
  current: number;
  duration: number;
  durationDisplay = '00:00';

  private _analyticsJson: JsonObject = {};
  private _sentEvents: JsonObject = {};
  private _shouldPlay = false;

  audioPlayButtonElement: HTMLElement;
  @ViewChild('audioPlayButton') set audioPlayButton(value: ElementRef) {
    if (value) {
      this.audioPlayButtonElement = value.nativeElement as HTMLElement;
    }
  }

  audioPauseButtonElement: HTMLElement;
  @ViewChild('audioPauseButton') set audioPauseButton(value: ElementRef) {
    if (value) {
      this.audioPauseButtonElement = value.nativeElement as HTMLElement;
    }
  }

  get isPlaying(): boolean {
    if (this.player) {
      return (
        (this.player.nativeElement as HTMLAudioElement).currentTime > 0 &&
        !(this.player.nativeElement as HTMLAudioElement).paused &&
        !(this.player.nativeElement as HTMLAudioElement).ended &&
        (this.player.nativeElement as HTMLAudioElement).readyState > 2
      );
    } else {
      return false;
    }
  }

  get progressPercentage(): number {
    return (
      ((this.player.nativeElement as HTMLAudioElement).currentTime /
        (this.player.nativeElement as HTMLAudioElement).duration) *
      100
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.detailCardData?.previousValue && changes?.detailCardData?.currentValue) {
      this._shouldPlay = !!(changes.detailCardData.currentValue as DetailCardData).audio;
    }
  }

  ngOnInit() {
    this._analyticsJson['title'] = this.title;
    this._analyticsJson['url'] = this.url;
    this._sentEvents[AnalyticEvent.audio_start] = false;
    this._sentEvents['audio_progress_25'] = false;
    this._sentEvents['audio_progress_50'] = false;
    this._sentEvents['audio_progress_75'] = false;
    this._sentEvents[AnalyticEvent.audio_end] = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this._shouldPlay) {
        (this.player.nativeElement as HTMLAudioElement).play();
      }
    }, 0);
  }

  ngOnDestroy() {
    if (!this._sentEvents[AnalyticEvent.audio_end]) {
      this.trackProgress(AnalyticEvent.audio_end);
      this._sentEvents[AnalyticEvent.audio_end] = true;
    }
    // This line is needed for not playing audio after the component is destroyed.
    // Not destroying the player due to potential issues when clicking back to audio and due to standard set at
    // https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
    (this.player.nativeElement as HTMLAudioElement).src = '';
  }

  play(event?: Event | MouseEvent) {
    if (this.player && !this.isPlaying) {
      (this.player.nativeElement as HTMLAudioElement).play();
      this._focusPauseButton();
      this.stateChanged.emit(true);
      if (['keyup', 'keydown'].includes(event?.type)) {
        event.preventDefault();
      }
    }
  }

  pause(event?: Event | MouseEvent) {
    if (this.player && this.isPlaying) {
      (this.player.nativeElement as HTMLAudioElement).pause();
      this._focusPlayButton();
      this.stateChanged.emit(false);
      if (['keyup', 'keydown'].includes(event?.type)) {
        event.preventDefault();
      }
    }
  }

  onError() {
    this._focusPlayButton();
  }

  playStarted() {
    this._focusPauseButton();
    this.stateChanged.emit(true);
  }

  paused() {
    this._focusPlayButton();
    this.stateChanged.emit(false);
  }

  getDuration() {
    this.durationDisplay =
      DateTimeUtil.formatSecondsAsTime((this.player.nativeElement as HTMLAudioElement).duration) ||
      'Failed to load audio';
  }

  updateTime() {
    const currentTimeInSec = (this.player.nativeElement as HTMLAudioElement).currentTime;
    if (currentTimeInSec > 0) {
      if (
        this.progressPercentage >= 0 &&
        this.progressPercentage < 25 &&
        !this._sentEvents[AnalyticEvent.audio_start]
      ) {
        this.trackProgress(AnalyticEvent.audio_start);
        this._sentEvents[AnalyticEvent.audio_start] = true;
      } else if (
        this.progressPercentage >= 25 &&
        this.progressPercentage < 50 &&
        !this._sentEvents['audio_progress_25']
      ) {
        this.trackProgress(AnalyticEvent.audio_progress);
        this._sentEvents['audio_progress_25'] = true;
      } else if (
        this.progressPercentage >= 50 &&
        this.progressPercentage < 75 &&
        !this._sentEvents['audio_progress_50']
      ) {
        this.trackProgress(AnalyticEvent.audio_progress);
        this._sentEvents['audio_progress_50'] = true;
      } else if (
        this.progressPercentage >= 75 &&
        this.progressPercentage < 95 &&
        !this._sentEvents['audio_progress_75']
      ) {
        this.trackProgress(AnalyticEvent.audio_progress);
        this._sentEvents['audio_progress_75'] = true;
      }

      this.progressPercentageChanged.emit(this.progressPercentage);
    }
    const currentTime = (this.player.nativeElement as HTMLAudioElement).currentTime;
    this.currentTime = DateTimeUtil.formatSecondsAsTime(currentTime) || 'Listen';
    this.current = currentTime;
    this.durationDisplay =
      DateTimeUtil.formatSecondsAsTime((this.player.nativeElement as HTMLAudioElement).duration) ||
      'Failed to load audio';

    for (let i = 0; i < (this.player.nativeElement as HTMLAudioElement).buffered.length; i++) {
      this.updateDuration(
        (this.player.nativeElement as HTMLAudioElement).buffered.end(i),
        (this.player.nativeElement as HTMLAudioElement).duration
      );
    }
  }

  seek(event: MouseEvent | Event, seekPercentage?: number): void {
    const percent = isOfType<MouseEvent, Event>(event, 'offsetX')
      ? event.offsetX / (event.target as HTMLProgressElement).clientWidth
      : seekPercentage;
    if (percent != null) {
      for (let i = 0; i < (this.player.nativeElement as HTMLAudioElement).buffered.length; i++) {
        this.updateDuration(
          (this.player.nativeElement as HTMLAudioElement).buffered.end(i),
          (this.player.nativeElement as HTMLAudioElement).duration
        );
        (this.player.nativeElement as HTMLAudioElement).currentTime =
          seekPercentage != null
            ? (this.player.nativeElement as HTMLAudioElement).currentTime +
              (this.player.nativeElement as HTMLAudioElement).duration * seekPercentage
            : percent * this.duration;
      }
      if (['keyup', 'keydown'].includes(event?.type)) {
        event.preventDefault();
      }
    }
  }

  updateDuration(bufferedEnd: number, duration: number) {
    this.duration = Math.max(bufferedEnd, duration);
  }

  ended() {
    if (!this._sentEvents[AnalyticEvent.audio_end]) {
      this.trackProgress(AnalyticEvent.audio_end);
      this._sentEvents[AnalyticEvent.audio_end] = true;
    }
    (this.player.nativeElement as HTMLAudioElement).currentTime = 0;
  }

  trackProgress(analyticEvent: AnalyticEvent) {
    this._analyticsJson['progress'] = this.progressPercentage;
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromInteraction({
        page: this.analyticsPageName,
        event: analyticEvent,
        meta: this._analyticsJson,
      })
    );
  }

  private _focusPlayButton() {
    if (this.audioPlayButtonElement) {
      setTimeout(() => this.audioPlayButtonElement.focus(), 10);
    }
  }

  private _focusPauseButton() {
    if (this.audioPauseButtonElement) {
      setTimeout(() => this.audioPauseButtonElement.focus(), 10);
    }
  }
}

export const MockAudioPlayerComponent = MockComponent({
  selector: 'kui-audio-player',
  inputs: ['analyticsPageName', 'detailCardData', 'isOnBackground', 'showControls', 'title', 'url'],
});
