import { Pipe, PipeTransform } from '@angular/core';
import { Question } from '@kp/question-set/models/question.model';

@Pipe({ name: 'imageAnswerIndex' })
export class ImageAnswerIndexPipe implements PipeTransform {
  transform(question: Question): number {
    let answerId: string;
    if (question.answer && question.answer.values && question.answer.values.some((ans) => ans.value === true)) {
      answerId = question.answer.values.filter((ans) => ans.value === true)[0].optionId;
    }

    let index = -1;
    if (question && question.display && question.display.options) {
      index = question.display.options.findIndex((opt) => opt.id === answerId);
    }
    return index;
  }
}
