import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserApi } from '@kapi';
import { PastReflections } from '@kp/past-reflections/models/past-reflections.model';
import { QuestionSetSource } from '@kp/question-set/models/question-set-source.model';
import { QuestionSet } from '@kp/question-set/models/question-set.model';
import { QuestionSetApi } from '@kp/question-set/question-set.api';
import { DataStoreService } from '@kservice';
import { DataStatus, Status } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PastReflectionsBloc {
  constructor(
    public questionSetApi: QuestionSetApi,
    private _dataStore: DataStoreService,
    public _userApi: UserApi,
    public router: Router
  ) {}

  // exposed publicly to allow for modal to be opened in app component
  pastReflectionViewClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  get pastReflectionViewClicked$(): Observable<boolean> {
    return this.pastReflectionViewClicked.asObservable();
  }

  private reflection: PastReflections;

  private _reflectionSubject: BehaviorSubject<DataStatus<PastReflections>> = new BehaviorSubject<
    DataStatus<PastReflections>
  >(null);

  get reflectionSubject$(): Observable<DataStatus<PastReflections>> {
    return this._reflectionSubject.asObservable();
  }

  async getReflectionHistory(): Promise<void> {
    this.reflection = new PastReflections().empty();

    const user = await this._userApi.getUser(this._dataStore.user?.id).catch((error) => {
      console.warn('There was an error trying to get the user data in PastReflectionBloc', error);
    });
    this.reflection.isLoading = true;
    this._reflectionSubject.next(new DataStatus<PastReflections>(Status.starting, this.reflection));
    const now = new Date();

    const nearestQuestionSet = await this._nearestTo(now);
    if (nearestQuestionSet && nearestQuestionSet.length > 0) {
      const nearestDate = nearestQuestionSet[0].completedDate ? nearestQuestionSet[0].completedDate : new Date();
      const questionSets = await this.questionSetApi.getQuestionSetsOnDate(
        nearestDate,
        QuestionSetSource.pastReflectionsFilter
      );
      if (this.reflection.availableDates && this.reflection.availableDates.length > 0) {
        const currentFirstDate =
          this.reflection.availableDates[0] !== null ? this.reflection.availableDates[0][0].completedDate : now;
        if (DateTimeUtil.isSameDay(nearestDate, currentFirstDate)) {
          this.reflection.availableDates.splice(0);
        }
      }
      this.reflection.date = nearestDate;
      this.reflection.isLoading = false;
      this.reflection.availableDates.unshift(questionSets);
      this.reflection.hasMoreQuestions = user && !DateTimeUtil.isSameDay(user.dateDeviceCreated, nearestDate);

      this._reflectionSubject.next(new DataStatus<PastReflections>(Status.done, this.reflection));
    } else {
      this.reflection.availableDates.unshift([]);
      this.reflection.date = DateTimeUtil.now();
      this.reflection.isLoading = false;
      this.reflection.hasMoreQuestions = false;
      this._reflectionSubject.next(new DataStatus<PastReflections>(Status.done, this.reflection));
    }
  }

  public getFor(date: Date, index: number) {
    this._reflectionHistoryGetDayEvent(date, index);
  }

  public getForPage(pageIndex: number) {
    if (pageIndex >= this.reflection.availableDates.length || this.reflection.availableDates[pageIndex] == null) {
      const previousIndex =
        pageIndex < this.reflection.availableDates.length ? pageIndex - 1 : this.reflection.availableDates.length - 1;
      const currentEarliest = this.reflection.availableDates[previousIndex][0];
      const completeDate = new Date(DateTimeUtil.stripTimeFromDate(currentEarliest.completedDate));
      completeDate.setMilliseconds(completeDate.getMilliseconds() - 1);
      const earlierDate = completeDate;
      this.getFor(earlierDate, pageIndex);
    } else {
      this.getFor(this.reflection.availableDates[pageIndex][0].completedDate, pageIndex);
    }
  }

  private async _nearestTo(date: Date) {
    return await this.questionSetApi.getNearestQuestionSets(date, null, QuestionSetSource.pastReflectionsFilter, 1, 1);
  }

  private async _reflectionHistoryGetDayEvent(date?: Date, index?: number) {
    let hasMore = true;
    const reflection = this.reflection.copyWith(
      date,
      true,
      this.reflection.hasMoreQuestions,
      this.reflection.availableDates
    );
    this._reflectionSubject.next(new DataStatus<PastReflections>(Status.starting, null, null));
    const user = await this._userApi.getUser(this._dataStore.user.id).catch((error) => {
      console.warn(`Error getting user: ${error}`);
    });
    let nearestDate = date;
    if (index >= reflection.availableDates.length) {
      let closeDate = new Date(date);
      closeDate = DateTimeUtil.stripTimeFromDate(closeDate);
      closeDate.setDate(closeDate.getDate() + 1);
      const nearest = await this._nearestTo(closeDate);
      if (nearest && nearest.length > 0) {
        nearestDate = nearest ? new Date(nearest[0].completedDate) : DateTimeUtil.now();
        let questionSets: QuestionSet[];
        nearestDate = new Date(DateTimeUtil.stripTimeFromDate(nearestDate));
        if (questionSets == null) {
          const temp = await this.questionSetApi.getQuestionSetsOnDate(
            nearestDate,
            QuestionSetSource.pastReflectionsFilter
          );
          if (temp.some((qs) => qs.hasAnswers)) {
            const tempFiltered = temp.filter((v) => v.hasAnswers);
            tempFiltered.forEach((questionSet) => {
              questionSet.pages.forEach(
                (page) => (page.questions = page.questions.filter((question) => question.hasAnswer))
              );
            });

            questionSets = tempFiltered;
          }
        }
        reflection.availableDates.push(questionSets);
      } else {
        reflection.availableDates.push(null);
        hasMore = false;
      }
    } else if (this.reflection.availableDates[index] == null) {
      hasMore = false;
    }
    reflection.isLoading = false;
    reflection.date = nearestDate;

    if (hasMore && user) {
      reflection.hasMoreQuestions = !DateTimeUtil.isSameDay(user.dateDeviceCreated, nearestDate);
    } else {
      reflection.hasMoreQuestions = false;
    }
    this._reflectionSubject.next(new DataStatus<PastReflections>(Status.done, reflection));
  }
}
