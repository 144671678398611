import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { Streak } from '@kp/streaks/streak.model';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { DataStatus, Status, StatusMessage } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StreakApi extends BaseApi {
  constructor(
    private client: HttpClient,
    public dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async getStreaks(): Promise<DataStatus<Streak>> {
    const dateNow = DateTimeUtil.toISOLocal(new Date());
    const body = {
      deviceCreatedTimestamp: dateNow,
    };

    const url = this.buildUrl('/streaks', true, body);

    const request$ = this.performRequest<Streak[]>(RequestType.GET, url, {
      includeToken: true,
      requestBody: body,
    }).pipe(
      map((response: HttpResponse<Streak[]>): DataStatus<Streak> => {
        if (response?.ok) {
          const streaks: Streak[] = response.body.map((streak) => new Streak().deserialize(streak));
          return new DataStatus<Streak>(Status.done, new StatusMessage(HttpStatusCode.OK, ''), streaks[0]);
        }
        return new DataStatus<Streak>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'There was an error retrieving your user streaks'),
          null
        );
      }),
      catchError(
        (): Observable<DataStatus<Streak>> =>
          of(
            new DataStatus<Streak>(
              Status.error,
              new StatusMessage(
                HttpStatusCode.INTERNAL_SERVER_ERROR,
                'There was a server error retrieving your user streaks'
              ),
              null
            )
          )
      )
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting streaks: ', error);
      return null;
    });
  }
}
