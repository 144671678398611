export enum AnalyticEvent {
  account_creation_reaction = 'account_creation_reaction',
  add_photo_clicked = 'add_photo_clicked',
  app_exit = 'app_exit',
  app_open = 'app_open',
  app_switch_requested = 'app_switch_requested',
  audio_end = 'audio_end',
  audio_progress = 'audio_progress',
  audio_start = 'audio_start',
  best_self_edit_click = 'best_self_edit_click',
  change_group_selector = 'change_group_selector',
  change_organization_selector = 'change_organization_selector',
  change_time_selector = 'change_time_selector',
  change_user_response_metric = 'change_user_response_metric',
  continue_on_web = 'continue_on_web',
  continue_on_web_after_account_creation = 'continue_on_web_after_account_creation',
  download_mobile = 'download_mobile',
  entering = 'entering',
  eligibility_inclusion_option_switched = 'eligibility_inclusion_option_switched',
  eligibility_method_switched = 'eligibility_method_switched',
  eligibility_method_failed = 'eligibility_method_failed',
  filter_selected = 'filter_selected',
  filter_bar_closed = 'filter_bar_closed',
  filter_bar_opened = 'filter_bar_opened',
  discover_navigation_from_recommended = 'discover_navigation_from_recommended',
  google_account_link_attempted = 'google_account_link_attempted',
  help_dialog_opened = 'help_dialog_opened',
  help_dialog_closed = 'help_dialog_closed',
  historyCalenderMetricChange = 'historyCalenderMetricChange',
  historyInfoModalOpen = 'historyInfoModalOpen',
  landing_page_button_clicked = 'landing_page_button_clicked',
  leaving = 'leaving',
  live_support_expanded = 'live_support_expanded',
  live_support_collapsed = 'live_support_collapsed',
  live_support_link_clicked = 'live_support_link_clicked',
  live_support_phone_clicked = 'live_support_phone_clicked',
  logged_in = 'logged_in',
  privacy_pledge_accepted = 'privacy_pledge_accepted',
  promo_clicked = 'promo_clicked',
  promo_expanded = 'promo_expanded',
  question_start = 'question_start',
  question_end = 'question_end',
  quick_actions_closed = 'quick_actions_closed',
  quick_actions_opened = 'quick_actions_opened',
  quick_action_selected = 'quick_action_selected',
  report_domain_clicked = 'report_domain_clicked',
  search_initiated = 'search_initiated',
  search_predefined_initiated = 'search_predefined_initiated',
  search_predefined_viewed = 'search_predefined_viewed',
  search_restored = 'search_restored',
  search_suspended = 'search_suspended',
  search_closed = 'search_closed',
  video_end = 'video_end',
  video_progress = 'video_progress',
  video_start = 'video_start',
  workbook_confirmation_shown = 'workbook_confirmation_shown',
  workbook_edit_shown = 'workbook_edit_shown',
  workbook_order_declined = 'workbook_order_declined',
  workbook_promo_shown = 'workbook_promo_shown',
  workbook_review_shown = 'workbook_review_shown',
}
