import { InjectionToken } from '@angular/core';
import { DeviceType } from '@ktypes/enums';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { AnalyticEvent } from './enums/analytic-event.enum';
import { AnalyticsCategory } from './enums/analytics-category.enum';
import { AnalyticsPageName } from './enums/analytics-page-name.enum';
import { PageMappingData } from './page-mapping-info.model';

export const PAGE_MAPPING = new InjectionToken<PageMappingData>('PageMappingData');

export interface AnalyticsData {
  page?: AnalyticsPageName;
  event?: AnalyticEvent;
  meta?: JsonObject;
}

export class AnalyticsEvent implements Deserializable<JsonObject, AnalyticsEvent>, Serializable<JsonObject> {
  // generated
  public appVersion: string;
  public deviceTimestamp: string;
  public platform = DeviceType.WEB;
  public platformVersion: string;
  public timezone: string;

  constructor(
    public page?: AnalyticsPageName,
    public url?: string,
    public category?: AnalyticsCategory,
    public section?: string,
    public event?: AnalyticEvent,
    public label?: string,
    public value?: number,
    public meta: JsonObject = {}
  ) {
    this.deviceTimestamp = DateTimeUtil.formatInLocal(new Date());
    this.timezone = DateTimeUtil.getTimeZone();
    this.platform = DeviceType.WEB;

    // these values come from the constructor
    this.page = page;
    this.url = url;
    this.category = category;
    this.section = section;
    this.event = event;
    this.label = label;
    this.meta = meta;
  }

  deserialize(input: JsonObject, existingData?: AnalyticsEvent): AnalyticsEvent {
    if (input == null) {
      return null;
    }

    this.page = this._getValueOrExisting('page', input, existingData) as AnalyticsPageName;
    this.url = this._getValueOrExisting('url', input, existingData) as string;
    this.category = this._getValueFromEnum(AnalyticsCategory, 'category', input, existingData) as AnalyticsCategory;
    this.section = this._getValueOrExisting('section', input, existingData) as string;
    this.event = this._getValueFromEnum(AnalyticEvent, 'event', input, existingData) as AnalyticEvent;
    this.label = this._getValueOrExisting('label', input, existingData) as string;
    this.value = this._getValueOrExisting('value', input, existingData) as number;
    this.meta = this._getValueOrExisting('meta', input, existingData) as JsonObject;
    this.deviceTimestamp = this._getValueOrExisting('deviceTimestamp', input, existingData) as string;
    this.timezone = this._getValueOrExisting('timezone', input, existingData) as string;
    this.platform =
      (this._getValueFromEnum(DeviceType, 'platform', input, existingData) as DeviceType) || DeviceType.WEB;
    this.platformVersion = this._getValueOrExisting('platformVersion', input, existingData) as string;
    this.appVersion = this._getValueOrExisting('appVersion', input, existingData) as string;
    this.platformVersion = this._getValueOrExisting('platformVersion', input, existingData) as string;

    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['deviceTimestamp'] = this.deviceTimestamp;
    json['timezone'] = this.timezone;
    json['platform'] = this.platform;
    json['platformVersion'] = this.platformVersion;
    json['appVersion'] = this.appVersion;
    json['page'] = this.page;
    json['category'] = this.category;
    json['section'] = this.section;
    json['event'] = this.event;
    switch (this.event) {
      case AnalyticEvent.app_open:
        json['event'] = 'App Open';
        break;
      case AnalyticEvent.app_exit:
        json['event'] = 'App Exit';
        break;
    }
    json['label'] = this.label;
    json['value'] = this.value;
    json['meta'] = this.meta;
    return json;
  }

  private _getValueOrExisting(key: string, input: JsonObject, existing: AnalyticsEvent): number | string | JsonObject {
    const existingData = existing == null ? this : existing;
    return (
      (input[key] as number | string | JsonObject) ||
      ((existingData as JsonObject)?.[key] as number | string | JsonObject)
    );
  }

  private _getValueFromEnum(values: JsonObject, key: string, input: JsonObject, existing: AnalyticsEvent): string {
    const existingData = existing == null ? this : existing;
    return (
      (values[input[key] as string] as string) ||
      ((existingData && values[(existingData as JsonObject)[key] as string]) as string)
    );
  }
}
