import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-smiley-row',
  templateUrl: './smiley-row.component.html',
  styleUrls: ['./smiley-row.component.scss'],
})
export class SmileyRowComponent {
  @Input() interactable = false;
  @Input() selectedIndex?: number;

  @Output() smileySelected = new EventEmitter<number>();

  selectSmiley(index: number) {
    if (this.interactable) {
      this.selectedIndex = index;
      this.smileySelected.emit(index);
    }
  }
}

export const MockSmileyRowComponent = MockComponent({
  selector: 'kp-smiley-row',
  inputs: ['interactable', 'selectedIndex'],
});
