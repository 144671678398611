import { isPlatformBrowser } from '@angular/common';
import {
  ClassProvider,
  FactoryProvider,
  Injectable,
  InjectionToken,
  PLATFORM_ID,
  ValueSansProvider,
} from '@angular/core';
import { JsonObject } from '@ktypes/models';

/**
 * Window Provider
 *
 * https://brianflove.com/2018/01/11/angular-window-provider/
 *
 * Why?
 *   Because we don't want to reference the global Window object directly in our Angular
 *   component since our application may be used in Angular Universal, and it's considered
 *   best practice to avoid directly referencing the global objects. While you may have no
 *   plans to use Angular Universal, this enables us to cleanly inject the browser's native
 *   Window object into a component.
 *
 * To use in a Component/Service (requires dependency injection),
 * import WINDOW and Inject it into the constructor
 *
 * import { WINDOW } from '@kservice';
 *
 * export class IndexComponent {
 *   constructor(@Inject(WINDOW) private window: Window) {
 *     console.log(window);
 *   }
 * }
 *
 */

/* Create a new injection token for injecting the window into a component. */
export const WINDOW = new InjectionToken('WindowToken');

declare global {
  interface Window {
    isSupportedBrowser: boolean;
    kpUpdateUser: JsonObject;
    Notification: {
      requestPermission: () => Promise<NotificationPermission>;
      get permission(): NotificationPermission;
    };
  }
}

/* Define abstract class for obtaining reference to the global window object. */
export abstract class WindowRef {
  // eslint-disable-next-line @typescript-eslint/ban-types
  get nativeWindow(): Window | Object {
    throw new Error('nativeWindow - Not implemented.');
  }
}

/* Define class that implements the abstract class and returns the native window object. */
@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  get nativeWindow(): Window | Object {
    return window;
  }
}

/* Create a factory function that returns the native window object. */
// eslint-disable-next-line @typescript-eslint/ban-types
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: Object): Window | Object {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return new Object();
}

/* Create a injectable provider for the WindowRef token that uses the BrowserWindowRef class. */
const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef,
};

/* Create an injectable provider that uses the windowFactory function for returning the native window object. */
const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID],
};

/* Create an array of providers. */
export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];

class MockWindowRef extends WindowRef {
  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  get nativeWindow(): Window | Object {
    // just the basics needed all the time
    return {
      document: {},
      location: {},
      navigator: { userAgent: 'UA_MockWindowRef' },
    };
  }
}

export function MOCK_WINDOW_PROVIDERS(options?: {
  browserWindowProvider?: Partial<ClassProvider>;
  windowProvider?: Partial<ValueSansProvider | FactoryProvider>;
}): [Partial<ClassProvider>, Partial<ValueSansProvider | FactoryProvider>] {
  return [
    options?.browserWindowProvider || { provide: WindowRef, useClass: MockWindowRef },
    options?.windowProvider || { provide: WINDOW, useFactory: () => ({}) },
  ];
}

export const windowMock = {
  addEventListener<K extends keyof WindowEventMap>(
    type: K,
    listener: (this: Window, ev: WindowEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ) {
    /* no-op */
  },
  confirm() {
    /* no-op */
  },
  close() {
    /* no-op */
  },
  document: {},
  getComputedStyle(elt: Element, pseudoElt?: string | null) {
    /* no-op */
  },
  history: {
    pushState(data: any, unused: string, url?: string | URL | null) {
      /* no-op */
    },
  },
  localStorage: {},
  location: {
    get href() {
      return '';
    },
    set href(value) {
      /* no-op */
    },
    get host() {
      return 'mockHost';
    },
    get origin() {
      return '';
    },
    reload() {
      /* no-op */
    },
  },
  matchMedia(query: string) {
    return { matches: false };
  },
  navigator: {
    serviceWorker: {
      getRegistration: (scope: string | URL) => Promise.resolve(undefined),
      register: () =>
        Promise.resolve({
          pushManager: {
            subscribe: () => {
              /* no-op */
            },
          },
        }),
    },
    userAgent: 'UA_test_windowMock',
  },
  Notification: {
    requestPermission: () => Promise<NotificationPermission>,
    get permission(): NotificationPermission {
      return 'default';
    },
  },
  open: (url?: string, target?: string, options?: any) => {
    /* no-op */
  },
  opener: '',
  scroll() {
    /* no-op */
  },
  scrollTo() {
    /* no-op */
  },
  screen: {},
  sessionStorage: {},
} as unknown as Window;
