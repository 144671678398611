import { Deserializable } from './deserializable.model';
import { Domain } from './domain.model';
import { JsonObject } from './json-object.model';

export class PredefinedSearch extends Domain implements Deserializable<JsonObject, PredefinedSearch> {
  constructor(
    id?: string,
    key?: string,
    name?: string,
    term?: string,
    public tracked = false
  ) {
    super(id, key, name, term);
  }

  deserialize(input: JsonObject): PredefinedSearch {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.key = input['key'] as string;
    this.name = input['name'] as string;
    this.term = input['term'] as string;
    this.tracked = !!input['tracked'];

    return this;
  }
}
