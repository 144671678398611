@if (navItem.type === navItemType.standard) {
  <a
    [routerLink]="navItem.link"
    (click)="navItemClicked.emit()"
    class="header-item"
    routerLinkActive="nav-active"
    ariaCurrentWhenActive="page">
    @if (state === navImageState.loaded && image?.urlSmallest) {
      <img
        class="image-icon"
        [src]="image?.urlSmallest | bypassUrlSafety"
        [alt]="('Uploaded image, original filename was' | translate | async) + ' ' + image?.originalFilename" />
    }
    @if (!image?.urlSmallest && navItem.icon) {
      <svg-icon [src]="navItem.icon"></svg-icon>
    }
    <div class="header-title">
      {{ navItem.title | translate | async }}
    </div>
  </a>
}

@if (navItem.type === navItemType.quickActions) {
  <kp-quick-actions
    [quickActionsOpen]="quickActionsOpen"
    (quickActionsToggled)="quickActionsToggled.emit($event)"></kp-quick-actions>
}
