import { Base64Util, EnumUtil } from '@kutil';
import { Product } from '../enums/product.enum';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AuthProviders = 'Google' | 'SignInWithApple' | 'kumanu' | 'other';

export enum SOCIAL_SSO {
  Google = 'Google',
  SignInWithApple = 'SignInWithApple',
}

export enum CORPORATE_SSO {
  kumanu = 'kumanu',
}

export enum NON_SSO_AUTH_PROVIDER {
  Cognito = 'Cognito',
  other = 'other',
}

export const SSO_PROVIDERS: string[] = [
  ...(Object.keys(SOCIAL_SSO).map((key) => EnumUtil.getByValue(SOCIAL_SSO, key)) as string[]),
  ...(Object.keys(CORPORATE_SSO).map((key) => EnumUtil.getByValue(CORPORATE_SSO, key)) as string[]),
];

export class AuthProviderState implements Deserializable<JsonObject, AuthProviderState>, Serializable<string> {
  constructor(
    public provider?: string,
    public token?: string,
    public id?: string,
    public product = Product.purposeful
  ) {}

  deserialize(input: JsonObject): AuthProviderState {
    if (input == null) {
      return null;
    }
    this.provider = input['provider'] as string;
    this.token = input['token'] as string;
    this.id = input['id'] as string;
    this.product = input['product'] as Product;

    return this;
  }

  serialize(): string {
    return JSON.stringify({ provider: this.provider, token: this.token, id: this.id, product: this.product });
  }

  get asBase64() {
    return Base64Util.toWebSafe(Base64Util.encode(this.serialize()));
  }

  get urlEncoded() {
    return encodeURIComponent(this.serialize());
  }

  get urlEncodedBase64() {
    return encodeURIComponent(this.asBase64);
  }
}
