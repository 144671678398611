import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _history: string[] = [];

  constructor(
    private _location: Location,
    private _router: Router
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._history.push(event.urlAfterRedirects);
      }
    });
  }

  back(): void {
    const lastPage = this._history.length > 1 && this._history.at(-2);
    if (lastPage) {
      this._history.pop(); // remove the current page
      this._location.back();
    } else {
      this.clear(); // clear history
      void this._router.navigate(['']);
    }
  }

  clear() {
    this._history = [];
  }
}
