<div class="checkboxes">
  <div class="instructions-container">
    <p class="instructions">{{ getInstructions(min, max) | translate | async }}</p>
  </div>
  <div class="checkbox-answer-widget">
    @for (option of displayOptions; track option) {
      <div class="checkbox-option-container">
        <kp-input-option
          [isSelected]="option.isSelected"
          [isDisabled]="!isLessThanMax() && max !== 1"
          [appearDisabled]="!isLessThanMax() && !option.isSelected"
          [answerText]="option.text"
          [checkbox]="true"
          [forId]="option.id"
          (selectInputOption)="onCheckboxChecked($event, option)"></kp-input-option>
      </div>
    }
  </div>
</div>
