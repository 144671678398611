import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clickableTelephoneNumber',
})
export class ClickableTelephoneNumberPipe implements PipeTransform {
  transform(value: string, extension: string = null): string {
    return 'tel:' + value + (extension ? ';' + extension : '');
  }
}
