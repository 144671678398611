import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnswerValue } from '@kp/question-set/models/answer-value.model';
import { DisplayOption } from '@kp/question-set/models/display-option.model';
import { DataType, Question } from '@kp/question-set/models/question.model';
import { MockComponent } from '@kutil/test';

type FlexibleOption = DisplayOption | number;

@Component({
  selector: 'kp-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnChanges, AfterViewInit {
  @Input() question: Question;

  @Output() sliderAnswer: EventEmitter<AnswerValue> = new EventEmitter<AnswerValue>();

  currentOption: string;
  dataType = DataType;
  initialCallToAction = 'Slide to answer...';
  labelMax: string;
  labelMin: string;
  optionIndex = 0;
  options: FlexibleOption[];
  step: number;
  valueMax: number;
  valueMin: number;
  initialValue: number;
  sliderValue: number;

  private _optionSelected: boolean;

  constructor(private _sliderComponentElement: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if ((changes?.question?.currentValue as Question)?.id !== (changes?.question?.previousValue as Question)?.id) {
      this._clearSliderAttributes();
    }
    if (this.question) {
      this._getSliderAttributes();
    }
  }

  ngAfterViewInit(): void {
    // setTimeout used here in order to get rid of ExpressionChanged error
    setTimeout(() => {
      if (this.question.hasAnswer) {
        return this.getSliderValue(this.initialValue);
      } else if (this.question) {
        return this.getSliderValue(this.valueMin, true);
      }
    });
  }

  private _getNumericalOptions(): number[] {
    const numericalOptions: number[] = [];
    for (let index = this.valueMin; index <= this.valueMax; index++) {
      numericalOptions.push(index);
    }
    return numericalOptions;
  }

  private _getCurrentOption() {
    if (this.question) {
      this.currentOption =
        (this.question.dataType === DataType.numeric
          ? this.options[this.optionIndex]?.toString()
          : this.question.display.options[this.optionIndex]?.text) || '';
    } else {
      this.currentOption = '';
    }
  }

  getSliderValue(value: string | number, isInitialView = false): void {
    if (this._optionSelected) {
      // if option was already selected, exit immediately
      return;
    }
    this._optionSelected = true;
    if (isInitialView) {
      if (!this.question.settings.required) {
        this.sliderAnswer.emit(new AnswerValue());
        this._resetOptionSelectedFlag();
      }
    } else {
      const numericalOptions = this.options as number[];
      const maxOptionIndex = this.options.length - 1;
      this.optionIndex = normalizeOptionIndex(
        this.question?.dataType === DataType.numeric
          ? numericalOptions.indexOf(Number(value))
          : Math.round(Number(value) * maxOptionIndex),
        maxOptionIndex
      );
      try {
        (this._sliderComponentElement.nativeElement as HTMLElement).querySelector<HTMLElement>('hr.glow').style.width =
          this.question?.dataType === DataType.numeric
            ? `${(this.optionIndex / maxOptionIndex) * 100}%`
            : `${Number(value) * 100}%`;
      } catch (err) {
        console.warn(`Element reference or hr element was not available: ${err}`);
      }
      this._getCurrentOption();
      let answerValue: AnswerValue;
      switch (this.question?.dataType) {
        case DataType.ordinal: {
          const option = this.options[this.optionIndex] as DisplayOption;
          answerValue = new AnswerValue().deserialize({
            optionId: option?.id,
            value: true,
          });
          break;
        }
        case DataType.numeric:
          answerValue = new AnswerValue().deserialize({
            value,
          });
          break;
      }
      this.sliderAnswer.emit(answerValue);
      this._resetOptionSelectedFlag();
    }
  }

  private _resetOptionSelectedFlag() {
    // allow options to be chosen again, but ensure all events are flushed
    setTimeout(() => {
      this._optionSelected = false;
    }, 100); // 100ms gives a small buffer, events fire about 5-10ms apart
  }

  private _getSliderAttributes() {
    this.currentOption = this.initialCallToAction;
    switch (this.question?.dataType) {
      case DataType.ordinal:
        this.valueMin = 0;
        this.valueMax = 1;
        this.step = 1 / (this.question.display.options.length - 1);
        this.labelMin = this.question.display.valueMinLabel || this.question.display.options[0].text;
        this.labelMax =
          this.question.display.valueMaxLabel ||
          this.question.display.options[this.question.display.options.length - 1].text;
        this.options = this.question?.display?.options;
        if (this.question.hasAnswer) {
          const optionIds = (this.options as DisplayOption[]).map((option: DisplayOption) => {
            return option.id;
          });
          this.optionIndex = normalizeOptionIndex(
            optionIds.indexOf(this.question.answer.values[0].optionId),
            this.question.display.options.length - 1
          );
          this.initialValue = this.optionIndex / (this.options.length - 1);
          this._getCurrentOption();
        } else {
          this.initialValue = this.valueMin;
        }
        break;
      case DataType.numeric:
        this.valueMin = this.question?.settings?.valueMin;
        this.valueMax = this.question?.settings?.valueMax;
        this.step = 1;
        this.labelMin = this.question.display.valueMinLabel || this.valueMin.toString();
        this.labelMax = this.question.display.valueMaxLabel || this.valueMax.toString();
        this.options = this._getNumericalOptions();
        if (this.question.hasAnswer) {
          this.initialValue = this.question.answer.values[0].value as number;
          this._getCurrentOption();
        } else {
          this.initialValue = this.valueMin;
        }
        break;
    }
  }

  private _clearSliderAttributes() {
    delete this.currentOption;
    this.initialCallToAction = 'Slide to answer...';
    delete this.labelMax;
    delete this.labelMin;
    this.optionIndex = 0;
    delete this.options;
    delete this.step;
    delete this.valueMax;
    delete this.valueMin;
    delete this.initialValue;
    delete this.sliderValue;
    try {
      // attempt to set input slider and hr elements back to unselected state
      (this._sliderComponentElement.nativeElement as HTMLElement).querySelector<HTMLInputElement>(
        'input.slider'
      ).value = '0';
      (this._sliderComponentElement.nativeElement as HTMLElement).querySelector<HTMLElement>('hr.glow').style.width =
        '0%';
    } catch (err) {
      console.warn(`Element reference or slider element was not available: ${err}`);
    }
  }
}

function normalizeOptionIndex(optionIndex: number, maxOptionIndex: number): number {
  // ensure optionIndex is in the array range if it wasn't; defaults to 0 if indexOf returned -1 earlier
  if (optionIndex < 0) {
    return 0;
  } else if (optionIndex > maxOptionIndex) {
    return maxOptionIndex;
  }
  return optionIndex;
}

export const MockSliderComponent = MockComponent({
  selector: 'kp-slider',
  inputs: ['question'],
});
