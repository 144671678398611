import { UserType } from '@ktypes/enums';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class EligibilityCandidate
  implements Serializable<JsonObject>, Deserializable<JsonObject, EligibilityCandidate>
{
  constructor(
    public candidateId?: string,
    public firstName?: string,
    public lastName?: string,
    public addressLine?: string,
    public apt?: string,
    public region?: string,
    public city?: string,
    public country?: string,
    public postalCode?: string,
    public eligibilityLink?: string,
    public email?: string,
    public workbookOnly?: boolean,
    public workbookOrdered?: boolean,
    public userType?: UserType
  ) {}

  serialize(): JsonObject {
    if (this.apt) {
      this.addressLine = `${this.addressLine} ${this.apt}`;
    }
    const json: JsonObject = {};
    json['eligibilityCandidateId'] = this.candidateId;
    json['firstName'] = this.firstName;
    json['lastName'] = this.lastName;
    json['postalCode'] = this.postalCode;
    json['addressLine'] = this.addressLine;
    json['region'] = this.region;
    json['country'] = this.country;
    json['city'] = this.city;
    json['email'] = this.email;
    json['workbookOnly'] = this.workbookOnly;
    json['workbookOrdered'] = this.workbookOrdered;
    json['userType'] = this.userType;

    return json;
  }

  deserialize(input: JsonObject): EligibilityCandidate {
    if (input == null) {
      return null;
    }
    this.candidateId = input['candidateId'] as string;
    this.firstName = input['firstName'] as string;
    this.lastName = input['lastName'] as string;
    this.addressLine = input['addressLine'] as string;
    this.apt = input['apt'] as string;
    this.region = input['region'] as string;
    this.city = input['city'] as string;
    this.country = input['country'] as string;
    this.postalCode = input['postalCode'] as string;
    this.eligibilityLink = input['eligibilityLink'] as string;
    this.email = input['email'] as string;
    this.workbookOnly = !!input['workbookOnly'];
    this.workbookOrdered = !!input['workbookOrdered'];
    this.userType = input['userType'] as UserType;
    return this;
  }

  clearPersonalInfo() {
    this.firstName = null;
    this.lastName = null;
    this.addressLine = null;
    this.apt = null;
    this.region = null;
    this.city = null;
    this.country = null;
    this.postalCode = null;
    this.email = null;
  }
}
