import { Component, Input, OnChanges } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {
  @Input() displayAsPercent = false;
  @Input() hasPrimaryColors = false;
  @Input() max: number;
  @Input() value: number;

  displayMax: number;
  displayValue: number;

  ngOnChanges(/* changes */) {
    if (this.displayAsPercent) {
      // convert value and max to percent (ratio of 100)
      this.displayValue = Math.round((this.value / this.max) * 100);
      this.displayMax = 100;
    } else {
      this.displayValue = this.value;
      this.displayMax = this.max;
    }
  }
}

export const MockProgressBarComponent = MockComponent({
  selector: 'kp-progress-bar',
  inputs: ['displayAsPercent', 'hasPrimaryColors', 'max', 'value'],
});
