import { KumanuKeyType } from '@kp/dialogue/models/dialogue.model';
import { Page } from '@kp/question-set/models/page.model';
import { Deserializable, JsonObject, Serializable } from '@ktypes/models';

export class QuestionSet implements Deserializable<JsonObject, QuestionSet>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public type?: string,
    public deviceCreatedTimestamp?: string,
    public deviceCompletedTimestamp?: string,
    public createdLocation?: string,
    public key?: string,
    public pages?: Page[],
    public keyType?: KumanuKeyType,
    public isPulseSurvey?: boolean
  ) {}

  get completedDate() {
    if (this.deviceCompletedTimestamp) {
      return new Date(this.deviceCompletedTimestamp);
    }
    return null;
  }

  get hasAnswers() {
    return this.pages.some((page) => page.questions.some((question) => question.hasAnswer));
  }

  isLastPage(currentPage: number) {
    return this.pages?.[currentPage + 1] == null;
  }

  deserialize(input: JsonObject): QuestionSet {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.type = input['type'] as string;
    this.deviceCreatedTimestamp = input['deviceCreatedTimestamp'] as string;
    this.deviceCompletedTimestamp = input['deviceCompletedTimestamp'] as string;
    this.createdLocation = input['createdLocation'] as string;
    this.key = (input['logicKey'] as string) || (input['refKey'] as string);
    this.pages = (input?.['pages'] as Page[])?.map((page) => new Page().deserialize(page));
    this.keyType = input['keyType'] as KumanuKeyType;
    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['type'] = this.type;
    json['deviceCreatedTimestamp'] = this.deviceCreatedTimestamp;
    json['deviceCompletedTimestamp'] = this.deviceCompletedTimestamp;
    json['createdLocation'] = this.createdLocation;
    json['key'] = this.key;
    json['pages'] = this.pages.map((page) => page.serialize());
    json['keyType'] = this.keyType;
    return json;
  }
}
