import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailLink',
})
export class EmailLinkPipe implements PipeTransform {
  transform(value: string, subject: string): string {
    return `mailto:${value}?subject=${encodeURIComponent(subject)}`;
  }
}
