@if (!useAlternate) {
  <div class="animation-container">
    <h1 class="good-job-text">
      {{ doneMessage | translate | async }}
    </h1>
    <canvas riv="reflection-complete" class="animation-canvas" [height]="canvasHeight" [width]="canvasWidth">
      <riv-player name="play" [play]="playAnimation" (load)="loaded()"></riv-player>
    </canvas>
  </div>
} @else {
  <div class="fallback-animation">
    <div class="diamond one"></div>
    <div class="diamond two"></div>
    <div class="diamond three"></div>
    <div class="diamond four"></div>
    <h1 class="animation-message" aria-live="assertive" aria-atomic="true">{{ doneMessage | translate | async }}</h1>
    <div class="diamond five"></div>
    <div class="diamond six"></div>
    <div class="diamond seven"></div>
    <div class="diamond eight"></div>
  </div>
}

<kui-a11y-live-announce
  politenessSetting="assertive"
  [announcement]="'Showing dialogue completed celebration animation, ' + doneMessage"></kui-a11y-live-announce>
