<div class="progress-wrap" [ngClass]="{ 'on-background': isOnBackground }">
  <kui-arc-chart
    class="group-progress-arc-chart arc-chart"
    [arcSize]="60"
    [arcWeight]="5"
    [isOnBackground]="isOnBackground"
    [target]="groupGoal"
    [progress]="groupProgress"
    [fillOnNoData]="false"
    [initProgress]="initGroupProgress"></kui-arc-chart>
  <kui-arc-chart
    class="individual-progress-arc-chart arc-chart"
    [arcSize]="44"
    [arcWeight]="5"
    [isOnBackground]="isOnBackground"
    [target]="individualGoal"
    [progress]="individualProgress"
    [fillOnNoData]="false"
    [initProgress]="initIndividualProgress"></kui-arc-chart>
  <svg #progress class="progress-svg" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"></svg>
</div>
