import { Component, Input, OnChanges } from '@angular/core';
import { Page } from '@kp/question-set/models/page.model';
import { QuestionSet } from '@kp/question-set/models/question-set.model';
import { Question, QuestionType } from '@kp/question-set/models/question.model';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-reflection-question-set',
  templateUrl: './reflection-question-set.component.html',
  styleUrls: ['./reflection-question-set.component.scss'],
})
export class ReflectionQuestionSetComponent implements OnChanges {
  @Input() questionSet: QuestionSet;
  QuestionType = QuestionType;
  questions: Question[];

  ngOnChanges() {
    this.calculateQuestions();
  }

  calculateQuestions() {
    const pages: Page[] = this.questionSet?.pages || [];
    // get / return all questions that have an answer value
    this.questions = pages
      .reduce<Question[]>((questions, page) => [...questions, ...(page?.questions || [])], [])
      .filter((question) => question.hasAnswer);
  }
}

export const MockReflectionQuestionSetComponent = MockComponent({
  selector: 'kp-reflection-question-set',
  inputs: ['questionSet'],
});
