import { DialogueLogicKey } from '@kp/dialogue/models/dialogue.model';
import { Deserializable, JsonObject } from '@ktypes/models';

export enum DialogueStatus {
  not_started = 'not_started',
  started = 'started',
  completed = 'completed',
}

export interface DailyReflectionDialogueData {
  [key: string]: {
    todayMessage: string;
    buttonText: string;
  };
}

export interface OnboardingDialogueData {
  explanation: string;
}

export type DialogueStatusData = DailyReflectionDialogueData | OnboardingDialogueData;

export interface DialogueStatusApiResponse {
  userDialogueId: string;
  status: string;
  lastCompleted?: string;
  logicKey?: string;
  data?: DialogueStatusData;
}

export class DialogueDataStatus implements Deserializable<JsonObject, DialogueDataStatus> {
  constructor(
    public dialogueId?: string,
    public dialogueStatus?: DialogueStatus,
    public lastCompleted?: string, // TODO: Should this be a date now? gonna be sent as ISO-8601
    public logicKey?: DialogueLogicKey,
    public data?: DialogueStatusData
  ) {}

  deserialize(input: DialogueStatusApiResponse): DialogueDataStatus {
    this.dialogueId = input['userDialogueId'];
    this.dialogueStatus = input['status'] as DialogueStatus;
    this.lastCompleted = input['lastCompleted'];
    this.logicKey = input['logicKey'] as DialogueLogicKey;
    this.data = input['data'];

    return this;
  }
}
