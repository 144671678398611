/**
 * validateKeyIsNumber returns true if it is a number (or a modifier key is pressed)
 * @param key
 * @param modifierKey
 */
export function validateKeyIsNumber(event: KeyboardEvent): boolean {
  const key = event?.key;
  const allowedKeys = '01234567879';
  const controlKeys = [
    'Enter',
    'Tab',
    'Delete',
    'Backspace',
    'ArrowUp',
    'ArrowLeft',
    'ArrowDown',
    'ArrowRight',
    'Home',
    'End',
  ];
  const modifiers = ['altKey', 'ctrlKey', 'metaKey', 'shiftKey'];
  // @ts-ignore TODO: Revisit to resolve lint issues
  if (modifiers.some((modifierKey) => event[modifierKey]) || controlKeys.includes(key)) {
    // allow anything with a modifier key pressed, or any of the control keys
    return true;
  }
  return !(key?.length > 1 || !allowedKeys.includes(key) || isNaN(parseInt(key, 10)));
}
