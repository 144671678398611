import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ProductInfo } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-app-switcher',
  templateUrl: './app-switcher.component.html',
  styleUrls: ['./app-switcher.component.scss'],
})
export class AppSwitcherComponent {
  @Input() apps: ProductInfo[];
  @Output() appDrawerOpened = new EventEmitter<void>();
  @Output() appClicked = new EventEmitter<ProductInfo>();

  open = false;

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    if (!(this._eRef.nativeElement as HTMLElement).contains(event.target as Node)) {
      this.open = false;
    }
  }

  constructor(private _eRef: ElementRef) {}

  onAppClick(app: ProductInfo) {
    this.open = false;
    this.appClicked.emit(app);
  }

  toggleAppDrawer() {
    if (!this.open) {
      this.appDrawerOpened.emit();
    }
    this.open = !this.open;
  }
}

export const MockAppSwitcherComponent = MockComponent({
  selector: 'kui-app-switcher',
  inputs: ['apps'],
});
