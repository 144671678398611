import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Document } from '@contentful/rich-text-types';
import { SocialChallenge, SocialChallengeState } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

interface TertiaryChallengeInfo {
  ariaLabelState?: string;
  ariaLabelStatus?: string;
  state?: string;
  separator?: string;
  statusIcon?: string;
  status?: number | string;
}

@Component({
  selector: 'kf-sc-social-challenge',
  templateUrl: './social-challenge.component.html',
  styleUrls: ['./social-challenge.component.scss'],
})
export class SocialChallengeComponent implements OnInit, OnChanges {
  @Input() challenge: SocialChallenge;
  @Input() challengeCompletedToday: boolean; // used to trigger an update on challenge completion for today

  challengeState = SocialChallengeState;
  challengeUrl: string;
  motivationMessage: Document;
  showExtraneous: boolean;
  showImage: boolean;
  showMotivationMessage: boolean;
  showProgress: boolean;
  tertiaryChallengeInfo: TertiaryChallengeInfo;

  ngOnInit() {
    this.challengeUrl = `/challenges/${this.challenge?.id}`;
  }

  ngOnChanges(/* changes: SimpleChanges */) {
    if (this.challenge) {
      this.tertiaryChallengeInfo = formatTertiaryInfo(this.challenge);
      this.showExtraneous = checkShowExtraneous(this.challenge);
      this.showImage = checkShowImage(this.challenge);
      this.showProgress = checkShowProgress(this.challenge);
      this.motivationMessage = this.challenge.individualGoalCompleted
        ? this.challenge.individual.completionMessage
        : this.challenge.individual.motivation;
      this.showMotivationMessage =
        this.motivationMessage &&
        (this.challenge.individualGoalCompleted || this.challenge.status.state !== SocialChallengeState.ended);
    }
  }
}

export const MockSocialChallengeComponent = MockComponent({
  selector: 'kf-sc-social-challenge',
  inputs: ['challenge', 'challengeCompletedToday'],
});

// export so it can be tested
export function formatTertiaryInfo(challenge: SocialChallenge): TertiaryChallengeInfo {
  if (!challenge) {
    return null;
  }
  const individualStatus = challenge.individual.status;
  const groupStatus = challenge.group.status;
  switch (challenge.status.state) {
    case SocialChallengeState.active:
      if (individualStatus.enrolled) {
        return {
          ariaLabelState: `Challenge ${individualStatus.completedToday ? 'Completed Today!' : 'Not completed today'}`,
          state: individualStatus.completedToday ? 'Completed Today!' : 'Not completed today',
        };
      }
      return {
        ariaLabelState: 'Challenge Not Joined',
        state: 'Not Joined',
        separator: ' • ',
        ariaLabelStatus: `${groupStatus.participating} challenge participants`,
        status: groupStatus.participating,
        statusIcon: '/assets/icons/users-group.svg',
      };
    case SocialChallengeState.upcoming:
      return {
        ariaLabelState: `Challenge ${individualStatus.enrolled ? 'Joined' : 'Not Joined'}`,
        state: individualStatus.enrolled ? 'Joined' : 'Not Joined',
        separator: ' • ',
        ariaLabelStatus: 'Not Started',
        status: 'Not Started',
      };
    case SocialChallengeState.ended:
      return {
        ariaLabelState: 'Challenge Ended',
        state: 'Ended',
      };
    default:
      return null;
  }
}

// export so it can be tested
export function checkShowExtraneous(challenge: SocialChallenge) {
  if (!challenge) {
    return false;
  }
  return (
    !challenge.individual.status.enrolled ||
    (!challenge.individual.status.completedToday && !challenge.individualGoalCompleted)
  );
}

export function checkShowImage(challenge: SocialChallenge) {
  if (!challenge) {
    return false;
  }
  return (
    challenge.image?.url != null &&
    challenge.status.state !== SocialChallengeState.ended &&
    checkShowExtraneous(challenge)
  );
}

export function checkShowProgress(challenge: SocialChallenge) {
  if (!challenge) {
    return false;
  }
  return challenge.status.state !== SocialChallengeState.upcoming;
}
