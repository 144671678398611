import { Component, Input } from '@angular/core';
import { MockComponent } from '@kutil/test';

export interface IconAndWord {
  icon: string;
  word: string;
}

@Component({
  selector: 'kp-image-icon-list',
  templateUrl: './image-icon-list.component.html',
  styleUrls: ['./image-icon-list.component.scss'],
})
export class ImageIconListComponent {
  @Input() imageAndIcons?: IconAndWord[];
}

export const MockImageIconListComponent = MockComponent({
  selector: 'kp-image-icon-list',
  inputs: ['imageAndIcons'],
});
