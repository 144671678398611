import { Pipe, PipeTransform } from '@angular/core';
import { Invite, InviteType } from '@ktypes/models';

@Pipe({
  name: 'inviteFilter',
})
export class InviteFilterPipe implements PipeTransform {
  transform(value: Invite[], type: InviteType): Invite {
    return value?.find((invite) => invite?.type === type && invite?.available !== 0);
  }
}
