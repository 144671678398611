import { parseExpression } from 'cron-parser';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

export enum NotificationRange {
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening',
  bedtime = 'bedtime',
  action = 'action',
}

export enum DefaultNotificationCronMap {
  morning = '30 7 * * *',
  afternoon = '30 12 * * *',
  evening = '30 17 * * *',
  bedtime = '30 21 * * *',
}

export enum NotificationType {
  Reflection = 'Reflection',
  Reminder = 'Reminder',
}

export class NotificationInfo implements Deserializable<JsonObject, NotificationInfo>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public userId?: string,
    public notificationType?: NotificationType,
    public notificationRange?: string,
    public notificationSchedule?: string,
    public dateDeviceCreated?: string,
    public dateCreated?: string,
    public dateUpdated?: string,
    public dateDeleted?: string,
    public contentId?: string,
    public isEditing = false
  ) {}

  public deserialize(input: JsonObject): NotificationInfo {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.userId = input['userId'] as string;
    this.notificationType = input['notificationType'] as NotificationType;
    this.notificationRange = input['notificationRange'] as string;
    this.notificationSchedule = input['notificationSchedule'] as string;
    this.dateDeviceCreated = input['dateDeviceCreated'] as string;
    this.dateCreated = input['dateCreated'] as string;
    this.dateUpdated = input['dateUpdated'] as string;
    this.dateDeleted = input['dateDeleted'] as string;
    this.contentId = input['userContentTrackedId'] as string;
    this.isEditing = !!input['isEditing'];
    return this;
  }

  public serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['userId'] = this.userId;
    json['notificationType'] = this.notificationType;
    json['notificationRange'] = this.notificationRange;
    json['notificationSchedule'] = this.notificationSchedule;
    json['dateDeviceCreated'] = this.dateDeviceCreated;
    json['dateCreated'] = this.dateCreated;
    json['dateUpdated'] = this.dateUpdated;
    json['dateDeleted'] = this.dateDeleted;
    json['contentId'] = this.contentId;
    return json;
  }

  get interval() {
    return parseExpression(this.notificationSchedule);
  }
}
