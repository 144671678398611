<div class="permissions-dialog">
  <div class="permissions-dialog-info">
    <div class="permissions-dialog-icon" [ngClass]="{ 'desktop-icon': canEnable }">
      @if (canEnable) {
        <svg-icon src="/assets/icons/desktop-circle.svg"></svg-icon>
      }
      @if (!canEnable) {
        <svg-icon src="/assets/icons/alert-circle.svg"></svg-icon>
      }
    </div>
    {{ description | translate | async }}
    @if (canEnable) {
      <button class="button button-reverse button-pill" (click)="enableNotifications()">
        {{ 'Turn On' | translate | async }}
      </button>
    }
  </div>
</div>
