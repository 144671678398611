import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AnalyticEvent, AnalyticsBloc, AnalyticsPageName } from '@kanalytics';
import { DetailCardData, JsonObject } from '@ktypes/models';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  constructor(private _analyticsBloc: AnalyticsBloc) {}

  @Input() altText: string;
  @Input() analyticsPageName: AnalyticsPageName;
  @Input() detailCardData: DetailCardData;
  @Input() imageUrl: string;
  @Input() primary = true;
  @Input() title: string;
  @Input() url: string;

  @Output() stateChanged = new EventEmitter<boolean>();
  @Output() progressPercentageChanged = new EventEmitter<number>();

  @ViewChild('player') player: ElementRef;

  isPlaying = false;

  private _analyticsJson: JsonObject = {};
  private _sentEvents: JsonObject = {};
  private _shouldPlay = false;

  get progressPercentage(): number {
    return Math.round(
      ((this.player.nativeElement as HTMLVideoElement).currentTime /
        (this.player.nativeElement as HTMLVideoElement).duration) *
        100
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.detailCardData?.previousValue && changes?.detailCardData?.currentValue) {
      this._shouldPlay = !!(changes.detailCardData.currentValue as DetailCardData).video;
    }
  }

  ngOnInit(): void {
    this._analyticsJson['title'] = this.title;
    this._analyticsJson['url'] = this.url;
    this._sentEvents[AnalyticEvent.video_start] = false;
    this._sentEvents['video_progress_25'] = false;
    this._sentEvents['video_progress_50'] = false;
    this._sentEvents['video_progress_75'] = false;
    this._sentEvents[AnalyticEvent.video_end] = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this._shouldPlay) {
        this.play();
      }
    }, 0);
  }

  ngOnDestroy() {
    if (!this._sentEvents[AnalyticEvent.video_end]) {
      this.trackVideoProgress(AnalyticEvent.video_end);
      this._sentEvents[AnalyticEvent.video_end] = true;
    }

    // This line is needed for not playing video after the component is destroyed.
    // Not destroying the player due to potential issues when clicking back to video and due to standard set at
    // https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
    (this.player.nativeElement as HTMLVideoElement).src = '';
  }

  updateTime() {
    const currentTimeInSec = (this.player.nativeElement as HTMLVideoElement).currentTime;
    if (currentTimeInSec > 0) {
      if (
        this.progressPercentage >= 0 &&
        this.progressPercentage < 25 &&
        !this._sentEvents[AnalyticEvent.video_start]
      ) {
        this.trackVideoProgress(AnalyticEvent.video_start);
        this._sentEvents[AnalyticEvent.video_start] = true;
      } else if (
        this.progressPercentage >= 25 &&
        this.progressPercentage < 50 &&
        !this._sentEvents['video_progress_25']
      ) {
        this.trackVideoProgress(AnalyticEvent.video_progress);
        this._sentEvents['video_progress_25'] = true;
      } else if (
        this.progressPercentage >= 50 &&
        this.progressPercentage < 75 &&
        !this._sentEvents['video_progress_50']
      ) {
        this.trackVideoProgress(AnalyticEvent.video_progress);
        this._sentEvents['video_progress_50'] = true;
      } else if (
        this.progressPercentage >= 75 &&
        this.progressPercentage < 95 &&
        !this._sentEvents['video_progress_75']
      ) {
        this.trackVideoProgress(AnalyticEvent.video_progress);
        this._sentEvents['video_progress_75'] = true;
      }

      this.progressPercentageChanged.emit(this.progressPercentage);
    }
  }

  play(event?: Event) {
    if (this.player) {
      this.isPlaying = true;
      (this.player.nativeElement as HTMLVideoElement).play();
      this.stateChanged.emit(true);
      if (['keyup', 'keydown'].includes(event?.type)) {
        event.preventDefault();
      }
    }
  }

  ended() {
    if (!this._sentEvents[AnalyticEvent.video_end]) {
      this.trackVideoProgress(AnalyticEvent.video_end);
      this._sentEvents[AnalyticEvent.video_end] = true;
    }
    (this.player.nativeElement as HTMLVideoElement).currentTime = 0;
    this.stateChanged.emit(false);
  }

  playStarted() {
    this.stateChanged.emit(true);
  }

  paused() {
    this.stateChanged.emit(false);
  }

  trackVideoProgress(analyticEvent: AnalyticEvent) {
    this._analyticsJson['progress'] = this.progressPercentage;
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromInteraction({
        page: this.analyticsPageName,
        event: analyticEvent,
        meta: this._analyticsJson,
      })
    );
  }
}

export const MockVideoPlayerComponent = MockComponent({
  selector: 'kui-video-player',
  inputs: ['altText', 'analyticsPageName', 'detailCardData', 'imageUrl', 'primary', 'title', 'url'],
});
