import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class SubscriptionAccount implements Deserializable<JsonObject, SubscriptionAccount> {
  constructor(public url?: string) {}

  deserialize(input: JsonObject): SubscriptionAccount {
    if (input == null) {
      return null;
    }

    this.url = input['url'] as string;

    return this;
  }
}
