import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { Focus } from '@kp/focus/focus.model';
import { DataStoreService } from '@kservice';
import { DataStatus, Status, StatusMessage } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FocusApi extends BaseApi {
  constructor(
    private client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, _environmentVariablesService);
  }

  async getFocus(): Promise<DataStatus<Focus>> {
    const url = this.buildUrl('/focus', true);
    const request$ = this.performRequest<Focus>(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Focus>) => {
        if (response?.ok) {
          return new DataStatus<Focus>(
            Status.done,
            new StatusMessage(Status.done, ''),
            new Focus().deserialize(response.body)
          );
        }
        return new DataStatus<Focus>(Status.error, new StatusMessage(Status.error, ''), null);
      }),
      catchError((err) => {
        console.warn('Failed getting focus: ', err);
        return of(new DataStatus<Focus>(Status.error, new StatusMessage(Status.done, ''), null));
      })
    );
    return firstValueFrom(request$).catch((error) => {
      console.warn(`Error getting focus: `, error);
      return new DataStatus<Focus>(Status.error, new StatusMessage(Status.error, ''), null);
    });
  }
}
