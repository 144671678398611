import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTimeUtil, DaysWeek } from '@kutil';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-action-reminder-diamond',
  templateUrl: './action-reminder-diamond.component.html',
  styleUrls: ['./action-reminder-diamond.component.scss'],
})
export class ActionReminderDiamondComponent {
  @Input() day: DaysWeek;
  @Input() isSelected?: boolean;

  dateTimeUtil = DateTimeUtil;

  @Output() reminderDay = new EventEmitter<DaysWeek>();
}

export const MockActionReminderDiamondComponent = MockComponent({
  selector: 'kp-action-reminder-diamond',
  inputs: ['day', 'isSelected'],
});
