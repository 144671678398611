import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { JsonObject, Settings, User } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class SettingsApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, _dataStoreService, _environmentVariablesService);
  }

  async getUserSettings(): Promise<Settings | HttpErrorResponse> {
    const request$ = this.performRequest<Settings>(RequestType.GET, this.buildUrl('/settings', true), {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Settings>): Settings => new Settings().deserialize(response?.body)),
      catchError((error) => {
        console.warn('Failed getting user settings: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error getting user settings: ', error);
      return error;
    });
  }

  async changeUserSetting(body: JsonObject): Promise<Partial<Settings> | HttpErrorResponse> {
    const request$ = this.performRequest<Partial<Settings>>(RequestType.POST, this.buildUrl('/settings', true), {
      requestBody: body,
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Partial<Settings>>): Partial<Settings> => response?.body),
      catchError((error) => {
        console.warn('Failed changing user settings: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error changing user settings: ', error);
      return error;
    });
  }

  async changeUserMfa(enabled: boolean, password: string): Promise<Partial<User> | HttpErrorResponse> {
    const requestBody: { enabled: boolean; password?: string | null } = {
      enabled,
    };
    if (password) {
      requestBody['password'] = password;
    }
    const request$ = this.performRequest<void>(RequestType.PUT, this.buildUrl('/mfa', true), {
      requestBody,
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<void>): Partial<User> => ({ id: this._userId, userMfaEnabledSetting: true })),
      catchError((error) => {
        console.warn('Failed changing user MFA setting: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error changing user MFA setting: ', error);
      return error;
    });
  }
}
