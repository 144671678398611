<div class="smileys" [class.interactable]="interactable">
  <button
    [class.non-interactable-smiley]="!interactable"
    [class.interactable-smiley]="interactable"
    (click)="selectSmiley(0)">
    <svg-icon
      [class.smiley]="!interactable"
      src="/assets/icons/smiley-sad.svg"
      [ngClass]="{ selected: selectedIndex === 0 }"
      [attr.aria-label]="'smiley 1 sad' + (selectedIndex === 0 ? ' selected' : '') | translate | async"></svg-icon>
  </button>
  <button
    [class.non-interactable-smiley]="!interactable"
    [class.interactable-smiley]="interactable"
    (click)="selectSmiley(1)">
    <svg-icon
      [class.smiley]="!interactable"
      src="/assets/icons/smiley-frown.svg"
      [ngClass]="{ selected: selectedIndex === 1 }"
      [attr.aria-label]="'smiley 2 frown' + (selectedIndex === 1 ? ' selected' : '') | translate | async"></svg-icon>
  </button>
  <button
    [class.non-interactable-smiley]="!interactable"
    [class.interactable-smiley]="interactable"
    (click)="selectSmiley(2)">
    <svg-icon
      [class.smiley]="!interactable"
      src="/assets/icons/smiley-meh.svg"
      [ngClass]="{ selected: selectedIndex === 2 }"
      [attr.aria-label]="'smiley 3 meh' + (selectedIndex === 2 ? ' selected' : '') | translate | async"></svg-icon>
  </button>
  <button
    [class.non-interactable-smiley]="!interactable"
    [class.interactable-smiley]="interactable"
    (click)="selectSmiley(3)">
    <svg-icon
      [class.smiley]="!interactable"
      src="/assets/icons/smiley-smile.svg"
      [ngClass]="{ selected: selectedIndex === 3 }"
      [attr.aria-label]="'smiley 4 smile' + (selectedIndex === 3 ? ' selected' : '') | translate | async"></svg-icon>
  </button>
  <button
    [class.non-interactable-smiley]="!interactable"
    [class.interactable-smiley]="interactable"
    (click)="selectSmiley(4)">
    <svg-icon
      [class.smiley]="!interactable"
      src="/assets/icons/smiley-grin.svg"
      [ngClass]="{ selected: selectedIndex === 4 }"
      [attr.aria-label]="'smiley 5 grin' + (selectedIndex === 4 ? ' selected' : '') | translate | async"></svg-icon>
  </button>
</div>
