<div
  class="arc-chart-wrap"
  [ngClass]="{
    'on-background': isOnBackground,
    'greyscale': greyscale
  }"
  [ngStyle]="{ height: arcSize + 'px', width: arcSize + 'px' }">
  <svg
    #arcChart
    class="arc-chart-svg"
    [attr.viewBox]="'0 0 ' + arcSize + ' ' + arcSize"
    xmlns="http://www.w3.org/2000/svg"></svg>
</div>
